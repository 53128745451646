// DescriptionSection.js
import React, { useState } from 'react';
import PropTypes from 'prop-types';

const DescriptionSection = ({ title, description, expandedIcon, collapsedIcon,isExpanded,toggleExpand,content }) => {
  
  return (
    <div className={`w-[259px] relative ${isExpanded ? 'h-[118px] text-[11px] text-[#a7a7ab]' : 'h-[48px] text-[#cebbbb]'}`}>
      <div className="absolute h-full w-full top-0 right-0 bottom-0 left-0">
      
        {/* Content for Expanded */}
        {isExpanded ? (
          <>
            <div className="absolute top-[58px] left-[calc(50%_-_115.5px)] w-[238px] min-h-screen text-[#d4d5dd]">
              <div className="absolute top-0 left-[calc(50%_-_119px)] leading-[150%] inline-block w-[238px]">
                {description}
              </div>
              {content && <div className="text-[11px] text-[#989ba1]">{content}</div>}
            </div>
            <div className="absolute h-[0.39%] w-[95.75%] top-[37.64%] right-[1.31%] bottom-[61.97%] left-[2.93%] bg-default-white border-[rgba(130,122,122,0.5)] border-t-[0.5px] border-solid box-border" />
            <div
              className="absolute h-[14.17%] w-[7.99%] top-[11.81%] right-[1.78%] bottom-[74.02%] left-[90.23%] cursor-pointer"
              onClick={toggleExpand}
            >
              <img
                className="absolute h-full w-[96.14%] top-0 right-[1.93%] bottom-0 left-[1.93%] max-w-full overflow-hidden max-h-full"
                alt="Expanded Icon"
                src={expandedIcon}
              />
            </div>
            <b className="absolute top-[12.6%] left-[3.05%] text-xs tracking-[0.01em] leading-[120%] text-default-white">
              {title}
            </b>
          </>
        ) : (
          // Content for Collapsed
          <>
          <div className="absolute h-[1.04%] w-[95.75%] top-[99.58%] right-[1.31%] bottom-[-0.62%] left-[2.93%] bg-default-white border-[rgba(130,122,122,0.5)] border-t-[0.5px] border-solid box-border" />
            <div
              className="absolute h-[37.5%] w-[7.99%] top-[68.75%] right-[-6.22%] bottom-[-6.25%] left-[98.22%] [transform:_rotate(-180deg)] [transform-origin:0_0] cursor-pointer"
              onClick={toggleExpand}
            >
              <img
                className="absolute h-full w-[96.14%] top-0 right-[1.93%] bottom-0 left-[1.93%] max-w-full overflow-hidden max-h-full [transform:_rotate(180deg)]"
                alt="Collapsed Icon"
                src={collapsedIcon}
              />
            </div>
            <div className="absolute top-[33.33%] left-[3.05%] tracking-[0.01em] leading-[120%]">
              {title}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

DescriptionSection.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  expandedIcon: PropTypes.string,
  collapsedIcon: PropTypes.string,
  content: PropTypes.string,
};

DescriptionSection.defaultProps = {
  title: 'Files and Links', // Default title for collapsed state
  description:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
  expandedIcon: '/group/16--chevrontop.svg',
  collapsedIcon: '/group/16--chevrontop1.svg',
};

export default DescriptionSection;
