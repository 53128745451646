import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Menu, X } from "lucide-react";
import axios from "axios";
import MainNavigation from "../../components/MainNavigation";
import NavBar from "../../components/NavBar";
import Rightside from "../../components/profile/Rightside";
import SettingHeader from "../../components/profile/SettingHeader";
import Loader from "../../components/Loader";

const UserPreference = () => {
    const navigate = useNavigate();
    const [userData, setUserData] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const [editedPreferences, setEditedPreferences] = useState({});
    const userId = localStorage.getItem("userId");
    const [dailyTargets, setDailyTargets] = useState([]);
    const [grades, setGrades] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isRightsideOpen, setIsRightsideOpen] = useState(false);

    // New states for learning goals
    const [availableLearningGoals, setAvailableLearningGoals] = useState([]);
    const [selectedGoals, setSelectedGoals] = useState([]);

    useEffect(() => {
        const accessToken = localStorage.getItem("accessToken");
        if (!accessToken) {
            navigate("/login");
        } else {
            fetchUserData(accessToken);
            fetchLearningGoals(accessToken);
            fetchDailyTargets(accessToken);
            fetchGrades(accessToken);
        }
    }, [navigate]);

    const fetchDailyTargets = async (token) => {
        try {
            setIsLoading(true);
            const response = await axios.get(
                "https://api.staging.sensebod.com/api/v1/preference/daily-target/",
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            // Sort daily targets by duration (assuming they follow a pattern like "X hours Y minutes")
            const sortedTargets = response.data.sort((a, b) => {
                const getMinutes = (str) => {
                    const hours = (str.match(/(\d+)\s*hour/i) || [0, 0])[1];
                    const minutes = (str.match(/(\d+)\s*minute/i) || [0, 0])[1];
                    return parseInt(hours) * 60 + parseInt(minutes);
                };
                return getMinutes(a.daily_target) - getMinutes(b.daily_target);
            });
            setDailyTargets(sortedTargets);
        } catch (error) {
            console.error("Error fetching daily targets:", error);
        } finally {
            setIsLoading(false);
        }
    };

    const fetchGrades = async (token) => {
        try {
            const response = await axios.get(
                "https://api.staging.sensebod.com/api/v1/preference/current-grade/",
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            setGrades(response.data);
        } catch (error) {
            console.error("Error fetching grades:", error);
        }
    };

    // Fetch learning goals from API
    const fetchLearningGoals = async (token) => {
        try {
            const response = await axios.get(
                "https://api.staging.sensebod.com/api/v1/preference/learning-goals/",
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            setAvailableLearningGoals(response.data);
        } catch (error) {
            console.error("Error fetching learning goals:", error);
        }
    };

    const fetchUserData = async (token) => {
        try {
            const response = await axios.get(`https://api.staging.sensebod.com/api/v1/users/${userId}/`, {
                headers: { Authorization: `Bearer ${token}` }
            });
            setUserData(response.data);
            setEditedPreferences(response.data.preferences);
            // Initialize selected goals from user data
            setSelectedGoals(response.data.preferences.learning_goals || []);
        } catch (error) {
            console.error("Error fetching user data:", error);
        }
    };

    const handleEdit = () => {
        setIsEditing(true);
    };

    const handleCancel = () => {
        setIsEditing(false);
        setEditedPreferences(userData.preferences);
        setSelectedGoals(userData.preferences.learning_goals || []);
    };

    const handleSave = async () => {
        try {
            const token = localStorage.getItem("accessToken");

            // Log the current state before creating the update
            console.log('Current editedPreferences:', editedPreferences);
            console.log('Current selectedGoals:', selectedGoals);

            const updatedData = {
                preferences: {
                    ...editedPreferences,
                    learning_goals: selectedGoals,
                    // Ensure all required fields are included
                    daily_target: editedPreferences.daily_target,
                    current_grade: editedPreferences.current_grade,
                    preferred_mentor_guidance: editedPreferences.preferred_mentor_guidance,
                    notification_preferences: editedPreferences.notification_preferences,
                    focus_subject_areas: editedPreferences.focus_subject_areas
                }
            };

            // Log the data being sent to the API
            console.log('Sending update data:', updatedData);

            const response = await axios.patch(
                `https://api.staging.sensebod.com/api/v1/users/${userId}/`,
                updatedData,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                }
            );

            // Log the API response
            console.log('API Response:', response.data);

            // Update local state with the response data
            setUserData(response.data);
            setEditedPreferences(response.data.preferences);
            setIsEditing(false);

            // Optional: Add success message
            alert('Preferences updated successfully!');

        } catch (error) {
            // Enhanced error logging
            console.error("Error updating user preferences:", error);
            console.error("Error response:", error.response?.data);

            // Show error message to user
            alert('Failed to update preferences. Please try again.');
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setEditedPreferences(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleGoalSelect = (goal) => {
        setSelectedGoals((prevSelectedGoals) =>
            prevSelectedGoals.includes(goal)
                ? prevSelectedGoals.filter((g) => g !== goal)
                : [...prevSelectedGoals, goal]
        );
    };

    const getBackgroundColor = (index) => {
        const colors = ["bg-blue-200", "bg-green-200", "bg-yellow-200", "bg-red-200", "bg-purple-200"];
        return colors[index % colors.length];
    };

    const handleArrayInputChange = (e, index, field) => {
        const { value } = e.target;
        setEditedPreferences(prevState => ({
            ...prevState,
            [field]: [
                ...prevState[field].slice(0, index),
                value,
                ...prevState[field].slice(index + 1)
            ]
        }));
    };

    const handleLogout = async () => {
        const refreshToken = localStorage.getItem("refreshToken");
        const accessToken = localStorage.getItem("accessToken");

        try {
            const response = await fetch("https://api.staging.sensebod.com/api/v1/logout/", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${accessToken}`
                },
                body: JSON.stringify({ refresh: refreshToken })
            });

            if (response.ok) {
                localStorage.clear();
                navigate("/");
            } else {
                localStorage.clear();
                navigate("/");
            }
        } catch (error) {
            console.error("Error during logout:", error);
            localStorage.clear();
            navigate("/");
        }
    };

    const handleDeactivateAccount = async () => {
        const isConfirmed = window.confirm("Are you sure you want to deactivate your account? This action cannot be undone.");

        if (isConfirmed) {
            try {
                const token = localStorage.getItem("accessToken");
                await axios.post(`https://api.staging.sensebod.com/api/v1/users/${userId}/deactivate/`, {}, {
                    headers: { Authorization: `Bearer ${token}` }
                });

                localStorage.clear();
                navigate("/account-deactivated");
            } catch (error) {
                console.error("Error deactivating account:", error);
                alert("An error occurred while trying to deactivate your account. Please try again later.");
            }
        }
    };

    const renderDailyTargetSection = () => (
        <div className="absolute top-[0px] left-[315px] w-[274px] flex flex-col items-start justify-start gap-[17px]">
            <div className="self-stretch flex flex-col items-start justify-start">
                <div className="self-stretch flex flex-col items-start justify-start gap-[4px]">
                    <div className="self-stretch flex flex-row items-center justify-start">
                        <div className="relative tracking-[-0.01em] leading-[20px]">
                            Daily Target
                        </div>
                    </div>
                    <div className="self-stretch rounded-[12px] bg-[#f5f5f5] border-[#efeefc] border-[1px] border-solid box-border h-[48px] overflow-hidden shrink-0 flex flex-row items-center justify-between py-[12px] px-[16px] text-sm">
                        {isEditing ? (
                            <select
                                name="daily_target"
                                value={editedPreferences.daily_target || ''}
                                onChange={handleInputChange}
                                className="w-full bg-transparent outline-none cursor-pointer"
                            >
                                <option value="">Select daily target</option>
                                {dailyTargets.map((target, index) => (
                                    <option key={index} value={target.daily_target}>
                                        {target.daily_target}
                                    </option>
                                ))}
                            </select>
                        ) : (
                            <div className="relative tracking-[-0.03em] leading-[20px]">
                                {userData.preferences.daily_target}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );

    const renderCurrentGradeSection = () => (
        <div className="absolute top-[200px] left-[315px] w-[274px] flex flex-col items-start justify-start gap-[17px]">
            <div className="self-stretch flex flex-col items-start justify-start">
                <div className="self-stretch flex flex-col items-start justify-start gap-[4px]">
                    <div className="self-stretch flex flex-row items-center justify-start">
                        <div className="relative tracking-[-0.01em] leading-[20px]">
                            Current Grade
                        </div>
                    </div>
                    <div className="self-stretch rounded-[12px] bg-[#f5f5f5] border-[#efeefc] border-[1px] border-solid box-border h-[48px] overflow-hidden shrink-0 flex flex-row items-center justify-between py-[12px] px-[16px] text-sm">
                        {isEditing ? (
                            <select
                                name="current_grade"
                                value={editedPreferences.current_grade || ''}
                                onChange={handleInputChange}
                                className="w-full bg-transparent outline-none cursor-pointer"
                            >
                                <option value="">Select grade</option>
                                {grades.map((grade, index) => (
                                    // Use grade.value or grade.id for the option value instead of display_name
                                    <option key={index} value={grade.value}>
                                        {grade.display_name}
                                    </option>
                                ))}
                            </select>
                        ) : (
                            <div className="relative tracking-[-0.03em] leading-[20px]">
                                {/* Find and display the display_name that matches the current grade value */}
                                {grades.find(g => g.value === userData.preferences.current_grade)?.display_name ||
                                    userData.preferences.current_grade}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );

    const renderPreferenceField = (label, content, helpText = "", fieldHeight = "h-[48px]") => (
        <div className="w-full flex flex-col gap-1 mb-6">
            <div className="flex items-center justify-between">
                <div className="text-xs tracking-[-0.01em] leading-[20px] text-[#939699]">
                    {label}
                </div>
                {helpText && (
                    <div className="text-[10px] text-gray-500">
                        {helpText}
                    </div>
                )}
            </div>
            <div className={`w-full text-gray-500 rounded-[12px] bg-[#f5f5f5] border-[#efeefc] border border-solid ${fieldHeight} overflow-y-auto`}>
                {content}
            </div>
        </div>
    );

    if (!userData) return <Loader duration={10000} />;

    return (
        <div className="w-full relative bg-[#fbf1f1] min-h-screen overflow-x-hidden text-left text-xl text-default-white font-poppins">
            <NavBar />

            {/* Desktop Navigation */}
            <div className="hidden lg:block">
                <MainNavigation
                    profileClass="left-[-29px] rounded-tl-[0px] rounded-tr-11xl rounded-br-11xl rounded-bl-[0px] bg-buttonselect border-buttonselect border-[2px] border-solid box-border text-default-white"
                />
            </div>

            {/* Mobile Navigation */}
            <div className="lg:hidden px-4">
                <MainNavigation
                    profileClass="rounded-tl-[0px] rounded-tr-11xl rounded-br-11xl rounded-bl-[0px] bg-buttonselect border-buttonselect border-[2px] border-solid box-border text-default-white"
                />
            </div>

            {/* Menu Button for Mobile */}
            <button
                onClick={() => setIsRightsideOpen(!isRightsideOpen)}
                className="fixed right-4 top-20 z-[10001] lg:hidden bg-buttonselect p-2 rounded-full"
            >
                {isRightsideOpen ? (
                    <X className="w-6 h-6 text-white" />
                ) : (
                    <Menu className="w-6 h-6 text-white" />
                )}
            </button>

            {/* Profile Title */}
            <div className="lg:absolute lg:top-[116px] lg:left-[calc(50%_-_59px)] text-[32px] leading-[150%] font-semibold text-[#0d0c0c] text-center lg:text-left mt-14 lg:mt-0">
                Profile
            </div>

            <div className="w-full lg:absolute lg:h-[973px] overflow-hidden text-left text-sm text-default-white font-poppins">
                {/* Mobile View */}
                <div className="lg:hidden px-4 py-6">
                    {/* Mobile Breadcrumb */}
                    <div className="flex flex-wrap gap-2 text-[#92929d] font-menu-menu-1-bold text-sm mb-6">
                        <div className="flex items-center gap-1">
                            <span className="tracking-[0.01em] leading-[130%] font-semibold">Profiles</span>
                            <img
                                className="w-4 h-4"
                                alt=""
                                src="/profile/16--chevronright.svg"
                            />
                        </div>
                        <div className="flex items-center gap-1">
                            <span className="tracking-[0.01em] leading-[130%] font-semibold">Settings</span>
                            <img
                                className="w-4 h-4"
                                alt=""
                                src="/profile/16--chevronright.svg"
                            />
                        </div>
                        <div className="flex items-center">
                            <span className="tracking-[0.01em] leading-[130%] font-semibold">Password</span>
                        </div>
                    </div>

                    <SettingHeader preference="text-[#232360]" />

                    {/* Mobile Edit/Save/Cancel Buttons */}
                    <div className="flex justify-end mb-6">
                        {isEditing ? (
                            <div className="flex gap-4">
                                <button
                                    onClick={handleCancel}
                                    className="rounded-xl bg-default-white px-6 py-3"
                                >
                                    <span className="text-black font-semibold">Cancel</span>
                                </button>
                                <button
                                    onClick={handleSave}
                                    className="rounded-xl bg-buttonselect px-6 py-3"
                                >
                                    <span className="font-semibold">Save</span>
                                </button>
                            </div>
                        ) : (
                            <button
                                onClick={handleEdit}
                                className="flex items-center gap-2 bg-background-main text-white rounded-[5px] px-4 py-2"
                            >
                                <img className="w-3.5 h-3.5" alt="" src="/profile/edit.svg" />
                                <span className="text-xs font-medium">Edit</span>
                            </button>
                        )}
                    </div>

                    {/* Mobile Form Fields */}
                    <div className="space-y-6">
                        {/* Learning Goals */}
                        {renderPreferenceField(
                            "Learning Goals",
                            <div className="p-3">
                                {isEditing ? (
                                    <div className="w-full space-y-2">
                                        {availableLearningGoals.map((goal, index) => (
                                            <div
                                                key={index}
                                                onClick={() => handleGoalSelect(goal.learning_goal)}
                                                className={`flex items-center gap-2 p-2 bg-white rounded-md border border-gray-100 cursor-pointer transition-all duration-200 ease-in-out hover:border-[#055db2]`}
                                            >
                                                <div className={`w-2 h-2 rounded-full ${selectedGoals.includes(goal.learning_goal)
                                                        ? 'bg-[#055db2]'
                                                        : 'bg-gray-300'
                                                    }`}></div>
                                                <span className="text-sm text-gray-700">{goal.learning_goal}</span>
                                            </div>
                                        ))}
                                    </div>
                                ) : (
                                    <div className="w-full space-y-2">
                                        {userData.preferences.learning_goals.map((goal, index) => (
                                            <div
                                                key={index}
                                                className="flex items-center gap-2 p-2 bg-white rounded-md border border-gray-100"
                                            >
                                                <div className="w-2 h-2 rounded-full bg-[#055db2]"></div>
                                                <span className="text-sm text-gray-700">{goal}</span>
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>,
                            isEditing ? "Select multiple goals" : "",
                            "min-h-[148px]"
                        )}

                        {/* Focus Subject Areas */}
                        {renderPreferenceField(
                            "Focus Subject Areas",
                            <div className="p-4">
                                {isEditing ? (
                                    <div className="space-y-2">
                                        {editedPreferences.focus_subject_areas.map((subject, index) => (
                                            <input
                                                key={index}
                                                type="text"
                                                value={subject}
                                                onChange={(e) => handleArrayInputChange(e, index, 'focus_subject_areas')}
                                                className="w-full bg-transparent outline-none"
                                            />
                                        ))}
                                    </div>
                                ) : (
                                    <div className="space-y-1">
                                        {userData.preferences.focus_subject_areas.map((subject, index) => (
                                            <div key={index}>• {subject}</div>
                                        ))}
                                    </div>
                                )}
                            </div>,
                            "",
                            "h-[148px]"
                        )}

                        {/* Daily Target */}
                        {renderPreferenceField(
                            "Daily Target",
                            <div className="p-4">
                                {isEditing ? (
                                    <select
                                        name="daily_target"
                                        value={editedPreferences.daily_target || ''}
                                        onChange={handleInputChange}
                                        className="w-full bg-transparent outline-none"
                                    >
                                        <option value="">Select daily target</option>
                                        {dailyTargets.map((target, index) => (
                                            <option key={index} value={target.daily_target}>
                                                {target.daily_target}
                                            </option>
                                        ))}
                                    </select>
                                ) : (
                                    <div>{userData.preferences.daily_target}</div>
                                )}
                            </div>
                        )}
                        {/* Preferred Mentor Guidance */}
                        {renderPreferenceField(
                            "Preferred Mentor Guidance",
                            <div className="p-4">
                                {isEditing ? (
                                    <select
                                        name="preferred_mentor_guidance"
                                        value={editedPreferences.preferred_mentor_guidance || ''}
                                        onChange={handleInputChange}
                                        className="w-full bg-transparent outline-none "
                                    >
                                        <option value="BOTH">Both</option>
                                        <option value="AI Mentor">AI</option>
                                        <option value="Human Mentor">Human</option>
                                    </select>
                                ) : (
                                    <div>{userData.preferences.preferred_mentor_guidance}</div>
                                )}
                            </div>
                        )}

                        {/* Current Grade */}
                        {renderPreferenceField(
                            "Current Grade",
                            <div className="p-4">
                                {isEditing ? (
                                    <select
                                        name="current_grade"
                                        value={editedPreferences.current_grade || ''}
                                        onChange={handleInputChange}
                                        className="w-full bg-transparent outline-none"
                                    >
                                        <option value="">Select grade</option>
                                        {grades.map((grade, index) => (
                                            <option key={index} value={grade.value}>
                                                {grade.display_name}
                                            </option>
                                        ))}
                                    </select>
                                ) : (
                                    <div>
                                        {grades.find(g => g.value === userData.preferences.current_grade)?.display_name ||
                                            userData.preferences.current_grade}
                                    </div>
                                )}
                            </div>
                        )}

                        {/* Notification Preferences */}
                        {renderPreferenceField(
                            "Notification Preferences",
                            <div className="p-4">
                                {isEditing ? (
                                    <select
                                        name="notification_preferences"
                                        value={editedPreferences.notification_preferences || ''}
                                        onChange={handleInputChange}
                                        className="w-full bg-transparent outline-none"
                                    >
                                        <option value="ALL">All</option>
                                        <option value="NONE">None</option>
                                        <option value="IMPORTANT">Important Only</option>
                                    </select>
                                ) : (
                                    <div>{userData.preferences.notification_preferences}</div>
                                )}
                            </div>
                        )}

                        {/* Mobile Action Buttons */}
                        <div className="flex flex-col gap-4 mt-8">
                            <button
                                onClick={handleLogout}
                                className="w-full rounded-xl bg-buttonselect py-3 text-center"
                            >
                                <span className="tracking-[-0.01em] leading-[20px] font-semibold">
                                    Log out
                                </span>
                            </button>
                            <button
                                onClick={handleDeactivateAccount}
                                className="w-full rounded-xl bg-[#df4559] py-3 text-center"
                            >
                                <span className="tracking-[-0.01em] leading-[20px] font-semibold">
                                    Deactivate Account
                                </span>
                            </button>
                        </div>
                    </div>
                </div>

                {/* Desktop View - Exact Original Layout */}
                <div className="hidden lg:block">
                <div className="absolute top-[122px] left-[285px] flex flex-row items-start justify-start gap-[9px] text-[#92929d] font-menu-menu-1-bold">
                        <div className="flex flex-row items-center justify-start gap-[8px]">
                            <div className="w-[16px] relative h-[16px]">
                                <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded-[2px] opacity-[0]" />
                                <img
                                    className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] max-w-full overflow-hidden max-h-full"
                                    alt=""
                                    src="/profile/16--chevronright.svg"
                                />
                            </div>
                            <div className="relative tracking-[0.01em] leading-[130%] font-semibold">
                                Profiles
                            </div>
                        </div>

                        <div className="flex flex-row items-center justify-start gap-[8px]">
                            <div className="w-[16px] relative h-[16px]">
                                <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded-[2px] opacity-[0]" />
                                <img
                                    className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] max-w-full overflow-hidden max-h-full"
                                    alt=""
                                    src="/profile/16--chevronright.svg"
                                />
                            </div>
                            <div className="flex flex-row items-center justify-center">
                                <div className="relative tracking-[0.01em] leading-[130%] font-semibold">
                                    Settings
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-row items-center justify-start gap-[8px]">
                            <div className="w-[16px] relative h-[16px]">
                                <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded-[2px] opacity-[0]" />
                                <img
                                    className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] max-w-full overflow-hidden max-h-full"
                                    alt=""
                                    src="/profile/16--chevronright.svg"
                                />
                            </div>
                            <div className="flex flex-row items-center justify-center">
                                <div className="relative tracking-[0.01em] leading-[130%] font-semibold">
                                    Preferences
                                </div>
                            </div>
                        </div>
                    </div>

                    <SettingHeader preference="text-[#232360]" />

                    {/* Details of the Preference */}
                    <div className="absolute top-[281px] left-[304px] w-[589px] h-[603px] text-xs text-[#939699]">
                        <div className="absolute top-[0px] left-[0px] w-[589px] h-[603px]">
                            {/* Learning Goals */}
                            <div className="absolute top-[0px] left-[0px] w-[274px] flex flex-col items-start justify-start gap-[17px]">
                                <div className="self-stretch flex flex-col items-start justify-start">
                                    <div className="self-stretch flex flex-col items-start justify-start gap-[4px]">
                                        <div className="self-stretch flex flex-row items-center justify-between">
                                            <div className="relative tracking-[-0.01em] leading-[20px]">
                                                Learning Goals
                                            </div>
                                            {isEditing && (
                                                <div className="text-[10px] text-gray-500">
                                                    Select multiple goals
                                                </div>
                                            )}
                                        </div>
                                        <div className="self-stretch rounded-[12px] bg-[#f5f5f5] border-[#efeefc] border-[1px] border-solid box-border min-h-[148px] overflow-hidden shrink-0 flex flex-col items-start justify-start p-3 text-sm">
                                            {isEditing ? (
                                                <div className="w-full space-y-2">
                                                    {availableLearningGoals.map((goal, index) => (
                                                        <div
                                                            key={index}
                                                            onClick={() => handleGoalSelect(goal.learning_goal)}
                                                            className={`flex items-center gap-2 p-2 bg-white rounded-md border border-gray-100 cursor-pointer transition-all duration-200 ease-in-out hover:border-[#055db2]`}
                                                        >
                                                            <div className={`w-2 h-2 rounded-full ${selectedGoals.includes(goal.learning_goal)
                                                                ? 'bg-[#055db2]'
                                                                : 'bg-gray-300'
                                                                }`}></div>
                                                            <span className="text-sm text-gray-700">{goal.learning_goal}</span>
                                                        </div>
                                                    ))}
                                                </div>
                                            ) : (
                                                <div className="w-full space-y-2">
                                                    {userData.preferences.learning_goals.map((goal, index) => (
                                                        <div
                                                            key={index}
                                                            className="flex items-center gap-2 p-2 bg-white rounded-md border border-gray-100"
                                                        >
                                                            <div className="w-2 h-2 rounded-full bg-[#055db2]"></div>
                                                            <span className="text-sm text-gray-700">{goal}</span>
                                                        </div>
                                                    ))}
                                                    {userData.preferences.learning_goals.length === 0 && (
                                                        <div className="text-gray-500 text-sm italic">
                                                            No learning goals selected
                                                        </div>
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* Focus Subject Areas */}
                            <div className={`absolute ${isEditing ? 'top-[300px]' : 'top-[250px]'} left-[0px] w-[274px] flex flex-col items-start justify-start gap-[17px]`}>
                                <div className="self-stretch flex flex-col items-start justify-start">
                                    <div className="self-stretch flex flex-col items-start justify-start gap-[4px]">
                                        <div className="self-stretch flex flex-row items-center justify-start">
                                            <div className="relative tracking-[-0.01em] leading-[20px]">
                                                Focus Subject Areas
                                            </div>
                                        </div>
                                        <div className="self-stretch rounded-[12px] bg-[#f5f5f5] border-[#efeefc] border-[1px] border-solid box-border h-[148px] overflow-hidden shrink-0 flex flex-col items-start justify-start py-[12px] px-[16px] text-sm">
                                            {isEditing ? (
                                                editedPreferences.focus_subject_areas.map((subject, index) => (
                                                    <input
                                                        key={index}
                                                        type="text"
                                                        value={subject}
                                                        onChange={(e) => handleArrayInputChange(e, index, 'focus_subject_areas')}
                                                        className="w-full bg-transparent mb-2"
                                                    />
                                                ))
                                            ) : (
                                                <div className="relative tracking-[-0.03em] leading-[20px]">
                                                    {userData.preferences.focus_subject_areas.map((subject, index) => (
                                                        <div key={index} className="mb-1">
                                                            • {subject}
                                                        </div>
                                                    ))}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* Daily Target */}
                            {renderDailyTargetSection()}

                            {/* Preferred Mentor Guidance */}
                            <div className="absolute top-[100px] left-[315px] w-[274px] flex flex-col items-start justify-start gap-[17px]">
                                <div className="self-stretch flex flex-col items-start justify-start">
                                    <div className="self-stretch flex flex-col items-start justify-start gap-[4px]">
                                        <div className="self-stretch flex flex-row items-center justify-start">
                                            <div className="relative tracking-[-0.01em] leading-[20px]">
                                                Preferred Mentor Guidance
                                            </div>
                                        </div>
                                        <div className="self-stretch rounded-[12px] bg-[#f5f5f5] border-[#efeefc] border-[1px] border-solid box-border h-[48px] overflow-hidden shrink-0 flex flex-row items-center justify-between py-[12px] px-[16px] text-sm">
                                            {isEditing ? (
                                                <select
                                                    name="preferred_mentor_guidance"
                                                    value={editedPreferences.preferred_mentor_guidance || ''}
                                                    onChange={handleInputChange}
                                                    className="w-full bg-transparent outline-none"
                                                >
                                                    <option value="BOTH">Both</option>
                                                    <option value="AI Mentor">AI</option>
                                                    <option value="Human Mentor">Human</option>
                                                </select>
                                            ) : (
                                                <div className="relative tracking-[-0.03em] leading-[20px]">
                                                    {userData.preferences.preferred_mentor_guidance}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* Current Grade */}
                            {renderCurrentGradeSection()}

                            {/* Notification Preferences */}
                            <div className="absolute top-[300px] left-[315px] w-[274px] flex flex-col items-start justify-start gap-[17px]">
                                <div className="self-stretch flex flex-col items-start justify-start">
                                    <div className="self-stretch flex flex-col items-start justify-start gap-[4px]">
                                        <div className="self-stretch flex flex-row items-center justify-start">
                                            <div className="relative tracking-[-0.01em] leading-[20px]">
                                                Notification Preferences
                                            </div>
                                        </div>
                                        <div className="self-stretch rounded-[12px] bg-[#f5f5f5] border-[#efeefc] border-[1px] border-solid box-border h-[48px] overflow-hidden shrink-0 flex flex-row items-center justify-between py-[12px] px-[16px] text-sm">
                                            {isEditing ? (
                                                <select
                                                    name="notification_preferences"
                                                    value={editedPreferences.notification_preferences || ''}
                                                    onChange={handleInputChange}
                                                    className="w-full bg-transparent outline-none"
                                                >
                                                    <option value="ALL">All</option>
                                                    <option value="NONE">None</option>
                                                    <option value="IMPORTANT">Important Only</option>
                                                </select>
                                            ) : (
                                                <div className="relative tracking-[-0.03em] leading-[20px]">
                                                    {userData.preferences.notification_preferences}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Desktop Edit/Save/Cancel Buttons */}
                        {isEditing ? (
                            <div className="absolute top-[-33px] left-[400px] flex flex-row gap-[17px]">
                                <div
                                    className="rounded-xl bg-default-white w-[88px] flex flex-row items-center justify-center py-[12px] px-[0px] box-border cursor-pointer"
                                    onClick={handleCancel}
                                >
                                    <div className="relative tracking-[-0.01em] leading-[20px] font-semibold">
                                        Cancel
                                    </div>
                                </div>
                                <div
                                    className="rounded-xl bg-buttonselect w-[88px] flex flex-row items-center justify-center py-[12px] px-[0px] box-border text-default-white cursor-pointer"
                                    onClick={handleSave}
                                >
                                    <div className="relative tracking-[-0.01em] leading-[20px] font-semibold">
                                        Save
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div
                                className="absolute top-[-33px] left-[529px] rounded-[5px] bg-background-main w-[60px] h-[25px] text-[11px] text-default-white cursor-pointer"
                                onClick={handleEdit}
                            >
                                <div className="absolute top-[3px] left-[11px] w-[38px] h-[20px]">
                                    <img
                                        className="absolute top-[3px] left-[0px] w-[14px] h-[14px] overflow-hidden"
                                        alt=""
                                        src="/profile/edit.svg"
                                    />
                                    <div className="absolute top-[0px] left-[18px] tracking-[-0.01em] leading-[20px] font-medium">
                                        Edit
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>

                    {/* Desktop Action Buttons */}
                    <div
                        className="absolute top-[788px] left-[285px] rounded-xl bg-buttonselect flex flex-row items-center justify-center py-[12px] px-[50px] cursor-pointer"
                        onClick={handleLogout}
                    >
                        <div className="relative tracking-[-0.01em] leading-[20px] font-semibold">
                            Log out
                        </div>
                    </div>
                    <div
                        className="absolute top-[788px] left-[669px] rounded-xl bg-[#df4559] flex flex-row items-center justify-center py-[12px] px-[50px] cursor-pointer"
                        onClick={handleDeactivateAccount}
                    >
                        <div className="relative tracking-[-0.01em] leading-[20px] font-semibold">
                            Deactivate Account
                        </div>
                    </div>
                </div>

                {/* Mobile Rightside Panel */}
                <div
                    className={`fixed right-0 top-0 h-full w-3/4 bg-[#fbf1f1] transform transition-transform duration-300 ease-in-out z-[10000] ${isRightsideOpen ? 'translate-x-0' : 'translate-x-full'
                        } pt-24 lg:hidden`}
                >
                    <Rightside isMobile={true} />
                </div>

                {/* Desktop Rightside */}
                <div className="hidden lg:block">
                    <Rightside isMobile={false} />
                </div>
            </div>

            {/* AI Chat Button */}
            <a href="/aichat" className="fixed bottom-6 right-6 lg:right-auto lg:bottom-6 lg:left-[285px] z-[10000]">
                <img
                    className="w-[50px] h-[50px] lg:w-[75px] lg:h-[75px] object-contain cursor-pointer"
                    alt="AI Chat"
                    src="/home/AIChart.svg"
                />
            </a>
        </div>
    );
};

export default UserPreference;