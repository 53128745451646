import { useState, useEffect } from "react";
import PropTypes from "prop-types";

const Accordion = ({ className = "", whatYoullLearn, items, isLastItem, isExpanded, currentItemIndex }) => {
  const [isOpen, setIsOpen] = useState(isExpanded);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    setIsOpen(isExpanded);
  }, [isExpanded]);

  return (
    <div
      className={`w-[19.438rem] relative h-auto text-left text-[1rem] text-gray-500 font-poppins-captions-bold ${className}`}
    >
      <div
        className="flex justify-between items-center cursor-pointer p-4"
        onClick={toggleAccordion}
      >
        <b className="tracking-[0.01em] leading-[120%]">{whatYoullLearn}</b>
        <img
          src={isOpen ? "/learning/ui--chevron-down1.svg" : "/learning/icon9@2x.png"}
          alt="Toggle Icon"
          className="w-[1.5rem] h-[1.5rem]"
        />
      </div>

      {!isLastItem && (
        <div className="absolute h-[1.39%] w-[91.22%] top-[99.31%] right-[4.41%] bottom-[-0.69%] left-[4.37%] bg-[#E1E1FB] border-[#E1E1FB] border-t-[1px] border-solid box-border" />
      )}

      {isOpen && (
        <div className="p-4 text-gray-700">
          {items.map((item, index) => (
            <div
              key={index}
              className={`flex flex-col mt-2 cursor-pointer ${currentItemIndex === index ? 'font-bold' : ''}`} // Bold the current item
              onClick={() => window.open(item.videoUrl, "_blank")}
            >
              <div className="flex items-center gap-2">
                <img
                  src="/learning/av--play-circle-outline@2x.png"
                  alt="Play Icon"
                  className="w-[1.5rem] h-[1.5rem]"
                />
                <span className="text-sm">{item.title}</span>
                <span className="ml-auto text-xs text-gray-500">{item.duration}</span>
              </div>
              {/* Display item details if available */}
              {/* {item.details && (
                <div className="mt-2 text-xs text-gray-600">
                  {item.details}
                </div>
              )} */}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

Accordion.propTypes = {
  className: PropTypes.string,
  whatYoullLearn: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      videoUrl: PropTypes.string.isRequired,
      duration: PropTypes.string,
      details: PropTypes.string, // Optional details field
    })
  ).isRequired,
  isLastItem: PropTypes.bool,
  isExpanded: PropTypes.bool,
  currentItemIndex: PropTypes.number, // Prop for highlighting the current item
};

export default Accordion;
