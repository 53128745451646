import Button from "./Button";
import PropTypes from "prop-types";

const CardLearning = ({
  className = "",
  title = "Title",
  subtitle = "Subtitle",
  imageSrc = "/learning/image-placeholder5@2x.png",
  lessonsInfo = "1/20 Lessons",
  buttonText = "Resume",
  progress = 13.14,
  notificationCount = 2,
  studentsCount = 20,
  link = "",
}) => {
  return (
    <div className={`relative w-full md:w-[46.25rem] bg-white rounded-lg shadow-md p-4 mb-4 ${className}`}>
      <div className="flex flex-col md:flex-row">
        <img
          className="w-full md:w-40 h-40 md:h-auto object-cover rounded-lg mb-4 md:mb-0 md:mr-4"
          alt=""
          src={imageSrc}
        />
        <div className="flex-grow">
          <h2 className="text-xl font-bold mb-2">{title}</h2>
          <p className="text-sm text-gray-600 mb-4">{subtitle}</p>
          <div className="flex items-center justify-between mb-2">
            <span className="text-xs font-bold">{lessonsInfo}</span>
            <div className="flex items-center">
              <img
                className="w-5 h-5 mr-1"
                alt=""
                src="/learning/group2.svg"
              />
              <span className="text-sm">{studentsCount}</span>
            </div>
            <div className="flex items-center">
              <img
                className="w-5 h-5 mr-1"
                alt=""
                src="/learning/group.svg"
              />
              <span className="text-sm">{notificationCount}</span>
            </div>
          </div>
          <div className="w-full h-1 bg-gray-200 rounded-full mb-4">
            <div
              className="h-full bg-primary-gold rounded-full"
              style={{ width: `${progress}%` }}
            />
          </div>
          <div className="flex justify-between items-center">
            
            <Button
              clickHere={buttonText}
              buttonPosition="relative"
              buttonBorderRadius="20px"
              buttonBackgroundColor="#055db2"
              buttonPadding="0.5rem 1rem"
              buttonWidth="auto"
              clickHereFontSize="0.75rem"
              clickHereFontFamily="Poppins"
              link={link}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

CardLearning.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  imageSrc: PropTypes.string,
  lessonsInfo: PropTypes.string,
  buttonText: PropTypes.string,
  progress: PropTypes.number,
  notificationCount: PropTypes.number,
  studentsCount: PropTypes.number,
  link: PropTypes.string,
};

export default CardLearning;