import React, { useState, useCallback, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Button from "../../components/Button";
import Progress7 from "../../components/Progress7";
import Loader from "../../components/Loader";

const PreferencesScreen11 = () => {
  const navigate = useNavigate();
  const [timeOptions, setTimeOptions] = useState([]);
  const [selectedTime, setSelectedTime] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  // Helper function to convert time strings to total minutes
  const convertToMinutes = (timeStr) => {
    const lowerStr = timeStr.toLowerCase();
    let totalMinutes = 0;

    const minuteMatch = lowerStr.match(/(\d+)\s*minute/);
    if (minuteMatch) {
      totalMinutes += parseInt(minuteMatch[1], 10);
    }

    const hourMatch = lowerStr.match(/(\d+)\s*hour/);
    if (hourMatch) {
      totalMinutes += parseInt(hourMatch[1], 10) * 60;
    }

    return totalMinutes;
  };

  // Function to fetch daily targets from the API
  const fetchDailyTargets = async () => {
    try {
      setIsLoading(true);
      const token = localStorage.getItem("accessToken");

      const response = await axios.get(
        "https://api.staging.sensebod.com/api/v1/preference/daily-target/",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      let fetchedData = response.data;

      // Sort the fetched data from smallest to largest based on duration
      fetchedData.sort((a, b) => {
        const aMinutes = convertToMinutes(a.daily_target);
        const bMinutes = convertToMinutes(b.daily_target);
        return aMinutes - bMinutes;
      });

      setTimeOptions(fetchedData);
      console.log("Time", fetchedData);
    } catch (error) {
      console.error("Error fetching daily targets:", error);
    }
    finally{
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchDailyTargets();
  }, []);

  const handleTimeSelect = (time) => {
    setSelectedTime(time);
  };

  const onButtonContainerClick = useCallback(() => {
    // Retrieve the existing preferencesData from localStorage
    const existingPreferences = JSON.parse(localStorage.getItem("preferencesData")) || {};

    // Add the selected daily target to the preferencesData
    const updatedPreferences = {
      ...existingPreferences,
      daily_target: selectedTime,
    };

    // Store the updated preferencesData back in localStorage
    localStorage.setItem("preferencesData", JSON.stringify(updatedPreferences));

    // Navigate to the next screen
    navigate("/11-preferencesscreen");
  }, [navigate, selectedTime]);

  const handleGoBack = () => {
    navigate(-1); // Navigate back to the previous screen
  };

  const handleSkip = () => {
    navigate("/11-preferencesscreen");
  }

  if (isLoading) {
    return <div><Loader duration={50000} /></div>;
  }

  return (
    <div className="min-h-screen w-full relative bg-default-white overflow-x-hidden text-left text-sm text-black font-poppins">
      {/* Background images with responsive positioning */}
      <div className="fixed inset-0 overflow-hidden pointer-events-none">
        <img
          className="absolute top-0 left-1/2 -translate-x-1/2 w-full max-w-[1933px] h-auto object-cover opacity-50"
          alt="Background pattern"
          src="/preference.svg"
        />
        <img
          className="absolute -bottom-16 -left-16 w-[300px] h-[300px] mq750:w-[400px] mq750:h-[400px] mq1280:w-[526px] mq1280:h-[526px] object-contain opacity-30"
          alt="Decorative system"
          src="/easystem-4-1@2x.png"
        />
        <img
          className="absolute -top-16 -right-16 w-[300px] h-[300px] mq750:w-[400px] mq750:h-[400px] mq1280:w-[562px] mq1280:h-[562px] object-contain opacity-30"
          alt="Decorative system"
          src="/easystem-4-22@2x.png"
        />
      </div>

      {/* Skip button - positioned at top right */}
      <div className="absolute top-6 right-6 z-20">
        <div 
          className="text-[#c3beb5] font-medium cursor-pointer hover:text-gray-600"
          onClick={() => navigate("/11-preferencesscreen")}
        >
          Skip
        </div>
      </div>

      {/* Main content container */}
      <div className="relative z-10 max-w-[1200px] mx-auto px-4 py-8 mq750:py-12">
        {/* Progress Bar and Back Button */}
        <div className="w-full max-w-[921px] mx-auto mb-8 flex items-center justify-between">
          <img
            onClick={() => navigate(-1)}
            className="w-8 h-8 cursor-pointer"
            alt="Go Back"
            src="/icon.svg"
          />
          <div className="flex-1 mx-4">
            <Progress7
              progressWidth="100%"
              progressPosition="relative"
              progressHeight="21px"
              progressTop="0"
              progressLeft="0"
              rectangleDivBackgroundColor="rgba(5, 93, 178, 0.1)"
              rectangleDivWidth="60%"
              rectangleDivRight="40%"
              rectangleDivBackgroundColor1="#f09a19"
            />
          </div>
          <div className="text-buttonselect leading-[150%]">6 of 10</div>
        </div>

        {/* Logo */}
        <div className="flex justify-center mb-8">
          <img
            className="w-24 h-24 mq750:w-32 mq750:h-32 object-contain"
            alt="Logo"
            src="/download-1-1@2x.png"
          />
        </div>

        {/* Info Box */}
        <div className="relative mx-auto max-w-[350px] mq750:max-w-[450px] mb-12">
          <img
            className="w-full h-auto"
            alt="Info background"
            src="/vector-1.svg"
          />
          <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-sm text-black font-medium p-4 w-[80%] text-center">
            Set your daily learning target to fit your schedule and goals.
          </div>
        </div>

        {/* Time Options Grid */}
        <div className="max-w-[770px] mx-auto">
          <div className="grid grid-cols-2 mq750:grid-cols-2 gap-4">
            {timeOptions.map((option, index) => (
              <div
                key={index}
                onClick={() => handleTimeSelect(option.daily_target)}
                className={`
                  cursor-pointer p-4 transition-all duration-200
                  text-left rounded-xl border-[2px] border-solid box-border border-[#efeefc]
                  hover:border-buttonselect
                  ${selectedTime === option.daily_target 
                    ? 'bg-green-100 border-green-200' 
                    : 'bg-white'
                  }
                `}
              >
                <div className="flex items-center gap-3">
                  <img className="w-5 h-5" alt="" src="/time.svg" />
                  <div className="tracking-[-0.03em] leading-[20px] font-semibold">
                    {option.daily_target}
                  </div>
                  {selectedTime === option.daily_target && (
                    <svg 
                      className="w-5 h-5 ml-auto flex-shrink-0 text-green-600" 
                      fill="none" 
                      stroke="currentColor" 
                      viewBox="0 0 24 24"
                    >
                      <path 
                        strokeLinecap="round" 
                        strokeLinejoin="round" 
                        strokeWidth="2" 
                        d="M5 13l4 4L19 7"
                      />
                    </svg>
                  )}
                </div>
              </div>
            ))}
          </div>

          {/* Next Button */}
          <div className="sticky bottom-4 mt-8 max-w-[370px] mx-auto">
            <Button
              buttonWidth="100%"
              buttonPosition="relative"
              buttonBackgroundColor="#055db2"
              buttonPadding="16px"
              button="Next"
              buttonFontSize="16px"
              buttonFontWeight="bold"
              buttonFontFamily="Poppins"
              onButtonContainerClick={onButtonContainerClick}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PreferencesScreen11;
