import React, { useState, useCallback, useEffect } from "react";
import Button from "../../components/Button";
import Progress7 from "../../components/Progress7";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const PreferencesScreen6 = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const handleGoBack = () => {
    navigate(-1); // This will navigate back to the previous screen
  };

  

  const onButtonContainerClick = useCallback(async () => {
    try {
      setIsLoading(true);
      // Retrieve data from localStorage
      const preferencesData = JSON.parse(localStorage.getItem("preferencesData"));
      const schoolData = JSON.parse(localStorage.getItem("selectedSchoolData"));
      const userId = localStorage.getItem("userId");
      
  
      // Initialize an empty patchData object
      const patchData = {};
  
      // Conditionally add school data if available
      if (schoolData) {
        if (schoolData.school) patchData.school = schoolData.school;
        if (schoolData.region) patchData.region = schoolData.region;
        if (schoolData.district) patchData.district = schoolData.district;
      }
  
      // Add preferencesData if available
      if (preferencesData) {
        patchData.preferences = preferencesData;
      }
  
      // Make sure there is some data to patch
      if (Object.keys(patchData).length === 0) {
        console.log("No data available to patch.");
        return;
      }
  
      // Make the PATCH request to update the user's data
      const response = await axios.patch(
        `https://api.staging.sensebod.com/api/v1/users/${userId}/`,
        patchData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      );
  
      console.log("Patch data sent:", patchData);
  
      if (response.status === 200) {
        console.log("User data updated successfully.");
  
        // Clear all local storage except for accessToken, userId, and refreshToken
        const accessToken = localStorage.getItem("accessToken");
        const refreshToken = localStorage.getItem("refreshToken");
        const username = localStorage.getItem("username");
  
        // Clear all local storage
        localStorage.clear();
  
        // Restore the essential items
        localStorage.setItem("accessToken", accessToken);
        localStorage.setItem("userId", userId);
        localStorage.setItem("refreshToken", refreshToken);
        localStorage.setItem("username", username);
  
        // Navigate to the home page or wherever you'd like to direct the user next
        navigate("/home");
      } else {
        console.error("Failed to update user data:", response.data);
      }
    } catch (error) {
      console.error("An error occurred while updating user data:", error);
    }
    finally {
      setIsLoading(false);
    }
  }, [navigate]);
  

  return (
    <div className="min-h-screen w-full relative bg-default-white overflow-x-hidden text-center font-poppins">
      {/* Background images with responsive positioning */}
      <div className="fixed inset-0 overflow-hidden pointer-events-none">
        <img
          className="absolute top-0 left-1/2 -translate-x-1/2 w-full max-w-[1933px] h-auto object-cover opacity-50"
          alt="Background pattern"
          src="/preference.svg"
        />
        <img
          className="absolute -bottom-16 -left-16 w-[300px] h-[300px] mq750:w-[400px] mq750:h-[400px] mq1280:w-[526px] mq1280:h-[526px] object-contain opacity-30"
          alt="Decorative system"
          src="/easystem-4-1@2x.png"
        />
        <img
          className="absolute -top-16 -right-16 w-[300px] h-[300px] mq750:w-[400px] mq750:h-[400px] mq1280:w-[562px] mq1280:h-[562px] object-contain opacity-30"
          alt="Decorative system"
          src="/easystem-4-22@2x.png"
        />
      </div>

      {/* Main content container */}
      <div className="relative z-10 max-w-[1200px] mx-auto px-4 py-8 mq750:py-12">
        {/* Progress Bar and Back Button */}
        <div className="w-full max-w-[921px] mx-auto mb-8 flex items-center justify-between">
          <img
            onClick={() => navigate(-1)}
            className="w-8 h-8 cursor-pointer"
            alt="Go Back"
            src="/icon.svg"
          />
          <div className="flex-1 mx-4">
            <Progress7
              progressWidth="100%"
              progressPosition="relative"
              progressHeight="21px"
              progressTop="0"
              progressLeft="0"
              rectangleDivBackgroundColor="rgba(5, 93, 178, 0.1)"
              rectangleDivWidth="100%"
              rectangleDivRight="0%"
              rectangleDivBackgroundColor1="#f09a19"
            />
          </div>
          <div className="text-buttonselect leading-[150%]">10 of 10</div>
        </div>

        {/* Animated Images Top Section */}
        <div className="relative mb-8">
          <img
            className="w-[200px] h-[200px] mx-auto object-cover mq750:w-[300px] mq750:h-[300px]"
            alt="Animation"
            src="/animation--1719088281184-2@2x.png"
          />
        </div>

        {/* Congratulations Text */}
        <div className="text-center mb-12">
          <h1 className="text-2xl mq750:text-3xl font-semibold text-[#3b3a36] mb-2 tracking-[-0.5px]">
            Congratulations
          </h1>
          <p className="text-sm text-[#78746d] max-w-[341px] mx-auto leading-[21px]">
            You are all set for a wonderful learning experience.
          </p>
        </div>

        {/* Main Illustration */}
        <div className="relative max-w-[600px] mx-auto mb-12">
          <img
            className="w-full h-auto object-contain"
            alt="Main illustration"
            src="/illustration@2x.png"
          />
        </div>

        {/* Decorative Animations */}
        <div className="hidden mq750:block">
          <div className="absolute top-[183px] left-0 w-[200px] h-[200px] mq1280:w-[300px] mq1280:h-[300px]">
            <img
              className="w-full h-full object-cover"
              alt="Animation left"
              src="/animation--1719088281184-1@2x.png"
            />
          </div>
          <div className="absolute top-[183px] right-0 w-[200px] h-[200px] mq1280:w-[300px] mq1280:h-[300px]">
            <img
              className="w-full h-full object-cover"
              alt="Animation right"
              src="/animation--1719088281184-1@2x.png"
            />
          </div>
        </div>

        {/* Button */}
        <div className="sticky bottom-4 mt-8 max-w-[370px] mx-auto">
          <Button
            buttonWidth="100%"
            buttonPosition="relative"
            buttonBackgroundColor="#055db2"
            buttonPadding="16px"
            button={isLoading ? "Loading..." : "Let's Dive In"}
            buttonFontSize="16px"
            buttonFontWeight="bold"
            buttonFontFamily="Poppins"
            onButtonContainerClick={onButtonContainerClick}
          />
        </div>
      </div>
    </div>
  );
};

export default PreferencesScreen6;
