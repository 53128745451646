import React, { useState, useCallback, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Button from "../../components/Button";
import Progress7 from "../../components/Progress7";

const PreferencesScreen7 = () => {
  const navigate = useNavigate();

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedGrade, setSelectedGrade] = useState("Select Grade");
  const [grades, setGrades] = useState([]);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleGradeSelect = (grade) => {
    setSelectedGrade(grade.display_name); // Use display_name for UI
    setIsDropdownOpen(false);
  };

  const fetchGrades = async () => {
    try {
      const token = localStorage.getItem("accessToken"); // Retrieve the token from localStorage

      const response = await axios.get("https://api.staging.sensebod.com/api/v1/preference/current-grade/", {
        headers: {
          Authorization: `Bearer ${token}`, // Include the token in the request headers
        },
      });
      setGrades(response.data); // Assuming the API returns an array of grades
      console.log("Grades", response.data);
    } catch (error) {
      console.error("Error fetching grades:", error);
    }
  };

  useEffect(() => {
    fetchGrades();
  }, []); // Fetch grades when the component mounts

  const onButtonContainerClick = useCallback(() => {
    // Retrieve the existing preferencesData from localStorage
    const existingPreferences = JSON.parse(localStorage.getItem("preferencesData")) || {};

    // Add the selected grade to the preferencesData
    const updatedPreferences = {
      ...existingPreferences,
      current_grade: selectedGrade,
    };

    // Store the updated preferencesData back in localStorage
    localStorage.setItem("preferencesData", JSON.stringify(updatedPreferences));

    // Navigate to the next screen
    navigate("/13-preferencesscreen");
  }, [navigate, selectedGrade]);

  const handleGoBack = () => {
    navigate(-1); // This will navigate back to the previous screen
  };

  

  const handleSkip = () => {
    navigate("/13-preferencesscreen");
  };

  return (
    <div className="min-h-screen w-full relative bg-default-white overflow-x-hidden text-left text-sm text-black font-poppins">
      {/* Background images with responsive positioning */}
      <div className="fixed inset-0 overflow-hidden pointer-events-none">
        <img
          className="absolute top-0 left-1/2 -translate-x-1/2 w-full max-w-[1933px] h-auto object-cover opacity-50"
          alt="Background pattern"
          src="/preference.svg"
        />
        <img
          className="absolute -bottom-16 -left-16 w-[300px] h-[300px] mq750:w-[400px] mq750:h-[400px] mq1280:w-[526px] mq1280:h-[526px] object-contain opacity-30"
          alt="Decorative system"
          src="/easystem-4-1@2x.png"
        />
        <img
          className="absolute -top-16 -right-16 w-[300px] h-[300px] mq750:w-[400px] mq750:h-[400px] mq1280:w-[562px] mq1280:h-[562px] object-contain opacity-30"
          alt="Decorative system"
          src="/easystem-4-22@2x.png"
        />
      </div>

      {/* Skip button - positioned at top right */}
      <div className="absolute top-6 right-6 z-20">
        <div 
          className="text-[#c3beb5] font-medium cursor-pointer hover:text-gray-600"
          onClick={handleSkip}
        >
          Skip
        </div>
      </div>

      {/* Main content container */}
      <div className="relative z-10 max-w-[1200px] mx-auto px-4 py-8 mq750:py-12">
        {/* Progress Bar and Back Button */}
        <div className="w-full max-w-[921px] mx-auto mb-8 flex items-center justify-between">
          <img
            onClick={() => navigate(-1)}
            className="w-8 h-8 cursor-pointer"
            alt="Go Back"
            src="/icon.svg"
          />
          <div className="flex-1 mx-4">
            <Progress7
              progressWidth="100%"
              progressPosition="relative"
              progressHeight="21px"
              progressTop="0"
              progressLeft="0"
              rectangleDivBackgroundColor="rgba(5, 93, 178, 0.1)"
              rectangleDivWidth="80%"
              rectangleDivRight="20%"
              rectangleDivBackgroundColor1="#f09a19"
            />
          </div>
          <div className="text-buttonselect leading-[150%]">8 of 10</div>
        </div>

        {/* Logo */}
        <div className="flex justify-center mb-8">
          <img
            className="w-24 h-24 mq750:w-32 mq750:h-32 object-contain"
            alt="Logo"
            src="/download-1-1@2x.png"
          />
        </div>

        {/* Info Box */}
        <div className="relative mx-auto max-w-[350px] mq750:max-w-[450px] mb-12">
          <img
            className="w-full h-auto"
            alt="Info background"
            src="/vector-1.svg"
          />
          <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-sm text-black font-medium p-4 w-[80%] text-center">
            Tell me your grade in school so I can tailor content specifically for your level.
          </div>
        </div>

        {/* Grade Selection Dropdown */}
        <div className="max-w-[370px] mx-auto relative">
          <div
            className={`
              relative rounded-xl bg-white border-2 border-solid border-[#efeefc] 
              h-14 cursor-pointer transition-all duration-200
              hover:border-buttonselect
              ${isDropdownOpen ? 'border-buttonselect' : ''}
            `}
            onClick={toggleDropdown}
          >
            <div className="absolute inset-0 flex items-center px-4 justify-between">
              <span className="text-base font-medium text-[#0c092a]">
                {selectedGrade}
              </span>
              <img
                className={`w-5 h-5 transition-transform duration-200 ${
                  isDropdownOpen ? "rotate-180" : ""
                }`}
                alt=""
                src="/icon1.svg"
              />
            </div>
          </div>

          {/* Dropdown Menu */}
          {isDropdownOpen && (
            <div className="absolute z-20 w-full mt-2 py-2 bg-white rounded-xl border-2 border-[#efeefc] shadow-lg max-h-[300px] overflow-y-auto">
              {grades.map((grade, index) => (
                <div
                  key={index}
                  className={`
                    px-4 py-3 cursor-pointer transition-colors duration-150
                    hover:bg-blue-50 text-[#0c092a]
                    ${selectedGrade === grade.display_name ? 'bg-blue-50' : ''}
                  `}
                  onClick={() => handleGradeSelect(grade)}
                >
                  <div className="flex items-center justify-between">
                    <span>{grade.display_name}</span>
                    {selectedGrade === grade.display_name && (
                      <svg className="w-5 h-5 text-buttonselect" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                      </svg>
                    )}
                  </div>
                </div>
              ))}
            </div>
          )}

          {/* Next Button */}
          <div className="sticky bottom-4 mt-8">
            <Button
              buttonWidth="100%"
              buttonPosition="relative"
              buttonBackgroundColor="#055db2"
              buttonPadding="16px"
              button="Next"
              buttonFontSize="16px"
              buttonFontWeight="bold"
              buttonFontFamily="Poppins"
              onButtonContainerClick={onButtonContainerClick}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PreferencesScreen7;
