import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate, useLocation } from 'react-router-dom';

const SettingHeader = ({ account = '', password, preference, privacy }) => {
  const navigate = useNavigate();
  const location = useLocation();

  // Function to check if a route is the current path
  const isActive = (path) => location.pathname === path;

  // Handlers for navigation
  const handleNavigate = (path) => () => {
    navigate(path);
  };

  const menuItems = [
    { label: 'Accounts', path: '/settings', className: account },
    { label: 'Password', path: '/password', className: password },
    { label: 'Preferences', path: '/user-preference', className: preference },
    { label: 'Privacy & Support', path: '/privacy', className: privacy },
  ];

  return (
    <div className="w-full px-4 lg:px-0 lg:absolute lg:top-[193px] lg:left-[289px] lg:w-[608px] lg:h-[21px] text-[#6a7181]">
      {/* Mobile View */}
      <div className="lg:hidden flex flex-wrap gap-4 justify-start items-center">
        {menuItems.map((item, index) => (
          <div
            key={index}
            onClick={handleNavigate(item.path)}
            className={`font-medium cursor-pointer whitespace-nowrap ${item.className} 
              ${isActive(item.path) ? 'text-[#232360]' : ''}`}
          >
            {item.label}
          </div>
        ))}
      </div>

      {/* Desktop View - Exact Original Layout */}
      <div className="hidden lg:block relative w-full h-full">
        <div className="absolute top-[0px] left-[0px] w-[608px] h-[21px]">
          <div 
            onClick={handleNavigate('/settings')} 
            className={`absolute top-[0px] left-[0px] font-medium cursor-pointer ${account}`}
          >
            Accounts
          </div>
          <div 
            onClick={handleNavigate('/password')} 
            className={`absolute top-[0px] left-[89px] font-medium cursor-pointer ${password}`}
          >
            Password
          </div>
          <div 
            onClick={handleNavigate('/user-preference')}
            className={`absolute top-[0px] left-[180px] font-medium cursor-pointer ${preference}`}
          >
            Preferences
          </div>
          <div 
            onClick={handleNavigate('/privacy')}
            className={`absolute top-[0px] left-[286px] font-medium cursor-pointer ${privacy}`}
          >
            Privacy & Support
          </div>
        </div>
      </div>

      {/* Mobile Underline Indicator */}
      {/* <div className="h-[2px] bg-[#e5e7eb] mt-4 lg:hidden">
        <div 
          className="h-full bg-[#232360] transition-all duration-300"
          style={{
            width: '25%',
            transform: `translateX(${
              isActive('/settings') ? '0%' :
              isActive('/password') ? '100%' :
              isActive('/user-preference') ? '200%' :
              isActive('/privacy') ? '300%' : '0%'
            })`
          }}
        />
      </div> */}
    </div>
  );
};

SettingHeader.propTypes = {
  account: PropTypes.string,
  password: PropTypes.string,
  preference: PropTypes.string,
  privacy: PropTypes.string,
};

export default SettingHeader;