import React, { useState } from 'react';
import PropTypes from 'prop-types';
import GroupComponent from './GroupComponent';
import SummaryCalender from "./SummaryCalender";
import useQuizData from './useQuizData';
import useEnrolledCoursesData from './useEnrolledCoursesData';

const ReportBoard = ({ className = '' }) => {
  const [date, setDate] = useState(new Date());
  const token = localStorage.getItem("accessToken");
  const { quizCount, loading: quizLoading, error: quizError } = useQuizData(token);
  const { coursesCount, loading: coursesLoading, error: coursesError } = useEnrolledCoursesData(token);

  return (
    <div
      className={`relative mq850:mb-[30px] w-full px-4 lg:px-0 lg:absolute lg:top-[0px] lg:left-[calc(50%_-_321px)] lg:w-[642px] lg:h-[397px] text-left text-sm text-default-white font-poppins mq750:w-[93%] ${className}`}
    >
      {/* Mobile Layout */}
      <div className="lg:hidden">
        <div className="mb-6">
          <SummaryCalender />
        </div>
        
        <div className="space-y-4">
          {/* First Group */}
          <div className="w-full">
            <GroupComponent
              icon="/profile/icon-2.svg"
              awardsWon="Awards Won"
              prop="2"
              courseEnrolled="Course Enrolled"
              prop1={coursesLoading ? "..." : coursesError ? "0" : coursesCount.toString()}
              icon1="/profile/icon-1.svg"
              className="w-full"
            />
          </div>

          {/* Second Group */}
          <div className="w-full">
            <GroupComponent
              icon="/profile/icon-4.svg"
              propWidth="24px"
              propOverflow="hidden"
              awardsWon="Day Streak"
              prop="1"
              courseEnrolled="Quizzes Completed"
              propLeft="13px"
              prop1={quizLoading ? "..." : quizError ? "0" : quizCount.toString()}
              icon1="/profile/icon-3.svg"
              className="w-full"
            />
          </div>

          {/* Third Group */}
          <div className="w-full">
            <GroupComponent
              icon="/profile/vector.svg"
              propWidth="15.5px"
              propOverflow="unset"
              awardsWon="Task completed"
              prop="0"
              courseEnrolled="Time Spent"
              propLeft="13px"
              prop1="2hr"
              icon1="/profile/icon-5.svg"
              className="w-full"
            />
          </div>
        </div>
      </div>

      {/* Desktop Layout */}
      <div className="hidden lg:block">
        <SummaryCalender />
        
        <div className="absolute top-[71px] left-[387px] w-[295px] h-[310px]">
          <GroupComponent
            icon="/profile/icon-2.svg"
            awardsWon="Awards Won"
            prop="2"
            courseEnrolled="Course Enrolled"
            prop1={coursesLoading ? "..." : coursesError ? "0" : coursesCount.toString()}
            icon1="/profile/icon-1.svg"
          />
          <GroupComponent
            propTop="107px"
            icon="/profile/icon-4.svg"
            propWidth="24px"
            propOverflow="hidden"
            awardsWon="Day Streak"
            propTop1="60px"
            prop="1"
            courseEnrolled="Quizzes Completed"
            propLeft="13px"
            prop1={quizLoading ? "..." : quizError ? "0" : quizCount.toString()}
            icon1="/profile/icon-3.svg"
          />
          <GroupComponent
            propTop="214px"
            icon="/profile/vector.svg"
            propWidth="15.5px"
            propOverflow="unset"
            awardsWon="Task completed"
            propTop1="60px"
            prop="0"
            courseEnrolled="Time Spent"
            propLeft="13px"
            prop1="2hr"
            icon1="/profile/icon-5.svg"
          />
        </div>
      </div>
    </div>
  );
};

ReportBoard.propTypes = {
  className: PropTypes.string,
};

export default ReportBoard;