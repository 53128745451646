import { useState, useMemo } from "react";
import PropTypes from "prop-types";

const Checkbox = ({
  className = "",
  showLabel = true,
  checkboxPosition,
  checkboxHeight,
  checkboxPadding,
  checkboxGap,
  checkboxFlex,
  boxWidth,
  boxBorder,
  boxHeight,
  smallLabelMedium,
  smallLabelFontSize,
  smallLabelFontFamily,
  smallLabelColor,
}) => {
  const [checked, setChecked] = useState(false);

  const toggleCheckbox = () => {
    setChecked(!checked);
  };

  const checkboxStyle = useMemo(() => {
    return {
      position: checkboxPosition,
      height: checkboxHeight,
      padding: checkboxPadding,
      gap: checkboxGap,
      flex: checkboxFlex,
    };
  }, [
    checkboxPosition,
    checkboxHeight,
    checkboxPadding,
    checkboxGap,
    checkboxFlex,
  ]);

  const boxStyle = useMemo(() => {
    return {
      width: boxWidth,
      border: boxBorder,
      height: boxHeight,
      backgroundColor: checked ? "#055db2" : "#fff", // Change color when checked
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer",
    };
  }, [boxWidth, boxBorder, boxHeight, checked]);

  const smallLabelStyle = useMemo(() => {
    return {
      fontSize: smallLabelFontSize,
      fontFamily: smallLabelFontFamily,
      color: smallLabelColor,
      cursor: "pointer", // Make label clickable
    };
  }, [smallLabelFontSize, smallLabelFontFamily, smallLabelColor]);

  return (
    <div
      className={`relative h-[40px] overflow-hidden flex flex-row items-center justify-center py-[8px] px-[0px] box-border gap-[8px] text-left text-xs text-[#333] font-poppins ${className}`}
      style={checkboxStyle}
      onClick={toggleCheckbox} // Toggle checkbox on click
    >
      <div
        className="w-[24px] relative rounded-[8px] border-[#ccc] border-[1px] border-solid box-border h-[24px]"
        style={boxStyle}
      >
        {checked && (
          <svg
            className="w-[16px] h-[16px] text-white"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M5 13l4 4L19 7"
            ></path>
          </svg>
        )}
      </div>
      {showLabel && (
        <div className="flex flex-row items-center justify-center">
          <b className="relative" style={smallLabelStyle}>
            {smallLabelMedium}
          </b>
        </div>
      )}
    </div>
  );
};

Checkbox.propTypes = {
  className: PropTypes.string,
  showLabel: PropTypes.bool,
  smallLabelMedium: PropTypes.string,

  /** Style props */
  checkboxPosition: PropTypes.any,
  checkboxHeight: PropTypes.any,
  checkboxPadding: PropTypes.any,
  checkboxGap: PropTypes.any,
  checkboxFlex: PropTypes.any,
  boxWidth: PropTypes.any,
  boxBorder: PropTypes.any,
  boxHeight: PropTypes.any,
  smallLabelFontSize: PropTypes.any,
  smallLabelFontFamily: PropTypes.any,
  smallLabelColor: PropTypes.any,
};

export default Checkbox;