import React, { useState } from "react";
import Button from "../../components/Button";
import Progress3 from "../../components/groups/Progress3";
import { useNavigate } from "react-router-dom";
import CustomDropdown from "../../components/groups/CustomDropdown";

const GroupsPreferencesScreen = () => {
  const navigate = useNavigate();

  // State to manage form input values
  const [groupName, setGroupName] = useState("");
  const [groupType, setGroupType] = useState("");
  const [groupDescription, setGroupDescription] = useState("");

  const userId = localStorage.getItem("userId");

  // Handler to navigate to the next screen
  const handleNext = () => {
    navigate("/groups-preferences-1"); // Navigate to the next screen
  };

  // Handler to go back to the previous screen
  const handleGoBack = () => {
    navigate(-1); // Navigate back to the previous screen
  };

  // Handler for form submission
  const handleSubmit = (e) => {
    e.preventDefault(); // Prevent default form submission
    // store data to the localstorage
    localStorage.setItem("group_name", groupName);
    localStorage.setItem("group_type", groupType);
    localStorage.setItem("group_description", groupDescription);
    localStorage.setItem("created_by", userId);

    if (groupType === "subject") {
      localStorage.setItem("subject", groupName);
  }

    handleNext(); // Navigate to the next screen
  };

  return (
    <div className="w-full relative bg-default-white h-[100vh] overflow-hidden text-left text-sm text-[#686b6d] font-poppins">
      <img
        className="absolute top-[-317px] left-[-399px] w-[1276.7px] h-[1305.2px] object-contain hidden"
        alt=""
        src="/o11@2x.png"
      />
      <img
        className="absolute top-[442px] left-[calc(50%_-_950px)] w-[526.8px] h-[526.8px] object-contain"
        alt=""
        src="/easystem-4-1@2x.png"
      />
      <img
        className="absolute top-[-325px] left-[-68px] w-[1933.8px] h-[1738.8px] object-contain"
        alt=""
        src="/preference.svg"
      />
      <img
        className="absolute top-[-120px] left-[calc(50%_+_450px)] w-[562px] h-[562px] object-contain"
        alt=""
        src="/easystem-4-22@2x.png"
      />
      <div className="absolute top-[110px] left-[500px] w-[185px] h-[105px] text-center text-black">
        <img
          className="absolute top-[0px] left-[0px] rounded-[3px] w-[185px] h-[105px]"
          alt=""
          src="/vector-1.svg"
        />
        <div className="absolute top-[10px] left-[29px] leading-[150%] font-medium flex items-center justify-center w-[148px] h-[84px]">
        Hello Sallay, pleae Provide basic information about your new group.
        </div>
      </div>
      <img
        className="absolute h-[18.03%] w-[11.72%] top-[8%] right-[80.08%] bottom-[71.51%] left-[15%] max-w-full overflow-hidden max-h-full object-cover"
        alt=""
        src="/download-1-1@2x.png"
      />

      <form onSubmit={handleSubmit} className="flex flex-col items-center">
        {/* Progress Bar */}
        <div className="absolute top-[calc(50%_-_422px)] left-[calc(50%_-_385px)] w-[921px] h-[53px] text-right text-buttonselect font-[Francy]">
          <Progress3
            progressWidth="871px"
            progressPosition="absolute"
            progressHeight="21px"
            progressTop="calc(50% - 21.5px)"
            progressLeft="calc(50% - 410.5px)"
            rectangleDivBackgroundColor="rgba(5, 93, 178, 0.1)"
            rectangleDivWidth="50%"
            rectangleDivRight="90%"
            rectangleDivBackgroundColor1="#f09a19"
          />
          <div className="absolute w-[11.16%] top-[60.38%] left-[88.82%] leading-[150%] flex items-center">
            1 of 2
          </div>
          <img
            onClick={handleGoBack} // Go back when this icon is clicked
            className="absolute h-[60.38%] w-[3.47%] top-[0%] right-[96.53%] bottom-[39.62%] left-[0%] max-w-full overflow-hidden max-h-full cursor-pointer"
            alt="Go Back"
            src="/icon.svg"
          />
        </div>

        {/* Group Name Input */}
        <div className="absolute top-[261px] left-[calc(50%_-_185px)] w-[370px] flex flex-col items-start justify-start gap-[4px] text-xs">
          <label htmlFor="groupName" className="self-stretch flex flex-row items-center justify-start">
            <div className="relative tracking-[-0.01em] leading-[20px]">Group Name</div>
          </label>
          <input
            type="text"
            id="groupName"
            value={groupName}
            onChange={(e) => setGroupName(e.target.value)}
            className="self-stretch rounded-[12px] border-[#efeefc] border-[1px] border-solid box-border h-[48px] overflow-hidden shrink-0 flex flex-row items-center justify-between py-[12px] px-[16px] text-sm text-[#939699]"
            placeholder="Enter group name"
          />
        </div>

        {/* Custom Styled Group Type Dropdown */}
        <div className="absolute top-[357px] left-[calc(50%_-_185px)] w-[370px] ">
          <label htmlFor="groupType" className="block mb-2 text-sm font-medium text-gray-700">
            Group Type
          </label>
          <CustomDropdown
            value={groupType}
            onChange={(value) => setGroupType(value)}
          />
        </div>

        {/* Group Description Input */}
        <div className="absolute top-[457px] left-[calc(50%_-_185px)] w-[370px] h-[150px] flex flex-col items-start justify-start gap-[4px] text-xs">
          <label htmlFor="groupDescription" className="self-stretch flex flex-row items-center justify-start">
            <div className="relative tracking-[-0.01em] leading-[20px]">Group Description</div>
          </label>
          <textarea
            id="groupDescription"
            value={groupDescription}
            onChange={(e) => setGroupDescription(e.target.value)}
            className="self-stretch flex-1 rounded-[12px] border-[#efeefc] border-[1px] border-solid overflow-hidden flex flex-row items-center justify-between py-[12px] px-[16px] text-sm text-[#939699]"
            placeholder="Enter group description"
          />
        </div>

        {/* Next Button */}
        <Button
          buttonWidth="370px"
          buttonPosition="absolute"
          buttonBackgroundColor="#055db2"
          buttonPadding="16px 133px"
          buttonBottom="137px"
          buttonLeft="calc(50% - 185px)"
          buttonTop="unset"
          button="Next"
          buttonFontSize="16px"
          buttonFontWeight="bold"
          buttonFontFamily="Poppins"
          type="submit"
        />
      </form>
    </div>
  );
};

export default GroupsPreferencesScreen;
