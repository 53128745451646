import PropTypes from "prop-types";

const FrameComponent1 = ({ className = "", badge, iconLock }) => {
  return (
    <div
      className={`w-[80.8px] flex flex-col items-start justify-end pt-[0px] px-[0px] pb-[22.4px] box-border text-center text-xs text-[rgba(255,194,38,0.5)] font-poppins ${className}`}
    >
      <div className="self-stretch flex flex-col items-end justify-start gap-[3.8px]">
        <div className="flex flex-row items-start justify-start pt-[2.5px] pb-[12.6px] pl-[8px] pr-[6px] relative">
          <div className="h-full w-full absolute !m-[0] top-[0px] right-[0px] bottom-[0px] left-[0px] max-w-full max-h-full z-[1] flex items-center justify-center">
            <img
              className="h-full w-full overflow-hidden z-[1] object-contain absolute left-[0px] top-[8px] [transform:scale(1.248)]"
              alt=""
              src={badge}
            />
          </div>
          <img
            className="h-[65.7px] w-[65.7px] relative overflow-hidden shrink-0 z-[2]"
            loading="lazy"
            alt=""
            src={iconLock}
          />
        </div>
        <div className="self-stretch flex flex-row items-start justify-end py-[0px] pl-[10px] pr-[8px]">
          <div className="h-[22.7px] flex-1 relative tracking-[0.04em] leading-[150%] uppercase font-medium flex items-center justify-center z-[1]">
            Locked
          </div>
        </div>
      </div>
    </div>
  );
};

FrameComponent1.propTypes = {
  className: PropTypes.string,
  badge: PropTypes.string,
  iconLock: PropTypes.string,
};

export default FrameComponent1;