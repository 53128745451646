import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Loader from '../../components/Loader';

const QuizTransition = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { quizData } = location.state;

  useEffect(() => {
    // Store quiz data in localStorage
    localStorage.setItem('currentQuizData', JSON.stringify(quizData));

    // Navigate to quiz questions after a short delay
    const timer = setTimeout(() => {
      navigate("/course/ai-quiz-questions");
    }, 500);

    return () => clearTimeout(timer);
  }, [quizData, navigate]);

  return <Loader duration={500} text='Preparing Quiz...' />;
};

export default QuizTransition;