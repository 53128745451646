import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import MainNavigation from "../components/MainNavigation";
import NavBar from "../components/NavBar";

const Home3 = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // Check if the user is logged in by checking for the accessToken in localStorage
    const accessToken = localStorage.getItem("accessToken");

    // If no accessToken is found, redirect to the login page
    if (!accessToken) {
      navigate("/login"); // Replace with the actual path to your login page
    }
  }, [navigate]);


  return (
    <div className="w-full relative bg-[#fbf1f1] h-[115.375rem] overflow-x-hidden relative bg-[#fbf1f1] h-[1170px] overflow-hidden text-center text-[6px] text-default-white font-poppins">
      <img
        className="absolute top-[-317px] left-[-399px] w-[1276.7px] h-[1305.2px] object-contain hidden"
        alt=""
        src="/o@2x.png"
      />
      <img
        className="absolute top-[604px] left-[calc(50%_-_984px)] w-[556.7px] h-[556.7px] object-contain hidden"
        alt=""
        src="/easystem-4-1@2x.png"
      />
      <img
        className="absolute top-[-185px] left-[-68px] w-[1933.8px] h-[1738.8px] object-contain hidden"
        alt=""
        src="/o1@2x.png"
      />


      <img
        className="absolute top-[-120px] left-[calc(50%_+_223px)] w-[562px] h-[562px] object-contain hidden"
        alt=""
        src="/easystem-4-2@2x.png"
      />
      <img
        className="absolute top-[0px] right-[500px] max-w-full overflow-hidden h-[827px]"
        alt=""
        src="/dashboard/vector-36.svg"
      />
      <a href="/aichat" className="fixed bottom-6 left-[285px] z-[10000]">
        <img
          className="w-[75px] h-[75px] object-contain cursor-pointer"
          alt="AI Chat"
          src="/home/AIChart.svg"
        />
      </a>
      <NavBar />
  
        <div className="flex-col overflow-hidden border-solid  h-[200px] w-[300px] ">

      
     <p className=" absolute rounded-[18px] left-[280px] font-[Poppins] text-[24px] bg-[#ffff] h-[180px] w-[618px] line-height: 36px font-[600] pl-[13px]
                    text-start text-[black] top-[105px] pt-[45px]" ></p>
      
      <p className=" absolute rounded-[18px] left-[467px] font-[Poppins] text-[24px]  line-height-[28px] font-[700] pl-[13px]
                    text-start text-[black] top-[105px] pt-[40px]" >History</p>

                      
      <p className=" absolute  left-[467px] font-[Poppins] text-[16px]  line-height: 36px font-[400] pl-[13px]
                    text-start text-[#1A3651] top-[155px] pt-[40px]" >1. Introduction to History</p>

      <img
        className=" absolute top-[155px] left-[103px] w-[550px] h-[170px]  overflow-hidden"
        alt=""
        src="/home/Image Placeholder.svg"
      />

<img
        className=" absolute top-[145px] left-[765px] w-[18px] h-[20.52px]  overflow-hidden"
        alt=""
        src="/home/Group.svg"
      />
      
<p className=" absolute rounded-[18px] left-[787px] font-[Poppins] text-[14px]  line-height: 36px font-[500]
                    text-[#055DB2] top-[125px]" >2</p>

<img
        className=" absolute top-[145px] left-[825px] w-[18px] h-[22.18px]  overflow-hidden"
        alt=""
        src="/home/Group (1).svg"
      />

<p className=" absolute rounded-[18px] left-[847px] font-[Poppins] text-[14px]  line-height: 36px font-[500]
                    text-[#055DB2] top-[130px]" >20</p>

<p className=" absolute  left-[485px] font-[Poppins] text-[12px]  line-height: 36px font-[700]
                text-[#031D1B] top-[215px] pt-[40px]" >1/20 Lessons</p>

  <img
        className=" absolute top-[296px] left-[469px] w-[312px] h-[4.52px] rounded-[15px] overflow"
        alt=""
        src="/home/Rectangle 9.svg"
      />

<img
        className=" absolute top-[279px] left-[485px] w-[37px] h-[37.52px] bg-[] overflow-hidden"
        alt=""
        src="/home/Group 1000004421.svg"
      />

<a href=""><button className="absolute top-[275px] left-[790px] font-[700] font-[12px] font-[Poppins] letter-spacing-[5px] cursor-[pointer]
                   line-height-[14.4px] rounded-[20px] w-[91.52px] h-[41.54px] bg-[#055DB2] text-[#fff] overflow-hidden">Resume</button>
</a>


<p className=" absolute rounded-[18px] left-[280px] font-[Poppins] text-[24px] bg-[#ffff] h-[180px] w-[618px] line-height: 36px font-[600] pl-[13px]
                    text-start text-[black] top-[2255px] pt-[40px]"> 
</p>


<p className=" absolute rounded-[18px] left-[280px] font-[Poppins] text-[24px] bg-[#ffff] h-[220px] w-[618px] line-height: 36px font-[600] pl-[13px]
                    text-start text-[black] top-[385px] pt-[40px]" ></p>
                    
<p className=" absolute rounded-[18px] left-[467px] font-[Poppins] text-[24px] line-height: 36px font-[600] pl-[13px]
                    text-start text-[black] top-[375px] pt-[40px]" >Chemistry</p>

<img
        className=" absolute top-[435px] left-[103px] w-[550px] h-[180px]  overflow-hidden"
        alt=""
        src="/home/Rectangle 480.svg"
      />

<p className=" absolute  left-[467px] font-[Poppins] text-[16px]  line-height: 36px font-[400] pl-[13px]
                    text-start text-[#1A3651] top-[430px] pt-[40px]" >1. Introduction to History</p>

<img
        className=" absolute top-[440px] left-[765px] w-[18px] h-[20.52px]  overflow-hidden"
        alt=""
        src="/home/Group.svg"
      />
      
<p className=" absolute rounded-[18px] left-[787px] font-[Poppins] text-[14px]  line-height: 36px font-[500]
                    text-[#055DB2] top-[420px]" >2</p>

<img
        className=" absolute top-[440px] left-[825px] w-[18px] h-[22.18px]  overflow-hidden"
        alt=""
        src="/home/Group (1).svg"
      />

<p className=" absolute rounded-[18px] left-[847px] font-[Poppins] text-[14px]  line-height: 36px font-[500]
                    text-[#055DB2] top-[420px]" >20</p>

<p className=" absolute  left-[485px] font-[Poppins] text-[12px]  line-height: 36px font-[700]
                text-[#031D1B] top-[505px] pt-[40px]" >1/20 Lessons</p>

  <img
        className=" absolute top-[586px] left-[469px] w-[312px] h-[4.52px] rounded-[15px] overflow"
        alt=""
        src="/home/Rectangle 9.svg"
      />

<img
        className=" absolute top-[569px] left-[485px] w-[37px] h-[37.52px] bg-[] overflow-hidden"
        alt=""
        src="/home/Group 1000004421.svg"
      />

<a href=""><button className="absolute top-[565px] left-[790px] font-[700] font-[12px] font-[Poppins] letter-spacing-[5px] cursor-[pointer]
                   line-height-[14.4px] rounded-[20px] w-[91.52px] h-[41.54px] bg-[#055DB2] text-[#fff] overflow-hidden">Resume</button>
</a>
                    
<p className=" absolute rounded-[18px] left-[285px] font-[Poppins] text-[20px] bg-[#FFFFFF] h-[240px] w-[625px]
               text-start text-[#707070] top-[705px] pt-[75px]">  </p>
               

<p className=" absolute left-[300px] text-[18px] font-[Poppins] top-[720px] text-[#1A3651] line-height-[36px] font-[500] pt-[10px] pl-[13px]">Study Hours </p>


<p className=" absolute left-[830px] text-[20px] font-[Poppins] top-[715px] cursor-[pointer] text-[#707070] line-height-[36px] font-[500] pt-[10px] w-[60px] pl-[13px]">...</p>

<div className="absolute top-[800px] left-[335.2px] border-[#000000] opacity-[10%] border-t-[1px] border-solid box-border w-[555.8px] h-[1px] opacity-[10%]" />

<p className=" absolute top-[770px] left-[278.2px] text-[10px] font-[Poppins] cursor-[pointer] text-[#707070] line-height-[36px] font-[400] pt-[10px] w-[60px] pl-[13px]">5hr</p>


<div className="absolute top-[845px] left-[335.2px] border-[#707070] border-t-[1px] border-solid box-border w-[555.8px] h-[1px] opacity-[10%]" />

<p className=" absolute top-[815px] left-[278.2px] text-[10px] font-[Poppins] cursor-[pointer] text-[#707070] line-height-[36px] font-[400] pt-[10px] w-[60px] pl-[13px]">4hr</p>


<div className="absolute top-[890px] left-[335.2px] border-[#707070] opacity-[10%] border-t-[1px] border-solid box-border w-[555.8px] h-[1px]" />

<p className=" absolute top-[860px] left-[278.2px] text-[10px] font-[Poppins] cursor-[pointer] text-[#707070] line-height-[36px] font-[400] pt-[10px] w-[60px] pl-[13px]">3hr</p>




<div className="absolute top-[935px] left-[335.2px] border-[#707070] border-t-[1px] opacity-[10%] border-solid box-border w-[555.8px] h-[1px]" />

<p className=" absolute top-[905px] left-[278.2px] text-[10px] font-[Poppins] cursor-[pointer] text-[#707070] line-height-[36px] font-[400] pt-[10px] w-[60px] pl-[13px]">2hr</p>


<div className="absolute top-[975px] left-[335.2px] border-[#707070] border-t-[1px] opacity-[10%] border-solid box-border w-[555.8px] h-[1px]" />

<p className=" absolute top-[945px] left-[278.2px] text-[10px] font-[Poppins] cursor-[pointer] text-[#707070] line-height-[36px] font-[400] pt-[10px] w-[60px] pl-[13px]">1hr</p>



<div className="absolute top-[1010px] left-[335.2px] border-[#707070] border-t-[1px] opacity-[10%] border-solid box-border w-[555.8px] h-[1px]" />

  
<p className="absolute top-[1005px] left-[375.2px] text-[10px] font-[400] font-[Poppins] cursor-[pointer] text-[#707070]">June</p>
<p className="absolute top-[1005px] left-[435.2px] text-[10px] font-[400] font-[Poppins] cursor-[pointer] text-[#707070]">July</p>
<p className="absolute top-[1005px] left-[495.2px] text-[10px] font-[400] font-[Poppins] cursor-[pointer] text-[#707070]">August</p>
<p className="absolute top-[1005px] left-[565.2px] text-[10px] font-[400] font-[Poppins] cursor-[pointer] text-[#707070]">Sept</p>
<p className="absolute top-[1005px] left-[625.2px] text-[10px] font-[400] font-[Poppins] cursor-[pointer] text-[#707070]">Oct</p>
<p className="absolute top-[1005px] left-[675.2px] text-[10px] font-[400] font-[Poppins] cursor-[pointer] text-[#707070]">Nov</p>
<p className="absolute top-[1005px] left-[735.2px] text-[10px] font-[400] font-[Poppins] cursor-[pointer] text-[#707070]">Dec</p>
<p className="absolute top-[1005px] left-[795.2px] text-[10px] font-[400] font-[Poppins] cursor-[pointer] text-[#707070]">Jan</p>
<p className="absolute top-[1005px] left-[855.2px] text-[10px] font-[400] font-[Poppins] cursor-[pointer] text-[#707070]">Feb</p>

</div>
<p className=" absolute rounded-[38px] left-[345px] font-[Poppins] text-[10px]  h-[25px] w-[8px] line-height-[7.04px] font-[600] overflow
                  bg-[#F09A19] text-[orange] top-[945px] pt-[20px] pl-[20px]"> </p>


<img
   className=" absolute top-[975px] left-[290px] w-[75px] h-[75px] "
   alt=""
   src="/home/AIChart.svg"
 />
<div className="flex-col overflow-hidden border-solid  h-[200px] w-[300px] ">

      
     <p className=" absolute rounded-[20px] left-[1148px] font-[Poppins] text-[14px] bg-[#FFFFFF] h-[75px] w-[220px] line-height: 21px font-[600] pl-[13px]
                    text-start text-[black] top-[200px] items-end pt-[20px] pl-[70px]" >You are doing better <br/>than 10% of other learners!</p>
     
     <p
        className=" absolute top-[245px] left-[1153px] w-[52px] h-[56px] text-[black] rounded-[20px] bg-[] overflow-hidden"> Rank <br/> 74
      </p>

</div>
<div className="flex-col overflow-hidden ">
<img
        className=" absolute top-[110px] left-[1253px] w-[85.71px] h-[90.23px]  overflow-hidden cursor-[pointer]"
        alt=""
        src="/home/avatar@2x.png"
      />
   </div>   


   <div className="flex-col overflow-hidden border-solid h-[200px] cursor-[pointer] w-[300px] "> 

  
<p className=" absolute rounded-[18px] left-[285px] font-[Poppins] text-[20px] bg-[#FFFFFF] h-[250px] w-[625px]
               text-start text-[#707070] top-[1045px] pt-[80px]">  </p>

<p className=" absolute left-[290px] text-[20px] top-[1055px] cursor-[pointer] text-[#707070] line-height-[36px] font-[600] pt-[10px] pl-[13px]">Courses</p>

<p className=" absolute left-[830px] text-[15px] top-[1060px] cursor-[pointer] font-[500] text-[#14B8B9] pt-[10px] ">See all</p>

 <img
   className=" absolute top-[1125px] left-[310px] w-[204px] h-[240px] rounded-[30px]  overflow-hidden"
   alt=""
   src="/home/Rectangle 476.png"
 />

 

 <p className=" absolute rounded-[25px] left-[310px] font-[Inter] text-[16px] bg-[#1A3651] h-[49.8px] w-[165px] line-height: 22px font-[500] pt-[10px] 
                text-start pl-[40px] text-[#FFFFF] top-[1290px] "> Chemistry</p>

  <p className=" absolute  left-[355px] font-[Inter] text-[12px] line-height: 22px font-weight-[400] 
                text-[#FFFFF] top-[1320px] ">WAEC</p>
</div>

    
      
<img
   className=" absolute top-[1125px] left-[550px] w-[204px] h-[240px] rounded-[30px]  overflow-hidden cursor-[pointer]"
   alt=""
   src="/home/Rectangle 480.svg"
 />
 <p className=" absolute rounded-[25px] left-[550px] font-[Inter] text-[16px] bg-[#1A3651] h-[49.8px] w-[175px] line-height: 22px font-[500] pt-[10px] 
                text-start pl-[30px] text-[#FFFFF] cursor-[pointer] top-[1290px] "> Mathematics</p>

  <p className=" absolute  left-[585px] font-[Inter] text-[12px] line-height: 22px font-weight-[400] 
                text-[#FFFFF] top-[1320px]  ">WAEC</p>

<img
   className=" absolute top-[1125px] left-[660px] w-[374px] h-[240px] rounded-[30px]  overflow object-contain cursor-[pointer]"
   alt=""
   src="/home/History-Card.svg"
 />

<p className=" absolute rounded-[18px] left-[280px] font-[Poppins] text-[20px] bg-[#FFFFFF] h-[285px] w-[621px] line-height: 36px font-[600] pl-[13px]
               text-start text-[#707070] top-[1400px] pt-[20px]"> Groups </p>


<p className=" absolute left-[850px] text-[15px] top-[1435px] cursor-[pointer] text-[#14B8B9] ">See all</p>

<img
   className=" absolute top-[1490px] left-[290px] w-[215px] h-[198px]  overflow-hidden  cursor-[pointer]"
   alt=""
   src="/home/Rectangle 476 (2).svg"
 />

<p className=" absolute top-[1585px] left-[290px] font-[Poppins] text-[11px] bg-[#1A3651] h-[85px] w-[200px] line-height: 22px font-[400] pt-[10px] pl-[15px] rounded-[10px]
                text-start  text-[#F09A19] cursor-[pointer] "> General Study Group</p>

<p className=" absolute top-[1585px] left-[470px] font-[Poppins] text-[11px]  h-[81px] w-[215px] line-height: 22px font-[400] pt-[10px] 
                text-start  text-[#FFFFF] cursor-[pointer] ">Join</p>

          

<p className=" absolute top-[1605px] left-[290px] font-[Poppins] text-[16px] line-height: 22px font-[400] pt-[10px] pl-[15px]
                text-start  text-[#fff] cursor-[pointer] "> SLGS Study Group</p>

<img
   className=" absolute top-[1665px] left-[290px] w-[31px] h-[20px] overflow object-none  cursor-[pointer]"
   alt=""
   src="/home/Icon.svg"
 />  

<p className=" absolute top-[1650px] left-[317px] font-[Poppins] text-[11px]  h-[81px] w-[215px] line-height: 22px font-[400] pt-[10px] 
                text-start  text-[#FFFFF] cursor-[pointer] ">7</p>

<p className=" absolute top-[1650px]  left-[340px] font-[Poppins] text-[11px]  h-[81px] w-[215px] line-height: 22px font-[400] pt-[10px] 
                text-start  text-[#FFFFF] cursor-[pointer] ">|</p>
<img
   className=" absolute top-[1667px]  left-[350px] w-[31px] h-[20px] overflow object-none  cursor-[pointer]"
   alt=""
   src="/home/Icon (1).svg"
 />  

<p className=" absolute top-[1650px] left-[380px] font-[Poppins] text-[11px]  h-[81px] w-[215px] line-height: 22px font-[400] pt-[10px] 
                text-start  text-[#FFFFF] cursor-[pointer] ">4.2</p>

<p className=" absolute top-[1650px] left-[410px] font-[Poppins] text-[11px]  h-[81px] w-[215px] line-height: 22px font-[400] pt-[10px] 
                text-start  text-[#FFFFF] cursor-[pointer] ">|</p>

<img
   className=" absolute top-[1667px] left-[415px] w-[31px] h-[20px] overflow object-none  cursor-[pointer]"
   alt=""
   src="/home/Icon (2).svg"
 />  

<p className=" absolute top-[1650px] left-[445px] font-[Poppins] text-[11px]  h-[81px] w-[215px] line-height: 22px font-[400] pt-[10px] 
                text-start  text-[#FFFFF] cursor-[pointer] ">Public</p>



<img
   className=" absolute top-[1490px] left-[535px] w-[215px] h-[198px]  overflow-hidden  cursor-[pointer]"
   alt=""
   src="/home/Rectangle 476 (2).svg"
 />

<p className=" absolute top-[1585px] left-[535px] font-[Poppins] text-[11px] bg-[#1A3651] h-[85px] w-[200px] line-height: 22px font-[400] pt-[10px] pl-[15px] rounded-[10px]
                text-start  text-[#F09A19] cursor-[pointer] "> Specific Subject Study Group</p>
          

<p className=" absolute top-[1605px] left-[535px] font-[Poppins] text-[16px] line-height: 22px font-[400] pt-[10px] pl-[15px]
                text-start  text-[#fff] cursor-[pointer] "> Mathematics</p>


<img
   className=" absolute top-[1665px] left-[535px] w-[31px] h-[20px] overflow object-none  cursor-[pointer]"
   alt=""
   src="/home/Icon.svg"
 />  

<p className=" absolute top-[1650px] left-[563px] font-[Poppins] text-[11px]  h-[81px] w-[215px] line-height: 22px font-[400] pt-[10px] 
                text-start  text-[#FFFFF] cursor-[pointer] ">15</p>

<p className=" absolute top-[1650px] left-[585px] font-[Poppins] text-[11px]  h-[81px] w-[215px] line-height: 22px font-[400] pt-[10px] 
                text-start  text-[#FFFFF] cursor-[pointer] ">|</p>

<img
   className=" absolute top-[1667px] left-[590px] w-[31px] h-[20px] overflow object-none  cursor-[pointer]"
   alt=""
   src="/home/Icon (1).svg"
 />  

<p className=" absolute top-[1650px] left-[620px] font-[Poppins] text-[11px]  h-[81px] w-[215px] line-height: 22px font-[400] pt-[10px] 
                text-start  text-[#FFFFF] cursor-[pointer]">3.1</p>

<p className=" absolute top-[1650px] left-[650px] font-[Poppins] text-[11px]  h-[81px] w-[215px] line-height: 22px font-[400] pt-[10px] 
                text-start  text-[#FFFFF] cursor-[pointer] ">|</p>

<img
   className=" absolute top-[1667px] left-[655px] w-[31px] h-[20px] overflow object-none  cursor-[pointer]"
   alt=""
   src="/home/Icon (2).svg"
 />  

<p className=" absolute top-[1650px] left-[685px] font-[Poppins] text-[11px]  h-[81px] w-[215px] line-height: 22px font-[400] pt-[10px] 
                text-start  text-[#FFFFF] cursor-[pointer] ">Private</p>

<img
   className=" absolute top-[1490px] left-[580px] w-[532px] h-[205px] rounded-[30px] object-none  overflow-hidden cursor-[pointer]"
   alt=""
   src="/home/Group 26086188.svg" />


 <p className=" absolute rounded-[20px] left-[1155px] font-[Poppins] text-[12.06px] bg-[#FFFFFF]  h-[305px] w-[228px] line-height-[18.09px] font-[600] 
                    text-start text-[#1A3651] top-[340px] items-end pt-[20px] pl-[50px]" >Point Progress</p>
                    
                  
  <p
  className=" absolute left-[1330px] rounded-[4px] h-[27px] w-[57px] top-[354px] text-center pr-[20px] pt-[8px] bg-[#EFF1F3] text-[#424252] font-[Poppins] font-[600] text-[12.06px]  "> Daily </p>

<img
   className=" absolute left-[1265px] top-[263px] w-[254px] h-[240px] rounded-[30px]  overflow object-none cursor-[pointer]"
   alt=""
   src="/home/Vector 13 (1).svg"
 />

<img
   className=" absolute left-[1181px] top-[355px] w-[258px] h-[240px] rounded-[30px]  overflow object-none cursor-[pointer]"
   alt=""
   src="/home/Ellipse 14 (1).svg"
 />

<img
   className=" absolute left-[1177px] top-[354px] w-[252px] h-[240px] rounded-[30px]  overflow object-none cursor-[pointer]"
   alt=""
   src="/home/Ellipse 16 (1).svg"
 />


<img
   className=" absolute left-[1125px] top-[395px] w-[254px] h-[240px] rounded-[30px]  overflow object-none cursor-[pointer]"
   alt=""
   src="/home/Vector 24.svg"
 />

 <img
   className=" absolute left-[1125px] top-[375px] w-[254px] h-[240px] rounded-[30px]  overflow object-none cursor-[pointer]"
   alt=""
   src="/home/Vector 25.svg"
 />

<img
   className=" absolute left-[1125px] top-[360px] w-[254px] h-[240px] rounded-[30px]  overflow object-none cursor-[pointer]"
   alt=""
   src="/home/Vector 26.svg"
 />

<img
   className=" absolute left-[1130px] top-[345px] w-[254px] h-[240px] rounded-[30px]  overflow object-none cursor-[pointer]"
   alt=""
   src="/home/Vector 27.svg"
 />

<img
   className=" absolute left-[1140px] top-[330px] w-[254px] h-[240px] rounded-[30px]  overflow object-none cursor-[pointer]"
   alt=""
   src="/home/Vector 28.svg"
 />

<img
   className=" absolute left-[1157px] top-[320px] w-[254px] h-[240px] rounded-[30px]  overflow object-none cursor-[pointer]"
   alt=""
   src="/home/Vector 14.svg"
 />

<img
   className=" absolute left-[1178px] top-[315px] w-[254px] h-[240px] rounded-[30px]  overflow object-none cursor-[pointer]"
   alt=""
   src="/home/Vector 15.svg"
 />

<img
   className=" absolute left-[1198px] top-[320px] w-[254px] h-[240px] rounded-[30px]  overflow object-none cursor-[pointer]"
   alt=""
   src="/home/Vector 16.svg"
 />

<img
   className=" absolute left-[125px] top-[375px] w-[254px] h-[240px] rounded-[30px]  overflow object-none cursor-[pointer]"
   alt=""
   src="/home/Vector 25.svg"
 />

<img
   className=" absolute left-[325px] top-[375px] w-[254px] h-[240px] rounded-[30px]  overflow object-none cursor-[pointer]"
   alt=""
   src="/home/Vector 25.svg"
 />

<img
   className=" absolute left-[1218px] top-[330px] w-[254px] h-[240px] rounded-[30px]  overflow object-none cursor-[pointer]"
   alt=""
   src="/home/Vector 16.svg"
 />

<img
   className=" absolute left-[1233px] top-[345px] w-[254px] h-[240px] rounded-[30px]  overflow object-none cursor-[pointer]"
   alt=""
   src="/home/Vector 18 (1).svg"
 />

<img
   className=" absolute left-[1240px] top-[363px] w-[254px] h-[240px] rounded-[30px]  overflow object-none cursor-[pointer]"
   alt=""
   src="/home/Vector 25.svg"
 />

<img
   className=" absolute left-[1240px] top-[385px] w-[254px] h-[240px] rounded-[30px]  overflow object-none cursor-[pointer]"
   alt=""
   src="/home/Vector 26.svg"
 />

<img
   className=" absolute left-[1235px] top-[405px] w-[254px] h-[240px] rounded-[30px]  overflow object-none cursor-[pointer]"
   alt=""
   src="/home/Vector 26.svg"
 />





  <input type="checkbox" className=" absolute h-[16px] w-[16px] bg-[#1A3651] border-[3px] solid left-[1175px] top-[370px] cursor-[pointer]"/>
  <img
   className=" absolute left-[1195px] top-[405px] w-[224px] h-[210px] rounded-[30px]  overflow object-none cursor-[pointer]"
   alt=""
   src="/home/Ellipse 15.svg"
 />
<p className=" absolute rounded-[20px] left-[1245px] font-[Poppins] text-[18px]  line-height-[18.09px] font-[500] 
                    text-start text-[#83868E] top-[535px] " >Your Point: </p>

<p className=" absolute rounded-[20px] left-[1335px] font-[Poppins] text-[20px]  line-height-[18.09px] font-[700] 
                    text-start text-[black] top-[530px]" >30</p>         

  <img
   className=" absolute left-[1230px] top-[590px] w-[135px] h-[55px] rounded-[10px]  overflow object-none cursor-[pointer]"
   alt=""
   src="/home/Points.svg"
 />
<img
   className=" absolute left-[1210px] top-[365px] w-[230px] h-[230px] rounded-[30px]  overflow object-none cursor-[pointer]"
   alt=""
   src="/home/Polygon 4 (1).svg"
 />

<p className=" absolute rounded-[20px] left-[1155px] font-[Poppins] text-[16px] bg-[#F9F9F9]  h-[305px] w-[258px] line-height-[22.04px] font-[500] 
                    text-start text-[#707070] top-[690px] pt-[20px] pl-[20px]" >Activities and Schedules</p>


 

<img
   className=" absolute left-[1178px] top-[780px] w-[40px] h-[40px] bg-[#FFE4C2] rounded-[8px] overflow-hidden  cursor-[pointer]"
   alt=""
   src="/home/Rectangle 14 (1).svg"
 />

<img
   className=" absolute left-[1190px] top-[790px] w-[14px] h-[16px] rounded-[30px] bg-[#FFE4C2] borderWidth-[5px] overflow-hidden  cursor-[pointer]"
   alt=""
   src="/home/Vector.svg"
 />
 <p className=" absolute rounded-[20px] left-[1205px] font-[Poppins] text-[10px]  h-[305px] w-[258px] line-height-[22.04px] font-[600] 
                    text-start text-[#707070] top-[750px] pt-[20px] pl-[20px]" >Meeting with Lorem Ipsum</p>
  
  <p className=" absolute rounded-[20px] left-[1205px] font-[Poppins] text-[10px]  h-[305px] w-[258px] line-height-[22.04px] font-[300] 
                    text-start text-[#707070] top-[775px] pt-[20px] pl-[20px]" >09:20AM</p>

<p className=" absolute rounded-[20px] left-[1255px] font-[Poppins] text-[10px]  h-[305px] w-[258px] line-height-[22.04px] font-[300] 
                    text-start text-[#FFAD47] top-[775px] pt-[20px] pl-[20px]" >Due Soon</p>
      
      <img
   className=" absolute left-[1400px] top-[790px] w-[10.11px] h-[14x] overflow-hidden  cursor-[pointer]"
   alt=""
   src="/home/16--chevronright.svg"
 />

<img
   className=" absolute left-[1178px] top-[875px] w-[40px] h-[40px] bg-[#F3E4FF] rounded-[8px] overflow-hidden  cursor-[pointer]"
   alt=""
   src="/home/Rectangle 14 (2).svg"
 />

<img
   className=" absolute left-[1190px] top-[885px] w-[14px] h-[16px] rounded-[30px] bg-[#F3E4FF] borderWidth-[5px] overflow-hidden  cursor-[pointer]"
   alt=""
   src="/home/ClipboardList.svg"
 />
 <p className=" absolute rounded-[20px] left-[1205px] font-[Poppins] text-[10px]  h-[305px] w-[258px] line-height-[22.04px] font-[600] 
                    text-start text-[#707070] top-[846px] pt-[20px] pl-[20px]" >Meeting with Lorem Ipsum</p>
  
  <p className=" absolute rounded-[20px] left-[1205px] font-[Poppins] text-[10px]  h-[305px] w-[258px] line-height-[22.04px] font-[300] 
                    text-start text-[#707070] top-[870px] pt-[20px] pl-[20px]" >04 Jan, 09:20AM</p>
  
      <img
   className=" absolute left-[1400px] top-[890px] w-[10.11px] h-[14x] overflow-hidden  cursor-[pointer]"
   alt=""
   src="/home/16--chevronright.svg"
 />

<p className=" absolute rounded-[20px] left-[1155px] font-[Poppins] text-[15px] bg-[#F9F9F9]  h-[222px] w-[294px] line-height-[22.04px] font-[500] 
                    text-start text-[#020202] top-[1050px] pt-[20px] pl-[20px]" >Completed Courses</p>

<p className=" absolute left-[1430px] font-[Poppins] text-[20px] line-height-[22.04px] font-[600] cursor-[pointer]
                   text-[#020202] top-[1060px] " >...</p>

<p className=" absolute rounded-[20px] left-[1175px] font-[Poppins] text-[14px] line-height-[22.04px] font-[500] 
                    text-start text-[#67686C] top-[1100px] " >Introduction to Python</p>

<img
   className=" absolute left-[1175px] top-[1150px] w-[229.04px] h-[14x] overflow-hidden  cursor-[pointer]"
   alt=""
   src="/home/Rectangle 5610.svg"
 />

<p className=" absolute rounded-[20px] left-[1380px] font-[Poppins] text-[14px] line-height-[22.04px] font-[500] 
                    text-start text-[#1C1E24] top-[1100px] " >100/100</p>
      <MainNavigation 
      homeClass="left-[-29px] rounded-tl-[0px] rounded-tr-11xl rounded-br-11xl rounded-bl-[0px] bg-buttonselect border-buttonselect border-[2px] border-solid box-border text-default-white"
      learningClass=""
      mentorClass="custom-mentor-class"
      groupsClass="another-custom-class"
      profileClass="custom-profile-class"
    />
    </div>
  );
};

export default Home3;
