import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog, faBell, faSignOutAlt, faUserCog } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";

const NavBar = ({ className = "" }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [totalXP, setTotalXP] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  
  const navigate = useNavigate();
  const baseUrl = window.location.origin;
  const username = localStorage.getItem("username");

  useEffect(() => {
    const checkScreenSize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    checkScreenSize();
    window.addEventListener('resize', checkScreenSize);

    return () => window.removeEventListener('resize', checkScreenSize);
  }, []);

  // Fetch user's total points
  useEffect(() => {
    const fetchUserPoints = async () => {
      setIsLoading(true);
      const accessToken = localStorage.getItem('accessToken');

      if (!accessToken || !username) {
        setError('Authentication data missing');
        setIsLoading(false);
        return;
      }

      try {
        const response = await axios.get('https://api.staging.sensebod.com/api/v1/leaderboards/national/', {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        // Find current user's data
        const userData = response.data.find(user => user.username === username);
        
        if (userData) {
          setTotalXP(parseFloat(userData.total_points));
        } else {
          console.log('User not found in leaderboard');
          setTotalXP(0);
        }
      } catch (error) {
        console.error('Error fetching user points:', error);
        setError('Failed to fetch points');
      } finally {
        setIsLoading(false);
      }
    };

    if (username) {
      fetchUserPoints();
    }
  }, [username]);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const refreshAccessToken = async () => {
    try {
      const refreshToken = localStorage.getItem("refreshToken");
      if (!refreshToken) {
        throw new Error("No refresh token available");
      }

      const response = await fetch("https://api.staging.sensebod.com/api/v1/token/refresh/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ refresh: refreshToken }),
      });

      if (response.ok) {
        const data = await response.json();
        localStorage.setItem("accessToken", data.access);
        return data.access;
      }
      return null;
    } catch (error) {
      console.error("Token refresh error:", error);
      return null;
    }
  };

  const handleLogout = async () => {
    try {
      let accessToken = localStorage.getItem("accessToken");
      const refreshToken = localStorage.getItem("refreshToken");

      // If the initial request fails due to expired token, try refreshing
      const makeLogoutRequest = async (token) => {
        try {
          const response = await fetch("https://api.staging.sensebod.com/api/v1/logout/", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "Authorization": `Bearer ${token}`
            },
            body: JSON.stringify({ refresh: refreshToken })
          });

          if (response.status === 401) {
            // Token expired, try to refresh
            const newToken = await refreshAccessToken();
            if (newToken) {
              // Retry with new token
              return makeLogoutRequest(newToken);
            }
            throw new Error("Unable to refresh token");
          }

          return response.ok;
        } catch (error) {
          console.error("Logout request error:", error);
          return false;
        }
      };

      const logoutSuccess = await makeLogoutRequest(accessToken);

      // Clear all storage and redirect regardless of logout API success
      localStorage.clear();
      sessionStorage.clear();
      
      // Clear any other stored data or state here
      // For example, if you're using any global state management:
      // dispatch(clearUserState());
      
      // Redirect to home
      navigate("/login");
    } catch (error) {
      console.error("Error during logout:", error);
      // Still clear everything and redirect on error
      localStorage.clear();
      sessionStorage.clear();
      navigate("/");
    }
  };

  const handleSettings = () => {
    console.log("Navigate to settings");
  };

  // XP Display Component
  const TotalXPDisplay = () => (
    <div className="w-[115px] relative h-[50px] text-xs text-black">
      <div className="absolute top-[0px] left-[0px] rounded-[10px] bg-default-white border-[#df4559] border-[0.5px] border-solid box-border w-[115px] h-[48px]" />
      <div className="absolute top-[3px] left-[34px]">Total XP</div>
      <b className="absolute top-[17px] left-[calc(50%_-_6.5px)] text-3xl text-transparent !bg-clip-text [background:linear-gradient(46.25deg,_#df4559,_#f09a19)] [-webkit-background-clip:text] [-webkit-text-fill-color:transparent]">
        {isLoading ? "..." : totalXP}
      </b>
    </div>
  );

  return (
    <div className={`relative ${className}`}>
      <div className="absolute top-[41px] w-full h-[50px]">
        {/* Mobile-specific content */}
        <div className={`${isMobile ? 'block' : 'hidden'} w-full px-4`}>
          {/* Total XP component - mobile */}
          <div className="absolute top-[-25px] right-4">
            <TotalXPDisplay />
          </div>

          <div className="absolute w-[calc(100%_-_354.6px)] left-[70px] top-[calc(50%_-_52px)] flex flex-row items-center justify-start mq1300:gap-[20px] gap-[100px] text-left text-base text-[#211c37] font-poppins">
            <div className="flex flex-col items-start justify-center gap-[7px]">
              <div className="flex flex-row items-center justify-start gap-[8px]">
                <div className="relative font-semibold">Hey, {username}</div>
                <img
                  className="w-[26.4px] relative h-[25.7px] object-contain"
                  alt=""
                  src={`${baseUrl}/dashboard/-emoji-waving-hand-sign@2x.png`}
                />
              </div>
              <div className="w-[170px] relative h-[10px]">
                <div className="absolute h-full w-full rounded-[24px] bg-background-main" />
                <div className="absolute h-full w-[10%] rounded-[24px] bg-primary-gold" />
              </div>
            </div>
          </div>
        </div>

        {/* Desktop content */}
        <div className={`${isMobile ? 'hidden' : 'block'}`}>
          <div className="absolute w-[calc(100%_-_354.6px)] left-[285px] top-[calc(50%_-_25px)] flex flex-row items-center justify-start mq1300:gap-[20px] gap-[100px] text-left text-base text-[#211c37] font-poppins">
            <div className="flex flex-col items-start justify-start gap-[7px]">
              <div className="flex flex-row items-center justify-start gap-[8px]">
                <div className="relative font-semibold">Hey, {username}</div>
                <img
                  className="w-[26.4px] relative h-[25.7px] object-contain"
                  alt=""
                  src={`${baseUrl}/dashboard/-emoji-waving-hand-sign@2x.png`}
                />
              </div>
              <div className="w-[208px] relative h-[10px]">
                <div className="absolute h-full w-full rounded-[24px] bg-background-main" />
                <div className="absolute h-full w-[10%] rounded-[24px] bg-primary-gold" />
              </div>
            </div>
          </div>

          <div className="absolute top-[calc(50%_-_17px)] right-[90px] h-[34px] flex items-center space-x-14">
            {/* Total XP component - desktop */}
            <TotalXPDisplay />

            <div className="relative flex items-center">
              <FontAwesomeIcon
                icon={faBell}
                className="w-[24px] h-[24px] text-gray-600 cursor-pointer"
              />
              <div className="absolute w-[10px] h-[10px] rounded-full bg-red-500 top-0 right-0 flex items-center justify-center text-xs text-white">
                1
              </div>
            </div>

            <img
              className="w-[24px] h-[24px] object-contain cursor-pointer"
              alt=""
              src={`${baseUrl}/dashboard/chatoutline.svg`}
            />

            <img
              className="w-[24px] h-[24px] object-contain cursor-pointer"
              alt=""
              src={`${baseUrl}/dashboard/badgelabelsvgrepocom-1.svg`}
            />

            <FontAwesomeIcon
              icon={faCog}
              className="w-[24px] h-[24px] text-gray-600 cursor-pointer"
              onClick={toggleDropdown}
            />

            {isDropdownOpen && (
              <div className="absolute right-0 mt-2 w-[180px] bg-[#000] rounded-lg shadow-lg z-50 border border-gray-200">
                <div
                  className="py-2 px-4 hover:bg-gray-100 cursor-pointer flex items-center"
                  onClick={handleSettings}
                >
                  <FontAwesomeIcon icon={faUserCog} className="w-5 h-5 mr-2" />
                  <span>Settings</span>
                </div>
                <div
                  className="py-2 px-4 hover:bg-red-100 hover:text-red-500 cursor-pointer flex items-center"
                  onClick={handleLogout}
                >
                  <FontAwesomeIcon icon={faSignOutAlt} className="w-5 h-5 mr-2" />
                  <span>Logout</span>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

NavBar.propTypes = {
  className: PropTypes.string,
};

export default NavBar;