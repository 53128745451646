import { useMemo } from "react";
import PropTypes from "prop-types";

const FrameComponent = ({
  className = "",
  badge,
  iconLock,
  propWidth,
  propPadding,
  propAlignSelf,
}) => {
  const frameDivStyle = useMemo(() => {
    return {
      width: propWidth,
      padding: propPadding,
    };
  }, [propWidth, propPadding]);

  const secondLockIconStyle = useMemo(() => {
    return {
      alignSelf: propAlignSelf,
    };
  }, [propAlignSelf]);

  return (
    <div
      className={`w-[80.8px] flex flex-col items-end justify-start gap-[3.8px] text-center text-xs text-[rgba(255,194,38,0.5)] font-poppins ${className}`}
      style={frameDivStyle}
    >
      <div
        className="flex flex-row items-start justify-start pt-[2.6px] pb-[12.5px] pl-[8px] pr-[6px] relative"
        style={secondLockIconStyle}
      >
        <div className="h-full w-full absolute !m-[0] top-[0px] right-[0px] bottom-[0px] left-[0px] max-w-full max-h-full z-[2] flex items-center justify-center">
          <img
            className="h-full w-full overflow-hidden z-[2] object-contain absolute left-[0px] top-[8px] [transform:scale(1.248)]"
            alt=""
            src={badge}
          />
        </div>
        <img
          className="h-[65.7px] w-[65.7px] relative overflow-hidden shrink-0 z-[3]"
          loading="lazy"
          alt=""
          src={iconLock}
        />
      </div>
      <div className="self-stretch flex flex-row items-start justify-end py-[0px] pl-[10px] pr-[8px]">
        <div className="h-[22.7px] flex-1 relative tracking-[0.04em] leading-[150%] uppercase font-medium flex items-center justify-center z-[2]">
          Locked
        </div>
      </div>
    </div>
  );
};

FrameComponent.propTypes = {
  className: PropTypes.string,
  badge: PropTypes.string,
  iconLock: PropTypes.string,

  /** Style props */
  propWidth: PropTypes.any,
  propPadding: PropTypes.any,
  propAlignSelf: PropTypes.any,
};

export default FrameComponent;