import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../components/Button";

const PreferencesScreen9 = () => {
  const navigate = useNavigate();

  const onButtonContainerClick = useCallback(() => {
    navigate("/preference-3");
  }, [navigate]);

  return (
    <div className="min-h-screen w-full bg-default-white text-center font-poppins relative overflow-x-hidden">
      {/* Background images with responsive positioning */}
      <div className="fixed inset-0 overflow-hidden pointer-events-none">
        <img
          className="absolute top-0 left-1/2 -translate-x-1/2 w-full max-w-[1933px] h-auto object-cover opacity-50"
          alt="Background pattern"
          src="/preference.svg"
        />
        <img
          className="absolute -bottom-16 -left-16 w-[300px] h-[300px] mq750:w-[400px] mq750:h-[400px] mq1280:w-[526px] mq1280:h-[526px] object-contain opacity-30"
          alt="Decorative system"
          src="/easystem-4-1@2x.png"
        />
        <img
          className="absolute -top-16 -right-16 w-[300px] h-[300px] mq750:w-[400px] mq750:h-[400px] mq1280:w-[562px] mq1280:h-[562px] object-contain opacity-30"
          alt="Decorative system"
          src="/easystem-4-22@2x.png"
        />
      </div>

      {/* Main content container */}
      <div className="relative z-10 max-w-[1200px] mx-auto px-4 py-8 mq750:py-12 mq1280:py-16">
        {/* Logo */}
        <div className="flex justify-center mb-8">
          <img
            className="w-24 h-24 mq750:w-32 mq750:h-32 mq1280:w-40 mq1280:h-40 object-contain"
            alt="Logo"
            src="/download-1-1@2x.png"
          />
        </div>

        {/* Question box */}
        <div className="relative mx-auto max-w-[350px] mq750:max-w-[450px] mb-8">
          <img
            className="w-full h-auto"
            alt="Question background"
            src="/vector-11.svg"
          />
          <p className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-sm text-black font-medium p-4 w-[80%]">
            Are you here to learn and grow, or to guide and mentor others? Please
            Choose your role to get started
          </p>
        </div>

        {/* Role selection cards */}
        <div className="space-y-4 max-w-[350px] mq750:max-w-[450px] mq1280:max-w-[600px] mx-auto mb-8">
          {/* Student card */}
          <div className="relative p-4 rounded-xl bg-buttonselect border-2 border-[#efeefc] flex items-center">
            <img
              className="w-12 h-12 mq750:w-16 mq750:h-16 object-cover mr-4"
              alt="Student icon"
              src="/hands-graduate@2x.png"
            />
            <div className="text-left flex-1">
              <h3 className="text-base font-semibold text-primary-gold mb-2">
                Student
              </h3>
              <p className="text-xs leading-relaxed text-default-white">
                Embark on your learning journey! As a student, you'll have access
                to a variety of courses, join study groups, participate in
                discussions, and track your progress.
              </p>
            </div>
          </div>

          {/* Tutor card */}
          <div className="relative p-4 rounded-xl bg-background-main border-2 border-[#efeefc] flex items-center">
            <img
              className="w-12 h-12 mq750:w-16 mq750:h-16 object-cover mr-4"
              alt="Tutor icon"
              src="/open-peeps-avatar@2x.png"
            />
            <div className="text-left flex-1">
              <h3 className="text-base font-semibold text-primary-gold mb-2">
                Tutor
              </h3>
              <p className="text-xs leading-relaxed text-default-white">
                Become a guide and inspire others! As a mentor, you'll have the
                opportunity to lead study groups, create engaging content, and
                provide guidance to students.
              </p>
            </div>
          </div>
        </div>

        {/* Next button */}
        <div className="flex justify-center px-4">
          <Button
            onButtonContainerClick={onButtonContainerClick}
            buttonWidth="100%"
            buttonPosition="relative"
            buttonBackgroundColor="#055db2"
            buttonPadding="16px"
            button="Next"
            buttonFontSize="16px"
            buttonFontWeight="bold"
            buttonFontFamily="Poppins"
            className="max-w-[350px] mq750:max-w-[450px]"
          />
        </div>
      </div>
    </div>
  );
};

export default PreferencesScreen9;