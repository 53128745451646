import { useState, useEffect } from 'react';

const useEnrolledCoursesData = (token) => {
  const [coursesCount, setCoursesCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchEnrolledCourses = async () => {
      if (!token) {
        setError('No access token available');
        setLoading(false);
        return;
      }

      try {
        const userId = localStorage.getItem("userId");
        const response = await fetch(
          `https://api.staging.sensebod.com/api/v1/courses/registrant/?user=${userId}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error(`Failed to fetch enrolled courses: ${response.status}`);
        }

        const data = await response.json();
        setCoursesCount(data.length);
        setError(null);
      } catch (err) {
        console.error('Error fetching enrolled courses:', err);
        setError(err.message);
        setCoursesCount(0);
      } finally {
        setLoading(false);
      }
    };

    fetchEnrolledCourses();
  }, [token]);

  return { coursesCount, loading, error };
};

export default useEnrolledCoursesData;