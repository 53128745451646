import { useMemo } from "react";
import PropTypes from "prop-types";

const Tag = ({
  className = "",
  text,
  tagPosition,
  tagBackgroundColor,
  tagTop,
  tagLeft,
  textTextAlign,
}) => {
  const tagStyle = useMemo(() => {
    return {
      position: tagPosition,
      backgroundColor: tagBackgroundColor,
      top: tagTop,
      left: tagLeft,
    };
  }, [tagPosition, tagBackgroundColor, tagTop, tagLeft]);

  const textStyle = useMemo(() => {
    return {
      textAlign: textTextAlign,
    };
  }, [textTextAlign]);

  return (
    <div
      className={`relative rounded-[12px] bg-[#9087e5] flex flex-row items-center justify-center py-[8px] px-[12px] text-left text-xs text-default-white font-[Rubik] ${className}`}
      style={tagStyle}
    >
      <div className="relative leading-[150%] font-medium" style={textStyle}>
        {text}
      </div>
    </div>
  );
};

Tag.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string,

  /** Style props */
  tagPosition: PropTypes.any,
  tagBackgroundColor: PropTypes.any,
  tagTop: PropTypes.any,
  tagLeft: PropTypes.any,
  textTextAlign: PropTypes.any,
};

export default Tag;
