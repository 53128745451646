import AIChatComponent from "./AIChatComponent";

function HomePage() {
  return (
    <div>
      <AIChatComponent />
    </div>
  );
}

export default HomePage;