import React, { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import MainNavigation from "../../components/MainNavigation";
import NavBar from "../../components/NavBar";

import { Input } from "postcss";

const Chat = () => {
  const navigate = useNavigate();



  useEffect(() => {
    // Check if the user is logged in by checking for the accessToken in localStorage
    const accessToken = localStorage.getItem("accessToken");

    // If no accessToken is found, redirect to the login page
    if (!accessToken) {
      navigate("/login"); // Replace with the actual path to your login page
    }
  }, [navigate]);
  
  return (
    <div className="w-full relative bg-[#fbf1f1] h-[1170px] overflow-hidden text-center text-[6px] text-default-white font-poppins">
      <img
        className="absolute top-[-317px] left-[-399px] w-[1276.7px] h-[1305.2px] object-contain hidden"
        alt=""
        src="/o@2x.png"
      />
      <img
        className="absolute top-[604px] left-[calc(50%_-_984px)] w-[556.7px] h-[556.7px] object-contain hidden"
        alt=""
        src="/easystem-4-1@2x.png"
      />
      <img
        className="absolute top-[-185px] left-[-68px] w-[1933.8px] h-[1738.8px] object-contain hidden"
        alt=""
        src="/o1@2x.png"
      />
      <img
        className="absolute top-[-120px] left-[calc(50%_+_223px)] w-[562px] h-[562px] object-contain hidden"
        alt=""
        src="/easystem-4-2@2x.png"
      />
      <a href="/aichat" className="fixed bottom-6 left-[285px] z-[10000]">
        <img
          className="w-[75px] h-[75px] object-contain cursor-pointer"
          alt="AI Chat"
          src="/home/AIChart.svg"
        />
      </a>
      <NavBar />

      <img
   className=" absolute top-[135px] left-[290px] w-[16px] h-[16px] "
   alt=""
   src="/mentor/Icon (3).svg" 
   />

<p className=" absolute text-center text-[#92929D] text-[14px] font-[600] font-[Mulish]
                line-height-[48px] top-[121px] left-[310px]">Mentor</p>

<img
   className=" absolute top-[135px] left-[370px] w-[16px] h-[16px] "
   alt=""
   src="/mentor/Icon (3).svg" 
   />

<p className=" absolute text-center text-[#92929D] text-[14px] font-[600] font-[Mulish]
                line-height-[48px] top-[121px] left-[395px]">Humans</p>
  
  <img
   className=" absolute top-[135px] left-[455px] w-[16px] h-[16px] "
   alt=""
   src="/mentor/Icon (3).svg" 
   />

<p className=" absolute text-center text-[#92929D] text-[14px] font-[600] font-[Mulish]
                line-height-[48px] top-[121px] left-[480px]">Messages</p>


      <div className="absolute bg-white w-[654px] h-[610px] top-[180px] left-[280px] rounded-lg shadow-md p-4 font-[Poppins]">
      <img src="/mentor/Icon (Stroke).svg" alt="" className="absolute flex w-[16px] h-[16px] top-[591px] cursor-[pointer] rounded-full font-[Poppins]"/>
     
      <img src="/mentor/IcnButton Circle.svg" alt="" className="absolute flex w-[40px] h-[40px] cursor-[pointer] top-[580px] left-[45px] rounded-full"/>
      
    <div className="absolute flex items-center space-x-2 font-[Poppins]">
    <img src="/mentor/Icon (Stroke) (1).svg"  alt="" className="absolute w-[15px] h-[16.28px] left-[630px] top-[30px] rounded-full cursor-[pointer]"/>
   
      <img src="/mentor/Avatar.svg" alt="Daphne Cole" className="w-10 h-12 rounded-full"/>

        <p className=" text-lg left-[70px] text-[black] font-semibold font-[Poppins]">Daphne Cole</p>
     </div>
     
     <p className="absolute text-[11px] top-[50px] left-[75px] text-[#858494] font-[Poppins]">Online</p>
    
    <div className="absolute top-[88px] pl-[5px] border-[#E0E0E0] border-[1px] border-solid box-border w-[670px] h-[1px]" />

  

  <div className="absolute flex top-[552px] w-[616px] h-[64px] left-[50px] items-center mt-4">
    <div className="flex-grow top-[150px]">
      
  
      <input type="text" className="w-[516px] px-3 py-2 border-[none] border-t-[none] rounded-md font-[Poppins]" placeholder="Type a message"/>
    </div>
    

  </div>
  <img src=" /mentor/IcnButton Circle (1).svg" alt="" className="absolute flex w-[40px] h-[40px] top-[581px] cursor-[pointer] left-[630px] rounded-full"/>
</div>

<div className="absolute bg-[#1A3651] w-[280px] h-[670px] top-[180px] left-[980px] rounded-lg shadow-md p-2 font-[Poppins]">
<img src="/mentor/Avatar.svg" alt="Daphne Cole" className="w-[110px] h-[110px] rounded-full"/>

<p className=" absolute text-lg left-[90px] text-[white] font-[600] font-[Poppins] pb-[1px]">Daphne Cole</p>
<p className=" absolute text-[12px] left-[95px] text-[#989BA1] top-[150px] font-[400] font-[Poppins]">English Language</p> 


<p className=" absolute text-[10px] left-[123px] text-[#E4E6E7] top-[180px] font-[400] font-[Poppins]">4.7 (Rating)</p> 
<img src="/mentor/Star 1.svg" alt="" className="absolute w-[10.75px] h-[10.75px] left-[105px] top-[191px] rounded-full"/>

<p className=" absolute text-[12px] left-[15px] text-[#989BA1] top-[220px] font-[700] font-[Poppins]">Bio</p> 
<img src="/mentor/16--chevrontop.svg" alt="" className="absolute w-[19.81px] h-[18px] cursor-[pointer] left-[260px] top-[235px] rounded-full"/>
<div className="absolute top-[265px] left-[15px] border-[#827A7A80] border-[1px] border-solid box-border w-[270px] h-[1px]" />
<p className=" absolute text-[10px] left-[15px] justify-between text-[#D4D5DD] text-start top-[260px] font-[400] font-[Poppins]">Lorem ipsum dolor sit amet, consectetur adipiscing elit, 
              sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim.</p> 
<p className=" absolute text-[12px] left-[15px] text-[#CEBBBB] top-[330px] font-[400] font-[Poppins]">Subject Expertise</p> 
<img src="/mentor/ui--chevron-down.svg" alt="" className="absolute w-[19.81px] h-[18px] cursor-[pointer] left-[260px] top-[345px] rounded-full"/>
<div className="absolute top-[370px] left-[15px] border-[#827A7A80] border-[1px] border-solid box-border w-[270px] h-[1px]" />
<p className=" absolute text-[12px] left-[15px] text-[#CEBBBB] top-[375px] font-[400] font-[Poppins]">Subject Expertise</p> 
<img src="/mentor/ui--chevron-down.svg" alt="" className="absolute w-[19.81px] h-[18px] cursor-[pointer] left-[260px] top-[390px] rounded-full"/>
<div className="absolute top-[420px] left-[15px] border-[#827A7A80] border-[1px] border-solid box-border w-[270px] h-[1px]" />
<p className=" absolute text-[12px] left-[15px] text-[#CEBBBB] top-[425px] font-[400] font-[Poppins]">Files and Links</p> 
<img src="/mentor/ui--chevron-down.svg" alt="" className="absolute w-[19.81px] h-[18px] cursor-[pointer] left-[260px] top-[440px] rounded-full"/>
<div className="absolute top-[470px] left-[15px] border-[#827A7A80] border-[1px] border-solid box-border w-[270px] h-[1px]" />
<p className=" absolute text-[12px] left-[15px] text-[#CEBBBB] top-[475px] font-[400] font-[Poppins]">Events</p> 
<img src="/mentor/ui--chevron-down.svg" alt="" className="absolute w-[19.81px] h-[18px] cursor-[pointer] left-[260px] top-[490px] rounded-full"/>
<div className="absolute top-[520px] left-[15px] border-[#827A7A80] border-[1px] border-solid box-border w-[270px] h-[1px]" />
<p className=" absolute text-[12px] left-[15px] text-[#CEBBBB] top-[525px] font-[400] font-[Poppins]">Task board</p> 
<img src="/mentor/ui--chevron-down.svg" alt="" className="absolute w-[19.81px] h-[18px] cursor-[pointer] left-[260px] top-[540px] rounded-full"/>
<div className="absolute top-[570px] left-[15px] border-[#827A7A80] border-[1px] border-solid box-border w-[270px] h-[1px]" />
<p className=" absolute text-[12px] left-[15px] text-[#CEBBBB] top-[575px] font-[400] font-[Poppins]">Privacy and Support</p> 
<img src="/mentor/ui--chevron-down.svg" alt="" className="absolute w-[19.81px] h-[18px] cursor-[pointer] left-[260px] top-[590px] rounded-full"/>
<div className="absolute top-[620px] left-[15px] border-[#827A7A80] border-[1px] border-solid box-border w-[270px] h-[1px]" />
 </div>


      <MainNavigation 
      homeClass=""
      learningClass=""
      mentorClass="left-[-29px] rounded-tl-[0px] rounded-tr-11xl rounded-br-11xl rounded-bl-[0px] bg-buttonselect border-buttonselect border-[2px] border-solid box-border text-default-white"
      groupsClass="another-custom-class"
      profileClass="custom-profile-class"
    />
    </div>
  );
};

export default Chat;
