import React, { useEffect, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import Loader from "./Loader";

const ProtectedRoute = ({ children }) => {
  const location = useLocation();
  const [isValid, setIsValid] = useState(true);
  const [isRefreshing, setIsRefreshing] = useState(false);

  const refreshAccessToken = async () => {
    try {
      const refreshToken = localStorage.getItem("refreshToken");
      if (!refreshToken) {
        throw new Error("No refresh token available");
      }

      const response = await fetch("https://api.staging.sensebod.com/api/v1/token/refresh/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ refresh: refreshToken }),
      });

      if (response.ok) {
        const data = await response.json();
        localStorage.setItem("accessToken", data.access);
        return true;
      }
      return false;
    } catch (error) {
      console.error("Token refresh error:", error);
      return false;
    }
  };

  const validateToken = async () => {
    try {
      const accessToken = localStorage.getItem("accessToken");
      if (!accessToken) {
        setIsValid(false);
        return;
      }

      const response = await fetch("https://api.staging.sensebod.com/api/v1/users/", {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (response.status === 401 && !isRefreshing) {
        setIsRefreshing(true);
        const refreshSuccess = await refreshAccessToken();
        setIsRefreshing(false);
        
        if (!refreshSuccess) {
          localStorage.clear();
          setIsValid(false);
        } else {
          setIsValid(true);
        }
      }
    } catch (error) {
      console.error("Token validation error:", error);
    }
  };

  useEffect(() => {
    validateToken();
  }, []);

  if (!isValid) {
    return <Navigate to={`/login?redirect=${encodeURIComponent(location.pathname)}`} />;
  }

  if (isRefreshing) {
    // You might want to show a loading spinner here
    return <div>
      <Loader duration={50000} />
    </div>;
  }

  return children;
};

export default ProtectedRoute;