import React, { useState, useEffect } from 'react';
import axios from 'axios';
import fetchRegistrants from './fetchRegistrants';

const RegistrantsComponent = () => {
  const [groupedRegistrants, setGroupedRegistrants] = useState({});
  const [error, setError] = useState(null);

  useEffect(() => {
    const getRegistrantsAndUsernames = async () => {
      try {
        const registrantsData = await fetchRegistrants();
        console.log("Registrants Data", registrantsData);

        const accessToken = localStorage.getItem("accessToken");
        if (!accessToken) {
          throw new Error('Access token is missing');
        }

        // Group registrants by course and fetch usernames
        const grouped = {};
        for (const registrant of registrantsData) {
          if (!grouped[registrant.course]) {
            grouped[registrant.course] = [];
          }

          try {
            const userResponse = await axios.get(`https://api.staging.sensebod.com/api/v1/users/${registrant.user}/`, {
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            });
            const username = userResponse.data.username; // Adjust this based on the actual API response structure
            grouped[registrant.course].push({ userId: registrant.user, username });
          } catch (userError) {
            console.error(`Error fetching user data for user ${registrant.user}:`, userError);
            grouped[registrant.course].push({ userId: registrant.user, username: 'Unknown User' });
          }
        }

        setGroupedRegistrants(grouped);
      } catch (error) {
        console.error('Error fetching data:', error);
        setError(error.message);
      }
    };

    getRegistrantsAndUsernames();
  }, []);

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div>
      <h2>Course Registrants</h2>
      {Object.entries(groupedRegistrants).map(([courseId, users]) => (
        <div key={courseId} className="course-group">
          <h3>Course ID: {courseId}</h3>
          <ul>
            {users.map((user) => (
              <li key={`${courseId}-${user.userId}`}>{user.username} (ID: {user.userId})</li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  );
};

export default RegistrantsComponent;