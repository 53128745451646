import React, { useState, useEffect } from 'react';
import ReactPlayer from 'react-player';
import Accordion from "../../components/learning/Accordion";
import NavigationBar from "../../components/learning/NavigationBar";
import { useNavigate, useLocation } from "react-router-dom";

// Updated mock lesson data with items
const lessons = [
  {
    title: "Introduction",
    items: [
      { 
        title: "Section 1", 
        videoUrl: "https://www.youtube.com/watch?v=zOjov-2OZ0E", 
        duration: "05:32",
        details: "Introduction to basic concepts and an overview of the course structure."
      },
      { 
        title: "Section 2", 
        videoUrl: "https://www.youtube.com/watch?v=kJOqIaGwQ7Y", 
        duration: "07:51",
        details: "A deeper dive into the specifics of the course topics covered in this section."
      }
    ]
  },
  {
    title: "Lorem Ipsum Theory",
    items: [
      { 
        title: "Lorem Ipsum comes from sections 1.10.32", 
        videoUrl: "https://www.youtube.com/watch?v=dTp0c41XnrQ", 
        duration: "05:32",
        details: "Explanation of the origins of Lorem Ipsum text from sections 1.10.32."
      },
      { 
        title: "and 1.10.33 of 'de Finibus Bonorum et Malorum'", 
        videoUrl: "https://www.youtube.com/watch?v=2lVDktWK-pc", 
        duration: "07:51",
        details: "Further details from section 1.10.33 of 'de Finibus Bonorum et Malorum'."
      },
      { 
        title: "Quiz", 
        videoUrl: "https://www.youtube.com/watch?v=2s6mIboARCM", 
        duration: "00:00",
        details: "Test your knowledge with a quiz based on the content covered."
      }
    ]
  },
  {
    title: "Subjects",
    items: [
      { 
        title: "Overview 1", 
        videoUrl: "https://www.youtube.com/watch?v=2s6mIboARCM", 
        duration: "03:45",
        details: "An overview of the first set of subjects covered in the course."
      },
      { 
        title: "Overview 2", 
        videoUrl: "https://www.youtube.com/watch?v=YhaPN9_lHPw", 
        duration: "04:20",
        details: "Continued overview of subjects with a focus on key topics."
      }
    ]
  },
];

const StartCourse = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const username = localStorage.getItem("username");
  const [currentLessonIndex, setCurrentLessonIndex] = useState(0);
  const [currentItemIndex, setCurrentItemIndex] = useState(0);

  // Update the URL based on the current lesson and item
  useEffect(() => {
    const currentLesson = lessons[currentLessonIndex];
    const currentItem = currentLesson.items[currentItemIndex];
    const newPath = `/course/startcourse/${encodeURIComponent(currentLesson.title)}/${encodeURIComponent(currentItem.title)}`;
    if (location.pathname !== newPath) {
      navigate(newPath, { replace: true });
    }
  }, [currentLessonIndex, currentItemIndex, navigate, location.pathname]);

  // Handle navigation to next item
  const handleNextItem = () => {
    const currentLesson = lessons[currentLessonIndex];
    if (currentItemIndex < currentLesson.items.length - 1) {
      setCurrentItemIndex(currentItemIndex + 1);
    } else if (currentLessonIndex < lessons.length - 1) {
      setCurrentLessonIndex(currentLessonIndex + 1);
      setCurrentItemIndex(0);
    }
  };

  // Handle navigation to previous item
  const handlePreviousItem = () => {
    if (currentItemIndex > 0) {
      setCurrentItemIndex(currentItemIndex - 1);
    } else if (currentLessonIndex > 0) {
      const prevLesson = lessons[currentLessonIndex - 1];
      setCurrentLessonIndex(currentLessonIndex - 1);
      setCurrentItemIndex(prevLesson.items.length - 1);
    }
  };

  const currentLesson = lessons[currentLessonIndex];
  const currentItem = currentLesson.items[currentItemIndex];

  return (
    <div className="w-full relative bg-[#fbf1f1] h-[1200px] overflow-hidden text-left text-base text-gray-100 font-poppins">
      <NavigationBar
        currentTitle={currentLesson.title}
        username={username}
        onNavigateToCourse={() => navigate("/course/learning-home")}
        onPrevious={handlePreviousItem}
        onNext={handleNextItem}
        isPrevDisabled={currentLessonIndex === 0 && currentItemIndex === 0}
        isNextDisabled={currentLessonIndex === lessons.length - 1 && currentItemIndex === currentLesson.items.length - 1}
      />

      {currentItem?.title.toLowerCase() === 'quiz' ? (
        <div className="absolute top-[161px] left-[440px] rounded-[8px] w-[736px] h-[510px] bg-cover bg-no-repeat bg-[top] text-center text-default-white font-poppins-menu-1-bold">
          <img
            className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded-[8px] max-w-full overflow-hidden max-h-full object-cover"
            alt=""
            src="/learning/video-player1@2x.png"
          />
          <div className="absolute h-[9.41%] w-[43.48%] top-[50%] right-[28.26%] bottom-[40.59%] left-[28.26%] rounded-[28px] border-primary-gold border-[1px] border-solid box-border flex flex-row items-center justify-center py-[14px] px-[24px]"
            onClick={() => navigate(`/start-quiz/${encodeURIComponent(currentLesson.title)}/${encodeURIComponent(currentItem.title)}`)}
          >
            <b className="relative tracking-[0.01em] leading-[120%] cursor-pointer">Open Quiz</b>
          </div>
          <b className="absolute top-[calc(50%_+_74px)] left-[calc(50%_-_76px)] tracking-[0.01em] leading-[120%]">
            Skip to Next Lesson
          </b>
        </div>
      ) : (
        <div className="absolute top-[190px] left-[440px] w-[936px] h-[510px] rounded-2xl bg-cover bg-no-repeat bg-[top] text-xs text-light-text-color-body-2 font-poppins-menu-1-bold">
          <ReactPlayer
            url={currentItem?.videoUrl || ''}
            width="100%"
            height="100%"
            controls={true}
            className="rounded-[8px]"
          />
        </div>
      )}

      <div className="absolute top-[190px] left-[61px] w-[341px] bg-default-white rounded-2xl flex flex-col items-start justify-start">
        {lessons.map((lesson, index) => (
          <Accordion
            key={index}
            whatYoullLearn={lesson.title}
            items={lesson.items}
            isLastItem={index === lessons.length - 1}
            isExpanded={index === currentLessonIndex} // Automatically expand the accordion if it's the current lesson
            currentItemIndex={index === currentLessonIndex ? currentItemIndex : null} // Pass current item index if this is the current lesson
            onClick={() => {
              setCurrentLessonIndex(index);
              setCurrentItemIndex(0);
            }}
          />
        ))}
      </div>

      <div className="absolute top-[728px] left-[440px] w-[987px] flex flex-col items-start justify-start py-[20px] pl-[20px] pr-[43px] box-border gap-[10px] text-5xl text-[#3d3d3d]">
        <b className="relative leading-[100%]">{currentItem ? currentItem.title : currentLesson.title}</b>
        <div className="self-stretch relative text-base leading-[150%] text-[#656565]">
          {/* Display item details in the paragraph */}
          <p className="m-[0px]">{currentItem?.details || currentLesson.title}</p>
        </div>
      </div>
    </div>
  );
};

export default StartCourse;
