import React, { useState, useEffect, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import MainNavigation from "../../components/MainNavigation";
import NavBar from "../../components/NavBar";
import GroupSetting from "../../components/groups/GroupSetting";
import Loader from "../../components/Loader";
import Leftside from "../../components/groups/Leftside";
import RightSide from "../../components/groups/RightSide";

const GroupsChat = () => {
  const navigate = useNavigate();
  const { groupId } = useParams();

  const [showGroupSetting, setShowGroupSetting] = useState(false);
  const [groups, setGroups] = useState([]);
  const [usernames, setUsernames] = useState({});
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [showChatList, setShowChatList] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [forumId, setForumId] = useState(null);

  const handleInfoClick = () => {
    setShowGroupSetting(!showGroupSetting);
  };

  const handleGroupClick = (group) => {
    setSelectedGroup(group);
    navigate(`/group/groups-chat/${group.uuid}`);
  };

  // Fetch last message for a group
  const fetchLastMessage = async (groupId) => {
    try {
      const response = await fetch(
        `https://api.staging.sensebod.com/api/v1/groups/message/?forum=${groupId}&limit=1`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        return data.results[0]?.content || "";
      }
      return "";
    } catch (error) {
      console.error("Error fetching last message:", error);
      return "";
    }
  };

  // Fetch usernames for group creators
  const fetchUsernames = async (groups) => {
    const usernamesMap = { ...usernames };

    for (const group of groups) {
      if (group.created_by?.user_id && !usernamesMap[group.created_by.user_id]) {
        try {
          const response = await fetch(
            `https://api.staging.sensebod.com/api/v1/users/${group.created_by.user_id}/`,
            {
              method: "GET",
              headers: {
                Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
              },
            }
          );

          if (response.ok) {
            const userData = await response.json();
            usernamesMap[group.created_by.user_id] = userData.username;
          }
        } catch (error) {
          console.error("Error fetching username:", error);
          usernamesMap[group.created_by.user_id] = "Unknown";
        }
      }
    }

    setUsernames(usernamesMap);
  };

  // Fetch groups and set initial state
  useEffect(() => {
    const fetchGroups = async () => {
      try {
        const response = await fetch(
          "https://api.staging.sensebod.com/api/v1/groups/group/",
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            },
          }
        );

        if (response.ok) {
          const data = await response.json();
          const userId = localStorage.getItem("userId");

          const userGroups = data.filter(group =>
            group.members.some(member => member.member.user_id === userId)
          );

          // Fetch last messages for each group
          const groupsWithLastMessages = await Promise.all(userGroups.map(async (group) => {
            const lastMessage = await fetchLastMessage(group.uuid);
            return { ...group, lastMessage };
          }));

          setGroups(groupsWithLastMessages);
          fetchUsernames(groupsWithLastMessages);

          // Set selected group based on URL or default to first group
          const groupFromUrl = userGroups.find((group) => group.uuid === groupId);
          if (groupFromUrl) {
            setSelectedGroup(groupFromUrl);
          } else if (userGroups.length > 0 && !selectedGroup) {
            setSelectedGroup(userGroups[0]);
            navigate(`/group/groups-chat/${userGroups[0].uuid}`);
          }
        }
      } catch (error) {
        console.error("Error fetching groups:", error);
      } finally {
        setIsLoading(false);
      }
    };

    const accessToken = localStorage.getItem("accessToken");
    if (!accessToken) {
      navigate("/login");
      return;
    }

    fetchGroups();
  }, [groupId, navigate, selectedGroup]);

  // Fetch forum data for the selected group
  useEffect(() => {
    const fetchForumData = async () => {
      if (!selectedGroup) return;

      try {
        const response = await fetch(
          "https://api.staging.sensebod.com/api/v1/groups/discussion_forum/",
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            },
          }
        );

        if (response.ok) {
          const data = await response.json();
          const matchingForum = data.find((forum) => forum.group.uuid === selectedGroup.uuid);
          if (matchingForum) {
            setForumId(matchingForum.id);
          }
        }
      } catch (error) {
        console.error("Error fetching forum data:", error);
      }
    };

    fetchForumData();
  }, [selectedGroup]);

  const toggleChatList = () => {
    setShowChatList(!showChatList);
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  if (isLoading) {
    return (
      <div className="p-4">
        <Loader />
      </div>
    );
  }

  return (
    <div className="w-full relative bg-[#fbf1f1] min-h-screen overflow-hidden text-center text-xs md:text-[6px] text-default-white font-poppins">
      <NavBar />

      <div className="flex flex-col md:flex-row px-4 md:px-0 mt-20 md:mt-0">
        {/* Sidebar */}
        <div className="w-full md:w-[300px] mb-6 md:mb-0">
          <div className="flex justify-between items-center mb-4 md:mb-0 md:block">
            <div className="flex space-x-4 md:space-x-0 md:block">
              <div className="flex items-center md:absolute md:top-[135px] md:left-[290px]">
                <img className="w-4 h-4 md:w-[16px] md:h-[16px]" alt="" src="/mentor/Icon (3).svg" />
                <p className="ml-2 text-[#92929D] text-sm font-semibold md:absolute md:top-[-14px] md:left-[20px]">Groups</p>
              </div>
              <div className="flex items-center md:absolute md:top-[135px] md:left-[370px]">
                <img className="w-4 h-4 md:w-[16px] md:h-[16px]" alt="" src="/mentor/Icon (3).svg" />
                <p className="ml-2 text-[#92929D] text-sm font-semibold md:absolute md:top-[-14px] md:left-[25px]">{selectedGroup?.group_type || 'Public'}</p>
              </div>
              <div className="flex items-center md:absolute md:top-[135px] md:left-[455px]">
                <img className="w-4 h-4 md:w-[16px] md:h-[16px]" alt="" src="/mentor/Icon (3).svg" />
                <p className="ml-2 text-[#92929D] text-sm font-semibold md:absolute md:top-[-14px] md:left-[25px]">Messages</p>
              </div>
            </div>
          </div>

          

          {showChatList && !showGroupSetting && (
            <div className="w-full md:w-[300px] mb-6 md:mb-0">
              <Leftside
                groups={groups.filter(group =>
                  group.name.toLowerCase().includes(searchQuery.toLowerCase())
                )}
                onGroupClick={handleGroupClick}
                selectedGroupId={selectedGroup?.uuid}
              />
            </div>
          )}
        </div>

        {/* Chat Area */}
        <div className={`w-full mq750:mb-[20px] md:w-[654px] md:h-[610px] md:absolute md:top-[180px] ${showGroupSetting ? "md:left-[289px]" : "md:left-[650px]"} text-left text-base md:text-[15px] text-[#0c092a] font-poppins`}>
          {selectedGroup && (
            <RightSide
              selectedGroup={{
                ...selectedGroup,
                id: selectedGroup.uuid,
                creator: selectedGroup.created_by?.username || usernames[selectedGroup.created_by?.user_id] || "Unknown",
                forumId: forumId
              }}
              handleInfoClick={handleInfoClick}
            />
          )}
        </div>

        {showGroupSetting && selectedGroup && (
          <GroupSetting selectedGroup={selectedGroup} />
        )}
      </div>

      <MainNavigation
        homeClass=""
        learningClass=""
        mentorClass=""
        groupsClass="left-[-29px] rounded-tl-[0px] rounded-tr-11xl rounded-br-11xl rounded-bl-[0px] bg-buttonselect border-buttonselect border-[2px] border-solid box-border text-default-white"
        profileClass="custom-profile-class"
      />

      {/* <a href="/aichat" className="fixed bottom-6 left-4 md:left-[285px] z-[10000]">
        <img
          className="w-[50px] h-[50px] md:w-[75px] md:h-[75px] object-contain cursor-pointer"
          alt="AI Chat"
          src="/home/AIChart.svg"
        />
      </a> */}
    </div>
  );
};

export default GroupsChat;