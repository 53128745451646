import PropTypes from "prop-types";

const Calender = ({ className = "" }) => {
  return (
    <div
      className={`absolute top-[15.375rem] left-[0rem] rounded-3xl bg-default-white w-[19.188rem] h-[8.625rem] overflow-hidden text-center text-[0.913rem] text-dimgray font-poppins ${className}`}
    >
      <div className="absolute top-[3.563rem] left-[3.375rem] rounded-81xl w-[2rem] h-[3.813rem]" />
      <div className="absolute top-[3.563rem] left-[8.938rem] rounded-81xl bg-background-main w-[2rem] h-[3.813rem] rounded-[50px]" />
      <div className="absolute top-[1.313rem] left-[6rem] font-semibold text-black text-left">
        June 2024
      </div>
      <img
        className="absolute top-[1.438rem] left-[1.719rem] w-[0.281rem] h-[0.656rem]"
        alt=""
        src="/learning/vector-1.svg"
      />
      <img
        className="absolute top-[1.438rem] left-[16.5rem] w-[0.281rem] h-[0.656rem] object-contain"
        alt=""
        src="/learning/vector-2.svg"
      />
      <div className="absolute top-[5.625rem] left-[2.25rem] flex flex-row items-start justify-start gap-[0.812rem] text-[0.625rem]">
        <div className="w-[1.5rem] relative h-[1.5rem]">
          <div className="absolute top-[0rem] left-[0rem] rounded-[50%] bg-default-white w-[1.5rem] h-[1.5rem]" />
          <div className="absolute top-[0.375rem] left-[0.313rem] font-medium">
            24
          </div>
        </div>
        <div className="w-[1.5rem] relative h-[1.5rem]">
          <div className="absolute top-[0rem] left-[0rem] rounded-[50%] bg-default-white w-[1.5rem] h-[1.5rem]" />
          <div className="absolute top-[0.375rem] left-[0.313rem] font-medium">
            25
          </div>
        </div>
        <div className="w-[1.5rem] relative h-[1.5rem]">
          <div className="absolute top-[0rem] left-[0rem] rounded-[50%] bg-default-white w-[1.5rem] h-[1.5rem]" />
          <div className="absolute top-[0.375rem] left-[0.313rem] font-medium">
            26
          </div>
        </div>
        <div className="w-[1.5rem] relative h-[1.5rem] rounded-[25%]">
          <div className="absolute top-[0rem] left-[0rem] rounded-[50%] bg-primary-gold w-[1.5rem] h-[1.5rem]" />
          <div className="absolute top-[0.375rem] left-[0.375rem] font-medium">
            27
          </div>
        </div>
        <div className="w-[1.5rem] relative h-[1.5rem]">
          <div className="absolute top-[0rem] left-[0rem] rounded-[50%] bg-default-white w-[1.5rem] h-[1.5rem]" />
          <div className="absolute top-[0.375rem] left-[0.313rem] font-medium">
            28
          </div>
        </div>
        <div className="w-[1.5rem] relative h-[1.5rem]">
          <div className="absolute top-[0rem] left-[0rem] rounded-[50%] bg-default-white w-[1.5rem] h-[1.5rem]" />
          <div className="absolute top-[0.375rem] left-[0.313rem] font-medium">
            29
          </div>
        </div>
        <div className="w-[1.5rem] relative h-[1.5rem]">
          <div className="absolute top-[0rem] left-[0rem] rounded-[50%] bg-default-white w-[1.5rem] h-[1.5rem]" />
          <div className="absolute top-[0.375rem] left-[0.313rem] font-medium">
            30
          </div>
        </div>
      </div>
      <div className="absolute top-[4.125rem] left-[3rem] flex flex-row items-start justify-start gap-[1.75rem] text-[0.606rem]">
        <div className="relative font-medium">M</div>
        <div className="relative font-medium text-slategray">T</div>
        <div className="relative font-medium">W</div>
        <div className="relative font-medium">T</div>
        <div className="relative font-medium">F</div>
        <div className="relative font-medium">S</div>
        <div className="relative font-medium">S</div>
      </div>
    </div>
  );
};

Calender.propTypes = {
  className: PropTypes.string,
};

export default Calender;
