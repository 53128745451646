import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useNavigate, useParams } from "react-router-dom";

const Leftside = ({ className = "", groups, selectedGroupId, onGroupClick }) => {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  const [showGroupList, setShowGroupList] = useState(true);

  const createGroup = () => {
    navigate("/groups-preferences");
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredGroups = groups.filter((group) => {
    if (group.name && typeof group.name === "string") {
      return group.name.toLowerCase().includes(searchTerm.toLowerCase());
    }
    return false;
  });

  console.log("filteredGroups", filteredGroups);

  const formatDate = (datetime) => {
    if (!datetime) return "";
    return datetime.split("T")[0];
  };

  const truncateMessage = (message, maxLength = 30) => {
    if (message.length <= maxLength) return message;
    return message.substring(0, maxLength) + '...';
  };

  const toggleGroupList = () => {
    setShowGroupList(!showGroupList);
  };

  return (
    <div
      className={`w-full md:w-auto md:absolute md:top-[193px] md:left-[289px] h-auto flex flex-col items-start justify-start gap-[20px] text-left text-sm text-[#555758] font-poppins ${className}`}
    >
      <div className="w-full md:w-[260px] flex flex-row items-center justify-between">
        <div className="flex flex-row items-center justify-start gap-[10px]">
          <div className="relative text-lg font-bold">Groups</div>
          <div className="rounded-full bg-background-main flex items-center justify-center py-[4px] px-[12px] text-[8px] text-primary-gold">
            <div className="relative cursor-pointer" 
            onClick={toggleGroupList}
            >
              {`${filteredGroups.length} Group${filteredGroups.length !== 1 ? 's' : ''}`}
            </div>
          </div>
        </div>
        <div className="relative w-[32px] h-[32px]">
          <div className="absolute top-0 left-0 rounded-full bg-background-main w-full h-full"></div>
          <img
            onClick={createGroup}
            className="absolute top-[calc(50%_-_7px)] left-[calc(50%_-_7px)] w-[14px] h-[14px] cursor-pointer"
            alt="Create Group"
            src="/group/feplus.svg"
          />
        </div>
      </div>

      <div className="w-full md:w-[230px] rounded-full bg-default-white flex items-center py-[10px] px-[20px] gap-[10px]">
        <img
          className="w-[17px] h-[17px]"
          alt="Search Icon"
          src="/group/search.svg"
        />
        <input
          type="text"
          placeholder="Search..."
          value={searchTerm}
          onChange={handleSearchChange}
          className="bg-transparent outline-none text-[#989ba1] w-full"
        />
      </div>

      {showGroupList && (

      <div className="w-full md:w-[260px] h-[500px] bg-white rounded-[10px] overflow-y-scroll pt-[20px] flex flex-col gap-[20px]">
        {filteredGroups.map((group) => (
          <div
            key={group.uuid}
            className={`flex flex-col p-[10px] cursor-pointer 
              ${selectedGroupId === group.uuid ? "bg-[#a9a9a9] text-white" : "bg-white"} 
              hover:bg-[#a9a9a9]`}
            onClick={() => onGroupClick(group)}
          >
            <div className="flex items-center gap-[12px]">
              <img
                className="w-[50px] h-[50px] rounded-full object-cover"
                alt="Group Avatar"
                src={group.avatar || "/group/group.png"}
              />
              <div className="flex flex-col">
                <div className="font-bold text-base">{group.name}</div>
                <div className="text-[12px] text-gray-500">
                  {`Created by ${group.created_by.username}, ${formatDate(group.creation_date)}`}
                </div>
                {group.lastMessage && (
                  <div className="text-[12px] text-gray-400 mt-1">
                    {truncateMessage(group.lastMessage)}
                  </div>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
      )}
    </div>
  );
};

Leftside.propTypes = {
  className: PropTypes.string,
  groups: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      creator: PropTypes.string.isRequired,
      creation_date: PropTypes.string,
      avatar: PropTypes.string,
      uuid: PropTypes.string.isRequired,
      lastMessage: PropTypes.string,
    })
  ).isRequired,
  selectedGroupId: PropTypes.string,
  onGroupClick: PropTypes.func.isRequired,
};

export default Leftside;