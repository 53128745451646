import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../components/Button";

const PreferencesScreen2 = () => {
  const navigate = useNavigate();

  const onButtonContainerClick = useCallback(() => {
    navigate("/preference-2");
  }, [navigate]);

  return (
    <div className="min-h-screen w-full relative bg-default-white overflow-x-hidden text-center font-poppins">
      {/* Background images with responsive positioning */}
      <div className="fixed inset-0 overflow-hidden pointer-events-none">
        <img
          className="absolute top-0 left-1/2 -translate-x-1/2 w-full max-w-[1933px] h-auto object-cover opacity-50"
          alt="Background pattern"
          src="/preference.svg"
        />
        <img
          className="absolute -bottom-16 -left-16 w-[300px] h-[300px] mq750:w-[400px] mq750:h-[400px] mq1280:w-[526px] mq1280:h-[526px] object-contain opacity-30"
          alt="Decorative system"
          src="/easystem-4-1@2x.png"
        />
        <img
          className="absolute -top-16 -right-16 w-[300px] h-[300px] mq750:w-[400px] mq750:h-[400px] mq1280:w-[562px] mq1280:h-[562px] object-contain opacity-30"
          alt="Decorative system"
          src="/easystem-4-22@2x.png"
        />
      </div>

      {/* Main content container */}
      <div className="relative z-10 max-w-[1200px] mx-auto px-4 py-8 mq750:py-12">
        {/* Logo */}
        <div className="mb-12">
          <img
            className="w-32 h-32 mq450:w-40 mq450:h-40 mq750:w-48 mq750:h-48 mx-auto object-contain"
            alt="Logo"
            src="/download-1-11@2x.png"
          />
        </div>

        {/* Welcome Message Box */}
        <div className="max-w-[500px] mx-auto mb-12 px-4">
          <div className="relative bg-default-white rounded-xl border-4 border-primary-gold p-6 shadow-lg">
            <p className="text-base mq450:text-lg mq750:text-xl leading-relaxed font-medium">
              Congratulations and welcome! me nae u Study Buddy, and I am excited to
              have you here. Let's set up your profile and preferences so I may be
              able to give you the best learning experience possible.
            </p>
          </div>
        </div>

        {/* Get Started Button */}
        <div className="max-w-[470px] mx-auto px-4">
          <Button
            onButtonContainerClick={onButtonContainerClick}
            buttonWidth="100%"
            buttonPosition="relative"
            buttonBackgroundColor="#055db2"
            buttonPadding="16px"
            button="Let's get you started"
            buttonFontSize="16px"
            buttonFontWeight="bold"
            buttonFontFamily="Poppins"
            className="w-full"
          />
        </div>
      </div>
    </div>
  );
};

export default PreferencesScreen2;