import { useMemo } from "react";
import PropTypes from "prop-types";

const Button1 = ({
  className = "",
  button,
  buttonWidth,
  buttonPosition,
  buttonBackgroundColor,
  buttonBottom,
  buttonLeft,
  buttonFontWeight,
  buttonFontFamily,
}) => {
  const button2Style = useMemo(() => {
    return {
      width: buttonWidth,
      position: buttonPosition,
      backgroundColor: buttonBackgroundColor,
      bottom: buttonBottom,
      left: buttonLeft,
    };
  }, [
    buttonWidth,
    buttonPosition,
    buttonBackgroundColor,
    buttonBottom,
    buttonLeft,
  ]);

  const button3Style = useMemo(() => {
    return {
      fontWeight: buttonFontWeight,
      fontFamily: buttonFontFamily,
    };
  }, [buttonFontWeight, buttonFontFamily]);

  return (
    <div
      className={`w-[327px] relative rounded-xl bg-[#6a5ae0] flex flex-row items-center justify-center py-[16px] px-[133px] box-border text-center text-base text-default-white font-[Rubik] ${className}`}
      style={button2Style}
    >
      <div className="relative leading-[150%] font-medium" style={button3Style}>
        {button}
      </div>
    </div>
  );
};

Button1.propTypes = {
  className: PropTypes.string,
  button: PropTypes.string,

  /** Style props */
  buttonWidth: PropTypes.any,
  buttonPosition: PropTypes.any,
  buttonBackgroundColor: PropTypes.any,
  buttonBottom: PropTypes.any,
  buttonLeft: PropTypes.any,
  buttonFontWeight: PropTypes.any,
  buttonFontFamily: PropTypes.any,
};

export default Button1;
