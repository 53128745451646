import React from 'react';

const RankBadge = ({ rank }) => (
  <svg width="70" height="70" viewBox="0 0 100 100">
    <defs>
      <linearGradient id="badgeGradient" x1="0%" y1="0%" x2="100%" y2="100%">
        <stop offset="0%" stopColor="#DF4559" />
        <stop offset="100%" stopColor="#F09A19" />
      </linearGradient>
    </defs>
    <rect x="0" y="0" width="100" height="100" rx="20" ry="20" fill="url(#badgeGradient)" />
    <text x="50" y="40" fontFamily="poppins, poppins" fontSize="24" fontWeight="bold" fill="white" textAnchor="middle">
      Rank
    </text>
    <text x="50" y="75" fontFamily="poppins,poppins" fontSize="36" fontWeight="bold" fill="white" textAnchor="middle">
      #{rank}
    </text>
  </svg>
);

const RankMessage = ({ rank, message }) => {
  return (
    <div className="flex items-center space-x-4 p-4 bg-white rounded-lg shadow-md w-[115%] h-[59px]">
      <div className="flex-shrink-0">
        <RankBadge rank={rank} />
      </div>
      <div className="flex-grow">
        <p className="text-[12px] font-semibold text-gray-800">{message}</p>
      </div>
    </div>
  );
};

export default RankMessage;