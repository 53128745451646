import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Input from "../components/Input";
import Button from "../components/Button";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faSpinner } from "@fortawesome/free-solid-svg-icons";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleEmailChange = (e) => setEmail(e.target.value);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError("");
    setSuccess("");

    try {
      const response = await axios.post("https://api.staging.sensebod.com/api/v1/reset-password/", {
        email,
      });

      if (response.status === 200) {
        setSuccess("Password reset instructions have been sent to your email.");
        // Optional: Redirect to login page after a delay
        setTimeout(() => navigate("/login"), 5000);
      }
    } catch (error) {
      setError(
        error.response?.data?.detail || 
        "Unable to process your request. Please check your email and try again."
      );
      console.error("Error requesting password reset:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleBackToLogin = () => navigate("/login");

  return (
    <div className="flex flex-col md:flex-none items-center justify-center w-full min-h-screen md:h-screen bg-default-white text-base text-buttonselect font-poppins md:relative">
      {/* Mobile view */}
      <div className="w-full px-4 py-8 flex flex-col items-center md:hidden">
        <h2 className="text-3xl font-bold mb-4">Forgot Password</h2>
        <p className="text-[#9e9c9a] text-center mb-8 max-w-[370px]">
          Enter your email address and we'll send you instructions to reset your password.
        </p>
        
        <form onSubmit={handleSubmit} className="w-full max-w-[370px]">
          <div className="mb-6">
            <Input
              type="email"
              value={email}
              onChange={handleEmailChange}
              placeholder="Your email address"
              inputWidth="100%"
              icon={faEnvelope}
              iconWidth="24px"
              iconHeight="24px"
              iconColor="#000000"
            />
          </div>
          
          {error && <div className="text-red-500 mb-4 text-center">{error}</div>}
          {success && <div className="text-green-500 mb-4 text-center">{success}</div>}
          
          <Button
            buttonWidth="100%"
            buttonBackgroundColor="#055db2"
            buttonPadding="16px"
            button={isLoading ? <FontAwesomeIcon icon={faSpinner} spin /> : "Send Reset Instructions"}
            buttonFontSize="16px"
            buttonFontWeight="bold"
            buttonFontFamily="Poppins"
            type="submit"
            disabled={isLoading}
          />
        </form>
        
        <button
          onClick={handleBackToLogin}
          className="mt-6 text-primary-gold font-medium"
        >
          Back to Login
        </button>
      </div>

      {/* Desktop view */}
      <div className="hidden md:block absolute top-[-5px] w-full h-[947px]">
        <img
          className="absolute top-[-100px] right-[10px] w-[821.9px] h-[821.9px] object-contain"
          alt=""
          src="/easystem-4-11@2x.png"
        />
        <img
          className="absolute top-[208px] right-[calc(50%_-_651px)] w-[801px] h-[739px] object-contain"
          alt=""
          src="/bg.png"
        />
        <img
          className="absolute top-[160px] right-[420px] w-[300px] h-[90.7px] overflow-hidden"
          alt=""
          src="/sensebody-secondary-logo--1-1.svg"
        />
      </div>
      
      <div className="hidden md:block absolute top-[-317px] left-[-399px] w-[1276.7px] h-[1305.2px] text-left text-sm text-default-white">
        <div className="absolute top-[0px] left-[0px] w-[1276.7px] h-[1305.2px]">
          <div className="absolute top-[317px] left-[399px] bg-background-main w-[581px] h-[100vh]" />
          <img
            className="absolute top-[0px] left-[0px] w-[1276.7px] h-[1305.2px] object-contain"
            alt=""
            src="/o14@2x.png"
          />
          
          <div className="absolute top-[397px] left-[calc(50%_-_185px)] text-center">
            <h1 className="text-[40px] font-bold mb-4">Forgot Password?</h1>
            <p className="text-base text-white/80 max-w-[370px] mb-8">
              Enter your email address and we'll send you instructions to reset your password.
            </p>
          </div>

          <form onSubmit={handleSubmit} className="absolute top-[557px] left-[calc(50%_-_185px)]">
            <div className="w-[370px] mb-6">
              <Input
                type="email"
                value={email}
                onChange={handleEmailChange}
                placeholder="Your email address"
                inputWidth="370px"
                icon={faEnvelope}
                iconWidth="24px"
                iconHeight="24px"
                iconColor="#FFFFFF"
                inputClassName="bg-transparent text-white placeholder-white/70"
              />
            </div>

            {error && <div className="text-red-500 mb-4 text-center w-[370px]">{error}</div>}
            {success && <div className="text-green-500 mb-4 text-center w-[370px]">{success}</div>}

            <Button
              buttonWidth="370px"
              buttonBackgroundColor="#055db2"
              buttonPadding="16px"
              button={isLoading ? <FontAwesomeIcon icon={faSpinner} spin /> : "Send Reset Instructions"}
              buttonFontSize="16px"
              buttonFontWeight="bold"
              buttonFontFamily="Poppins"
              type="submit"
              disabled={isLoading}
            />

            <button
              onClick={handleBackToLogin}
              className="mt-6 text-primary-gold font-medium w-full text-center"
            >
              Back to Login
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;