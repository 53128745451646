import React, { useState, useCallback, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Button from "../../components/Button";
import Progress7 from "../../components/Progress7";
import Loader from "../../components/Loader";

const PreferencesScreen10 = () => {
  const navigate = useNavigate();
  const [subjects, setSubjects] = useState([]);
  const [selectedSubjects, setSelectedSubjects] = useState([]);
  const [IsLoading, setIsLoading] = useState(false);

  const colors = ["#2F80ED", "#27AE60", "#EB5757", "#F2C94C", "#9B51E0"];

  // Function to fetch subjects from the API
  const fetchSubjects = async () => {
    try {
      setIsLoading(true);
      const token = localStorage.getItem("accessToken"); 

      const response = await axios.get(
        "https://api.staging.sensebod.com/api/v1/preference/focus-subjectarea/",
        {
          headers: {
            Authorization: `Bearer ${token}`, 
          },
        }
      );
      console.log(response.data);
      setSubjects(response.data); 
      
    } catch (error) {
      console.error("Error fetching subjects:", error);
    }
    finally {
      setIsLoading(false);
    }
    
  };

  useEffect(() => {
    fetchSubjects();
  }, []); 

  const handleSubjectSelect = (subject) => {
    console.log("Selected subject:", subject);
    const isSelected = selectedSubjects.find(
      (item) => item.name === subject.name 
    );
    if (isSelected) {
      setSelectedSubjects(
        selectedSubjects.filter((item) => item.name !== subject.name)
      );
    } else {
      const color = colors[selectedSubjects.length % colors.length];
      setSelectedSubjects([...selectedSubjects, { subject: subject.name, color }]);
    }
  };

  console.log("Selected subjects:", selectedSubjects);

  const onButtonContainerClick = useCallback(() => {
    // Retrieve the existing preferencesData from localStorage
    const existingPreferences = JSON.parse(localStorage.getItem("preferencesData")) || {};

    // Add the selected subjects to the preferencesData
    const updatedPreferences = {
      ...existingPreferences,
      focus_subject_areas: selectedSubjects.map((subject) => subject.subject),
    };

    // Store the updated preferencesData back in localStorage
    localStorage.setItem("preferencesData", JSON.stringify(updatedPreferences));

    // Navigate to the next screen
    navigate("/10-preferencesscreen");
  }, [navigate, selectedSubjects]);

  const handleGoBack = () => {
    navigate(-1); // Navigate back to the previous screen
  };

  const handleSkip = () => {
    navigate("/10-preferencesscreen");
  };

  if (IsLoading) {
    return <Loader duration={50000} />;
  }

  return (
    <div className="min-h-screen w-full relative bg-default-white overflow-x-hidden text-left text-sm text-black font-poppins">
      {/* Background images with responsive positioning */}
      <div className="fixed inset-0 overflow-hidden pointer-events-none">
        <img
          className="absolute top-0 left-1/2 -translate-x-1/2 w-full max-w-[1933px] h-auto object-cover opacity-50"
          alt="Background pattern"
          src="/preference.svg"
        />
        <img
          className="absolute -bottom-16 -left-16 w-[300px] h-[300px] mq750:w-[400px] mq750:h-[400px] mq1280:w-[526px] mq1280:h-[526px] object-contain opacity-30"
          alt="Decorative system"
          src="/easystem-4-1@2x.png"
        />
        <img
          className="absolute -top-16 -right-16 w-[300px] h-[300px] mq750:w-[400px] mq750:h-[400px] mq1280:w-[562px] mq1280:h-[562px] object-contain opacity-30"
          alt="Decorative system"
          src="/easystem-4-22@2x.png"
        />
      </div>

      {/* Skip button - positioned at top right */}
      <div className="absolute top-6 right-6 z-20">
        <div 
          className="text-[#c3beb5] font-medium cursor-pointer hover:text-gray-600"
          onClick={handleSkip}
        >
          Skip
        </div>
      </div>

      {/* Main content container */}
      <div className="relative z-10 max-w-[1200px] mx-auto px-4 py-8 mq750:py-12">
        {/* Progress Bar and Back Button */}
        <div className="w-full max-w-[921px] mx-auto mb-8 flex items-center justify-between">
          <img
            onClick={() => navigate(-1)}
            className="w-8 h-8 cursor-pointer"
            alt="Go Back"
            src="/icon.svg"
          />
          <div className="flex-1 mx-4">
            <Progress7
              progressWidth="100%"
              progressPosition="relative"
              progressHeight="21px"
              progressTop="0"
              progressLeft="0"
              rectangleDivBackgroundColor="rgba(5, 93, 178, 0.1)"
              rectangleDivWidth="50%"
              rectangleDivRight="50%"
              rectangleDivBackgroundColor1="#f09a19"
            />
          </div>
          <div className="text-buttonselect leading-[150%]">5 of 10</div>
        </div>

        {/* Logo */}
        <div className="flex justify-center mb-8">
          <img
            className="w-24 h-24 mq750:w-32 mq750:h-32 object-contain"
            alt="Logo"
            src="/download-1-1@2x.png"
          />
        </div>

        {/* Info Box */}
        <div className="relative mx-auto max-w-[350px] mq750:max-w-[450px] mb-12">
          <img
            className="w-full h-auto"
            alt="Info background"
            src="/vector-1.svg"
          />
          <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-sm text-black font-medium p-4 w-[80%] text-center">
            Select the subjects you want to focus on for a customized learning path
          </div>
        </div>

        {/* Subjects Grid */}
        <div className="max-w-[815px] mx-auto">
        <div className="grid grid-cols-4 mq750:grid-cols-2 mq1280:grid-cols-3 gap-4">
          {subjects.map((subject, index) => {
            const isSelected = selectedSubjects.find(
              (selected) => selected.subject === subject.name
            );

            return (
              <div
                key={index}
                onClick={() => handleSubjectSelect(subject)}
                style={{
                  backgroundColor: isSelected ? isSelected.color : '#FFFFFF',
                  color: isSelected ? '#FFFFFF' : '#0c092a',
                  boxShadow: isSelected ? 'none' : '0 0 0 1px rgba(239, 238, 252, 0.2)',
                }}
                className={`
                  cursor-pointer p-5 rounded-xl
                  transition-all duration-200
                  rounded-xl border-[2px] border-solid box-border border-[#efeefc]
                  text-left min-h-[60px]
                  flex items-center justify-center
                  hover:shadow-[0_0_0_2px_#055db2]
                  ${isSelected ? 'transform scale-[1.02]' : 'bg-white'}
                `}
              >
                <span className="font-medium text-base">{subject.name}</span>
                {isSelected && (
                  <svg 
                    className="w-5 h-5 ml-2 flex-shrink-0" 
                    fill="none" 
                    stroke="currentColor" 
                    viewBox="0 0 24 24"
                  >
                    <path 
                      strokeLinecap="round" 
                      strokeLinejoin="round" 
                      strokeWidth="2" 
                      d="M5 13l4 4L19 7"
                    />
                  </svg>
                )}
              </div>
            );
          })}
        </div>

          {/* Next Button */}
          <div className="sticky bottom-4 mt-8 max-w-[370px] mx-auto">
            <Button
              buttonWidth="100%"
              buttonPosition="relative"
              buttonBackgroundColor="#055db2"
              buttonPadding="16px"
              button="Next"
              buttonFontSize="16px"
              buttonFontWeight="bold"
              buttonFontFamily="Poppins"
              onButtonContainerClick={onButtonContainerClick}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PreferencesScreen10;
