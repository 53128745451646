import React, { useState } from "react";
import Button from "../../components/Button";
import Progress3 from "../../components/groups/Progress3";
import { useNavigate } from "react-router-dom";

const GroupsPreferencesScreen1 = () => {
  const navigate = useNavigate();

  // State to handle radio button selections and form data
  const [joinOption, setJoinOption] = useState("");
  const [seeOption, setSeeOption] = useState("");

  const groupName = localStorage.getItem("group_name");
  const groupType = localStorage.getItem("group_type");
  const groupDescription = localStorage.getItem("group_description");
  const createdBy = localStorage.getItem("created_by");
  const subject = localStorage.getItem("subject");

  // Handler to submit the form data to the backend
  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent default form submission behavior

    // Prepare data to send
    const data = {
      name: groupName,
      description: groupDescription,
      group_type: groupType,
      group_policy: "study",
      subject: subject,
      max_members: 10,
      join_privacy_settings: joinOption,
      visibility_privacy_settings: seeOption,
      group_points: 100,
    };

    try {
      // Send data to the backend
      const response = await fetch("https://api.staging.sensebod.com/api/v1/groups/group/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
        body: JSON.stringify(data),
      });

      // Handle the response
      if (response.ok) {
        const result = await response.json();
        console.log("Group created successfully:", result);

        const userId = localStorage.getItem("userId");
        const accessToken = localStorage.getItem("accessToken");
        const refreshToken = localStorage.getItem("refreshToken");
        const username = localStorage.getItem("username");


        localStorage.clear();

        // Restore the essential items
        localStorage.setItem("accessToken", accessToken);
        localStorage.setItem("userId", userId);
        localStorage.setItem("refreshToken", refreshToken);
        localStorage.setItem("username", username);

        // Navigate to the next screen
        navigate(`/group/groups-chat/${result.uuid}`);
      } else {
        console.error("Failed to create group:", response.statusText);
        console.log(data);
      }
    } catch (error) {
      console.error("Error submitting data:", error);
    }
  };

  const handleGoBack = () => {
    navigate(-1); // This will navigate back to the previous screen
  };

  return (
    <div className="w-full relative bg-default-white h-[100vh] overflow-hidden text-left text-sm text-[#686b6d] font-poppins">
      <img
        className="absolute top-[-317px] left-[-399px] w-[1276.7px] h-[1305.2px] object-contain hidden"
        alt=""
        src="/o11@2x.png"
      />
      <img
        className="absolute top-[442px] left-[calc(50%_-_950px)] w-[526.8px] h-[526.8px] object-contain"
        alt=""
        src="/easystem-4-1@2x.png"
      />
      <img
        className="absolute top-[-325px] left-[-68px] w-[1933.8px] h-[1738.8px] object-contain"
        alt=""
        src="/preference.svg"
      />
      <img
        className="absolute top-[-120px] left-[calc(50%_+_450px)] w-[562px] h-[562px] object-contain"
        alt=""
        src="/easystem-4-22@2x.png"
      />

      {/* Form Section */}
      <form onSubmit={handleSubmit} className="absolute top-[220px] left-[calc(50%_-_185px)] w-[370px] flex flex-col gap-8">
        

        {/* Who can join */}
        <div>
          <h2 className="text-lg font-bold text-black mb-4">Who can join</h2>
          <div className="flex justify-between">
            <label className="flex items-center gap-2">
              <input
                type="radio"
                name="join"
                value="private"
                checked={joinOption === "private"}
                onChange={(e) => setJoinOption(e.target.value)}
                className="w-5 h-5 accent-blue-500"
              />
              Private Group
            </label>
            <label className="flex items-center gap-2">
              <input
                type="radio"
                name="join"
                value="public"
                checked={joinOption === "public"}
                onChange={(e) => setJoinOption(e.target.value)}
                className="w-5 h-5 accent-blue-500"
              />
              Public Group
            </label>
          </div>
        </div>

        {/* Who can see this group */}
        <div>
          <h2 className="text-lg font-bold text-black mb-4">Who can see this group</h2>
          <div className="flex justify-between">
            <label className="flex items-center gap-2">
              <input
                type="radio"
                name="see"
                value="private"
                checked={seeOption === "private"}
                onChange={(e) => setSeeOption(e.target.value)}
                className="w-5 h-5 accent-blue-500"
              />
              Private Group
            </label>
            <label className="flex items-center gap-2">
              <input
                type="radio"
                name="see"
                value="public"
                checked={seeOption === "public"}
                onChange={(e) => setSeeOption(e.target.value)}
                className="w-5 h-5 accent-blue-500"
              />
              Public Group
            </label>
          </div>
        </div>

        {/* Submit Button */}
        <div className="w-full flex justify-center p-4">
          <Button
            buttonWidth="370px"
            buttonBackgroundColor="#055db2"
            buttonPadding="16px 133px"
            buttonFontWeight="bold"
            buttonFontFamily="Poppins"
            buttonFontSize="16px"
            button="Create Group"
            type="submit"
          />
        </div>
      </form>

      {/* Progress Bar */}
      <div className="absolute top-[calc(50%_-_422px)] left-[calc(50%_-_385px)] w-[921px] h-[53px] text-right text-buttonselect font-[Francy]">
        <Progress3
          progressWidth="871px"
          progressPosition="absolute"
          progressHeight="21px"
          progressTop="calc(50% - 21.5px)"
          progressLeft="calc(50% - 410.5px)"
          rectangleDivBackgroundColor="rgba(5, 93, 178, 0.1)"
          rectangleDivWidth="100%"
          rectangleDivRight="90%"
          rectangleDivBackgroundColor1="#f09a19"
        />
        <div className="absolute w-[11.16%] top-[60.38%] left-[88.82%] leading-[150%] flex items-center">
          2 of 2
        </div>
        <img
          onClick={handleGoBack} // Go back when this icon is clicked
          className="absolute h-[60.38%] w-[3.47%] top-[0%] right-[96.53%] bottom-[39.62%] left-[0%] max-w-full overflow-hidden max-h-full cursor-pointer"
          alt="Go Back"
          src="/icon.svg"
        />
      </div>
      <div className="absolute top-[110px] left-[500px] w-[185px] h-[105px] text-center text-black">
        <img
          className="absolute top-[0px] left-[0px] rounded-[3px] w-[185px] h-[105px]"
          alt=""
          src="/vector-1.svg"
        />
        <div className="absolute top-[10px] left-[29px] leading-[150%] font-medium flex items-center justify-center w-[148px] h-[84px]">
          Set participants limits and privacy settings for your group.
        </div>
      </div>
      <img
        className="absolute h-[18.03%] w-[11.72%] top-[8%] right-[80.08%] bottom-[71.51%] left-[15%] max-w-full overflow-hidden max-h-full object-cover"
        alt=""
        src="/download-1-1@2x.png"
      />
    </div>
  );
};

export default GroupsPreferencesScreen1;
