import { useMemo } from "react";
import PropTypes from "prop-types";

const GroupComponent = ({
  className = "",
  propTop,
  icon,
  propWidth,
  propOverflow,
  awardsWon,
  propTop1,
  prop,
  courseEnrolled,
  propLeft,
  prop1,
  icon1,
}) => {
  const groupDiv2Style = useMemo(() => {
    return {
      top: propTop,
    };
  }, [propTop]);

  const icon1Style = useMemo(() => {
    return {
      width: propWidth,
      overflow: propOverflow,
    };
  }, [propWidth, propOverflow]);

  const awardsWonStyle = useMemo(() => {
    return {
      top: propTop1,
    };
  }, [propTop1]);

  const courseEnrolledStyle = useMemo(() => {
    return {
      left: propLeft,
    };
  }, [propLeft]);

  return (
    <div
      className={`relative lg:absolute lg:top-[0px] lg:left-[0px] w-full lg:w-[295px] h-[96px] text-left text-sm lg:text-xs text-default-white font-poppins ${className}`}
      style={groupDiv2Style}
    >
      {/* Container for both cards with flex on mobile */}
      <div className="flex flex-row w-full h-full gap-2">
        {/* Left Card (Course Info) */}
        <div className="relative flex-1 lg:absolute lg:top-[0px] lg:left-[0px] lg:w-[140px] h-[96px] text-sm lg:text-xs text-[#0c092a] font-poppins">
          <div className="absolute top-0 left-0 w-full lg:w-[140px] h-[96px] rounded-xl bg-default-white" />
          <div
            className="absolute top-[64px] left-[17px] leading-[140%] text-base lg:text-xs"
            style={courseEnrolledStyle}
          >
            {courseEnrolled}
          </div>
          <b className="absolute top-[16px] left-[16px] text-3xl lg:text-[32px] leading-[150%] font-poppins">
            {prop1}
          </b>
          <img
            className="absolute top-[16px] right-[16px] lg:left-[100px] w-[28px] h-[28px] lg:w-[24px] lg:h-[24px] overflow-hidden"
            alt=""
            src={icon1}
          />
        </div>

        {/* Right Card (Awards Info) */}
        <div className="relative flex-1 lg:absolute lg:top-[0px] lg:left-[155px] lg:w-[140px] h-[96px]">
          <div className="absolute top-0 left-0 w-full lg:w-[140px] h-[96px] rounded-xl bg-buttonselect" />
          <img
            className="absolute top-[16px] right-[16px] lg:left-[100px] w-[28px] h-[28px] lg:w-[24px] lg:h-[24px] overflow-hidden"
            alt=""
            src={icon}
            style={icon1Style}
          />
          <div
            className="absolute top-[64px] left-[15px] leading-[140%] text-base lg:text-xs"
            style={awardsWonStyle}
          >
            {awardsWon}
          </div>
          <b className="absolute top-[16px] left-[16px] text-3xl lg:text-[32px] leading-[150%]">
            {prop}
          </b>
        </div>
      </div>
    </div>
  );
};

GroupComponent.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.string,
  awardsWon: PropTypes.string,
  prop: PropTypes.string,
  courseEnrolled: PropTypes.string,
  prop1: PropTypes.string,
  icon1: PropTypes.string,
  propTop: PropTypes.any,
  propWidth: PropTypes.any,
  propOverflow: PropTypes.any,
  propTop1: PropTypes.any,
  propLeft: PropTypes.any,
};

export default GroupComponent;