import React, { useState, useEffect, Fragment, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Dialog, Transition } from '@headlessui/react';
import axios from 'axios';

const AIQuizQuestions = () => {
  const navigate = useNavigate();
  const [quizData, setQuizData] = useState(null);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [selectedOption, setSelectedOption] = useState(null);
  const [theoryAnswer, setTheoryAnswer] = useState("");
  const [totalUserPoints, setTotalUserPoints] = useState(0);
  const [timeLeft, setTimeLeft] = useState(0);
  const [userAnswers, setUserAnswers] = useState([]);
  const [quizStarted, setQuizStarted] = useState(false);
  const [showStartDialog, setShowStartDialog] = useState(true);
  const [showFlashMessage, setShowFlashMessage] = useState(false);
  const [isTimerRed, setIsTimerRed] = useState(false);
  const [skippedQuestions, setSkippedQuestions] = useState(0);
  const [showFeedback, setShowFeedback] = useState(false);
  const [isCorrect, setIsCorrect] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  
  const correctSoundRef = useRef(null);
  const wrongSoundRef = useRef(null);
  
  // Sound effect references
  // const correctSoundRef = useRef(new Audio("https://www.soundjay.com/buttons/sounds/button-09.mp3"));
  // const wrongSoundRef = useRef(new Audio("https://www.soundjay.com/buttons/sounds/button-10.mp3"));

  useEffect(() => {
    const storedQuizData = localStorage.getItem('currentQuizData');
    if (storedQuizData) {
      const parsedQuizData = JSON.parse(storedQuizData);
      setQuizData(parsedQuizData);
      const initialTime = parsedQuizData.quiz_type === "theory"
        ? parsedQuizData.questions.length * 120  // 2 minutes per question for theory
        : parsedQuizData.questions.length * 60;  // 1 minute per question for objective
      setTimeLeft(initialTime);
    } else {
      navigate("/course/quiz-ai");
    }

    // Preload audio
    correctSoundRef.current = new Audio("/sounds/correct.mp3");
    wrongSoundRef.current = new Audio("/sounds/wrong.mp3");
  }, [navigate]);

  const currentQuestion = quizData?.questions[currentQuestionIndex];

  useEffect(() => {
    if (quizStarted) {
      const timer = setInterval(() => {
        setTimeLeft((prevTime) => {
          if (prevTime <= 31) {
            setIsTimerRed(true);
          }
          if (prevTime <= 1) {
            clearInterval(timer);
            handleQuizComplete();
            return 0;
          }
          return prevTime - 1;
        });
      }, 1000);

      return () => clearInterval(timer);
    }
  }, [quizStarted]);

  const handleStartQuiz = () => {
    setQuizStarted(true);
    setShowStartDialog(false);
    setShowFlashMessage(true);
    setTimeout(() => setShowFlashMessage(false), 3000);
  };

  const handleOptionClick = (option) => {
    setSelectedOption(option);
  };

  const handleTheoryAnswerChange = (e) => {
    setTheoryAnswer(e.target.value);
  };

  const handleConfirmClick = () => {
    if (quizData.quiz_type === "objective" && !selectedOption) return;
    if (quizData.quiz_type === "theory" && !theoryAnswer.trim()) return;

    let isCorrect = false;
    let answer = "";

    if (quizData.quiz_type === "objective") {
      isCorrect = selectedOption.is_correct;
      answer = selectedOption.text;
    } else {
      isCorrect = true;
      answer = theoryAnswer;
    }

    setIsCorrect(isCorrect);
    setShowFeedback(true);

    if (isCorrect) {
      correctSoundRef.current.play();
      setTotalUserPoints((prevPoints) => prevPoints + currentQuestion.points);
    } else {
      wrongSoundRef.current.play();
    }

    setUserAnswers((prevAnswers) => [
      ...prevAnswers,
      {
        question: currentQuestion.text,
        selectedOption: answer,
        correctAnswer: quizData.quiz_type === "objective" ? currentQuestion.choices.find(choice => choice.is_correct).text : "N/A",
        isCorrect: isCorrect,
        explanation: currentQuestion.explanation,
        points: isCorrect ? currentQuestion.points : 0,
      },
    ]);

    setTimeout(() => {
      setShowFeedback(false);
      setSelectedOption(null);
      setTheoryAnswer("");

      if (currentQuestionIndex < quizData.questions.length - 1) {
        setCurrentQuestionIndex(currentQuestionIndex + 1);
      } else {
        setIsSubmitting(true); // Show loader
        handleQuizComplete();
      }
    }, 1500);
  };

  const FeedbackIcon = ({ isCorrect }) => (
    <div className={`fixed inset-0 flex items-center justify-center z-50 ${showFeedback ? 'opacity-100' : 'opacity-0'} transition-opacity duration-300`}>
      <div className={`rounded-full p-4 ${isCorrect ? 'bg-green-500' : 'bg-red-500'}`}>
        {isCorrect ? (
          <svg xmlns="http://www.w3.org/2000/svg" className="h-16 w-16 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14 10h4.764a2 2 0 011.789 2.894l-3.5 7A2 2 0 0115.263 21h-4.017c-.163 0-.326-.02-.485-.06L7 20m7-10V5a2 2 0 00-2-2h-.095c-.5 0-.905.405-.905.905 0 .714-.211 1.412-.608 2.006L7 11v9m7-10h-2M7 20H5a2 2 0 01-2-2v-6a2 2 0 012-2h2.5" />
          </svg>
        ) : (
          <svg xmlns="http://www.w3.org/2000/svg" className="h-16 w-16 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 14H5.236a2 2 0 01-1.789-2.894l3.5-7A2 2 0 018.736 3h4.018a2 2 0 01.485.06l3.76.94m-7 10v5a2 2 0 002 2h.096c.5 0 .905-.405.905-.904 0-.715.211-1.413.608-2.008L17 13V4m-7 10h2m5-10h2a2 2 0 012 2v6a2 2 0 01-2 2h-2.5" />
          </svg>
        )}
      </div>
    </div>
  );

  const handleSkip = () => {
    setUserAnswers((prevAnswers) => [
      ...prevAnswers,
      {
        question: currentQuestion.text,
        selectedOption: "Skipped",
        correctAnswer: quizData.quiz_type === "objective" ? currentQuestion.choices.find(choice => choice.is_correct).text : "N/A",
        isCorrect: false,
        explanation: currentQuestion.explanation,
        points: 0,
      },
    ]);

    setSelectedOption(null);
    setTheoryAnswer("");
    setSkippedQuestions((prev) => prev + 1);
    setTotalUserPoints((prevPoints) => Math.max(0, prevPoints - 1));

    if (currentQuestionIndex < quizData.questions.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    } else {
      handleQuizComplete();
    }
  };

  const handleQuizComplete = async () => {
    let finalAnswers = [...userAnswers];

    if (quizData.quiz_type === "objective" && selectedOption) {
      finalAnswers.push({
        question: currentQuestion.text,
        selectedOption: selectedOption.text,
        correctAnswer: currentQuestion.choices.find(choice => choice.is_correct).text,
        isCorrect: selectedOption.is_correct,
        explanation: currentQuestion.explanation,
        points: selectedOption.is_correct ? currentQuestion.points : 0,
      });
    } else if (quizData.quiz_type === "theory" && theoryAnswer.trim()) {
      finalAnswers.push({
        question: currentQuestion.text,
        selectedOption: theoryAnswer,
        correctAnswer: "N/A",
        isCorrect: true, // Consider all non-empty theory answers as correct
        explanation: currentQuestion.explanation,
        points: currentQuestion.points,
      });
    }

    // Add unanswered questions as skipped
    for (let i = finalAnswers.length; i < quizData.questions.length; i++) {
      const question = quizData.questions[i];
      finalAnswers.push({
        question: question.text,
        selectedOption: "Skipped",
        correctAnswer: quizData.quiz_type === "objective" ? question.choices.find(choice => choice.is_correct).text : "N/A",
        isCorrect: false,
        explanation: question.explanation,
        points: 0,
      });
      setSkippedQuestions((prev) => prev + 1);
    }

    // Prepare data for API submission
    const submissionData = finalAnswers.map(answer => {
      const question = quizData.questions.find(q => q.text === answer.question);
      const choice = question.choices ? question.choices.find(c => c.text === answer.selectedOption) : null;
      return {
        question: question.id,
        choice: choice ? choice.id : null
      };
    });

    try {
      const accessToken = localStorage.getItem("accessToken");
      const AIQuizId = quizData.id;
      const response = await axios.post(
        `https://api.staging.sensebod.com/api/v1/courses/quiz/${AIQuizId}/submit/`,
        submissionData,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
          },
        }
      );

      console.log('Quiz submission response:', response.data);

      const quizResults = {
        quizName: quizData.name,
        totalPoints: response.data.earned_points,
        maxPossiblePoints: response.data.quiz_points,
        correctAnswers: response.data.correct_question,
        incorrectAnswers: response.data.total_question - response.data.correct_question,
        skippedQuestions: skippedQuestions,
        percentage: response.data.percentage,
        userAnswers: finalAnswers,
        quizType: quizData.quiz_type,
        is_bonus: response.data.is_bonus
      };

      localStorage.setItem("quizResults", JSON.stringify(quizResults));
      localStorage.removeItem('currentQuizData');
      setIsSubmitting(false); // Hide loader
      navigate("/course/ai-quiz-complete");
    } catch (error) {
      console.error('Error submitting quiz:', error);
      alert('Failed to submit quiz. Please try again.');
      setIsSubmitting(false); // Hide loader
    }
  };

  const handleCancelQuiz = () => {
    localStorage.removeItem('currentQuizData');
    navigate("/course/quiz-ai");
  };

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes.toString().padStart(2, "0")}:${remainingSeconds.toString().padStart(2, "0")}`;
  };

  if (!quizData || !currentQuestion) {
    return <div>Loading...</div>;
  }

  return (
    <div className="w-full relative bg-[#fbf1f1] min-h-screen overflow-hidden text-left text-xs text-default-white font-poppins font-body-xs">
      {/* Background Images */}
      <img
        className="absolute hidden lg:block top-[-1089.1px] left-[-1174.5px] w-[2827.8px] h-[2818.5px] object-contain"
        alt=""
        src="/learning/o2@2x.png"
      />
      <img
        className="absolute hidden lg:block top-[245px] left-[calc(50%_-_985px)] w-[556.7px] h-[556.7px] object-contain"
        alt=""
        src="/learning/easystem-4-1@2x.png"
      />
      <img
        className="absolute hidden lg:block top-[-78.5px] left-[-470.7px] w-[2863.3px] h-[1567.3px] object-contain"
        alt=""
        src="/learning/o3@2x.png"
      />
      <img
        className="absolute hidden lg:block top-[-120px] left-[calc(50%_+_222px)] w-[562px] h-[562px] object-contain"
        alt=""
        src="/learning/easystem-4-2@2x.png"
      />

      {/* Overlay */}
      <div className="absolute inset-0 bg-background-main" />

      {/* Start Dialog */}
      <Transition appear show={showStartDialog} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={() => { }}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all font-poppins">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    Welcome to the AI Quiz!
                  </Dialog.Title>
                  <div className="mt-2 text-poppins">
                    <p className="text-sm text-gray-500">
                      {quizData.quiz_type === "theory" ? (
                        <>
                          You have {formatTime(timeLeft)} to complete {quizData.questions.length} questions.
                          Each question has a time limit of 2 minutes.
                          The timer will start when you click "Start Quiz".
                        </>
                      ) : (
                        <>
                          You have {formatTime(timeLeft)} to complete {quizData.questions.length} questions.
                          Each question is worth 2 marks.
                          The timer will start when you click "Start Quiz".
                        </>
                      )}
                    </p>
                  </div>

                  <div className="mt-4">
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                      onClick={handleStartQuiz}
                    >
                      Start Quiz
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>

      {/* Flash Message */}
      <Transition
        show={showFlashMessage}
        enter="transition-opacity duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-300"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="fixed top-4 left-1/2 transform -translate-x-1/2 bg-green-500 text-white px-4 py-2 rounded shadow-lg z-50 font-poppins">
          Quiz Started! Good luck!
        </div>
      </Transition>

      {/* Feedback Icon */}
      {showFeedback && <FeedbackIcon isCorrect={isCorrect} />}

      {isSubmitting && (
        <div className="fixed inset-0 bg-background-main bg-opacity-75 flex items-center justify-center z-50">
          <div className="bg-white p-5 rounded-lg shadow-lg">
            <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-primary-gold"></div>
            <p className="mt-4 text-primary-gold font-medium">Submitting Quiz...</p>
          </div>
        </div>
      )}

      {/* Quiz Content */}
      {quizStarted && (
        <>
          {/* Header Section */}
          <div className="relative flex-1 flex flex-col z-10 px-4 sm:px-6 lg:px-8 py-8">
            <div className="mb-8">
              <div className="max-w-7xl mx-auto">
                <div className="flex flex-wrap items-center justify-between gap-2 sm:gap-4">
                  {/* Question Number */}
                  <div className="rounded-[8px] bg-[#14b8b9] flex items-center justify-center p-2 sm:p-3">
                    <img className="w-3 h-3 sm:w-4 sm:h-4" alt="User Icon" src="/learning/user.svg" />
                    <div className="text-xs sm:text-sm font-semibold ml-1">{currentQuestionIndex + 1}</div>
                  </div>

                  {/* Timer Box */}
                  <div className={`shadow-md rounded-[6px] ${isTimerRed ? 'bg-red-500' : 'bg-default-white'} flex items-center justify-center p-2 sm:p-3 ${isTimerRed ? 'text-white' : 'text-[#4f257b]'}`}>
                    <img className="w-3 h-3 sm:w-4 sm:h-4" alt="Clock Icon" src="/learning/clock.svg" />
                    <div className="text-xs sm:text-sm tracking-[0.04px] uppercase font-medium ml-1">{formatTime(timeLeft)}</div>
                  </div>

                  {/* Progress Bar */}
                  <div className="flex-grow max-w-[40%] sm:max-w-[50%] h-6 relative">
                    <div className="w-full bg-primary-gold bg-opacity-10 rounded-[8px] h-full">
                      <div
                        className="absolute top-0 left-0 h-full bg-primary-gold rounded-[8px]"
                        style={{ width: `${((currentQuestionIndex + 1) / quizData.questions.length) * 100}%` }}
                      />
                      <div className="absolute inset-0 flex items-center justify-center text-[10px] sm:text-xs text-white font-medium">
                        {currentQuestionIndex + 1} of {quizData.questions.length}
                      </div>
                    </div>
                  </div>

                  {/* Total Points */}
                  <div className="rounded-[8px] bg-primary-gold flex items-center justify-center p-2 sm:p-3 text-[#361757]">
                    <div className="text-sm sm:text-sm font-semibold ml-1">{totalUserPoints}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Question and Instructions */}
          <div className="absolute top-[138px] left-[5%] lg:left-[calc(50%_-_351px)] w-[90%] lg:w-[703px] flex flex-col items-start justify-center pt-[0px] px-[0px] pb-[10px] gap-[2px] text-center text-sm text-primary-gold
                          mq850:top-[130px] mq850:left-[2.5%] mq850:w-[95%]">
            <b className="self-stretch leading-[26px] mq850:text-base mq850:leading-[22px]">{currentQuestion.text}</b>
            <div className="self-stretch text-xs leading-[16px] text-default-white mq850:text-[10px] mq850:leading-[14px]">
              {quizData.quiz_type === "objective"
                ? "Please choose one of the options below and confirm your answer by clicking the 'Confirm' button"
                : "Please type your answer in the text box below and confirm by clicking the 'Confirm' button"}
            </div>
          </div>

          {/* Options or Text Box */}
          <div className="absolute top-[30%] left-[5%] lg:left-[calc(50%_-_331px)] w-[90%] lg:w-[661px] flex flex-col items-center justify-start gap-[18px]
                          mq850:top-[40%] mq850:left-[2.5%] mq850:w-[95%] mq850:gap-[12px]">
            {quizData.quiz_type === "objective" ? (
              currentQuestion.choices.map((choice) => (
                <div
                  key={choice.id}
                  className={`self-stretch rounded-[10px] ${selectedOption === choice ? 'bg-primary-gold' : 'bg-[rgba(20,184,185,0.3)]'} h-[44px] flex flex-row items-center justify-between py-[0px] pl-[20px] pr-[8px] box-border cursor-pointer
                              mq850:h-[36px] mq850:pl-[12px] mq850:pr-[6px]`}
                  onClick={() => handleOptionClick(choice)}
                >
                  <div className="leading-[16px] mq850:text-xs">{choice.text}</div>
                  {selectedOption === choice && (
                    <img className="w-[24px] h-[24px] mq850:w-[18px] mq850:h-[18px]" alt="Checked Icon" src="/learning/checked.svg" />
                  )}
                </div>
              ))
            ) : (
              <textarea
                className="w-full h-[200px] p-3 rounded-[10px] bg-[rgba(20,184,185,0.3)] text-white resize-none"
                value={theoryAnswer}
                onChange={handleTheoryAnswerChange}
                placeholder="Type your answer here..."
              />
            )}
          </div>

          {/* Buttons Section */}
          <div className="absolute bottom-[10%] left-[5%] lg:left-[calc(50%_-_293px)] w-[90%] lg:w-[586px] flex flex-col items-center justify-center gap-4
                          mq850:bottom-[5%] mq850:left-[2.5%] mq850:w-[95%]">
            {/* Skip and Confirm Buttons */}
            <div className="w-full flex flex-row items-center justify-center gap-[29px] text-[#fdc872]
                            mq850:gap-[15px]">
              <div
                className={`rounded-[10px] flex flex-row items-center justify-center py-[12px] px-[0px] gap-[1px] ${currentQuestionIndex === quizData.questions.length - 1 ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer'
                  } mq850:py-[8px]`}
                onClick={currentQuestionIndex === quizData.questions.length - 1 ? undefined : handleSkip}
              >
                <span className="font-semibold mq850:text-xs">Skip</span>
                <span className="font-black mq850:text-xs"> (-1</span>
                <img className="w-[12px] h-[12px] mq850:w-[10px] mq850:h-[10px]" alt="Ticket Icon" src="/learning/ticket1.svg" />
                <span className="font-extrabold mq850:text-xs">)</span>
              </div>
              <div className="relative text-xl font-medium text-[rgba(255,255,255,0.5)] mq850:text-base">
                /
              </div>
              <div
                className="w-[271px] flex flex-col items-center justify-start text-sm text-default-white font-poppins cursor-pointer
                           mq850:w-[200px]"
                onClick={handleConfirmClick}
              >
                <div className="self-stretch rounded-[10px] bg-[rgba(20,184,185,0.2)] h-[44px] flex flex-col items-center justify-center
                                mq850:h-[36px]">
                  <div className="leading-[16px] font-medium mq850:text-xs">Confirm</div>
                </div>
              </div>
            </div>

            {/* Cancel Quiz Button */}
            <div
              className="w-full flex flex-col items-center justify-start text-sm text-default-white font-poppins cursor-pointer"
              onClick={handleCancelQuiz}
            >
              <div className="self-stretch rounded-[10px] bg-[rgba(255,0,0,0.2)] h-[44px] flex flex-col items-center justify-center
                              mq850:h-[36px]">
                <div className="leading-[16px] font-medium mq850:text-xs">Cancel Quiz</div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default AIQuizQuestions;