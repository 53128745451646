import PropTypes from "prop-types";

const LeaderboardPagination = ({ className = "" }) => {
  return (
    <div className="absolute top-[935px] left-[76px] w-[460px] h-[55px] text-sm text-[#10027e]">
        <div className="absolute top-[0px] left-[41px] rounded-[10px] bg-default-white w-[376px] h-[55px]" />
        <div className="absolute top-[11px] left-[76px] rounded-[10px] bg-background-main w-[20px] h-[34.9px]" />
        <div className="absolute top-[11px] left-[123.6px] rounded-[10px] bg-[#f1f0ff] w-[20px] h-[34.9px]" />
        <div className="absolute top-[11px] left-[171.2px] rounded-[10px] bg-[#f1f0ff] w-[20px] h-[34.9px]" />
        <div className="absolute top-[11px] left-[218.7px] rounded-[10px] bg-[#f1f0ff] w-[20px] h-[34.9px]" />
        <div className="absolute top-[11px] left-[266.3px] rounded-[10px] bg-[#f1f0ff] w-[20px] h-[34.9px]" />
        <div className="absolute top-[11px] left-[313.9px] rounded-[10px] bg-[#f1f0ff] w-[20px] h-[34.9px]" />
        <div className="absolute top-[11px] left-[361.5px] rounded-[10px] bg-[#f1f0ff] w-[20px] h-[34.9px]" />
        <img
          className="absolute top-[15px] left-[430px] w-[30px] h-[28.5px] object-contain"
          alt=""
          src="/profile/group-29@2x.png"
        />
        <img
          className="absolute top-[15px] left-[0px] w-[30px] h-[28.5px] object-contain"
          alt=""
          src="/profile/group-28@2x.png"
        />
        <div className="absolute top-[19px] left-[77px] text-default-white inline-block w-[16.6px] h-[29px]">
          1
        </div>
        <div className="absolute top-[19px] left-[124.9px] inline-block w-[16.6px] h-[29px]">
          2
        </div>
        <div className="absolute top-[19px] left-[172.9px] inline-block w-[16.6px] h-[29px]">
          3
        </div>
        <div className="absolute top-[19px] left-[220.9px] inline-block w-[16.6px] h-[29px]">
          4
        </div>
        <div className="absolute top-[19px] left-[268.9px] inline-block w-[16.6px] h-[29px]">
          5
        </div>
        <div className="absolute top-[19px] left-[316.9px] inline-block w-[16.6px] h-[29px]">
          6
        </div>
        <div className="absolute top-[19px] left-[364.9px] inline-block w-[16.6px] h-[29px]">
          7
        </div>
      </div>
  );
};

LeaderboardPagination.propTypes = {
  className: PropTypes.string,
};

export default LeaderboardPagination;
