import { useNavigate } from "react-router-dom";

const Quiz = () => {

  const navigate = useNavigate();
  return (
    <div className="w-full relative bg-[#fbf1f1] h-[1036px] overflow-hidden text-left text-base text-default-white font-poppins">
      <div className="absolute top-[161px] left-[440px] rounded-[8px] w-[736px] h-[510px] bg-cover bg-no-repeat bg-[top] text-center font-menu-menu-1-bold">
        <img
          className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded-[8px] max-w-full overflow-hidden max-h-full object-cover"
          alt=""
          src="/learning/video-player1@2x.png"
        />
        <div className="absolute h-[10.98%] w-[97.83%] top-[87.45%] right-[1.09%] bottom-[1.57%] left-[1.09%] hidden text-left text-xs text-light-text-color-body-2">
          <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded-[8px] bg-black" />
          <img
            className="absolute h-[85.71%] w-[6.67%] top-[7.14%] right-[2.22%] bottom-[7.14%] left-[91.11%] max-w-full overflow-hidden max-h-full object-contain"
            alt=""
            src="/learning/icon9@2x.png"
          />
         
          <div className="absolute h-[42.86%] w-[59.72%] top-[28.57%] right-[22.5%] bottom-[28.57%] left-[17.78%]">
            <div className="absolute h-[16.67%] w-full top-[41.67%] right-[0%] bottom-[41.67%] left-[0%] rounded-[2px] bg-gainsboro-100 opacity-[0.6]" />
            <div className="absolute h-[16.67%] w-[39.07%] top-[41.67%] right-[60.93%] bottom-[41.67%] left-[0%] rounded-[2px] bg-primary-gold" />
            <div className="absolute h-full w-[5.58%] top-[0%] right-[59.07%] bottom-[0%] left-[35.35%] shadow-[0px_4px_8px_rgba(105,_105,_105,_0.1)] rounded-[28px] bg-default-white" />
          </div>
          <img
            className="absolute h-[85.71%] w-[6.67%] top-[7.14%] right-[84.44%] bottom-[7.14%] left-[8.89%] max-w-full overflow-hidden max-h-full object-contain"
            alt=""
            src="/learning/icon10@2x.png"
          />
          <div className="absolute h-[85.71%] w-[6.67%] top-[7.14%] right-[91.11%] bottom-[7.14%] left-[2.22%]">
            <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded-[8px] opacity-[0]" />
            <div className="absolute h-[66.67%] w-[66.67%] top-[16.67%] right-[16.67%] bottom-[16.67%] left-[16.67%]">
              <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%]">
                <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%]" />
                <img
                  className="absolute h-[51.56%] w-[42.19%] top-[24.38%] right-[28.75%] bottom-[24.06%] left-[29.06%] max-w-full overflow-hidden max-h-full"
                  alt=""
                  src="/learning/icon-color.svg"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="absolute h-[9.41%] w-[43.48%] top-[50%] right-[28.26%] bottom-[40.59%] left-[28.26%] rounded-[28px] border-primary-gold border-[1px] border-solid box-border flex flex-row items-center justify-center py-[14px] px-[24px]"
       onClick={() => navigate("/start-quiz")}
        >
          <b className="relative tracking-[0.01em] leading-[120%] cursor-pointer">Open Quiz</b>
        </div>
        <b className="absolute top-[calc(50%_+_74px)] left-[calc(50%_-_76px)] tracking-[0.01em] leading-[120%]">
          Skip to Next Lesson
        </b>
      </div>
      <div className="absolute top-[224px] left-[61px] w-[341px] flex flex-col items-start justify-start text-gray-100">
        <div className="self-stretch relative h-[72px]">
          <img
            className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] max-w-full overflow-hidden max-h-full"
            alt=""
            src="/learning/container6.svg"
          />
          <div className="absolute h-[1.39%] w-[91.2%] top-[99.31%] right-[4.4%] bottom-[-0.69%] left-[4.4%] bg-[#e1e1fb] border-[#e1e1fb] border-t-[1px] border-solid box-border" />
          <div className="absolute h-[33.33%] w-[6.83%] top-[33.33%] right-[6.8%] bottom-[33.33%] left-[86.36%]">
            <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded-[4px] opacity-[0]" />
            <img
              className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] max-w-full overflow-hidden max-h-full"
              alt=""
              src="/learning/ui--chevron-down2.svg"
            />
          </div>
          <b className="absolute top-[33.33%] left-[6.83%] tracking-[0.01em] leading-[120%]">
            Introduction
          </b>
        </div>
        <div className="self-stretch relative h-[72px]">
          <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%]">
            <img
              className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] max-w-full overflow-hidden max-h-full"
              alt=""
              src="/learning/container7.svg"
            />
            <div className="absolute h-[1.39%] w-[91.2%] top-[99.31%] right-[4.4%] bottom-[-0.69%] left-[4.4%] bg-[#e1e1fb] border-[#e1e1fb] border-t-[1px] border-solid box-border" />
            <div className="absolute h-[33.33%] w-[6.83%] top-[33.33%] right-[6.51%] bottom-[33.33%] left-[86.66%]">
              <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded-[4px] opacity-[0]" />
              <img
                className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] max-w-full overflow-hidden max-h-full"
                alt=""
                src="/learning/ui--chevron-down2.svg"
              />
            </div>
            <div className="absolute top-[33.33%] left-[6.83%] tracking-[0.01em] leading-[120%] font-medium">
              Lorem Ipsum Theory
            </div>
          </div>
        </div>
        <div className="self-stretch relative h-[168px] text-sm text-black">
          <img
            className="absolute top-[0px] left-[0px] w-[341px] h-[168px]"
            alt=""
            src="/learning/container8.svg"
          />
          <div className="absolute top-[24px] left-[23.2px] w-[302.3px] h-[39px] text-gray-100">
            <div className="absolute top-[3px] left-[31.1px] tracking-[0.01em] leading-[130%] inline-block w-[211.2px]">{`Lorem Ipsum comes from sections 1.10.32 `}</div>
            <div className="absolute top-[5px] left-[257.7px] text-xs tracking-[0.01em] leading-[120%] font-menu-menu-1-bold text-[#92929d] text-right inline-block w-[44.6px]">
              05.32
            </div>
            <img
              className="absolute h-[61.54%] w-[8.83%] top-[0%] right-[91.17%] bottom-[38.46%] left-[0%] max-w-full overflow-hidden max-h-full object-cover"
              alt=""
              src="/learning/av--play-circle-outline@2x.png"
            />
          </div>
          <div className="absolute top-[74px] left-[23.2px] w-[302.3px] h-[39px]">
            <div className="absolute top-[3px] left-[31.1px] tracking-[0.01em] leading-[130%] inline-block w-[211.2px]">
              and 1.10.33 of "de Finibus Bonorum et Malorum"
            </div>
            <div className="absolute top-[5px] left-[257.7px] text-xs tracking-[0.01em] leading-[120%] font-menu-menu-1-bold text-[#92929d] text-right inline-block w-[44.6px]">
              07.51
            </div>
            <img
              className="absolute h-[61.54%] w-[8.83%] top-[0%] right-[91.17%] bottom-[38.46%] left-[0%] max-w-full overflow-hidden max-h-full object-cover"
              alt=""
              src="/learning/av--play-circle-outline@2x.png"
            />
          </div>
          <div className="absolute top-[124px] left-[23.2px] w-[62px] h-[24px]">
            <div className="absolute top-[3px] left-[31.1px] tracking-[0.01em] leading-[130%]">
              Quiz
            </div>
            <img
              className="absolute h-full w-[43.06%] top-[0%] right-[56.94%] bottom-[0%] left-[0%] max-w-full overflow-hidden max-h-full object-cover"
              alt=""
              src="/learning/av--play-circle-outline@2x.png"
            />
          </div>
          <div className="absolute h-[0.6%] w-[91.2%] top-[99.7%] right-[4.4%] bottom-[-0.3%] left-[4.4%] bg-[#e1e1fb] border-[#e1e1fb] border-t-[1px] border-solid box-border" />
        </div>
        <div className="self-stretch relative h-[72px]">
          <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%]">
            <img
              className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] max-w-full overflow-hidden max-h-full"
              alt=""
              src="/learning/container7.svg"
            />
            <div className="absolute h-[1.39%] w-[91.2%] top-[99.31%] right-[4.4%] bottom-[-0.69%] left-[4.4%] bg-[#e1e1fb] border-[#e1e1fb] border-t-[1px] border-solid box-border" />
            <div className="absolute h-[33.33%] w-[6.83%] top-[33.33%] right-[6.51%] bottom-[33.33%] left-[86.66%]">
              <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded-[4px] opacity-[0]" />
              <img
                className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] max-w-full overflow-hidden max-h-full"
                alt=""
                src="/learning/ui--chevron-down2.svg"
              />
            </div>
            <div className="absolute top-[33.33%] left-[6.83%] tracking-[0.01em] leading-[120%] font-medium">
              Lorem Ipsum for Newbiew
            </div>
          </div>
        </div>
        <div className="self-stretch relative h-[72px]">
          <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%]">
            <img
              className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] max-w-full overflow-hidden max-h-full"
              alt=""
              src="/learning/container7.svg"
            />
            <div className="absolute h-[1.39%] w-[91.2%] top-[99.31%] right-[4.4%] bottom-[-0.69%] left-[4.4%] bg-[#e1e1fb] border-[#e1e1fb] border-t-[1px] border-solid box-border" />
            <div className="absolute h-[33.33%] w-[6.83%] top-[33.33%] right-[6.51%] bottom-[33.33%] left-[86.66%]">
              <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded-[4px] opacity-[0]" />
              <img
                className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] max-w-full overflow-hidden max-h-full"
                alt=""
                src="/learning/ui--chevron-down2.svg"
              />
            </div>
            <div className="absolute top-[33.33%] left-[6.83%] tracking-[0.01em] leading-[120%] font-medium">
              Lorem Ipsum Getting Started
            </div>
          </div>
        </div>
        <div className="self-stretch relative h-[72px]">
          <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%]">
            <img
              className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] max-w-full overflow-hidden max-h-full"
              alt=""
              src="/learning/container7.svg"
            />
            <div className="absolute h-[1.39%] w-[91.2%] top-[99.31%] right-[4.4%] bottom-[-0.69%] left-[4.4%] bg-[#e1e1fb] border-[#e1e1fb] border-t-[1px] border-solid box-border" />
            <div className="absolute h-[33.33%] w-[6.83%] top-[33.33%] right-[6.51%] bottom-[33.33%] left-[86.66%]">
              <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded-[4px] opacity-[0]" />
              <img
                className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] max-w-full overflow-hidden max-h-full"
                alt=""
                src="/learning/ui--chevron-down2.svg"
              />
            </div>
            <div className="absolute top-[33.33%] left-[6.83%] tracking-[0.01em] leading-[120%] font-medium">
              Placeholders for History Geeks
            </div>
          </div>
        </div>
        <div className="self-stretch relative h-[72px]">
          <img
            className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] max-w-full overflow-hidden max-h-full"
            alt=""
            src="/learning/container9.svg"
          />
          <div className="absolute h-[33.33%] w-[6.83%] top-[33.33%] right-[6.8%] bottom-[33.33%] left-[86.36%]">
            <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded-[4px] opacity-[0]" />
            <img
              className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] max-w-full overflow-hidden max-h-full"
              alt=""
              src="/learning/ui--chevron-down2.svg"
            />
          </div>
          <div className="absolute top-[33.33%] left-[6.83%] tracking-[0.01em] leading-[120%] font-medium">
            Designing for iOS and Android
          </div>
        </div>
      </div>
      <div className="absolute top-[699px] left-[440px] w-[787px] flex flex-col items-start justify-start py-[20px] pl-[20px] pr-[43px] box-border text-5xl text-[#3d3d3d]">
        <b className="relative leading-[100%]">Quiz - 4 Lorem Ipsum</b>
      </div>
      <div className="absolute top-[858px] left-[61px] rounded-[8px] bg-buttonselect w-[341px] h-[47px] text-center" onClick={() => navigate("/start-quiz")}>
        <b className="absolute top-[calc(50%_-_9.5px)] left-[calc(50%_-_40.5px)] tracking-[0.01em] leading-[120%] cursor-pointer">
          Take Quiz
        </b>
      </div>
      <b className="absolute top-[161px] left-[61px] text-5xl tracking-[0.01em] leading-[120%] font-menu-menu-1-bold text-gray-100">
        Lessons
      </b>
      <div className="absolute w-[calc(100%_-_2px)] top-[0px] right-[2px] left-[0px] bg-default-white border-[#efefef] border-b-[1px] border-solid box-border flex flex-row items-center justify-start py-[20px] pl-[41px] pr-[40px] gap-[321px] text-background-main">
        <div className="flex flex-row items-center justify-start gap-[10px]">
          <img
            className="w-[40px] rounded-11xl h-[40px] overflow-hidden shrink-0 object-contain cursor-pointer"
            alt=""
            src="/learning/iconamoonarrowup2@2x.png"
            onClick={() => navigate("/course")}
          />
          <div className="flex flex-row items-start justify-start p-[20px]">
            <div className="relative leading-[100%]">Quiz - 4</div>
          </div>
        </div>
        <div className="flex flex-row items-center justify-start gap-[60px] text-primary-gold">
          <div className="flex flex-row items-start justify-start gap-[20px]">
            <div className="rounded-[9px] bg-background-main flex flex-row items-start justify-start">
              <div className="flex flex-row items-start justify-start p-[20px]">
                <div className="relative leading-[100%]">Prev</div>
              </div>
            </div>
            <div className="rounded-[9px] bg-background-main flex flex-row items-start justify-start">
              <div className="flex flex-row items-start justify-start p-[20px]">
                <div className="relative leading-[100%]">Next</div>
              </div>
            </div>
          </div>
          <div className="rounded-[9px] bg-[rgba(26,54,81,0.2)] flex flex-row items-start justify-start">
            <div className="flex flex-row items-start justify-start p-[20px]">
              <div className="relative leading-[100%]">Mark as Complete</div>
            </div>
          </div>
          <div className="border-[#efefef] border-l-[1px] border-solid flex flex-row items-center justify-start py-[0px] px-[40px] text-[#3d3d3d]">
            <div className="w-[176px] relative h-[56px]">
              <img
                className="absolute h-full w-[31.82%] top-[0%] right-[68.18%] bottom-[0%] left-[0%] max-w-full overflow-hidden max-h-full object-cover"
                alt=""
                src="/learning/avatar1@2x.png"
              />
              <div className="absolute top-[20px] left-[64px] leading-[100%]">
                Sallay Sankoh
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Quiz;
