import React, { useState } from 'react';

// Mock API function (unchanged)
const generateQuestions = async (topic, difficulty, numQuestions) => {
    // Simulate API call delay
    await new Promise(resolve => setTimeout(resolve, 1000));
  
    const questions = [
      {
        question: `What is the capital of France?`,
        answer: `Paris`
      },
      {
        question: `Who wrote "Romeo and Juliet"?`,
        answer: `William Shakespeare`
      },
      {
        question: `What is the chemical symbol for gold?`,
        answer: `Au`
      },
      {
        question: `What year did World War II end?`,
        answer: `1945`
      },
      {
        question: `What is the largest planet in our solar system?`,
        answer: `Jupiter`
      }
    ];
  
    // Return a subset of questions based on numQuestions
    return questions.slice(0, numQuestions);
  };

const FlashcardApp = () => {
    const [topic, setTopic] = useState('');
    const [difficulty, setDifficulty] = useState('');
    const [numQuestions, setNumQuestions] = useState(5);
    const [flashcards, setFlashcards] = useState([]);
    const [currentCardIndex, setCurrentCardIndex] = useState(0);
    const [isFlipped, setIsFlipped] = useState(false);
    const [isGenerating, setIsGenerating] = useState(false);
  
    const handleSubmit = async (e) => {
      e.preventDefault();
      setIsGenerating(true);
      try {
        const generatedCards = await generateQuestions(topic, difficulty, numQuestions);
        setFlashcards(generatedCards);
        setCurrentCardIndex(0);
        setIsFlipped(false);
      } catch (error) {
        console.error('Error generating flashcards:', error);
        // Handle error (e.g., show error message to user)
      } finally {
        setIsGenerating(false);
      }
    };
  
    const handleFlip = () => {
      setIsFlipped(!isFlipped);
    };
  
    const handleNext = () => {
      if (currentCardIndex < flashcards.length - 1) {
        setCurrentCardIndex(currentCardIndex + 1);
        setIsFlipped(false);
      }
    };
  
    const handlePrevious = () => {
      if (currentCardIndex > 0) {
        setCurrentCardIndex(currentCardIndex - 1);
        setIsFlipped(false);
      }
    };
  
    return (
      <div className="container mx-auto mt-10 px-4">
        {flashcards.length === 0 ? (
          <div className="max-w-md mx-auto bg-white shadow-lg rounded-lg overflow-hidden">
            <div className="px-6 py-4">
              <h2 className="text-2xl font-bold text-center mb-4 text-gray-800">Flashcard Setup</h2>
              <form onSubmit={handleSubmit}>
                <div className="mb-4">
                  <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="topic">
                    Topic
                  </label>
                  <input
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="topic"
                    type="text"
                    value={topic}
                    onChange={(e) => setTopic(e.target.value)}
                    required
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="difficulty">
                    Difficulty
                  </label>
                  <select
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="difficulty"
                    value={difficulty}
                    onChange={(e) => setDifficulty(e.target.value)}
                    required
                  >
                    <option value="">Select difficulty</option>
                    <option value="Easy">Easy</option>
                    <option value="Medium">Medium</option>
                    <option value="Hard">Hard</option>
                  </select>
                </div>
                <div className="mb-6">
                  <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="numQuestions">
                    Number of Questions
                  </label>
                  <input
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="numQuestions"
                    type="number"
                    value={numQuestions}
                    onChange={(e) => setNumQuestions(Number(e.target.value))}
                    required
                    min="1"
                    max="20"
                  />
                </div>
                <button
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full"
                  type="submit"
                  disabled={isGenerating}
                >
                  {isGenerating ? 'Generating...' : 'Generate Flashcards'}
                </button>
              </form>
            </div>
          </div>
        ) : (
          <div className="flex flex-col items-center justify-center h-[70vh]">
            <div
              className={`w-[300px] h-[200px] perspective-1000 cursor-pointer ${
                isFlipped ? 'rotate-y-180' : ''
              } transition-transform duration-600 transform-style-preserve-3d`}
              onClick={handleFlip}
            >
              <div className="absolute w-full h-full backface-hidden flex flex-col justify-center items-center p-6 rounded-lg bg-white shadow-md">
                <h2 className="text-2xl font-bold mb-4 text-gray-800">Question</h2>
                <p className="text-lg text-gray-600">{flashcards[currentCardIndex].question}</p>
              </div>
              <div className="absolute w-full h-full backface-hidden flex flex-col justify-center items-center p-6 rounded-lg bg-blue-500 text-white shadow-md rotate-y-180">
                <h2 className="text-2xl font-bold mb-4">Answer</h2>
                <p className="text-lg">{flashcards[currentCardIndex].answer}</p>
              </div>
            </div>
            <div className="mt-[80px] flex items-center justify-center">
              <button
                className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded-l"
                onClick={handlePrevious}
                disabled={currentCardIndex === 0}
              >
                Previous
              </button>
              <span className="mx-4 text-lg font-semibold">
                {currentCardIndex + 1} / {flashcards.length}
              </span>
              <button
                className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded-r"
                onClick={handleNext}
                disabled={currentCardIndex === flashcards.length - 1}
              >
                Next
              </button>
            </div>
          </div>
        )}
      </div>
    );
  };
  
  export default FlashcardApp;