import Button1 from "../../components/groups/Button1";
import Progress3 from "../../components/groups/Progress3";
import Toggle from "../../components/groups/Toggle";
import { useNavigate } from "react-router-dom";


const GroupsPreferencesScreen2 = () => {
  const navigate = useNavigate();
const handleGoBack = () => {
    navigate(-1); // This will navigate back to the previous screen
  };
  return (
    <div className="w-full relative bg-default-white h-[100vh] overflow-hidden text-left text-sm text-[#686b6d] font-poppins">
      <img
        className="absolute top-[-317px] left-[-399px] w-[1276.7px] h-[1305.2px] object-contain hidden"
        alt=""
        src="/o11@2x.png"
      />
      <img
        className="absolute top-[442px] left-[calc(50%_-_950px)] w-[526.8px] h-[526.8px] object-contain"
        alt=""
        src="/easystem-4-1@2x.png"
      />
      <img
        className="absolute top-[-325px] left-[-68px] w-[1933.8px] h-[1738.8px] object-contain"
        alt=""
        src="/preference.svg"
      />
      <img
        className="absolute top-[-120px] left-[calc(50%_+_450px)] w-[562px] h-[562px] object-contain"
        alt=""
        src="/easystem-4-22@2x.png"
      />
      <Button1
        button="Next"
        buttonWidth="370px"
        buttonPosition="absolute"
        buttonBackgroundColor="#055db2"
        buttonBottom="86px"
        buttonLeft="calc(50% - 185px)"
        buttonFontWeight="bold"
        buttonFontFamily="Poppins"
      />
      <div className="absolute top-[106px] left-[255px] w-[185px] h-[105px]">
        <img
          className="absolute top-[0px] left-[0px] rounded-[3px] w-[185px] h-[105px]"
          alt=""
          src="/vector-1.svg"
        />
        <div className="absolute top-[10px] left-[29px] leading-[150%] font-medium flex items-center justify-center w-[148px] h-[84px]">
          Now, enable or disable features for your group.
        </div>
      </div>
      <img
        className="absolute h-[18.03%] w-[11.72%] top-[10.46%] right-[80.08%] bottom-[71.51%] left-[8.2%] max-w-full overflow-hidden max-h-full object-cover"
        alt=""
        src="/download-1-1@2x.png"
      />
      <div className="absolute top-[calc(50%_-_382px)] left-[calc(50%_-_385px)] w-[921px] h-[53px] text-right text-buttonselect font-[Francy]">
        <Progress3
          progressWidth="871px"
          progressPosition="absolute"
          progressHeight="21px"
          progressTop="calc(50% - 21.5px)"
          progressLeft="calc(50% - 410.5px)"
          rectangleDivBackgroundColor="rgba(5, 93, 178, 0.1)"
          rectangleDivRight="0%"
          rectangleDivBackgroundColor1="#f09a19"
        />
        <div className="absolute top-[60.38%] left-[96.53%] leading-[150%]">
          2 of 3
        </div>
        <img
          onClick={handleGoBack} // Go back when this icon is clicked
          className="absolute h-[60.38%] w-[3.47%] top-[0%] right-[96.53%] bottom-[39.62%] left-[0%] max-w-full overflow-hidden max-h-full cursor-pointer"
          alt="Go Back"
          src="/icon.svg"
        />
      </div>
      <div className="absolute h-[47.48%] w-[32.81%] top-[30.41%] right-[33.59%] bottom-[22.12%] left-[33.59%] text-left text-[#0c092a]">
        <div className="absolute h-[6.08%] w-full top-[0%] right-[0%] bottom-[93.92%] left-[0%]">
          <div className="absolute h-[166.67%] w-[77.86%] top-[-33.33%] right-[24.05%] bottom-[-33.33%] left-[-1.9%] rounded-[16px] bg-[#f2f2f2] hidden" />
          <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%]">
            <div className="absolute top-[0%] left-[0%] leading-[150%] font-medium">
              File Sharing
            </div>
            <Toggle
              toggleWidth="10.48%"
              togglePosition="absolute"
              toggleHeight="100%"
              toggleTop="0%"
              toggleRight="0%"
              toggleBottom="0%"
              toggleLeft="89.52%"
              rectangleDivBackgroundColor="#f09a19"
            />
          </div>
        </div>
        <div className="absolute h-[6.08%] w-full top-[13.42%] right-[0%] bottom-[80.51%] left-[0%]">
          <div className="absolute h-[166.67%] w-[77.86%] top-[-33.33%] right-[24.05%] bottom-[-33.33%] left-[-1.9%] rounded-[16px] bg-[#f2f2f2] hidden" />
          <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%]">
            <div className="absolute top-[0%] left-[0%] leading-[150%] font-medium">
              Messaging
            </div>
            <Toggle
              toggleWidth="10.48%"
              togglePosition="absolute"
              toggleHeight="100%"
              toggleTop="0%"
              toggleRight="0%"
              toggleBottom="0%"
              toggleLeft="89.52%"
              rectangleDivBackgroundColor="#f09a19"
            />
          </div>
        </div>
        <div className="absolute h-[6.08%] w-full top-[26.84%] right-[0%] bottom-[67.09%] left-[0%]">
          <div className="absolute h-[166.67%] w-[77.86%] top-[-33.33%] right-[24.05%] bottom-[-33.33%] left-[-1.9%] rounded-[16px] bg-[#f2f2f2] hidden" />
          <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%]">
            <div className="absolute top-[0%] left-[0%] leading-[150%] font-medium">
              Discussion Forums
            </div>
            <Toggle
              toggleWidth="10.48%"
              togglePosition="absolute"
              toggleHeight="100%"
              toggleTop="0%"
              toggleRight="0%"
              toggleBottom="0%"
              toggleLeft="89.52%"
              rectangleDivBackgroundColor="#f09a19"
            />
          </div>
        </div>
        <div className="absolute h-[6.08%] w-full top-[40.25%] right-[0%] bottom-[53.67%] left-[0%]">
          <div className="absolute h-[166.67%] w-[77.86%] top-[-33.33%] right-[24.05%] bottom-[-33.33%] left-[-1.9%] rounded-[16px] bg-[#f2f2f2] hidden" />
          <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%]">
            <div className="absolute top-[0%] left-[0%] leading-[150%] font-medium">
              Calendar Integration
            </div>
            <Toggle
              toggleWidth="10.48%"
              togglePosition="absolute"
              toggleHeight="100%"
              toggleTop="0%"
              toggleRight="0%"
              toggleBottom="0%"
              toggleLeft="89.52%"
              rectangleDivBackgroundColor="#f09a19"
            />
          </div>
        </div>
        <div className="absolute h-[6.08%] w-full top-[53.67%] right-[0%] bottom-[40.25%] left-[0%]">
          <div className="absolute h-[166.67%] w-[77.86%] top-[-33.33%] right-[24.05%] bottom-[-33.33%] left-[-1.9%] rounded-[16px] bg-[#f2f2f2] hidden" />
          <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%]">
            <div className="absolute top-[0%] left-[0%] leading-[150%] font-medium">
              Polls and Survey
            </div>
            <Toggle
              toggleWidth="10.48%"
              togglePosition="absolute"
              toggleHeight="100%"
              toggleTop="0%"
              toggleRight="0%"
              toggleBottom="0%"
              toggleLeft="89.52%"
              rectangleDivBackgroundColor="#f09a19"
            />
          </div>
        </div>
        <div className="absolute h-[6.08%] w-full top-[67.09%] right-[0%] bottom-[26.84%] left-[0%]">
          <div className="absolute h-[166.67%] w-[77.86%] top-[-33.33%] right-[24.05%] bottom-[-33.33%] left-[-1.9%] rounded-[16px] bg-[#f2f2f2] hidden" />
          <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%]">
            <div className="absolute top-[0%] left-[0%] leading-[150%] font-medium">
              Notifications
            </div>
            <Toggle
              toggleWidth="10.48%"
              togglePosition="absolute"
              toggleHeight="100%"
              toggleTop="0%"
              toggleRight="0%"
              toggleBottom="0%"
              toggleLeft="89.52%"
              rectangleDivBackgroundColor="#f09a19"
            />
          </div>
        </div>
        <div className="absolute h-[6.08%] w-full top-[80.51%] right-[0%] bottom-[13.42%] left-[0%]">
          <div className="absolute h-[166.67%] w-[77.86%] top-[-33.33%] right-[24.05%] bottom-[-33.33%] left-[-1.9%] rounded-[16px] bg-[#f2f2f2] hidden" />
          <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%]">
            <div className="absolute top-[0%] left-[0%] leading-[150%] font-medium">
              Resource Library
            </div>
            <Toggle
              toggleWidth="10.48%"
              togglePosition="absolute"
              toggleHeight="100%"
              toggleTop="0%"
              toggleRight="0%"
              toggleBottom="0%"
              toggleLeft="89.52%"
              rectangleDivBackgroundColor="#f09a19"
            />
          </div>
        </div>
        <div className="absolute h-[6.08%] w-full top-[93.92%] right-[0%] bottom-[0%] left-[0%]">
          <div className="absolute h-[166.67%] w-[77.86%] top-[-33.33%] right-[24.05%] bottom-[-33.33%] left-[-1.9%] rounded-[16px] bg-[#f2f2f2] hidden" />
          <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%]">
            <div className="absolute top-[0%] left-[0%] leading-[150%] font-medium">
              Task Management
            </div>
            <Toggle
              toggleWidth="10.48%"
              togglePosition="absolute"
              toggleHeight="100%"
              toggleTop="0%"
              toggleRight="0%"
              toggleBottom="0%"
              toggleLeft="89.52%"
              rectangleDivBackgroundColor="#f09a19"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default GroupsPreferencesScreen2;
