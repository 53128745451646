import PropTypes from "prop-types";

const CourseProgress = ({ className = "", progress = 0, points = 0 }) => {
  // Calculate stroke dash array and offset for circular progress
  const radius = 44;
  const circumference = 2 * Math.PI * radius;
  const strokeDashoffset = circumference - (progress / 100) * circumference;

  return (
    <div
      className={`relative w-[19.188rem] h-[14rem] text-left text-[1rem] text-gray-500 font-poppins ${className}`}
    >
      <div className="absolute top-0 left-0 rounded-lg bg-default-white w-[17.188rem] shadow-md p-4">
        <div className="tracking-[0.01em] leading-[120%] font-medium">
          Course Progress
        </div>
        <div className="flex justify-center items-center mt-6">
          {/* SVG for circular progress */}
          <svg width="100" height="100" className="relative">
            <circle
              cx="50"
              cy="50"
              r={radius}
              stroke="#1a3651" 
              strokeWidth="6"
              fill="none"
            />
            <circle
              cx="50"
              cy="50"
              r={radius}
              stroke="#FBBF24" // Progress circle color (primary gold)
              strokeWidth="6"
              fill="none"
              strokeDasharray={circumference}
              strokeDashoffset={strokeDashoffset}
              strokeLinecap="round"
              transform="rotate(-90 50 50)" // Start from the top, rotate clockwise
            />
            <text
              x="50%"
              y="50%"
              dominantBaseline="middle"
              textAnchor="middle"
              className="text-black text-[1rem] font-medium"
            >
              {progress}%
            </text>
          </svg>
        </div>
        <div className="flex justify-center items-center mt-4 text-gray-900">
          <span className="font-medium">Your Point:</span>
          <b className="ml-2 text-primary-gold">{points}</b>
        </div>
      </div>
    </div>
  );
};

CourseProgress.propTypes = {
  className: PropTypes.string,
  progress: PropTypes.number, // The current progress percentage
  points: PropTypes.number,   // The current points earned
};

export default CourseProgress;
