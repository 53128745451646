import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

// Full data set for 12 months
const data = [
  { month: 'Jun 2024', value1: 12, value2: 15 },
  { month: 'Jul 2024', value1: 15, value2: 18 },
  { month: 'Aug 2024', value1: 25, value2: 22 },
  { month: 'Sep 2024', value1: 20, value2: 24 },
  { month: 'Oct 2024', value1: 30, value2: 28 },
  { month: 'Nov 2024', value1: 20, value2: 25 },
  { month: 'Dec 2024', value1: 25, value2: 30 },
  { month: 'Jan 2025', value1: 30, value2: 20 },
  { month: 'Feb 2025', value1: 35, value2: 30 },
  { month: 'Mar 2025', value1: 30, value2: 35 },
  { month: 'Apr 2025', value1: 45, value2: 40 },
  { month: 'May 2025', value1: 35, value2: 45 },
];

const TotalPoints = ({ className = "" }) => {
  const [fromMonth, setFromMonth] = useState("Jun 2024");
  const [toMonth, setToMonth] = useState("May 2025");

  // Get all months from the data array
  const months = data.map(item => item.month);

  // Filter months for the 'to' dropdown based on the 'from' month selection
  const filteredToMonths = months.filter(month => months.indexOf(month) > months.indexOf(fromMonth));

  // Filter data based on the selected from and to months
  const filteredData = data.filter(
    item => months.indexOf(item.month) >= months.indexOf(fromMonth) && months.indexOf(item.month) <= months.indexOf(toMonth)
  );

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="bg-background-main p-4 border border-gray-700 rounded-lg shadow-lg">
          <p className="text-sm text-gray-300 mb-2">{label}</p>
          <p className="text-sm">
            <span className="text-[#f09a19]">Value 1: {payload[0].value}</span>
          </p>
          <p className="text-sm">
            <span className="text-white">Value 2: {payload[1].value}</span>
          </p>
        </div>
      );
    }
    return null;
  };

  return (
    <div className={`relative w-full h-full min-h-[400px] lg:absolute lg:h-full lg:w-full lg:top-[0%] lg:right-[0%] lg:bottom-[0%] lg:left-[0%] text-left text-xl text-default-white font-poppins ${className}`}>
      {/* Background Container */}
      <div className="absolute h-full w-full top-0 right-0 bottom-0 left-0 shadow-[0px_1px_4px_rgba(0,_0,_0,_0.2)] rounded-xl bg-background-main overflow-hidden" />
      
      {/* Content Container */}
      <div className="relative h-full w-full p-4 lg:p-0">
        {/* Title - Hidden on mobile as it's shown elsewhere */}
        <b className="hidden lg:block lg:absolute lg:top-[29px] lg:right-[274px] text-5xl leading-[120%]">
          Statistics
        </b>

        {/* Controls Section */}
        <div className="w-full lg:absolute lg:w-[91.31%] lg:top-[calc(50%_-_155px)] lg:right-[5.52%] lg:left-[3.17%] mt-4 lg:mt-0">
          {/* Points Consistency Label */}
          <div className="text-center lg:text-left lg:absolute lg:top-[calc(50%_-_15px)] lg:left-0 mb-4 lg:mb-0">
            Points Consistency
          </div>

          {/* Date Range Controls */}
          <div className="flex flex-col lg:flex-row lg:absolute lg:h-3/5 lg:w-[37.5%] space-y-4 lg:space-y-0 lg:space-x-4 lg:top-[13.33%] lg:right-[0%] lg:bottom-[26.67%] lg:left-[62.5%] text-xs text-[#758aa1] mt-4 lg:mt-0">
            {/* From Month */}
            <div className="flex items-center justify-between lg:justify-start space-x-2 lg:w-[55%] mq750:w-[95%]">
              <span className="w-12">from</span>
              <select
                className="flex-1 lg:flex-none lg:w-30 bg-background-main border border-gray-400 rounded px-2 py-1 text-default-white"
                value={fromMonth}
                onChange={(e) => {
                  setFromMonth(e.target.value);
                  setToMonth(filteredToMonths[0]);
                }}
              >
                {months.map(month => (
                  <option key={month} value={month}>{month}</option>
                ))}
              </select>
            </div>

            {/* To Month */}
            <div className="flex items-center justify-between lg:justify-start space-x-2 lg:w-[40.55%] mq750:w-[95%]">
              <span className="w-12">to</span>
              <select
                className="flex-1 lg:flex-none lg:w-30 bg-background-main mq850:w-[10%] border border-gray-400 rounded px-2 py-1 text-default-white"
                value={toMonth}
                onChange={(e) => setToMonth(e.target.value)}
              >
                {filteredToMonths.map(month => (
                  <option key={month} value={month}>{month}</option>
                ))}
              </select>
            </div>
          </div>
        </div>

        {/* Chart Section */}
        <div className="relative lg:absolute h-[300px] lg:h-[67.57%] w-full mt-8 lg:mt-0 lg:top-[23.24%] lg:right-[0%] lg:bottom-[9.19%] lg:left-[0.5%] text-center text-3xs">
          <ResponsiveContainer width="100%" height="100%">
            <LineChart 
              data={filteredData} 
              margin={{ 
                top: 20, 
                right: 30, 
                left: 0, 
                bottom: 0 
              }}
            >
              <CartesianGrid strokeDasharray="3 3" stroke="#33393f" />
              <XAxis 
                dataKey="month" 
                stroke="#758aa1"
                tick={{ fontSize: 10 }}
                angle={-45}
                textAnchor="end"
                height={60}
              />
              <YAxis 
                stroke="#758aa1"
                tick={{ fontSize: 10 }}
              />
              <Tooltip content={<CustomTooltip />} />
              <Line 
                type="monotone" 
                dataKey="value1" 
                stroke="#f09a19" 
                strokeWidth={2} 
                dot={false}
                activeDot={{ r: 8 }}
              />
              <Line 
                type="monotone" 
                dataKey="value2" 
                stroke="#fff" 
                strokeWidth={2} 
                dot={false}
                activeDot={{ r: 8 }}
              />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
};

TotalPoints.propTypes = {
  className: PropTypes.string,
};

export default TotalPoints;