import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import MainNavigation from "../../components/MainNavigation";
import NavBar from "../../components/NavBar";
import Button from "../../components/groups/Button";
import GroupComponent2 from "../../components/groups/GroupComponent2";
import GroupBoard from "../../components/groups/GroupBoard";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Dialog } from '@headlessui/react';
import StudySessionCard from "../../components/groups/StudySessionCard";
import GroupRequestsSummary from "../../components/groups/GroupRequestsSummary";
import Loader from "../../components/Loader";

const Group = () => {
  const navigate = useNavigate();
  const [groups, setGroups] = useState([]);
  const [myGroups, setMyGroups] = useState([]);
  const [membershipRequests, setMembershipRequests] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showAnimations, setShowAnimations] = useState(false);
  const [generalGroupCount, setGeneralGroupCount] = useState(0);
  const [subjectGroupCount, setSubjectGroupCount] = useState(0);
  const [totalPoints, setTotalPoints] = useState(0);
  const [userAdminGroups, setuserAdminGroups] = useState([]);

  const openModal = (group) => {
    setSelectedGroup(group);
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
    setSelectedGroup(null);
  };

  const getGroupStatus = (groupId) => {
    const userId = localStorage.getItem('userId');
    const request = membershipRequests.find((req) => req.group.uuid === groupId && req.user.user_id === userId);
    return request ? request.status : "Join";
  };

  const joinGroup = async () => {
    const userId = localStorage.getItem('userId');
    const groupId = selectedGroup.uuid;

    if (!userId) {
      console.error("User ID not found.");
      return;
    }

    try {
      const response = await fetch("https://api.staging.sensebod.com/api/v1/groups/membership_request/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
        body: JSON.stringify({
          user: userId,
          group: groupId,
        }),
      });

      if (!response.ok) {
        throw new Error(`Failed to join group: ${response.statusText}`);
      }

      const data = await response.json();
      console.log("Group join request successful:", data);
      closeModal();
      setShowSuccessModal(true);
      
      // Add a small delay before refreshing to show the success modal
      setTimeout(() => {
        window.location.reload();
      }, 1500); // 1.5 second delay
      
    } catch (error) {
      console.error("Error joining group:", error);
    }
  };

  useEffect(() => {
    const fetchGroups = async () => {
      setIsLoading(true);
      try {
        const response = await fetch(
          "https://api.staging.sensebod.com/api/v1/groups/group/",
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            },
          }
        );

        if (response.ok) {
          const data = await response.json();
          const userId = localStorage.getItem("userId");

          console.log("Groups", data);

          const userGroups = data.filter(group =>
            group.members.some(member => member.member.user_id === userId)
          );

          const userAdGroups = data.filter(group =>
            group.members.some(member => member.role === "admin" && member.member.user_id === userId)
          );
          const otherGroups = data.filter(group =>
            !group.members.some(member => member.member.user_id === userId) &&
            group.visibility_privacy_settings === "public"
          );

          setGroups(otherGroups);
          setMyGroups(userGroups);
          setuserAdminGroups(userAdGroups);

          // Count general and subject groups
          const generalCount = userGroups.filter(group => group.group_type === 'general').length;
          const subjectCount = userGroups.filter(group => group.group_type === 'subject').length;
          setGeneralGroupCount(generalCount);
          setSubjectGroupCount(subjectCount);

          // Calculate total points
          const points = userGroups.reduce((sum, group) => sum + (group.group_points || 0), 0);
          setTotalPoints(points);

        } else {
          console.error("Failed to fetch groups:", response.statusText);
        }
      } catch (error) {
        console.error("Error fetching groups:", error);
      } finally {
        setIsLoading(false);
        // Trigger animations after a short delay
        setTimeout(() => setShowAnimations(true), 300);
      }
    };

    const fetchMembershipRequests = async () => {
      try {
        const response = await fetch(
          "https://api.staging.sensebod.com/api/v1/groups/membership_request/",
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            },
          }
        );

        if (response.ok) {
          const data = await response.json();
          setMembershipRequests(data);
        } else {
          console.error("Failed to fetch membership requests:", response.statusText);
        }
      } catch (error) {
        console.error("Error fetching membership requests:", error);
      }
    };

    fetchGroups();
    fetchMembershipRequests();
  }, []);

  const slickSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  const handleGroupClick = (groupId) => {
    navigate(`/group/groups-chat/${groupId}`);
  };

  const userHasGroups = () => {
    console.log("UserGroup", userAdminGroups.length);
    return userAdminGroups.length > 0;
  };

  const renderGroupSlider = (groupList, title) => {
    let topPosition;
    switch (title) {
      case 'My Groups':
        topPosition = '474px';
        break;
      case 'General Groups':
        topPosition = myGroups.length > 0 ? '844px' : '474px';
        break;
      case 'Subject Groups':
        topPosition = myGroups.length > 0 ? '1214px' : '844px';
        break;
      default:
        topPosition = '474px';
    }

    return (
      <div className="w-full md:w-[621px] rounded-[30px] mq850:w-[95%] pb-[20px] pr-[15px] bg-[#FFFFFF] transition-opacity duration-1000 ease-in-out mb-8 md:mb-0 md:absolute md:left-[280px]"
        style={{ top: title === 'My Groups' ? '474px' : title === 'General Groups' ? (myGroups.length > 0 ? '844px' : '474px') : (myGroups.length > 0 ? '1214px' : '844px') }}>
        <div className="flex justify-between items-center mb-4 px-4 pt-4">
          <p className="text-[20px] text-[#707070] font-semibold">{title}</p>
        </div>
        <Slider {...slickSettings}>
          {groupList.map((group, index) => (
            <div key={index} className="px-2">
              <div className="relative rounded-[30px] overflow-hidden h-[198px] mx-2 cursor-pointer" 
              
              >
                <img
                  src="/group/Group-02.png"
                  alt={group.name}
                  className="w-[95%] h-[80%] object-cover cursor-pointer"
                />
                <div className="absolute bottom-0 left-0 right-0 bg-[#1A3651] text-white p-3 cursor-pointer h-[80px]">
                  <p className="text-[16px] font-medium text-white" onClick={handleGroupClick.bind(this, group.uuid)}>{group.name}</p>
                  <div className="flex justify-between items-center mt-2">
                    <div className="flex items-center">
                      <img src="/home/Icon.svg" alt="Members" className="w-[20px] h-[20px] mr-1" />
                      <span className="text-[11px]">{group.member_count}</span>
                      <p className="absolute pl-[40px] font-[Poppins] text-[11px] line-height: 22px font-[400] text-start text-[#FFFFF] cursor-[pointer]">|</p>
                    </div>
                    {/* <div className="flex items-center">
                      <img src="/home/Icon (1).svg" alt="Rating" className="w-[20px] h-[20px] mr-1" />
                      <span className="text-[11px]">{group.group_points}</span>
                      <p className="absolute pl-[50px] font-[Poppins] text-[11px] line-height: 22px font-[400] text-start text-[#FFFFF] cursor-[pointer]">|</p>
                    </div> */}
                    <div className="flex items-center">
                      <img src="/home/Icon (2).svg" alt="Privacy" className="w-[20px] h-[20px] mr-1" />
                      <span className="text-[11px]">{group.join_privacy_settings}</span>
                    </div>
                  </div>
                </div>
                {title !== 'My Groups' && (
                  <button
                    className="absolute top-2 right-2 bg-[#F09A19] text-white px-3 py-1 rounded-md cursor-pointer"
                    onClick={() => openModal(group)}
                  >
                    {getGroupStatus(group.uuid)}
                  </button>
                )}
              </div>
            </div>
          ))}
        </Slider>
      </div>
    );
  };

  if (isLoading) {
    return <Loader duration={10000} />;
  }

  return (
    <div className="w-full relative bg-[#fbf1f1] min-h-screen overflow-auto text-left text-xl text-default-white font-poppins pb-[200px]">
      <a href="/aichat" className="fixed bottom-6 left-4 md:left-[285px] z-[10000]">
        <img
          className="w-[75px] h-[75px] object-contain cursor-pointer"
          alt="AI Chat"
          src="/home/AIChart.svg"
        />
      </a>
      <NavBar />
      <MainNavigation
        homeClass=""
        learningClass=""
        mentorClass=""
        groupsClass="left-[-29px] rounded-tl-[0px] rounded-tr-11xl rounded-br-11xl rounded-bl-[0px] bg-buttonselect border-buttonselect border-[2px] border-solid box-border text-default-white"
        profileClass="custom-profile-class"
      />

      <div className="mq850:top-[65px] relative">

        <div className="text-center md:text-left md:absolute md:top-[116px] md:left-[calc(50%_-_59px)] text-[32px] leading-[150%] font-semibold text-[#0d0c0c] mt-4 md:mt-0">
          Groups
        </div>

        <GroupBoard
            generalCount={generalGroupCount}
            subjectCount={subjectGroupCount}
            totalGroups={myGroups.length}
            totalPoints={totalPoints}
          />

        <div className="px-4 md:px-0 md:absolute md:top-[195px] md:left-[285px] transition-opacity duration-1000 ease-in-out mt-8 md:mt-0">
          {userHasGroups() ? (
            <GroupRequestsSummary groups={groups} membershipRequests={membershipRequests} />
          ) : (
            <div className="w-full md:w-[616px] h-[232px] overflow-hidden text-center text-base mq850:bg-background-main rounded-lg">
              <img
                className="hidden md:block absolute top-[-74px] left-[-117.6px] w-[849.7px] h-[374px] object-cover"
                alt=""
                src="/group/mask-group@2x.png"
              />
              <Button
                button="Create Group"
                showIcon={false}
                buttonPosition="relative md:absolute"
                buttonBackgroundColor="#f09a19"
                buttonTop="165px"
                buttonLeft="calc(50% - 5px)"
                buttonWidth="122px"
                buttonHeight="40px"
                buttonColor="#fff"
                link="/groups-preferences"
                zIndex={10}
              />
              <img
                className="hidden md:block absolute h-[36.08%] w-[15.26%] top-[24.14%] right-[83.6%] bottom-[39.78%] left-[1.14%] max-w-full overflow-hidden max-h-full object-contain"
                alt=""
                src="/group/wavy-buddies-avatar@2x.png"
              />
              <div className="mt-4 md:mt-0 md:absolute md:top-[39px] md:left-[calc(50%_-_103px)] leading-[135%] font-medium">
                You have no active group
              </div>
            </div>
          )}
        </div>

        <div className="mt-8 md:mt-0 px-4 md:px-0">
          {myGroups.length > 0 && renderGroupSlider(myGroups, 'My Groups')}
          {renderGroupSlider(groups.filter(group => group.group_type === 'general'), 'General Groups')}
          {renderGroupSlider(groups.filter(group => group.group_type === 'subject'), 'Subject Groups')}
        </div>


        {/* Join Group Modal */}
        <Dialog open={isOpen} onClose={closeModal} className="relative z-50">
          <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
          <div className="fixed inset-0 flex items-center justify-center p-4">
            <Dialog.Panel className="mx-auto bg-white rounded-lg shadow-lg p-6">
              <Dialog.Title className="text-lg font-bold">Join Group</Dialog.Title>
              <Dialog.Description className="mt-2 text-sm text-gray-600">
                Do you want to join the group {selectedGroup?.name}?
              </Dialog.Description>
              <div className="mt-4 flex justify-end space-x-3">
                <button
                  className="bg-gray-300 text-gray-800 px-4 py-2 rounded-md"
                  onClick={closeModal}
                >
                  Cancel
                </button>
                <button
                  className="bg-blue-500 text-white px-4 py-2 rounded-md cursor-pointer"
                  onClick={joinGroup}
                >
                  Join Group
                </button>
              </div>
            </Dialog.Panel>
          </div>
        </Dialog>

        {/* Success Notification Modal */}
        <Dialog open={showSuccessModal} onClose={() => setShowSuccessModal(false)} className="relative z-50">
          <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
          <div className="fixed inset-0 flex items-center justify-center p-4">
            <Dialog.Panel className="mx-auto bg-white rounded-lg shadow-lg p-6">
              <Dialog.Title className="text-lg font-bold text-green-600">Success!</Dialog.Title>
              <Dialog.Description className="mt-2 text-sm text-gray-600">
                Your request to join the group has been sent successfully.
              </Dialog.Description>
              <div className="mt-4 flex justify-end">
                <button
                  className="bg-green-500 text-white px-4 py-2 rounded-md"
                  onClick={() => setShowSuccessModal(false)}
                >
                  OK
                </button>
              </div>
            </Dialog.Panel>
          </div>
        </Dialog>

        <div className="mt-8 md:mt-0">
          
          <StudySessionCard lastSessionTime="0hr / 0m" />
        </div>
      </div>
    </div>
  );
};

export default Group;