import React, { useState, useEffect } from 'react';
import { ChatEngine, getOrCreateChat } from 'react-chat-engine';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';

const GroupChat = () => {
  const { groupId } = useParams(); // Retrieve group ID from the URL params
  const [groupName, setGroupName] = useState(''); // Store group name
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  // Chat Engine credentials
  const projectID = process.env.REACT_APP_CHAT_ENGINE_PROJECT_ID;
  const userName = localStorage.getItem('username');
  const userSecret = localStorage.getItem('secret');

  useEffect(() => {
    // Fetch the group name from your backend (Sensebod API)
    const fetchGroupName = async () => {
      try {
        const response = await fetch(`https://api.staging.sensebod.com/api/v1/groups/group/${groupId}/`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          },
        });
        if (response.ok) {
          const data = await response.json();
          setGroupName(data.name); // Set the group name from the API response
        } else {
          throw new Error('Failed to fetch group name');
        }
      } catch (error) {
        console.error('Error fetching group name:', error);
        navigate('/error'); // Optionally navigate to an error page
      }
    };

    // Fetch the group name before setting up the chat
    fetchGroupName();
  }, [groupId, navigate]);

  // This function checks if a chat exists and joins or creates the chat
  const createOrJoinChat = async () => {
    if (!userName || !userSecret) {
      alert('Missing Chat Engine username or secret.');
      return;
    }
  
    try {
      // Fetch all chats for the user to check if the chat already exists
      const response = await axios.get(`https://api.chatengine.io/chats/`, {
        headers: {
          "Project-ID": projectID,
          "User-Name": userName,
          "User-Secret": userSecret,
        },
      });
  
      // If response exists, check if the chat with group name exists
      const chats = response?.data || []; // Ensure response.data exists
      const existingChat = chats.find(chat => chat.title === groupName);
  
      if (existingChat) {
        // Chat already exists, navigate to the chat URL with chat ID
        console.log('Joining existing chat:', existingChat);
        navigate(`/chat/${groupId}/${existingChat.id}`); // Navigate to the URL with chat ID
        setLoading(false); // Stop loading once the chat is joined
      } else {
        // Create the group chat if it doesn't exist
        getOrCreateChat(
          {
            projectID,
            userName,
            userSecret,
          },
          { is_direct_chat: false, title: groupName }, // Group chat options
          (chat) => {
            console.log('New group chat created:', chat);
            navigate(`/chat/${chat.id}`); // Navigate to the newly created chat URL
            setLoading(false); // Stop loading once the chat is created or joined
          }
        );
      }
    } catch (error) {
      console.error('Error fetching or creating chat:', error);
    }
  };

  useEffect(() => {
    if (groupName) {
      createOrJoinChat(); // Initialize or join chat when groupName is ready
    }
  }, [groupName]);

  if (loading) return <div>Loading...</div>;

  return (
    <div style={{ height: '100vh' }}>
      <ChatEngine
        projectID={projectID}
        userName={userName}
        userSecret={userSecret}
        height="100vh"
        onNewMessage={() => console.log('New message!')}
      />
    </div>
  );
};

export default GroupChat;
