import React, { useState, useEffect } from 'react';
import axios from 'axios';
import RankMessage from './RankBadge';

const SideBar = () => {
    const [userRank, setUserRank] = useState(null);
    const [percentile, setPercentile] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const currentUsername = localStorage.getItem("username");

    useEffect(() => {
        const fetchLeaderboardData = async () => {
            setIsLoading(true);
            const accessToken = localStorage.getItem('accessToken');

            if (!accessToken || !currentUsername) {
                setError('Authentication data missing');
                setIsLoading(false);
                return;
            }

            try {
                const response = await axios.get('https://api.staging.sensebod.com/api/v1/leaderboards/national/', {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                });

                const sortedUsers = response.data.sort((a, b) =>
                    parseFloat(b.total_points) - parseFloat(a.total_points)
                );

                const userPosition = sortedUsers.findIndex(user =>
                    user.username === currentUsername
                );

                if (userPosition !== -1) {
                    const rank = userPosition + 1;
                    setUserRank(rank);

                    const totalUsers = sortedUsers.length;
                    const usersBelow = totalUsers - rank;
                    const percentileValue = Math.round((usersBelow / totalUsers) * 100);
                    setPercentile(percentileValue);
                }

                setIsLoading(false);
            } catch (error) {
                console.error('Error fetching leaderboard data:', error);
                setError('Failed to fetch rank data');
                setIsLoading(false);
            }
        };

        fetchLeaderboardData();
    }, [currentUsername]);

    return (
        <div className="w-full flex justify-center md:block md:w-[300px] md:absolute md:right-[80px]">
            <div className="w-full max-w-[300px] relative px-4 md:px-0">
                <div className="relative">
                    {/* New horizontal layout for avatar and rank */}
                    <div className="flex items-center space-x-4 absolute top-[110px] left-0 right-0 px-4">
                        <img
                            className="w-[90px] h-[90px] relative left-[-40px] overflow-hidden cursor-pointer flex-shrink-0"
                            alt=""
                            src="/home/avatar@2x.png"
                        />
                        
                        <div className="flex-grow relative left-[-30px]">
                            {isLoading ? (
                                <div className="text-center">Loading rank...</div>
                            ) : error ? (
                                <div className="text-red-500 text-center">{error}</div>
                            ) : userRank ? (
                                <RankMessage
                                    rank={userRank}
                                    message={`You are doing better than ${percentile}% of other learners!`}
                                />
                            ) : (
                                <RankMessage
                                    rank={""}
                                    message={`Your Rank will show when you start earning points!`}
                                />
                            )}
                        </div>
                    </div>

                    <div className='mq750:top-[920px] mq750:absolute'>
                        <p className="absolute rounded-[20px] left-[-20px] font-[Poppins] text-[12.06px] bg-[#FFFFFF] h-[305px] w-[292px] line-height-[18.09px] font-[600] 
                text-start text-[#1A3651] top-[220px] items-end pt-[20px] pl-[50px]">Point Progress</p>

                        <p className="absolute left-[190px] rounded-[4px] h-[27px] w-[57px] top-[240px] text-center pr-[20px] pt-[8px] bg-[#EFF1F3] text-[#424252] font-[Poppins] font-[600] text-[12.06px]">Daily</p>

                        <img
                            className="absolute left-[125px] top-[240px] w-[254px] h-[240px] rounded-[30px] overflow object-none cursor-[pointer]"
                            alt=""
                            src="/home/Vector 13 (1).svg"
                        />

                        <img
                            className="absolute left-[35px] top-[255px] w-[254px] h-[240px] rounded-[30px] overflow object-none cursor-[pointer]"
                            alt=""
                            src="/home/Ellipse 14.svg"
                        />

                        <p className="absolute w-[100px] rounded-[20px] left-[105px] font-[Poppins] text-[18px] line-height-[18.09px] font-[500] 
                text-start text-[#83868E] top-[500px]">Your Point: </p>

                        <p className="absolute rounded-[20px] left-[215px] font-[Poppins] text-[20px] line-height-[18.09px] font-[700] 
                text-start text-[black] top-[500px]">0</p>

                        <img
                            className="absolute left-[90px] mq750:top-[450px] top-[450px] w-[135px] h-[55px] rounded-[10px] overflow object-none cursor-[pointer]"
                            alt=""
                            src="/home/Points.svg"
                        />

                        <img
                            className="absolute left-[30px] top-[295px] w-[230px] h-[230px] rounded-[30px] overflow object-none cursor-[pointer]"
                            alt=""
                            src="/home/Polygon 4.svg"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SideBar;