import Comments from "./Comments";
import PropTypes from "prop-types";

const RatingAndReviews = ({ className = "" }) => {
  return (
    <div
      className={`absolute top-[0rem] left-[0rem] w-[38.913rem] h-[32.688rem] text-left text-[2rem] text-gray-500 font-poppins ${className}`}
    >
      <b className="absolute top-[0rem] left-[0rem] tracking-[0.01em] leading-[120%]">
        Student Reviews
      </b>
      <div className="absolute top-[4.813rem] left-[0rem] w-[38.913rem] h-[9.625rem] text-[0.875rem] text-light-text-color-body-2 font-captions-captions-bold">
        <b className="absolute top-[1.313rem] left-[0.906rem] text-[3.875rem] leading-[120%] text-gray-500">
          4.9
        </b>
        <div className="absolute top-[6.438rem] left-[0rem] w-[7.381rem] h-[2rem]">
          <div className="absolute top-[0.438rem] left-[2.131rem] tracking-[0.01em] leading-[130%]">
            12.6 reviews
          </div>
          <img
            className="absolute top-[0rem] left-[0rem] w-[1.706rem] h-[2rem]"
            alt=""
            src="/learning/icon3.svg"
          />
        </div>
        <div className="absolute top-[0rem] left-[9.056rem] w-[29.856rem] h-[9.625rem]">
          <div className="absolute top-[0rem] left-[0rem] w-[29.856rem] h-[1.125rem]">
            <img
              className="absolute top-[0.125rem] left-[0rem] w-[0.85rem] h-[1rem]"
              alt=""
              src="/learning/icon4.svg"
            />
            <img
              className="absolute top-[0.125rem] left-[1.281rem] w-[0.85rem] h-[1rem]"
              alt=""
              src="/learning/icon5.svg"
            />
            <img
              className="absolute top-[0.125rem] left-[2.556rem] w-[0.85rem] h-[1rem]"
              alt=""
              src="/learning/icon6.svg"
            />
            <img
              className="absolute top-[0.125rem] left-[3.838rem] w-[0.85rem] h-[1rem]"
              alt=""
              src="/learning/icon7.svg"
            />
            <img
              className="absolute top-[0.125rem] left-[5.113rem] w-[0.85rem] h-[1rem]"
              alt=""
              src="/learning/icon8.svg"
            />
            <div className="absolute top-[0.438rem] left-[9.481rem] w-[17.156rem] h-[0.25rem]">
              <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded-sm bg-light-fills-color-progress-container" />
            </div>
            <div className="absolute top-[0rem] left-[27.919rem] tracking-[0.01em] leading-[130%]">
              80%
            </div>
            <div className="absolute top-[0.438rem] left-[9.481rem] w-[13.213rem] h-[0.25rem]">
              <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded-sm bg-darkslategray-100" />
            </div>
          </div>
          <div className="absolute top-[2.125rem] left-[0rem] w-[29.856rem] h-[1.125rem]">
            <img
              className="absolute top-[0.125rem] left-[0rem] w-[0.85rem] h-[1rem]"
              alt=""
              src="/learning/icon4.svg"
            />
            <img
              className="absolute top-[0.125rem] left-[1.281rem] w-[0.85rem] h-[1rem]"
              alt=""
              src="/learning/icon5.svg"
            />
            <img
              className="absolute top-[0.125rem] left-[2.556rem] w-[0.85rem] h-[1rem]"
              alt=""
              src="/learning/icon6.svg"
            />
            <img
              className="absolute top-[0.125rem] left-[3.838rem] w-[0.85rem] h-[1rem]"
              alt=""
              src="/learning/icon7.svg"
            />
            <img
              className="absolute top-[0.125rem] left-[5.113rem] w-[0.85rem] h-[1rem]"
              alt=""
              src="/learning/icon8.svg"
            />
            <div className="absolute top-[0.438rem] left-[9.481rem] w-[17.156rem] h-[0.25rem]">
              <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded-sm bg-light-fills-color-progress-container" />
            </div>
            <div className="absolute top-[0rem] left-[27.919rem] tracking-[0.01em] leading-[130%]">
              10%
            </div>
            <div className="absolute top-[0.438rem] left-[9.481rem] w-[1.919rem] h-[0.25rem]">
              <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded-sm bg-darkslategray-100" />
            </div>
          </div>
          <div className="absolute top-[4.25rem] left-[0rem] w-[29.769rem] h-[1.125rem]">
            <img
              className="absolute top-[0.125rem] left-[0rem] w-[0.85rem] h-[1rem]"
              alt=""
              src="/learning/icon4.svg"
            />
            <img
              className="absolute top-[0.125rem] left-[1.281rem] w-[0.85rem] h-[1rem]"
              alt=""
              src="/learning/icon5.svg"
            />
            <img
              className="absolute top-[0.125rem] left-[2.556rem] w-[0.85rem] h-[1rem]"
              alt=""
              src="/learning/icon6.svg"
            />
            <img
              className="absolute top-[0.125rem] left-[3.838rem] w-[0.85rem] h-[1rem]"
              alt=""
              src="/learning/icon7.svg"
            />
            <img
              className="absolute top-[0.125rem] left-[5.113rem] w-[0.85rem] h-[1rem]"
              alt=""
              src="/learning/icon8.svg"
            />
            <div className="absolute top-[0.438rem] left-[9.481rem] w-[17.156rem] h-[0.25rem]">
              <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded-sm bg-light-fills-color-progress-container" />
            </div>
            <div className="absolute top-[0rem] left-[28.394rem] tracking-[0.01em] leading-[130%]">
              5%
            </div>
            <div className="absolute top-[0.438rem] left-[9.481rem] w-[0.85rem] h-[0.25rem]">
              <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded-sm bg-darkslategray-100" />
            </div>
          </div>
          <div className="absolute top-[6.375rem] left-[0rem] w-[29.769rem] h-[1.125rem]">
            <img
              className="absolute top-[0.125rem] left-[0rem] w-[0.85rem] h-[1rem]"
              alt=""
              src="/learning/icon4.svg"
            />
            <img
              className="absolute top-[0.125rem] left-[1.281rem] w-[0.85rem] h-[1rem]"
              alt=""
              src="/learning/icon5.svg"
            />
            <img
              className="absolute top-[0.125rem] left-[2.556rem] w-[0.85rem] h-[1rem]"
              alt=""
              src="/learning/icon6.svg"
            />
            <img
              className="absolute top-[0.125rem] left-[3.838rem] w-[0.85rem] h-[1rem]"
              alt=""
              src="/learning/icon7.svg"
            />
            <img
              className="absolute top-[0.125rem] left-[5.113rem] w-[0.85rem] h-[1rem]"
              alt=""
              src="/learning/icon8.svg"
            />
            <div className="absolute top-[0.438rem] left-[9.481rem] w-[17.156rem] h-[0.25rem]">
              <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded-sm bg-light-fills-color-progress-container" />
            </div>
            <div className="absolute top-[0rem] left-[28.394rem] tracking-[0.01em] leading-[130%]">
              3%
            </div>
            <div className="absolute top-[0.438rem] left-[9.481rem] w-[0.588rem] h-[0.25rem]">
              <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded-sm bg-darkslategray-100" />
            </div>
          </div>
          <div className="absolute top-[8.5rem] left-[0rem] w-[29.769rem] h-[1.125rem]">
            <img
              className="absolute top-[0.125rem] left-[0rem] w-[0.85rem] h-[1rem]"
              alt=""
              src="/learning/icon4.svg"
            />
            <img
              className="absolute top-[0.125rem] left-[1.281rem] w-[0.85rem] h-[1rem]"
              alt=""
              src="/learning/icon5.svg"
            />
            <img
              className="absolute top-[0.125rem] left-[2.556rem] w-[0.85rem] h-[1rem]"
              alt=""
              src="/learning/icon6.svg"
            />
            <img
              className="absolute top-[0.125rem] left-[3.838rem] w-[0.85rem] h-[1rem]"
              alt=""
              src="/learning/icon7.svg"
            />
            <img
              className="absolute top-[0.125rem] left-[5.113rem] w-[0.85rem] h-[1rem]"
              alt=""
              src="/learning/icon8.svg"
            />
            <div className="absolute top-[0.438rem] left-[9.481rem] w-[17.156rem] h-[0.25rem]">
              <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded-sm bg-light-fills-color-progress-container" />
            </div>
            <div className="absolute top-[0rem] left-[28.394rem] tracking-[0.01em] leading-[130%]">
              2%
            </div>
            <div className="absolute top-[0.438rem] left-[9.481rem] w-[0.425rem] h-[0.25rem]">
              <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded-sm bg-darkslategray-100" />
            </div>
          </div>
        </div>
      </div>
      <Comments
        vidalLisk="Vidal Lisk"
        contraryToPopularBeliefLorem="Contrary to popular belief, Lorem Ipsum is not simply random text."
      />
      <Comments
        vidalLisk="James Jalloh"
        contraryToPopularBeliefLorem="It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old."
        propTop="25.313rem"
      />
    </div>
  );
};

RatingAndReviews.propTypes = {
  className: PropTypes.string,
};

export default RatingAndReviews;
