import PropTypes from "prop-types";

const WhatYouLearn = ({ className = "", description= ""}) => {
  return (
    <div
      className={`absolute top-[0rem] left-[0rem] w-[38.894rem] h-[41.688rem] text-left text-[0.75rem] text-gray-500 font-poppins ${className}`}
    >
      <div className="absolute top-[0rem] left-[0rem] rounded-lg bg-default-white w-[38.75rem] h-[41.688rem]" />
      <div className="absolute top-[27.563rem] left-[1.125rem] w-[35.625rem] h-[11.813rem] text-[1.938rem]">
        <div className="absolute top-[0rem] left-[0rem] w-[35.625rem] h-[11.813rem]">
          <b className="absolute top-[0rem] left-[0rem] tracking-[0.01em] leading-[120%]">
            Description
          </b>
          <div className="absolute h-[71.96%] w-full top-[28.04%] right-[0%] bottom-[0%] left-[0%] text-[0.875rem]">
            <div className="absolute w-full top-[0%] left-[0%] tracking-[0.01em] leading-[120%] inline-block">
              {description}
            </div>
          </div>
        </div>
      </div>
      <div className="absolute top-[14.438rem] left-[1.25rem] w-[34.5rem] h-[11.125rem] text-[0.875rem] font-captions-captions-bold">
        <div className="absolute top-[0rem] left-[0rem] w-[34.5rem] h-[11.125rem]">
          <div className="absolute top-[0rem] left-[0rem] text-[2rem] tracking-[0.01em] leading-[120%] font-medium font-poppins">
            Requirement
          </div>
          <div className="absolute h-[10.11%] w-[82.59%] top-[33.71%] right-[17.41%] bottom-[56.18%] left-[0%]">
            <div className="absolute h-full w-[94.01%] top-[0%] right-[0%] bottom-[0%] left-[5.99%]">
              <div className="absolute w-full top-[0%] left-[0%] tracking-[0.01em] leading-[130%] inline-block">{`Lorem Ipsum and some other placeholders for start `}</div>
            </div>
            <div className="absolute top-[0.063rem] left-[0rem] rounded-[50%] bg-[#777777] w-[1rem] h-[1rem]" />
          </div>
          <div className="absolute h-[10.11%] w-[82.59%] top-[55.8%] right-[17.41%] bottom-[28.09%] left-[0%]">
            <div className="absolute h-full w-[94.01%] top-[0%] right-[0%] bottom-[0%] left-[5.99%]">
              <div className="absolute w-full top-[0%] left-[0%] tracking-[0.01em] leading-[130%] inline-block">
                A PC or Mac is required
              </div>
            </div>
            <div className="absolute top-[0.063rem] left-[0rem] rounded-[50%] bg-[#777777] w-[1rem] h-[1rem]" />
          </div>
          <div className="absolute h-[20.22%] w-full top-[79.78%] right-[0%] bottom-[0%] left-[0%]">
            <div className="absolute h-full w-[95.05%] top-[0%] right-[0%] bottom-[0%] left-[4.95%]">
              <div className="absolute w-full top-[0%] left-[0%] tracking-[0.01em] leading-[130%] inline-block">
                No software is required in advance of the course (all software
                used in the course is free or has a demo version)
              </div>
            </div>
            <div className="absolute top-[0.063rem] left-[0rem] rounded-[50%] bg-[#777777] w-[1rem] h-[1rem]" />
          </div>
        </div>
      </div>
      <div className="absolute top-[1.888rem] left-[1.263rem] text-[1.938rem] tracking-[0.01em] leading-[120%] font-medium">
        What you’ll learn
      </div>
      <div className="absolute h-[4.84%] w-[25.23%] top-[12.55%] right-[72.33%] bottom-[82.61%] left-[2.44%]">
        <div className="absolute top-[21.67%] left-[17.13%] tracking-[0.01em] leading-[130%]">{`Lorem Ipsum for start `}</div>
        <div className="absolute top-[0rem] left-[0rem] w-[1.688rem] h-[2.019rem]">
          <div className="absolute top-[0rem] left-[0rem] rounded-lg w-[1.688rem] h-[2.019rem]" />
          <img
            className="absolute top-[0.5rem] left-[0.419rem] w-[0.844rem] h-[1.006rem]"
            alt=""
            src="/learning/ui--check.svg"
          />
        </div>
      </div>
      <div className="absolute h-[4.84%] w-[41.15%] top-[12.55%] right-[0%] bottom-[82.61%] left-[58.85%]">
        <div className="absolute h-[56.04%] w-[89.46%] top-[21.67%] left-[10.5%] tracking-[0.01em] leading-[130%] inline-block">
          Create mockups using Figma
        </div>
        <div className="absolute top-[0rem] left-[0rem] w-[1.688rem] h-[2.019rem]">
          <div className="absolute top-[0rem] left-[0rem] rounded-lg w-[1.688rem] h-[2.019rem]" />
          <img
            className="absolute top-[0.5rem] left-[0.419rem] w-[0.844rem] h-[1.006rem]"
            alt=""
            src="/learning/ui--check.svg"
          />
        </div>
      </div>
      <div className="absolute h-[4.84%] w-[40.82%] top-[19.34%] right-[0.37%] bottom-[75.82%] left-[58.81%]">
        <div className="absolute h-[56.04%] w-[89.49%] top-[21.98%] left-[10.51%] tracking-[0.01em] leading-[130%] inline-block">
          Start a new career as a UI/UX designer
        </div>
        <div className="absolute top-[0rem] left-[0rem] w-[1.669rem] h-[2.019rem]">
          <div className="absolute top-[0rem] left-[0rem] rounded-lg w-[1.669rem] h-[2.019rem]" />
          <img
            className="absolute top-[0.506rem] left-[0.419rem] w-[0.838rem] h-[1.006rem]"
            alt=""
            src="/learning/ui--check1.svg"
          />
        </div>
      </div>
      <div className="absolute h-[5.85%] w-[46.89%] top-[18.59%] right-[50.67%] bottom-[75.56%] left-[2.44%]">
        <div className="absolute w-[90.82%] top-[17.95%] left-[9.18%] tracking-[0.01em] leading-[130%] inline-block">
          Understand the differences between designing for iOS and Android
        </div>
        <div className="absolute top-[0rem] left-[0rem] w-[1.688rem] h-[2.019rem]">
          <div className="absolute top-[0rem] left-[0rem] rounded-lg w-[1.688rem] h-[2.019rem]" />
          <img
            className="absolute top-[0.5rem] left-[0.419rem] w-[0.844rem] h-[1.006rem]"
            alt=""
            src="/learning/ui--check.svg"
          />
        </div>
      </div>
      <div className="absolute h-[4.84%] w-[51.1%] top-[26.3%] right-[46.46%] bottom-[68.86%] left-[2.44%]">
        <div className="absolute top-[21.67%] left-[8.46%] tracking-[0.01em] leading-[130%]">
          Create wireframe designs for e-learning Project
        </div>
        <div className="absolute top-[0rem] left-[0rem] w-[1.688rem] h-[2.019rem]">
          <div className="absolute top-[0rem] left-[0rem] rounded-lg w-[1.688rem] h-[2.019rem]" />
          <img
            className="absolute top-[0.5rem] left-[0.419rem] w-[0.844rem] h-[1.006rem]"
            alt=""
            src="/learning/ui--check.svg"
          />
        </div>
      </div>
    </div>
  );
};

WhatYouLearn.propTypes = {
  className: PropTypes.string,
};

export default WhatYouLearn;
