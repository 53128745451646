import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Menu, X } from "lucide-react";
import MainNavigation from "../../components/MainNavigation";
import NavBar from "../../components/NavBar";
import Leaderboard from "../../components/profile/Leaderboard";
import Rightside from "../../components/profile/Rightside";

const NationalLeaderboard = () => {
    const navigate = useNavigate();
    const [isRightsideOpen, setIsRightsideOpen] = useState(false);

    useEffect(() => {
        const accessToken = localStorage.getItem("accessToken");
        if (!accessToken) {
            navigate("/login");
        }
    }, [navigate]);

    return (
        <div className="w-full relative bg-[#fbf1f1] min-h-screen overflow-x-hidden text-left text-xl text-default-white font-poppins">
            <NavBar />

            {/* Desktop Navigation */}
            <div className="hidden lg:block">
                <MainNavigation
                    homeClass=""
                    learningClass=""
                    mentorClass=""
                    groupsClass=""
                    profileClass="left-[-29px] rounded-tl-[0px] rounded-tr-11xl rounded-br-11xl rounded-bl-[0px] bg-buttonselect border-buttonselect border-[2px] border-solid box-border text-default-white"
                />
            </div>

            {/* Mobile Navigation */}
            <div className="lg:hidden px-4">
                <MainNavigation
                    homeClass=""
                    learningClass=""
                    mentorClass=""
                    groupsClass=""
                    profileClass="rounded-tl-[0px] rounded-tr-11xl rounded-br-11xl rounded-bl-[0px] bg-buttonselect border-buttonselect border-[2px] border-solid box-border text-default-white"
                />
            </div>

            {/* Menu Button for Mobile */}
            <button
                onClick={() => setIsRightsideOpen(!isRightsideOpen)}
                className="fixed right-4 top-20 z-[10001] lg:hidden bg-buttonselect p-2 rounded-full"
            >
                {isRightsideOpen ? (
                    <X className="w-6 h-6 text-white" />
                ) : (
                    <Menu className="w-6 h-6 text-white" />
                )}
            </button>

            {/* Profile Title */}
            <div className="lg:absolute lg:top-[116px] lg:left-[calc(50%_-_59px)] text-[32px] leading-[150%] font-semibold text-[#0d0c0c] text-center lg:text-left mt-14 lg:mt-0">
                Profile
            </div>

            {/* Mobile Layout */}
            <div className="lg:hidden px-4 mt-6 space-y-6">
                <div className="w-full">
                    <Leaderboard />
                </div>
                {/* Mobile Rightside Panel */}
                <div className={`fixed right-0 top-0 h-full w-3/4 bg-[#fbf1f1] transform transition-transform duration-300 ease-in-out z-[10000] ${isRightsideOpen ? 'translate-x-0' : 'translate-x-full'
                    } pt-24`}>
                    <Rightside isMobile={true} />
                </div>
            </div>

            {/* Desktop Layout - Preserved original positioning */}
            <div className="hidden lg:block">
                <div className="absolute top-[193px] left-[284px] w-[615px] h-[990px] pb-[100px]">
                    <Leaderboard />
                </div>
                <Rightside isMobile={false} />
            </div>

            {/* AI Chat Button */}
            <a href="/aichat" className="fixed bottom-6 right-6 lg:right-auto lg:bottom-6 lg:left-[285px] z-[10000]">
                <img
                    className="w-[50px] h-[50px] lg:w-[75px] lg:h-[75px] object-contain cursor-pointer"
                    alt="AI Chat"
                    src="/home/AIChart.svg"
                />
            </a>
        </div>
    );
};

export default NationalLeaderboard;