import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Menu, X } from "lucide-react";
import axios from "axios";
import MainNavigation from "../../components/MainNavigation";
import NavBar from "../../components/NavBar";
import Rightside from "../../components/profile/Rightside";
import SettingHeader from "../../components/profile/SettingHeader";
import Loader from "../../components/Loader";

const Setting = () => {
    const navigate = useNavigate();
    const [userData, setUserData] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const [editedData, setEditedData] = useState({});
    const userId = localStorage.getItem("userId");
    const [isLoading, setIsLoading] = useState(true);
    const [isRightsideOpen, setIsRightsideOpen] = useState(false);

    // School search states
    const [schools, setSchools] = useState([]);
    const [filteredSchools, setFilteredSchools] = useState([]);
    const [showDropdown, setShowDropdown] = useState(false);

    // Location states
    const [countries, setCountries] = useState([]);
    const [regions, setRegions] = useState([]);
    const [districts, setDistricts] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState("");
    const [selectedRegion, setSelectedRegion] = useState("");
    const [selectedDistrict, setSelectedDistrict] = useState("");

    useEffect(() => {
        const accessToken = localStorage.getItem("accessToken");
        if (!accessToken) {
            navigate("/login");
        } else {
            fetchUserData(accessToken);
            fetchCountries(accessToken);
        }
    }, [navigate]);

    // Fetch countries
    const fetchCountries = async (token) => {
        try {
            const response = await axios.get("https://api.staging.sensebod.com/api/v1/countries/", {
                headers: { Authorization: `Bearer ${token}` },
            });
            setCountries(response.data);
        } catch (error) {
            console.error("Error fetching countries:", error);
        }
    };

    // Fetch regions when country changes
    useEffect(() => {
        const fetchRegions = async () => {
            if (!selectedCountry) return;
            try {
                const response = await axios.get(
                    `https://api.staging.sensebod.com/api/v1/regions/?country_short_code=${selectedCountry}`,
                    {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
                        },
                    }
                );
                setRegions(response.data);
            } catch (error) {
                console.error("Error fetching regions:", error);
            }
        };

        fetchRegions();
    }, [selectedCountry]);

    // Fetch districts when region changes
    useEffect(() => {
        const fetchDistricts = async () => {
            if (!selectedRegion) return;
            try {
                const response = await axios.get(
                    `https://api.staging.sensebod.com/api/v1/districts/?region_id=${selectedRegion}`,
                    {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
                        },
                    }
                );
                setDistricts(response.data);
            } catch (error) {
                console.error("Error fetching districts:", error);
            }
        };

        fetchDistricts();
    }, [selectedRegion]);

    // Initialize location data when editing starts
    useEffect(() => {
        if (isEditing && userData?.school) {
            setSelectedCountry(userData.school.country.country_short_code);
            setSelectedRegion(userData.school.region.region_id);
            setSelectedDistrict(userData.school.district.district_name);
        }
    }, [isEditing, userData]);

    // Fetch schools based on district
    useEffect(() => {
        const fetchSchools = async () => {
            if (!selectedDistrict || !selectedRegion) return;
            try {
                const response = await axios.get(
                    `https://api.staging.sensebod.com/api/v1/schools/?district_name=${selectedDistrict}&region_id=${selectedRegion}`,
                    {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
                        },
                    }
                );
                setSchools(response.data);
            } catch (error) {
                console.error("Error fetching schools:", error);
            }
        };

        fetchSchools();
    }, [selectedDistrict, selectedRegion]);

    const fetchUserData = async (token) => {
        try {
            setIsLoading(true);
            const response = await axios.get(`https://api.staging.sensebod.com/api/v1/users/${userId}/`, {
                headers: { Authorization: `Bearer ${token}` }
            });

            if (!response.data.school) {
                navigate("/preference-1");
                return;
            }

            const formattedData = {
                ...response.data,
                first_name: response.data.first_name || '',
                last_name: response.data.last_name || '',
                email: response.data.email || '',
                phone_number: response.data.phone_number || '',
                date_of_birth: response.data.date_of_birth || '',
                picture: response.data.picture || null,
                school: response.data.school || {
                    school_name: '',
                    country: { country: 'N/A' }
                },
                username: response.data.username || ''
            };

            setUserData(formattedData);
            setEditedData(formattedData);
        } catch (error) {
            console.error("Error fetching user data:", error);
            if (error.response?.data?.school === null || error.response?.data?.school === undefined) {
                navigate("/preference-1");
                return;
            }
        } finally {
            setIsLoading(false);
        }
    };

    const handleEdit = () => {
        setIsEditing(true);
    };

    const handleCancel = () => {
        setIsEditing(false);
        setEditedData(userData);
        setShowDropdown(false);
        setSelectedCountry(userData.school.country.country_short_code);
        setSelectedRegion(userData.school.region.region_id);
        setSelectedDistrict(userData.school.district.district_name);
    };

    const handleSave = async () => {
        try {
            const token = localStorage.getItem("accessToken");
            const updatedData = {
                first_name: editedData.first_name || null,
                last_name: editedData.last_name || null,
                email: editedData.email || null,
                phone_number: editedData.phone_number || null,
                date_of_birth: editedData.date_of_birth || null,
                school: {
                    school_name: editedData.school?.school_name || null,
                    country: editedData.school?.country,
                    region: editedData.school?.region,
                    district: editedData.school?.district
                }
            };

            const response = await axios.patch(`https://api.staging.sensebod.com/api/v1/users/${userId}/`, updatedData, {
                headers: { Authorization: `Bearer ${token}` }
            });

            setUserData(response.data);
            setEditedData(response.data);
            setIsEditing(false);
            setShowDropdown(false);
        } catch (error) {
            console.error("Error updating user data:", error);
            alert("Failed to update profile. Please try again.");
        }
    };

    // Handle location changes
    const handleCountryChange = (e) => {
        const country = countries.find((c) => c.country === e.target.value);
        if (country) {
            setSelectedCountry(country.country_short_code);
            setEditedData(prev => ({
                ...prev,
                school: {
                    ...prev.school,
                    country: {
                        country: country.country,
                        country_short_code: country.country_short_code
                    },
                    // Clear region, district, and school data
                    region: {},
                    district: {},
                    school_name: '',
                }
            }));
            setSelectedRegion("");
            setSelectedDistrict("");
            setSchools([]);
            setFilteredSchools([]);
            setShowDropdown(false);
        }
    };

    const handleRegionChange = (e) => {
        const regionId = e.target.value;
        setSelectedRegion(regionId);
        const region = regions.find(r => r.region_id === Number(regionId));
        if (region) {
            setEditedData(prev => ({
                ...prev,
                school: {
                    ...prev.school,
                    region: {
                        region_id: region.region_id,
                        region_name: region.region_name
                    },
                    // Clear school data
                    school_name: '',
                }
            }));
        }
        // Clear district and school-related states
        setSelectedDistrict("");
        setSchools([]);
        setFilteredSchools([]);
        setShowDropdown(false);
    };

    const handleDistrictChange = (e) => {
        const districtName = e.target.value;
        setSelectedDistrict(districtName);
        setEditedData(prev => ({
            ...prev,
            school: {
                ...prev.school,
                district: {
                    district_name: districtName
                },
                // Clear school data
                school_name: '',
            }
        }));
        // Clear school-related states
        setSchools([]);
        setFilteredSchools([]);
        setShowDropdown(false);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setEditedData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSchoolInputChange = (e) => {
        const input = e.target.value;

        setEditedData(prevState => ({
            ...prevState,
            school: {
                ...prevState.school,
                school_name: input
            }
        }));

        if (input) {
            const filtered = schools.filter((school) =>
                school.toLowerCase().includes(input.toLowerCase())
            );
            setFilteredSchools(filtered);
            setShowDropdown(true);
        } else {
            setShowDropdown(false);
        }
    };

    const handleSchoolSelect = (selectedSchool) => {
        setEditedData(prevState => ({
            ...prevState,
            school: {
                ...prevState.school,
                school_name: selectedSchool
            }
        }));
        setShowDropdown(false);
    };

    const handleLogout = async () => {
        const refreshToken = localStorage.getItem("refreshToken");
        const accessToken = localStorage.getItem("accessToken");

        try {
            const response = await fetch("https://api.staging.sensebod.com/api/v1/logout/", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${accessToken}`
                },
                body: JSON.stringify({ refresh: refreshToken })
            });

            if (response.ok) {
                localStorage.clear();
                navigate("/");
            } else {
                console.error("Logout failed:", await response.text());
                localStorage.clear();
                navigate("/");
            }
        } catch (error) {
            console.error("Error during logout:", error);
            localStorage.clear();
            navigate("/");
        }
    };

    const handleDeactivateAccount = async () => {
        const isConfirmed = window.confirm("Are you sure you want to deactivate your account? This action cannot be undone.");

        if (isConfirmed) {
            try {
                const token = localStorage.getItem("accessToken");
                await axios.post(`https://api.staging.sensebod.com/api/v1/users/${userId}/deactivate/`, {}, {
                    headers: { Authorization: `Bearer ${token}` }
                });

                localStorage.clear();
                navigate("/account-deactivated");
            } catch (error) {
                console.error("Error deactivating account:", error);
                alert("Failed to deactivate account. Please try again.");
            }
        }
    };

    const formatDate = (dateString) => {
        if (!dateString) return '';
        return new Date(dateString).toISOString().split('T')[0];
    };

    if (isLoading) return <Loader duration={10000} />;

    if (!userData) return null;

    return (
        <div className="w-full relative bg-[#fbf1f1] min-h-screen overflow-x-hidden text-left text-xl text-default-white font-poppins">
            <NavBar />

            {/* Desktop Navigation */}
            <div className="hidden lg:block">
                <MainNavigation
                    profileClass="left-[-29px] rounded-tl-[0px] rounded-tr-11xl rounded-br-11xl rounded-bl-[0px] bg-buttonselect border-buttonselect border-[2px] border-solid box-border text-default-white"
                />
            </div>

            {/* Mobile Navigation */}
            <div className="lg:hidden px-4">
                <MainNavigation
                    profileClass="rounded-tl-[0px] rounded-tr-11xl rounded-br-11xl rounded-bl-[0px] bg-buttonselect border-buttonselect border-[2px] border-solid box-border text-default-white"
                />
            </div>

            {/* Menu Button for Mobile */}
            <button
                onClick={() => setIsRightsideOpen(!isRightsideOpen)}
                className="fixed right-4 top-20 z-[10001] lg:hidden bg-buttonselect p-2 rounded-full"
            >
                {isRightsideOpen ? (
                    <X className="w-6 h-6 text-white" />
                ) : (
                    <Menu className="w-6 h-6 text-white" />
                )}
            </button>

            {/* Profile Title */}
            <div className="lg:absolute lg:top-[116px] lg:left-[calc(50%_-_59px)] text-[32px] leading-[150%] font-semibold text-[#0d0c0c] text-center lg:text-left mt-14 lg:mt-0">
                Profile
            </div>

            {/* Main Content Area */}
            <div className="w-full relative lg:absolute lg:h-[973px] overflow-hidden text-left text-sm text-default-white font-poppins">
                {/* Mobile View */}
                <div className="lg:hidden px-4 py-6">
                    <div className="flex flex-wrap gap-2 text-[#92929d] font-menu-menu-1-bold text-sm mb-6">
                        <div className="flex items-center gap-1">
                            <span className="tracking-[0.01em] leading-[130%] font-semibold">Profiles</span>
                            <img
                                className="w-4 h-4"
                                alt=""
                                src="/profile/16--chevronright.svg"
                            />
                        </div>
                        <div className="flex items-center gap-1">
                            <span className="tracking-[0.01em] leading-[130%] font-semibold">Settings</span>
                            <img
                                className="w-4 h-4"
                                alt=""
                                src="/profile/16--chevronright.svg"
                            />
                        </div>
                        <div className="flex items-center">
                            <span className="tracking-[0.01em] leading-[130%] font-semibold">Accounts</span>
                        </div>
                    </div>
                    <SettingHeader account="text-[#232360]" />

                    <div className="mt-6 text-xs text-[#939699]">
                        {/* Edit/Save/Cancel Buttons */}
                        <div className="flex justify-end mb-4">
                            {isEditing ? (
                                <div className="flex gap-4">
                                    <button
                                        onClick={handleCancel}
                                        className="rounded-xl bg-default-white text-black px-6 py-3"
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        onClick={handleSave}
                                        className="rounded-xl bg-buttonselect text-white px-6 py-3"
                                    >
                                        Save
                                    </button>
                                </div>
                            ) : (
                                <button
                                    onClick={handleEdit}
                                    className="flex items-center gap-2 bg-background-main rounded-[5px] px-4 py-2"
                                >
                                    <img
                                        className="w-4 h-4"
                                        alt=""
                                        src="/profile/edit.svg"
                                    />
                                    <span className="text-[11px] text-white">Edit</span>
                                </button>
                            )}
                        </div>

                        {/* Profile Picture */}
                        <div className="flex justify-center mb-6">
                            <img
                                className="w-[110px] h-[128px] object-contain"
                                alt=""
                                src="/profile/square@2x.png"
                            />
                        </div>

                        {/* Form Fields */}
                        <div className="space-y-6">
                            {/* Personal Information Section */}
                            <div className="space-y-4">
                                {/* Full Name */}
                                <div className="space-y-1">
                                    <label className="tracking-[-0.01em] leading-[20px]">
                                        Full name
                                    </label>
                                    <div className="rounded-[12px] bg-[#f5f5f5] border-[#efeefc] border-[1px] h-[48px] overflow-hidden">
                                        {isEditing ? (
                                            <div className="flex px-4 h-full">
                                                <input
                                                    type="text"
                                                    name="first_name"
                                                    placeholder="First Name"
                                                    value={editedData.first_name}
                                                    onChange={handleInputChange}
                                                    className="w-1/2 bg-transparent outline-none"
                                                />
                                                <input
                                                    type="text"
                                                    name="last_name"
                                                    placeholder="Last Name"
                                                    value={editedData.last_name}
                                                    onChange={handleInputChange}
                                                    className="w-1/2 bg-transparent outline-none"
                                                />
                                            </div>
                                        ) : (
                                            <div className="px-4 h-full flex items-center">
                                                {userData.first_name || userData.last_name
                                                    ? `${userData.first_name || ''} ${userData.last_name || ''}`
                                                    : userData.username || 'N/A'}
                                            </div>
                                        )}
                                    </div>
                                </div>

                                {/* Date of Birth */}
                                <div className="space-y-1">
                                    <label className="tracking-[-0.01em] leading-[20px]">
                                        Date of Birth
                                    </label>
                                    <div className="rounded-[12px] bg-[#f5f5f5] border-[#efeefc] border-[1px] h-[48px] overflow-hidden">
                                        {isEditing ? (
                                            <input
                                                type="date"
                                                name="date_of_birth"
                                                value={formatDate(editedData.date_of_birth)}
                                                onChange={handleInputChange}
                                                className="w-full h-full px-4 bg-transparent outline-none"
                                            />
                                        ) : (
                                            <div className="px-4 h-full flex items-center">
                                                {userData.date_of_birth ? formatDate(userData.date_of_birth) : 'Not set'}
                                            </div>
                                        )}
                                    </div>
                                </div>

                                {/* Email */}
                                <div className="space-y-1">
                                    <label className="tracking-[-0.01em] leading-[20px]">
                                        Email
                                    </label>
                                    <div className="rounded-[12px] bg-[#f5f5f5] border-[#efeefc] border-[1px] h-[48px] overflow-hidden">
                                        {isEditing ? (
                                            <input
                                                type="email"
                                                name="email"
                                                placeholder="Enter email"
                                                value={editedData.email}
                                                onChange={handleInputChange}
                                                className="w-full h-full px-4 bg-transparent outline-none"
                                            />
                                        ) : (
                                            <div className="px-4 h-full flex items-center">
                                                {userData.email || 'Not set'}
                                            </div>
                                        )}
                                    </div>
                                </div>

                                {/* Phone Number */}
                                <div className="space-y-1">
                                    <label className="tracking-[-0.01em] leading-[20px]">
                                        Phone number
                                    </label>
                                    <div className="rounded-[12px] bg-[#f5f5f5] border-[#efeefc] border-[1px] h-[48px] overflow-hidden">
                                        {isEditing ? (
                                            <input
                                                type="tel"
                                                name="phone_number"
                                                placeholder="Enter phone number"
                                                value={editedData.phone_number}
                                                onChange={handleInputChange}
                                                className="w-full h-full px-4 bg-transparent outline-none"
                                            />
                                        ) : (
                                            <div className="px-4 h-full flex items-center">
                                                {userData.phone_number || 'Not set'}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>

                            {/* Location Information Section */}
                            <div className="space-y-4">
                                {/* Country */}
                                <div className="space-y-1">
                                    <label className="tracking-[-0.01em] leading-[20px]">
                                        Country
                                    </label>
                                    <div className="rounded-[12px] bg-[#f5f5f5] border-[#efeefc] border-[1px] h-[48px] overflow-hidden">
                                        {isEditing ? (
                                            <select
                                                className="w-full h-full px-4 bg-transparent outline-none"
                                                value={editedData.school?.country?.country || ""}
                                                onChange={handleCountryChange}
                                            >
                                                <option value="">Select country</option>
                                                {countries.map((country, index) => (
                                                    <option key={index} value={country.country}>
                                                        {country.country}
                                                    </option>
                                                ))}
                                            </select>
                                        ) : (
                                            <div className="px-4 h-full flex items-center">
                                                {userData.school?.country?.country || 'Not set'}
                                            </div>
                                        )}
                                    </div>
                                </div>

                                {/* Region */}
                                <div className="space-y-1">
                                    <label className="tracking-[-0.01em] leading-[20px]">
                                        Region
                                    </label>
                                    <div className="rounded-[12px] bg-[#f5f5f5] border-[#efeefc] border-[1px] h-[48px] overflow-hidden">
                                        {isEditing ? (
                                            <select
                                                className="w-full h-full px-4 bg-transparent outline-none"
                                                value={selectedRegion}
                                                onChange={handleRegionChange}
                                            >
                                                <option value="">Select region</option>
                                                {regions.map((region, index) => (
                                                    <option key={index} value={region.region_id}>
                                                        {region.region_name}
                                                    </option>
                                                ))}
                                            </select>
                                        ) : (
                                            <div className="px-4 h-full flex items-center">
                                                {userData.school?.region?.region_name || 'Not set'}
                                            </div>
                                        )}
                                    </div>
                                </div>

                                {/* District */}
                                <div className="space-y-1">
                                    <label className="tracking-[-0.01em] leading-[20px]">
                                        District
                                    </label>
                                    <div className="rounded-[12px] bg-[#f5f5f5] border-[#efeefc] border-[1px] h-[48px] overflow-hidden">
                                        {isEditing ? (
                                            <select
                                                className="w-full h-full px-4 bg-transparent outline-none"
                                                value={selectedDistrict}
                                                onChange={handleDistrictChange}
                                            >
                                                <option value="">Select district</option>
                                                {districts.map((district, index) => (
                                                    <option key={index} value={district.district_name}>
                                                        {district.district_name}
                                                    </option>
                                                ))}
                                            </select>
                                        ) : (
                                            <div className="px-4 h-full flex items-center">
                                                {userData.school?.district?.district_name || 'Not set'}
                                            </div>
                                        )}
                                    </div>
                                </div>

                                {/* School */}
                                <div className="space-y-1 relative">
                                    <label className="tracking-[-0.01em] leading-[20px]">
                                        School
                                    </label>
                                    <div className="rounded-[12px] bg-[#f5f5f5] border-[#efeefc] border-[1px] h-[48px] overflow-hidden">
                                        {isEditing ? (
                                            <input
                                                type="text"
                                                value={editedData.school?.school_name || ''}
                                                onChange={handleSchoolInputChange}
                                                placeholder="Start typing to search..."
                                                className="w-full h-full px-4 bg-transparent outline-none"
                                            />
                                        ) : (
                                            <div className="px-4 h-full flex items-center">
                                                {userData.school?.school_name || 'Not set'}
                                            </div>
                                        )}
                                    </div>
                                    {showDropdown && (
                                        <div className="absolute top-full left-0 w-full mt-1 rounded-[12px] border-[#ebebeb] border-[1px] bg-white shadow-lg z-50 max-h-[200px] overflow-y-auto">
                                            {filteredSchools.length > 0 ? (
                                                filteredSchools.map((school, index) => (
                                                    <div
                                                        key={index}
                                                        className="py-2 px-4 hover:bg-gray-500 hover:text-white cursor-pointer text-black"
                                                        onClick={() => handleSchoolSelect(school)}
                                                    >
                                                        {school}
                                                    </div>
                                                ))
                                            ) : (
                                                <div className="py-2 px-4 text-gray-500">
                                                    No schools found
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </div>
                            </div>

                            {/* Mobile Action Buttons */}
                            <div className="flex flex-col gap-4 mt-8">
                                <button
                                    onClick={handleLogout}
                                    className="w-full rounded-xl bg-buttonselect py-3 text-center"
                                >
                                    <span className="tracking-[-0.01em] leading-[20px] font-semibold">
                                        Log out
                                    </span>
                                </button>
                                <button
                                    onClick={handleDeactivateAccount}
                                    className="w-full rounded-xl bg-[#df4559] py-3 text-center"
                                >
                                    <span className="tracking-[-0.01em] leading-[20px] font-semibold">
                                        Deactivate Account
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Desktop View - Exact Original Layout */}
                <div className="hidden lg:block">
                <div className="absolute top-[122px] left-[285px] flex flex-row items-start justify-start gap-[9px] text-[#92929d] font-menu-menu-1-bold">
                        <div className="flex flex-row items-center justify-start gap-[8px]">
                            <div className="w-[16px] relative h-[16px]">
                                <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded-[2px] opacity-[0]" />
                                <img
                                    className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] max-w-full overflow-hidden max-h-full"
                                    alt=""
                                    src="/profile/16--chevronright.svg"
                                />
                            </div>
                            <div className="relative tracking-[0.01em] leading-[130%] font-semibold">
                                Profiles
                            </div>
                        </div>

                        <div className="flex flex-row items-center justify-start gap-[8px]">
                            <div className="w-[16px] relative h-[16px]">
                                <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded-[2px] opacity-[0]" />
                                <img
                                    className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] max-w-full overflow-hidden max-h-full"
                                    alt=""
                                    src="/profile/16--chevronright.svg"
                                />
                            </div>
                            <div className="flex flex-row items-center justify-center">
                                <div className="relative tracking-[0.01em] leading-[130%] font-semibold">
                                    Settings
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-row items-center justify-start gap-[8px]">
                            <div className="w-[16px] relative h-[16px]">
                                <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded-[2px] opacity-[0]" />
                                <img
                                    className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] max-w-full overflow-hidden max-h-full"
                                    alt=""
                                    src="/profile/16--chevronright.svg"
                                />
                            </div>
                            <div className="flex flex-row items-center justify-center">
                                <div className="relative tracking-[0.01em] leading-[130%] font-semibold">
                                    Accounts
                                </div>
                            </div>
                        </div>
                    </div>
                    <SettingHeader account="text-[#232360]" />

                    {/* Profile Form Section */}
                    <div className="absolute top-[281px] left-[304px] w-[589px] h-[403px] text-xs text-[#939699]">
                        <div className="absolute top-[0px] left-[0px] w-[589px] h-[403px]">
                            {/* Left Column */}
                            <div className="absolute top-[153px] left-[0px] w-[274px] flex flex-col items-start justify-start gap-[17px]">
                                {/* Full name */}
                                <div className="self-stretch flex flex-col items-start justify-start">
                                    <div className="self-stretch flex flex-col items-start justify-start gap-[4px]">
                                        <div className="self-stretch flex flex-row items-center justify-start">
                                            <div className="relative tracking-[-0.01em] leading-[20px]">
                                                Full name
                                            </div>
                                        </div>
                                        <div className="self-stretch rounded-[12px] bg-[#f5f5f5] border-[#efeefc] border-[1px] border-solid box-border h-[48px] overflow-hidden shrink-0 flex flex-row items-center justify-between py-[12px] px-[16px] text-sm">
                                            {isEditing ? (
                                                <div className="flex w-full gap-2">
                                                    <input
                                                        type="text"
                                                        name="first_name"
                                                        placeholder="First Name"
                                                        value={editedData.first_name}
                                                        onChange={handleInputChange}
                                                        className="w-1/2 bg-transparent outline-none"
                                                    />
                                                    <input
                                                        type="text"
                                                        name="last_name"
                                                        placeholder="Last Name"
                                                        value={editedData.last_name}
                                                        onChange={handleInputChange}
                                                        className="w-1/2 bg-transparent outline-none"
                                                    />
                                                </div>
                                            ) : (
                                                <div className="relative tracking-[-0.03em] leading-[20px] font-semibold">
                                                    {userData.first_name || userData.last_name
                                                        ? `${userData.first_name || ''} ${userData.last_name || ''}`
                                                        : userData.username || 'N/A'}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>

                                {/* Date of Birth */}
                                <div className="self-stretch flex flex-col items-start justify-start">
                                    <div className="self-stretch flex flex-col items-start justify-start gap-[4px]">
                                        <div className="self-stretch flex flex-row items-center justify-start">
                                            <div className="relative tracking-[-0.01em] leading-[20px]">
                                                Date of Birth
                                            </div>
                                        </div>
                                        <div className="self-stretch rounded-[12px] bg-[#f5f5f5] border-[#efeefc] border-[1px] border-solid box-border h-[48px] overflow-hidden shrink-0 flex flex-row items-center justify-between py-[12px] px-[16px] text-sm">
                                            {isEditing ? (
                                                <input
                                                    type="date"
                                                    name="date_of_birth"
                                                    value={formatDate(editedData.date_of_birth)}
                                                    onChange={handleInputChange}
                                                    className="w-full bg-transparent outline-none"
                                                />
                                            ) : (
                                                <div className="relative tracking-[-0.03em] leading-[20px] font-semibold">
                                                    {userData.date_of_birth ? formatDate(userData.date_of_birth) : 'Not set'}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>

                                {/* Email */}
                                <div className="self-stretch flex flex-col items-start justify-start">
                                    <div className="self-stretch flex flex-col items-start justify-start gap-[4px]">
                                        <div className="self-stretch flex flex-row items-center justify-start">
                                            <div className="relative tracking-[-0.01em] leading-[20px]">Email</div>
                                        </div>
                                        <div className="self-stretch rounded-[12px] bg-[#f5f5f5] border-[#ebebeb] border-[1px] border-solid box-border h-[48px] overflow-hidden shrink-0 flex flex-row items-center justify-between py-[12px] px-[16px] text-sm">
                                            {isEditing ? (
                                                <input
                                                    type="email"
                                                    name="email"
                                                    placeholder="Enter email"
                                                    value={editedData.email}
                                                    onChange={handleInputChange}
                                                    className="w-full bg-transparent outline-none"
                                                />
                                            ) : (
                                                <div className="relative tracking-[-0.03em] leading-[20px]">
                                                    {userData.email || 'Not set'}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                {/* Phone number */}
                                <div className="self-stretch flex flex-col items-start justify-start">
                                    <div className="self-stretch flex flex-col items-start justify-start gap-[4px]">
                                        <div className="self-stretch flex flex-row items-center justify-start">
                                            <div className="relative tracking-[-0.01em] leading-[20px]">
                                                Phone number
                                            </div>
                                        </div>
                                        <div className="self-stretch rounded-[16px] bg-[#f5f5f5] border-[#ebebeb] border-[1px] border-solid box-border h-[48px] overflow-hidden shrink-0 flex flex-row items-center py-[16px] px-[16px] text-[#686b6d]">
                                            {isEditing ? (
                                                <input
                                                    type="tel"
                                                    name="phone_number"
                                                    placeholder="Enter phone number"
                                                    value={editedData.phone_number}
                                                    onChange={handleInputChange}
                                                    className="w-full bg-transparent outline-none"
                                                />
                                            ) : (
                                                <div className="relative tracking-[-0.03em] leading-[20px]">
                                                    {userData.phone_number || 'Not set'}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>

                            </div>

                            {/* Right Column */}
                            <div className="absolute top-[153px] left-[315px] w-[274px] flex flex-col items-start justify-start gap-[17px]">

                                {/* Country Field */}
                                <div className="self-stretch flex flex-col items-start justify-start gap-[4px]">
                                    <div className="self-stretch flex flex-row items-center justify-start">
                                        <div className="relative tracking-[-0.01em] leading-[20px]">Country</div>
                                    </div>
                                    <div className="self-stretch rounded-[12px] bg-[#f5f5f5] border-[#ebebeb] border-[1px] border-solid box-border h-[48px] overflow-hidden shrink-0">
                                        {isEditing ? (
                                            <select
                                                className="w-full h-full px-4 bg-transparent outline-none"
                                                value={editedData.school?.country?.country || ""}
                                                onChange={handleCountryChange}
                                            >
                                                <option value="">Select country</option>
                                                {countries.map((country, index) => (
                                                    <option key={index} value={country.country}>
                                                        {country.country}
                                                    </option>
                                                ))}
                                            </select>
                                        ) : (
                                            <div className="p-4">{userData.school?.country?.country || 'Not set'}</div>
                                        )}
                                    </div>
                                </div>

                                {/* Region Field */}
                                <div className="self-stretch flex flex-col items-start justify-start gap-[4px]">
                                    <div className="self-stretch flex flex-row items-center justify-start">
                                        <div className="relative tracking-[-0.01em] leading-[20px]">Region</div>
                                    </div>
                                    <div className="self-stretch rounded-[12px] bg-[#f5f5f5] border-[#ebebeb] border-[1px] border-solid box-border h-[48px] overflow-hidden shrink-0">
                                        {isEditing ? (
                                            <select
                                                className="w-full h-full px-4 bg-transparent outline-none"
                                                value={selectedRegion}
                                                onChange={handleRegionChange}
                                            >
                                                <option value="">Select region</option>
                                                {regions.map((region, index) => (
                                                    <option key={index} value={region.region_id}>
                                                        {region.region_name}
                                                    </option>
                                                ))}
                                            </select>
                                        ) : (
                                            <div className="p-4">{userData.school?.region?.region_name || 'Not set'}</div>
                                        )}
                                    </div>
                                </div>

                                {/* District Field */}
                                <div className="self-stretch flex flex-col items-start justify-start gap-[4px]">
                                    <div className="self-stretch flex flex-row items-center justify-start">
                                        <div className="relative tracking-[-0.01em] leading-[20px]">District</div>
                                    </div>
                                    <div className="self-stretch rounded-[12px] bg-[#f5f5f5] border-[#ebebeb] border-[1px] border-solid box-border h-[48px] overflow-hidden shrink-0">
                                        {isEditing ? (
                                            <select
                                                className="w-full h-full px-4 bg-transparent outline-none"
                                                value={selectedDistrict}
                                                onChange={handleDistrictChange}
                                            >
                                                <option value="">Select district</option>
                                                {districts.map((district, index) => (
                                                    <option key={index} value={district.district_name}>
                                                        {district.district_name}
                                                    </option>
                                                ))}
                                            </select>
                                        ) : (
                                            <div className="p-4">{userData.school?.district?.district_name || 'Not set'}</div>
                                        )}
                                    </div>
                                </div>

                                {/* School Field */}
                                <div className="self-stretch flex flex-col items-start justify-start gap-[4px] relative">
                                    <div className="self-stretch flex flex-row items-center justify-start">
                                        <div className="relative tracking-[-0.01em] leading-[20px]">School</div>
                                    </div>
                                    <div className="self-stretch rounded-[12px] bg-[#f5f5f5] border-[#ebebeb] border-[1px] border-solid box-border h-[48px] overflow-hidden shrink-0">
                                        {isEditing ? (
                                            <input
                                                type="text"
                                                value={editedData.school?.school_name || ''}
                                                onChange={handleSchoolInputChange}
                                                placeholder="Start typing to search..."
                                                className="w-full h-full px-4 bg-transparent outline-none"
                                            />
                                        ) : (
                                            <div className="p-4">{userData.school?.school_name || 'Not set'}</div>
                                        )}
                                    </div>
                                    {showDropdown && (
                                        <div className="absolute top-[72px] left-0 w-full rounded-[12px] border-[#ebebeb] border-[1px] border-solid box-border bg-white shadow-lg z-50 max-h-[200px] overflow-y-auto">
                                            {filteredSchools.length > 0 ? (
                                                filteredSchools.map((school, index) => (
                                                    <div
                                                        key={index}
                                                        className="py-2 px-4 hover:bg-gray-500 hover:text-white cursor-pointer text-black"
                                                        onClick={() => handleSchoolSelect(school)}
                                                    >
                                                        {school}
                                                    </div>
                                                ))
                                            ) : (
                                                <div className="py-2 px-4 text-gray-500">
                                                    No schools found
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </div>
                            </div>

                            {/* Profile Picture */}
                            <img
                                className="absolute top-[0px] left-[220px] w-[110px] h-[128px] object-contain"
                                alt=""
                                src="/profile/square@2x.png"
                            />
                        </div>

                        {/* Edit/Save/Cancel Buttons */}
                        {isEditing ? (
                            <div className="absolute top-[-33px] left-[400px] flex flex-row gap-[17px]">
                                <div
                                    className="rounded-xl bg-default-white w-[88px] flex flex-row items-center justify-center py-[12px] px-[0px] box-border cursor-pointer"
                                    onClick={handleCancel}
                                >
                                    <div className="relative tracking-[-0.01em] leading-[20px] font-semibold">
                                        Cancel
                                    </div>
                                </div>
                                <div
                                    className="rounded-xl bg-buttonselect w-[88px] flex flex-row items-center justify-center py-[12px] px-[0px] box-border text-default-white cursor-pointer"
                                    onClick={handleSave}
                                >
                                    <div className="relative tracking-[-0.01em] leading-[20px] font-semibold">
                                        Save
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div
                                className="absolute top-[-33px] left-[529px] rounded-[5px] bg-background-main w-[60px] h-[25px] text-[11px] text-default-white cursor-pointer"
                                onClick={handleEdit}
                            >
                                <div className="absolute top-[3px] left-[11px] w-[38px] h-[20px]">
                                    <img
                                        className="absolute top-[3px] left-[0px] w-[14px] h-[14px] overflow-hidden"
                                        alt=""
                                        src="/profile/edit.svg"
                                    />
                                    <div className="absolute top-[0px] left-[18px] tracking-[-0.01em] leading-[20px] font-medium">
                                        Edit
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>

                    {/* Desktop Logout and Deactivate Buttons */}
                    <div
                        className="absolute top-[788px] left-[285px] rounded-xl bg-buttonselect flex flex-row items-center justify-center py-[12px] px-[50px] cursor-pointer"
                        onClick={handleLogout}
                    >
                        <div className="relative tracking-[-0.01em] leading-[20px] font-semibold">
                            Log out
                        </div>
                    </div>
                    <div
                        className="absolute top-[788px] left-[669px] rounded-xl bg-[#df4559] flex flex-row items-center justify-center py-[12px] px-[50px] cursor-pointer"
                        onClick={handleDeactivateAccount}
                    >
                        <div className="relative tracking-[-0.01em] leading-[20px] font-semibold">
                            Deactivate Account
                        </div>
                    </div>
                </div>

                {/* Mobile Rightside */}
                <div
                    className={`fixed right-0 top-0 h-full w-3/4 bg-[#fbf1f1] transform transition-transform duration-300 ease-in-out z-[10000] ${isRightsideOpen ? 'translate-x-0' : 'translate-x-full'
                        } pt-24 lg:hidden`}
                >
                    <Rightside isMobile={true} />
                </div>

                {/* Desktop Rightside */}
                <div className="hidden lg:block">
                    <Rightside isMobile={false} />
                </div>
            </div>

            {/* AI Chat Button */}
            <a href="/aichat" className="fixed bottom-6 right-6 lg:right-auto lg:bottom-6 lg:left-[285px] z-[10000]">
                <img
                    className="w-[50px] h-[50px] lg:w-[75px] lg:h-[75px] object-contain cursor-pointer"
                    alt="AI Chat"
                    src="/home/AIChart.svg"
                />
            </a>
        </div>
    );
};

export default Setting;