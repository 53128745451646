import React, { useState, useEffect } from 'react'
import MainNavigation from "../../components/MainNavigation";
import NavBar from "../../components/NavBar";
import AllCourses from '../../components/learning/AllCourses';

const CoursesPage = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
    const [isTablet, setIsTablet] = useState(window.innerWidth >= 768 && window.innerWidth < 1024);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
            setIsTablet(window.innerWidth >= 768 && window.innerWidth < 1024);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <div className="w-full relative bg-[#fbf1f1] min-h-screen pb-20 overflow-x-hidden text-left text-base text-darkslategray-100 font-poppins">
            <a href="/aichat" className="fixed bottom-6 left-4 md:left-[285px] z-[10000]">
                <img
                    className="w-[75px] h-[75px] object-contain cursor-pointer"
                    alt="AI Chat"
                    src="/home/AIChart.svg"
                />
            </a>
            <NavBar />
            <MainNavigation
                homeClass=""
                learningClass="left-[-29px] rounded-tl-[0px] rounded-tr-11xl rounded-br-11xl rounded-bl-[0px] bg-buttonselect border-buttonselect border-[2px] border-solid box-border text-default-white"
                mentorClass="custom-mentor-class"
                groupsClass="another-custom-class"
                profileClass="custom-profile-class"
            />

            <div className={`${isMobile || isTablet ? 'px-4 mt-20' : 'absolute top-[8.875rem] left-[17.813rem] right-[30px]'} text-[0.875rem] text-light-text-color-body-1`}>
                <h1 className="text-3xl md:text-4xl font-semibold text-[#0d0c0c] text-center md:text-left mb-8">
                    All Courses
                </h1>
                <AllCourses />
            </div>
        </div>
    )
}

export default CoursesPage;