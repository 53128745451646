import { useMemo } from "react";
import PropTypes from "prop-types";

const Progress7 = ({
  className = "",
  progressWidth,
  progressPosition,
  progressHeight,
  progressTop,
  progressLeft,
  rectangleDivBackgroundColor,
  rectangleDivWidth,
  rectangleDivRight,
  rectangleDivBackgroundColor1,
}) => {
  const progressStyle = useMemo(() => {
    return {
      width: progressWidth,
      position: progressPosition,
      height: progressHeight,
      top: progressTop,
      left: progressLeft,
    };
  }, [
    progressWidth,
    progressPosition,
    progressHeight,
    progressTop,
    progressLeft,
  ]);

  const rectangleDiv1Style = useMemo(() => {
    return {
      backgroundColor: rectangleDivBackgroundColor,
    };
  }, [rectangleDivBackgroundColor]);

  const rectangleDiv2Style = useMemo(() => {
    return {
      width: rectangleDivWidth,
      right: rectangleDivRight,
      backgroundColor: rectangleDivBackgroundColor1,
    };
  }, [rectangleDivWidth, rectangleDivRight, rectangleDivBackgroundColor1]);

  return (
    <div
      className={`w-[148px] relative h-[4px] ${className}`}
      style={progressStyle}
    >
      <div
        className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded-[24px] bg-[#efeefc]"
        style={rectangleDiv1Style}
      />
      <div
        className="absolute h-full w-4/5 top-[0%] right-[20%] bottom-[0%] left-[0%] rounded-[24px] bg-[#6a5ae0]"
        style={rectangleDiv2Style}
      />
    </div>
  );
};

Progress7.propTypes = {
  className: PropTypes.string,

  /** Style props */
  progressWidth: PropTypes.any,
  progressPosition: PropTypes.any,
  progressHeight: PropTypes.any,
  progressTop: PropTypes.any,
  progressLeft: PropTypes.any,
  rectangleDivBackgroundColor: PropTypes.any,
  rectangleDivWidth: PropTypes.any,
  rectangleDivRight: PropTypes.any,
  rectangleDivBackgroundColor1: PropTypes.any,
};

export default Progress7;
