import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import Fuse from 'fuse.js';
import Loader from '../../components/Loader';

const AIFlashCard = () => {
    const navigate = useNavigate();
    const username = localStorage.getItem("username");
    const [formKey, setFormKey] = useState(Date.now());
    const [subjectArea, setSubjectArea] = useState('');
    const [subjectAreas, setSubjectAreas] = useState([]);
    const [filteredSubjectAreas, setFilteredSubjectAreas] = useState([]);
    const [selectedSubjectId, setSelectedSubjectId] = useState(null);
    const [topic, setTopic] = useState('');
    const [difficultyLevel, setDifficultyLevel] = useState('');
    const [numFlashcards, setNumFlashcards] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isLoading1, setIsLoading1] = useState(false);

    useEffect(() => {
        fetchSubjectAreas();
        // Reset form state when component mounts
        setSubjectArea('');
        setSelectedSubjectId(null);
        setTopic('');
        setDifficultyLevel('');
        setNumFlashcards('');
        setFormKey(Date.now());
    }, []);

    const fetchSubjectAreas = async () => {
        const accessToken = localStorage.getItem("accessToken");
        try {
            setIsLoading1(true);
            const response = await axios.get('https://api.staging.sensebod.com/api/v1/courses/subject/', {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
            const subjects = response.data.map(subject => ({
                id: subject.id,
                name: subject.name
            }));
            console.log("Subjects", subjects);
            setSubjectAreas(subjects);
        } catch (error) {
            console.error('Error fetching subject areas:', error);
        } finally {
            setIsLoading1(false);
        }
    };

    useEffect(() => {
        if (subjectArea) {
            const fuse = new Fuse(subjectAreas, {
                keys: ['name'],
                includeScore: true,
                threshold: 0.4,
            });
            const results = fuse.search(subjectArea);
            setFilteredSubjectAreas(results.map(result => result.item));
        } else {
            setFilteredSubjectAreas([]);
        }
    }, [subjectArea, subjectAreas]);

    const handleSubjectAreaChange = (e) => {
        setSubjectArea(e.target.value);
        setSelectedSubjectId(null);
    };

    const handleSubjectAreaSelect = (selected) => {
        setSubjectArea(selected.name);
        setSelectedSubjectId(selected.id);
        setFilteredSubjectAreas([]);
    };

    const handleGenerateFlashcards = async () => {
        if (selectedSubjectId && topic && difficultyLevel && numFlashcards) {
          setIsLoading(true);
          const accessToken = localStorage.getItem("accessToken");
          try {
            const response = await axios.post('https://api.staging.sensebod.com/api/v1/courses/flashcards/',
              {
                subject_area: selectedSubjectId,
                topic: topic,
                difficulty_level: difficultyLevel,
                num_flashcards: parseInt(numFlashcards, 10),
                user: username
              },
              {
                headers: {
                  Authorization: `Bearer ${accessToken}`,
                },
              }
            );
      
            const flashcardsData = response.data;
      
            // Store flashcards data and subject area in localStorage
            localStorage.setItem('currentFlashcardsData', JSON.stringify({
              flashcards: flashcardsData,
              subjectArea: subjectArea
            }));
            
            // Navigate to the flashcards display page
            navigate("/course/ai-flashcards-display");
          } catch (error) {
            console.error('Error generating flashcards:', error);
            alert('Failed to generate flashcards. Please try again.');
          } finally {
            setIsLoading(false);
          }
        } else {
          alert("Please fill in all fields before generating flashcards.");
        }
      };

    if (isLoading1) {
        return <div><Loader duration={10000} /></div>;
    }

    if (isLoading) {
        return <div><Loader duration={5000} text='Generating Flashcards...' /></div>;
    }

    return (
        <div className="w-full min-h-screen bg-[#fbf1f1] text-white font-poppins">
            {/* Header */}
            <div className="w-full bg-white border-b border-[#efefef] py-4 px-6 flex justify-between items-center">
                <div className="flex items-center gap-2">
                    <img
                        className="w-8 h-8 cursor-pointer"
                        alt=""
                        src="/learning/iconamoonarrowup2@2x.png"
                        onClick={() => navigate("/course/learning-home")}
                    />
                    <span className="text-background-main text-sm">AI Flashcard Generator</span>
                </div>
                <div className="flex items-center gap-2 right-[100px] mq850:right-[50px] absolute">
                    <img
                        className="w-10 h-10 rounded-full"
                        alt=""
                        src="/learning/avatar1@2x.png"
                    />
                    <span className="text-[#3d3d3d] text-sm">{username}</span>
                </div>
            </div>

            {/* Main Content */}
            <div className="max-w-3xl mx-auto my-8 p-6 bg-background-main rounded-xl shadow-lg mq850:ml-[10px] mq850:mr-[10px]">
                <h1 className="text-3xl font-bold text-center text-[#c3cece] mb-6">AI Flashcard Generator</h1>

                <div className="space-y-4" key={formKey}>
                    <div>
                        <label className="block text-sm font-bold mb-2" htmlFor="subjectArea">Subject Area</label>
                        <input
                            type="text"
                            id="subjectArea"
                            value={subjectArea}
                            onChange={handleSubjectAreaChange}
                            className="w-[90%] px-3 py-2 text-gray-700 border rounded-lg focus:outline-none"
                            placeholder="Enter subject area"
                        />
                        {filteredSubjectAreas.length > 0 && (
                            <ul className="mt-2 bg-white text-black border border-gray-300 rounded-md shadow-sm list-none p-0">
                                {filteredSubjectAreas.map((subject) => (
                                    <li
                                        key={subject.id}
                                        className="px-3 py-2 hover:bg-blue-500 hover:text-white cursor-pointer"
                                        onClick={() => handleSubjectAreaSelect(subject)}
                                    >
                                        {subject.name}
                                    </li>
                                ))}
                            </ul>
                        )}
                    </div>

                    <div>
                        <label className="block text-sm font-bold mb-2" htmlFor="topic">Topic</label>
                        <input
                            type="text"
                            id="topic"
                            value={topic}
                            onChange={(e) => setTopic(e.target.value)}
                            className="w-[90%] px-3 py-2 text-gray-700 border rounded-lg focus:outline-none"
                            placeholder="Enter topic"
                        />
                    </div>

                    <div>
                        <label className="block text-sm font-bold mb-2" htmlFor="difficultyLevel">Difficulty Level</label>
                        <select
                            id="difficultyLevel"
                            value={difficultyLevel}
                            onChange={(e) => setDifficultyLevel(e.target.value)}
                            className="w-[93%] mq850:w-[96.5%] px-3 py-2 text-gray-700 border rounded-lg focus:outline-none"
                        >
                            <option value="">Select Difficulty Level</option>
                            <option value="beginner">Beginner</option>
                            <option value="intermediate">Intermediate</option>
                            <option value="advanced">Advanced</option>
                        </select>
                    </div>

                    <div>
                        <label className="block text-sm font-bold mb-2" htmlFor="numFlashcards">Number of Flashcards</label>
                        <input
                            type="number"
                            id="numFlashcards"
                            value={numFlashcards}
                            onChange={(e) => setNumFlashcards(e.target.value)}
                            className="w-[90%] px-3 py-2 text-gray-700 border rounded-lg focus:outline-none"
                            placeholder="Enter number of flashcards"
                            min="1"
                            max="50"
                        />
                    </div>
                </div>

                <div className="mt-6 text-center">
                    <button
                        className={`bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-lg focus:outline-none focus:shadow-outline ${isLoading ? 'opacity-50 cursor-not-allowed' : ''}`}
                        type="button"
                        onClick={handleGenerateFlashcards}
                        disabled={isLoading}
                    >
                        {isLoading ? 'Generating Flashcards...' : 'Generate AI Flashcards'}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default AIFlashCard;