import React, { useState, useCallback, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Button from "../../components/Button";
import Progress7 from "../../components/Progress7";

const PreferencesScreen8 = () => {
  const navigate = useNavigate();
  const [learningGoals, setLearningGoals] = useState([]);
  const [selectedGoals, setSelectedGoals] = useState([]);

  // Function to fetch learning goals from the API
  const fetchLearningGoals = async () => {
    try {
      const token = localStorage.getItem("accessToken"); 

      const response = await axios.get(
        "https://api.staging.sensebod.com/api/v1/preference/learning-goals/",
        {
          headers: {
            Authorization: `Bearer ${token}`, 
          },
        }
      );
      setLearningGoals(response.data); 
    } catch (error) {
      console.error("Error fetching learning goals:", error);
    }
  };

  useEffect(() => {
    fetchLearningGoals();
  }, []); 

  const handleGoalSelect = (goal) => {
    setSelectedGoals((prevSelectedGoals) =>
      prevSelectedGoals.includes(goal)
        ? prevSelectedGoals.filter((g) => g !== goal)
        : [...prevSelectedGoals, goal]
    );
  };

  const onButtonContainerClick = useCallback(() => {
    // Retrieve the existing preferencesData from localStorage
    const existingPreferences = JSON.parse(localStorage.getItem("preferencesData")) || {};

    console.log("existingPreferences",existingPreferences);

    // Add the selected learning goals to the preferencesData
    const updatedPreferences = {
      ...existingPreferences,
      learning_goals: selectedGoals,
    };

    // Store the updated preferencesData back in localStorage
    localStorage.setItem("preferencesData", JSON.stringify(updatedPreferences));

    // Navigate to the next screen
    navigate("/9-preferencesscreen");
  }, [navigate, selectedGoals]);

  const handleGoBack = () => {
    navigate(-1); // Navigate back to the previous screen
  };

  const handleSkip = () => {
    navigate("/9-preferencesscreen");
  }

  const getBackgroundColor = (index) => {
    const colors = [
      "bg-blue-200 hover:bg-blue-300",
      "bg-green-200 hover:bg-green-300",
      "bg-yellow-200 hover:bg-yellow-300",
      "bg-red-200 hover:bg-red-300",
      "bg-purple-200 hover:bg-purple-300"
    ];
    return colors[index % colors.length];
  };

  return (
    <div className="min-h-screen w-full relative bg-default-white overflow-x-hidden text-left text-sm text-black font-poppins">
      {/* Background images with responsive positioning */}
      <div className="fixed inset-0 overflow-hidden pointer-events-none">
        <img
          className="absolute top-0 left-1/2 -translate-x-1/2 w-full max-w-[1933px] h-auto object-cover opacity-50"
          alt="Background pattern"
          src="/preference.svg"
        />
        <img
          className="absolute -bottom-16 -left-16 w-[300px] h-[300px] mq750:w-[400px] mq750:h-[400px] mq1280:w-[526px] mq1280:h-[526px] object-contain opacity-30"
          alt="Decorative system"
          src="/easystem-4-1@2x.png"
        />
        <img
          className="absolute -top-16 -right-16 w-[300px] h-[300px] mq750:w-[400px] mq750:h-[400px] mq1280:w-[562px] mq1280:h-[562px] object-contain opacity-30"
          alt="Decorative system"
          src="/easystem-4-22@2x.png"
        />
      </div>

      {/* Skip button - positioned at top right */}
      <div className="absolute top-6 right-6 z-20">
        <div 
          className="text-[#c3beb5] font-medium cursor-pointer hover:text-gray-600"
          onClick={() => navigate("/9-preferencesscreen")}
        >
          Skip
        </div>
      </div>

      {/* Main content container */}
      <div className="relative z-10 max-w-[1200px] mx-auto px-4 py-8 mq750:py-12">
        {/* Progress Bar and Back Button */}
        <div className="w-full max-w-[921px] mx-auto mb-8 flex items-center justify-between">
          <img
            onClick={() => navigate(-1)}
            className="w-8 h-8 cursor-pointer"
            alt="Go Back"
            src="/icon.svg"
          />
          <div className="flex-1 mx-4">
            <Progress7
              progressWidth="100%"
              progressPosition="relative"
              progressHeight="21px"
              progressTop="0"
              progressLeft="0"
              rectangleDivBackgroundColor="rgba(5, 93, 178, 0.1)"
              rectangleDivWidth="40%"
              rectangleDivRight="60%"
              rectangleDivBackgroundColor1="#f09a19"
            />
          </div>
          <div className="text-buttonselect leading-[150%]">4 of 10</div>
        </div>

        {/* Logo */}
        <div className="flex justify-center mb-8">
          <img
            className="w-24 h-24 mq750:w-32 mq750:h-32 object-contain"
            alt="Logo"
            src="/download-1-1@2x.png"
          />
        </div>

        {/* Info Box */}
        <div className="relative mx-auto max-w-[350px] mq750:max-w-[450px] mb-12">
          <img
            className="w-full h-auto"
            alt="Info background"
            src="/vector-1.svg"
          />
          <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-sm text-black font-medium p-4 w-[80%] text-center">
            Choose your learning goals to help me guide you effectively.
          </div>
        </div>

        {/* Learning Goals Selection */}
        <div className="max-w-[370px] mx-auto">
          {/* Goals List */}
          <div className="space-y-4 mb-8">
            {learningGoals.map((goal, index) => (
              <div
                key={index}
                onClick={() => handleGoalSelect(goal.learning_goal)}
                className={`
                  p-4 rounded-xl cursor-pointer transition-all duration-200
                  ${selectedGoals.includes(goal.learning_goal)
                    ? getBackgroundColor(selectedGoals.indexOf(goal.learning_goal))
                    : 'bg-white border-[#EFEEFC]'
                  }
                  border-2 hover:border-buttonselect
                `}
              >
                <div className="flex items-center gap-2">
                  <div className="flex-1 font-medium">{goal.learning_goal}</div>
                  {selectedGoals.includes(goal.learning_goal) && (
                    <svg className="w-5 h-5 text-buttonselect flex-shrink-0" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                    </svg>
                  )}
                </div>
              </div>
            ))}
          </div>

          {/* Next Button */}
          <div className="sticky bottom-4 mt-4">
            <Button
              buttonWidth="100%"
              buttonPosition="relative"
              buttonBackgroundColor="#055db2"
              buttonPadding="16px"
              button="Next"
              buttonFontSize="16px"
              buttonFontWeight="bold"
              buttonFontFamily="Poppins"
              onButtonContainerClick={onButtonContainerClick}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PreferencesScreen8;