import React, { useState } from 'react';
import { Eye, EyeOff } from 'lucide-react';
import axios from 'axios';

const PasswordChangeForm = () => {
  const [isEditing, setIsEditing] = useState(false);
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');

  // Keep your existing handler functions
  const handleEdit = () => {
    setIsEditing(true);
    setErrors({});
    setSuccessMessage('');
  };

  const handleCancel = () => {
    setIsEditing(false);
    setOldPassword('');
    setNewPassword('');
    setConfirmPassword('');
    setErrors({});
    setSuccessMessage('');
  };

  const handleSave = async () => {
    // Keep your existing save logic
    setErrors({});
    setSuccessMessage('');

    if (newPassword !== confirmPassword) {
      setErrors({ confirm_password: "New passwords don't match" });
      return;
    }

    if (!oldPassword || !newPassword || !confirmPassword) {
      setErrors({ general: "All fields are required" });
      return;
    }

    setIsSubmitting(true);

    try {
      const token = localStorage.getItem("accessToken");
      await axios.post(
        'https://api.staging.sensebod.com/api/v1/change-password/',
        {
          old_password: oldPassword,
          new_password: newPassword
        },
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        }
      );

      setSuccessMessage('Password changed successfully!');
      setOldPassword('');
      setNewPassword('');
      setConfirmPassword('');
      setIsEditing(false);
    } catch (error) {
      console.error('Password change error:', error);
      if (error.response?.data) {
        setErrors(error.response.data);
      } else {
        setErrors({ general: 'Failed to change password. Please try again.' });
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const togglePasswordVisibility = (setter) => {
    setter(prev => !prev);
  };

  const renderPasswordField = (label, value, onChange, showPassword, setShowPassword, error) => (
    <div className="w-full flex flex-col items-start justify-start gap-1">
      <div className="w-full flex items-center justify-start">
        <div className="text-xs tracking-[-0.01em] leading-[20px] text-[#939699]">
          {label}
        </div>
      </div>
      <div className={`w-full rounded-[16px] bg-[#f5f5f5] border border-solid h-[56px] flex items-center justify-between py-4 px-5 
        ${error ? 'border-red-500' : 'border-[#ebebeb]'}`}>
        <div className="flex items-center gap-3 flex-1">
          <img
            className="w-5 h-5"
            alt=""
            src="/profile/password.svg"
          />
          <input
            type={showPassword ? "text" : "password"}
            value={value}
            onChange={(e) => onChange(e.target.value)}
            disabled={!isEditing || isSubmitting}
            className="bg-transparent border-none outline-none text-sm tracking-[-0.03em] leading-[20px] font-medium w-full"
            placeholder="Enter Password"
          />
        </div>
        <button
          type="button"
          onClick={() => togglePasswordVisibility(setShowPassword)}
          className="bg-transparent border-none cursor-pointer ml-2"
          disabled={!isEditing || isSubmitting}
        >
          {showPassword ? (
            <EyeOff className="w-5 h-5 text-gray-500" />
          ) : (
            <Eye className="w-5 h-5 text-gray-500" />
          )}
        </button>
      </div>
      {error && (
        <div className="text-red-500 text-xs mt-1">{error}</div>
      )}
    </div>
  );

  return (
    <div className="w-full px-4 lg:px-0 lg:absolute lg:top-[262px] lg:left-[382px] lg:w-[511px] lg:h-[408px] text-buttonselect">
      {/* Edit/Save Buttons */}
      <div className="flex justify-end mb-6 lg:mb-0">
        {!isEditing ? (
          <button 
            onClick={handleEdit}
            className="flex items-center gap-2 bg-background-main rounded-[5px] px-4 py-2 relative right-[10px]"
          >
            <img
              className="w-3.5 h-3.5"
              alt=""
              src="/profile/edit.svg"
            />
            <span className="text-xs text-white tracking-[-0.01em] leading-[20px] font-medium">
              Edit
            </span>
          </button>
        ) : (
          <div className="flex gap-4">
            <button 
              onClick={handleCancel}
              className="rounded-xl bg-default-white px-6 py-3"
            >
              <span className="tracking-[-0.01em] leading-[20px] font-semibold">
                Cancel
              </span>
            </button>
            <button 
              onClick={handleSave}
              className={`rounded-xl bg-buttonselect px-6 py-3 text-white
                ${isSubmitting ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer'}`}
              disabled={isSubmitting}
            >
              <span className="tracking-[-0.01em] leading-[20px] font-semibold">
                {isSubmitting ? 'Saving...' : 'Save'}
              </span>
            </button>
          </div>
        )}
      </div>

      {/* Success/Error Messages */}
      {successMessage && (
        <div className="w-full text-green-500 text-sm text-center mb-4">
          {successMessage}
        </div>
      )}

      {errors.general && (
        <div className="w-full text-red-500 text-sm text-center mb-4">
          {errors.general}
        </div>
      )}

      {/* Password Fields */}
      <div className="mq750:relative mq750:left-[-30px] mq750:w-[80%] max-w-[504px] mx-auto lg:mx-0 flex flex-col gap-4">
        {renderPasswordField(
          "Old password",
          oldPassword,
          setOldPassword,
          showOldPassword,
          setShowOldPassword,
          errors.old_password
        )}
        {renderPasswordField(
          "New password",
          newPassword,
          setNewPassword,
          showNewPassword,
          setShowNewPassword,
          errors.new_password
        )}
        {renderPasswordField(
          "Confirm new password",
          confirmPassword,
          setConfirmPassword,
          showConfirmPassword,
          setShowConfirmPassword,
          errors.confirm_password
        )}
      </div>
    </div>
  );
};

export default PasswordChangeForm;