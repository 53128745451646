import React from 'react';
import { useNavigate } from 'react-router-dom';

const CourseCard = ({ course }) => {
  const navigate = useNavigate();

  const handleViewCourse = () => {
    navigate(`/course/${course.slug}/${course.id}`);
  };

  return (
    <div className="bg-white rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300 p-4">
      <img
        className="w-full h-48 object-cover rounded-t-lg"
        src={course.imageUrl}
        alt={course.title}
      />
      <div className="p-4">
        <h3 className="text-xl font-semibold mb-2">{course.title}</h3>
        <p className="text-gray-600">{course.description}</p>
        <div className="text-sm text-gray-500 mt-2 flex items-center">
          <span className="flex items-center">
            {course.rating}
            <img
              className="h-[25px] w-[25px] ml-1"
              alt=""
              src="/learning/group2.svg"
            />
          </span>
          <span className="flex items-center ml-4">
            {course.coursePoints}
            <img
              className="h-[25px] w-[25px] ml-1"
              alt=""
              src="/learning/group.svg"
            />
          </span>
        </div>
        <button
          className="mt-4 bg-[#055db2] text-white px-4 py-2 rounded-lg hover:bg-[#023566] transition-colors duration-300 cursor-pointer w-full"
          onClick={handleViewCourse}
        >
          View Course
        </button>
      </div>
    </div>
  );
};

export default CourseCard;