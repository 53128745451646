import { useState } from "react";
import PropTypes from "prop-types";

const Accordion1 = ({ className = "", whatYoullLearn, details, isLastItem }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleAccordion = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div
      className={`w-[19.438rem] relative h-auto text-left text-[1rem] text-gray-500 font-poppins-captions-bold ${className}`}
    >
      <div
        className="flex justify-between items-center cursor-pointer p-4"
        onClick={toggleAccordion}
      >
        <b className="tracking-[0.01em] leading-[120%]">{whatYoullLearn}</b>
        <img
          src={isExpanded ? "/learning/ui--chevron-down1.svg" : "/learning/icon9@2x.png"}
          alt="Toggle Icon"
          className="w-[1.5rem] h-[1.5rem]"
        />
      </div>

      {/* Conditionally render separator line */}
      {!isLastItem && (
        <div className="absolute h-[1.39%] w-[91.22%] top-[99.31%] right-[4.41%] bottom-[-0.69%] left-[4.37%] bg-[#E1E1FB] border-[#E1E1FB] border-t-[1px] border-solid box-border" />
      )}

      {isExpanded && <div className="p-4 text-gray-700">{details}</div>}
    </div>
  );
};

Accordion1.propTypes = {
  className: PropTypes.string,
  whatYoullLearn: PropTypes.string.isRequired,
  details: PropTypes.string.isRequired,
  isLastItem: PropTypes.bool,  // New prop to control separator rendering
};

export default Accordion1;
