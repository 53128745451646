import axios from 'axios';

const fetchRegistrants = async () => {
  try {
    const accessToken = localStorage.getItem("accessToken");
    if (!accessToken) {
      throw new Error('Access token is missing');
    }

    const response = await axios.get('https://api.staging.sensebod.com/api/v1/courses/registrant/', {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (response.status === 200) {
      console.log("Registrant",response.data);
      return response.data;
    } else {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
  } catch (error) {
    console.error('Error fetching registrants:', error);
    throw error;
  }
};

export default fetchRegistrants;