import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import MainNavigation from "../components/MainNavigation";
import NavBar from "../components/NavBar";
import SideBar from "../components/SideBar";
import Loader from "../components/Loader";
import ImprovedSlider from "../components/ImprovedSlider";

const Home = () => {
  const navigate = useNavigate();
  const [groups, setGroups] = useState([]);
  const [courses, setCourses] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showCourses, setShowCourses] = useState(false);
  const [showGroups, setShowGroups] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch groups
        const groupsResponse = await fetch(
          "https://api.staging.sensebod.com/api/v1/groups/group/",
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            },
          }
        );

        if (groupsResponse.ok) {
          const groupsData = await groupsResponse.json();
          const groupsDatas = groupsData.filter(group =>
            group.visibility_privacy_settings === "public"
          );
          setGroups(groupsDatas);
        } else {
          console.error("Failed to fetch groups:", groupsResponse.statusText);
        }

        // Fetch courses
        const coursesResponse = await fetch(
          "https://api.staging.sensebod.com/api/v1/courses/course/",
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            },
          }
        );

        if (coursesResponse.ok) {
          const coursesData = await coursesResponse.json();
          setCourses(coursesData);
        } else {
          console.error("Failed to fetch courses:", coursesResponse.statusText);
        }

        setIsLoading(false);

        // Trigger animations after a short delay
        setTimeout(() => setShowCourses(true), 500);
        setTimeout(() => setShowGroups(true), 1000);
      } catch (error) {
        console.error("Error fetching data:", error);
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  const slickSettings = {
    dots: courses?.length > 1,
    infinite: courses?.length > 1,
    speed: 500,
    slidesToShow: Math.min(3, courses?.length || 1),
    slidesToScroll: 1,
    autoplay: courses?.length > 1,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    centerMode: courses?.length === 1,
    centerPadding: courses?.length === 1 ? '0px' : '60px',
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: Math.min(2, courses?.length || 1),
          slidesToScroll: 1,
          infinite: courses?.length > 1,
          dots: courses?.length > 1,
          centerMode: courses?.length === 1,
          centerPadding: courses?.length === 1 ? '0px' : '60px',
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: courses?.length > 1,
          dots: courses?.length > 1,
          centerMode: courses?.length === 1,
          centerPadding: courses?.length === 1 ? '0px' : '60px',
        }
      }
    ],
    appendDots: dots => (
      <div style={{ top: "60px", position: "relative" }}>
        <ul style={{ margin: "0px" }}> {dots} </ul>
      </div>
    ),
    dotsClass: "slick-dots slick-thumb",
  };

  const GroupslickSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: false,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ],
    appendDots: dots => (
      <div style={{ top: "60px", position: "relative" }}>
        <ul style={{ margin: "0px" }}> {dots} </ul>
      </div>
    ),
    dotsClass: "slick-dots slick-thumb",
  };

  if (isLoading) {
    return <Loader duration={10000} />;
  }

  const handleCourseClick = (course) => {
    navigate(`/course/${course.slug}/${course.id}`);
  };

  const handleGroupClick = () => {
    navigate(`/groups`);
  };

  // const baseImageURL = "https://api.staging.sensebod.com";

  return (
    <div className="w-full relative bg-[#fbf1f1] min-h-screen overflow-x-hidden text-center text-[6px] text-default-white font-poppins">
      <NavBar />

      <div className="md:w-1/4 mt-4 md:mt-0">
        <SideBar />
      </div>

      <div className="md:flex px-[30px] md:px-0 mt-[370px] md:mt-0">
        <div className="md:w-3/4">
          <div className="flex-col overflow-hidden border-solid h-[200px] w-[300px] mq750:hidden">
            <p className="absolute rounded-[18px] left-[280px] font-[Poppins] text-[25px] bg-[#1A3651] h-[120px] w-[615px] line-height: 36px font-[600] pl-[13px] text-start text-[#ffff] top-[85px] pt-[40px]">
              Be part of study groups and <br />learn with peers
            </p>
            <img
              className="absolute top-[100px] left-[453px] w-[550px] h-[170px] overflow-hidden"
              alt=""
              src="/home/CroodsPartyTime.svg"
            />
          </div>

          {courses.length > 0 && (
        <div className={`mt-4 relative md:mt-0 md:absolute md:left-[280px] mq750:top-[-120px] md:top-[295px] w-full md:w-[621px] rounded-[18px] pb-[20px] pr-[10px] bg-default-white transition-opacity duration-1000 ease-in-out ${showCourses ? 'opacity-100' : 'opacity-0'}`}>
          <div className="flex justify-between items-center mb-4 px-4 md:px-0">
            <p className="text-[20px] text-[#707070] font-semibold">Courses</p>
            <a href="/courses" className="text-[15px] text-[#14B8B9] cursor-pointer">See all</a>
          </div>
          {isMobile ? (
            <ImprovedSlider 
              items={courses} 
              settings={slickSettings} 
              type="courses" 
              onItemClick={handleCourseClick}
            />
          ) : (
            <div className={`${courses.length === 1 ? 'flex justify-center items-center px-4' : ''}`}>
              {courses.length === 1 ? (
                <div className="w-full max-w-[350px]">
                  <div className="relative rounded-[30px] overflow-hidden h-[240px] transition-transform duration-500 ease-in-out transform hover:scale-105 cursor-pointer" 
                       onClick={() => navigate(`/course/${courses[0].slug}/${courses[0].id}`)}>
                    <img
                      src={courses[0].banner ? `${courses[0].banner}` : "/learning/courses/courses.jpg"}
                      alt={courses[0].name}
                      className="w-full h-full object-cover"
                    />
                    <div className="absolute bottom-0 left-0 right-0 bg-background-main text-white p-3 cursor-pointer h-[80px] flex flex-col justify-center">
                      <p className="text-[16px] font-medium truncate">{courses[0].name}</p>
                      <p className="text-[12px] mt-1">{courses[0].type}</p>
                    </div>
                  </div>
                </div>
              ) : (
                <Slider {...slickSettings}>
                  {courses.map((course, index) => (
                    <div key={index} className="px-2">
                      <div className="relative rounded-[30px] overflow-hidden h-[240px] mx-2 transition-transform duration-500 ease-in-out transform hover:scale-105 cursor-pointer" 
                           onClick={() => navigate(`/course/${course.slug}/${course.id}`)}>
                        <img
                          src={course.banner ? `${course.banner}` : "/learning/courses/courses.jpg"}
                          alt={course.name}
                          className="w-full h-full object-cover"
                        />
                        <div className="absolute bottom-0 left-0 right-0 bg-background-main text-white p-3 cursor-pointer h-[80px] flex flex-col justify-center">
                          <p className="text-[16px] font-medium truncate">{course.name}</p>
                          <p className="text-[12px] mt-1">{course.type}</p>
                        </div>
                      </div>
                    </div>
                  ))}
                </Slider>
              )}
            </div>
          )}
        </div>
      )}

          <div className={`relative mq750:top-[-120px] mt-4 mq750:mt-[3rem] md:mt-0 md:absolute md:left-[280px] md:top-[700px] w-full md:w-[621px] rounded-[30px] pb-[20px] bg-[#FFFFFF] transition-opacity duration-1000 ease-in-out ${showGroups ? 'opacity-100' : 'opacity-0'}`}>
            <div className="flex justify-between items-center mb-4 px-4 pt-4">
              <p className="text-[20px] text-[#707070] font-semibold">Groups</p>
              <a href="/groups" className="text-[15px] text-[#14B8B9] cursor-pointer">See all</a>
            </div>
            {isMobile ? (
          <ImprovedSlider items={groups} settings={GroupslickSettings} type="groups" />
        ) : (
              <Slider {...GroupslickSettings}>
                {groups.map((group, index) => (
                  <div key={index} className="px-2">
                    <div className="relative rounded-[30px] overflow-hidden h-[198px] mx-2 transition-transform duration-500 ease-in-out transform hover:scale-105" onClick={() => handleGroupClick()}>
                      <img
                        src="/group/Group-02.png"
                        alt={group.name}
                        className="w-full h-full object-cover"
                      />
                      <div className="absolute bottom-0 left-0 right-0 bg-[#1A3651] text-white p-3 cursor-pointer h-[85px]">
                        <p className="text-[16px] font-medium text-white">{group.name}</p>
                        <div className="flex justify-between items-center mt-2 px-[10px]">
                          <div className="flex items-center">
                            <img src="/home/Icon.svg" alt="Members" className="w-[20px] h-[20px] mr-1" />
                            <span className="text-[11px]">{group.member_count}</span>
                            <p className="absolute pl-[40px] font-[Poppins] text-[11px] line-height: 22px font-[400] text-start text-[#FFFFF] cursor-[pointer]">|</p>
                          </div>
                          {/* <div className="flex items-center">
                            <img src="/home/Icon (1).svg" alt="Rating" className="w-[20px] h-[20px] mr-1" />
                            <span className="text-[11px]">{group.group_points}</span>
                            <p className="absolute pl-[50px] font-[Poppins] text-[11px] line-height: 22px font-[400] text-start text-[#FFFFF] cursor-[pointer]">|</p>
                          </div> */}
                          <div className="flex items-center">
                            <img src="/home/Icon (2).svg" alt="Privacy" className="w-[20px] h-[20px] mr-1" />
                            <span className="text-[11px]">{group.join_privacy_settings}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </Slider>
            )}
          </div>
        </div>
      </div>

      <a href="/aichat" className="fixed bottom-6 left-4 md:left-[285px] z-[10000]">
        <img
          className="w-[75px] h-[75px] object-contain cursor-pointer"
          alt="AI Chat"
          src="/home/AIChart.svg"
        />
      </a>

      <MainNavigation
        homeClass="left-[-29px] rounded-tl-[0px] rounded-tr-11xl text-white rounded-br-11xl rounded-bl-[0px] bg-buttonselect border-buttonselect border-[2px] border-solid box-border text-default-white"
        learningClass=""
        mentorClass="custom-mentor-class"
        groupsClass="another-custom-class"
        profileClass="custom-profile-class"
      />
    </div>
  );
};

export default Home;