import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Menu, X } from "lucide-react";
import MainNavigation from "../../components/MainNavigation";
import NavBar from "../../components/NavBar";
import Rightside from "../../components/profile/Rightside";
import SettingHeader from "../../components/profile/SettingHeader";
import PasswordChangeForm from "../../components/profile/PasswordChangeForm";
import axios from "axios";

const SetttingPassword = () => {
    const navigate = useNavigate();
    const [isRightsideOpen, setIsRightsideOpen] = useState(false);

    useEffect(() => {
        const accessToken = localStorage.getItem("accessToken");
        if (!accessToken) {
            navigate("/login");
        }
    }, [navigate]);

    const handleLogout = async () => {
        const refreshToken = localStorage.getItem("refreshToken");
        const accessToken = localStorage.getItem("accessToken");

        try {
            const response = await fetch("https://api.staging.sensebod.com/api/v1/logout/", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${accessToken}`
                },
                body: JSON.stringify({ refresh: refreshToken })
            });

            if (response.ok) {
                // Logout successful
                localStorage.clear();
                navigate("/");
            } else {
                // Handle logout failure
                console.error("Logout failed:", await response.text());
                // Optionally, you can still clear local storage and redirect
                localStorage.clear();
                navigate("/");
            }
        } catch (error) {
            console.error("Error during logout:", error);
            // In case of network errors, still clear local storage and redirect
            localStorage.clear();
            navigate("/");
        }
    };



    const handleDeactivateAccount = async () => {
        const isConfirmed = window.confirm("Are you sure you want to deactivate your account? This action cannot be undone.");

        if (isConfirmed) {
            try {
                const token = localStorage.getItem("accessToken");
                const userId = localStorage.getItem("userId");

                await axios.post(`https://api.staging.sensebod.com/api/v1/users/${userId}/deactivate/`, {}, {
                    headers: { Authorization: `Bearer ${token}` }
                });

                localStorage.removeItem("accessToken");
                localStorage.removeItem("userId");
                navigate("/account-deactivated");
            } catch (error) {
                console.error("Error deactivating account:", error);
                alert("An error occurred while trying to deactivate your account. Please try again later.");
            }
        }
    };

    return (
        <div className="w-full relative bg-[#fbf1f1] min-h-screen overflow-x-hidden text-left text-xl text-default-white font-poppins">
            <NavBar />

            {/* Desktop Navigation */}
            <div className="hidden lg:block">
                <MainNavigation
                    homeClass=""
                    learningClass=""
                    mentorClass=""
                    groupsClass=""
                    profileClass="left-[-29px] rounded-tl-[0px] rounded-tr-11xl rounded-br-11xl rounded-bl-[0px] bg-buttonselect border-buttonselect border-[2px] border-solid box-border text-default-white"
                />
            </div>

            {/* Mobile Navigation */}
            <div className="lg:hidden px-4">
                <MainNavigation
                    homeClass=""
                    learningClass=""
                    mentorClass=""
                    groupsClass=""
                    profileClass="rounded-tl-[0px] rounded-tr-11xl rounded-br-11xl rounded-bl-[0px] bg-buttonselect border-buttonselect border-[2px] border-solid box-border text-default-white"
                />
            </div>

            {/* Menu Button for Mobile */}
            <button
                onClick={() => setIsRightsideOpen(!isRightsideOpen)}
                className="fixed right-4 top-20 z-[10001] lg:hidden bg-buttonselect p-2 rounded-full"
            >
                {isRightsideOpen ? (
                    <X className="w-6 h-6 text-white" />
                ) : (
                    <Menu className="w-6 h-6 text-white" />
                )}
            </button>

            {/* Profile Title */}
            <div className="lg:absolute lg:top-[116px] lg:left-[calc(50%_-_59px)] text-[32px] leading-[150%] font-semibold text-[#0d0c0c] text-center lg:text-left mt-14 lg:mt-0">
                Profile
            </div>


            <div className="w-full lg:absolute lg:h-[973px] overflow-hidden text-left text-sm text-default-white font-poppins">
                {/* Mobile Layout */}
                <div className="lg:hidden px-4 py-6">
                    {/* Mobile Breadcrumb */}
                    <div className="flex flex-wrap gap-2 text-[#92929d] font-menu-menu-1-bold text-sm mb-6">
                        <div className="flex items-center gap-1">
                            <span className="tracking-[0.01em] leading-[130%] font-semibold">Profiles</span>
                            <img
                                className="w-4 h-4"
                                alt=""
                                src="/profile/16--chevronright.svg"
                            />
                        </div>
                        <div className="flex items-center gap-1">
                            <span className="tracking-[0.01em] leading-[130%] font-semibold">Settings</span>
                            <img
                                className="w-4 h-4"
                                alt=""
                                src="/profile/16--chevronright.svg"
                            />
                        </div>
                        <div className="flex items-center">
                            <span className="tracking-[0.01em] leading-[130%] font-semibold">Password</span>
                        </div>
                    </div>

                    <SettingHeader password="text-[#232360]" />

                    <div className="mt-6">
                        <PasswordChangeForm />
                    </div>

                    {/* Mobile Action Buttons */}
                    <div className="flex flex-col gap-4 mt-8">
                        <button
                            onClick={handleLogout}
                            className="w-full rounded-xl bg-buttonselect py-3 text-center"
                        >
                            <span className="tracking-[-0.01em] leading-[20px] font-semibold">
                                Log out
                            </span>
                        </button>
                        <button
                            onClick={handleDeactivateAccount}
                            className="w-full rounded-xl bg-[#df4559] py-3 text-center"
                        >
                            <span className="tracking-[-0.01em] leading-[20px] font-semibold">
                                Deactivate Account
                            </span>
                        </button>
                    </div>
                </div>

                {/* Desktop Layout - Exact Original */}
                <div className="hidden lg:block">
                    {/* Desktop Breadcrumb */}
                    <div className="absolute top-[122px] left-[285px] flex flex-row items-start justify-start gap-[9px] text-[#92929d] font-menu-menu-1-bold">
                        <div className="flex flex-row items-center justify-start gap-[8px]">
                            <div className="w-[16px] relative h-[16px]">
                                <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded-[2px] opacity-[0]" />
                                <img
                                    className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] max-w-full overflow-hidden max-h-full"
                                    alt=""
                                    src="/profile/16--chevronright.svg"
                                />
                            </div>
                            <div className="relative tracking-[0.01em] leading-[130%] font-semibold">
                                Profiles
                            </div>
                        </div>

                        <div className="flex flex-row items-center justify-start gap-[8px]">
                            <div className="w-[16px] relative h-[16px]">
                                <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded-[2px] opacity-[0]" />
                                <img
                                    className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] max-w-full overflow-hidden max-h-full"
                                    alt=""
                                    src="/profile/16--chevronright.svg"
                                />
                            </div>
                            <div className="flex flex-row items-center justify-center">
                                <div className="relative tracking-[0.01em] leading-[130%] font-semibold">
                                    Settings
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-row items-center justify-start gap-[8px]">
                            <div className="w-[16px] relative h-[16px]">
                                <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded-[2px] opacity-[0]" />
                                <img
                                    className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] max-w-full overflow-hidden max-h-full"
                                    alt=""
                                    src="/profile/16--chevronright.svg"
                                />
                            </div>
                            <div className="flex flex-row items-center justify-center">
                                <div className="relative tracking-[0.01em] leading-[130%] font-semibold">
                                    Password
                                </div>
                            </div>
                        </div>
                    </div>

                    <SettingHeader password="text-[#232360]" />
                    <PasswordChangeForm />

                    {/* Desktop Action Buttons */}
                    <div
                        className="absolute top-[788px] left-[285px] rounded-xl bg-buttonselect flex flex-row items-center justify-center py-[12px] px-[50px] cursor-pointer"
                        onClick={handleLogout}
                    >
                        <div className="relative tracking-[-0.01em] leading-[20px] font-semibold">
                            Log out
                        </div>
                    </div>
                    <div
                        className="absolute top-[788px] left-[669px] rounded-xl bg-[#df4559] flex flex-row items-center justify-center py-[12px] px-[50px] cursor-pointer"
                        onClick={handleDeactivateAccount}
                    >
                        <div className="relative tracking-[-0.01em] leading-[20px] font-semibold">
                            Deactivate Account
                        </div>
                    </div>
                </div>

                {/* Mobile Rightside */}
                <div
                    className={`fixed right-0 top-0 h-full w-3/4 bg-[#fbf1f1] transform transition-transform duration-300 ease-in-out z-[10000] ${isRightsideOpen ? 'translate-x-0' : 'translate-x-full'
                        } pt-24 lg:hidden`}
                >
                    <Rightside isMobile={true} />
                </div>

                {/* Desktop Rightside */}
                <div className="hidden lg:block">
                    <Rightside isMobile={false} />
                </div>
            </div>

            {/* AI Chat Button */}
            <a href="/aichat" className="fixed bottom-6 right-6 lg:right-auto lg:bottom-6 lg:left-[285px] z-[10000]">
                <img
                    className="w-[50px] h-[50px] lg:w-[75px] lg:h-[75px] object-contain cursor-pointer"
                    alt="AI Chat"
                    src="/home/AIChart.svg"
                />
            </a>
        </div>
    );
};

export default SetttingPassword;