import React from "react";
import PropTypes from "prop-types";

const GroupBoard = ({ className = "", generalCount, subjectCount, totalGroups, totalPoints }) => {
  return (
    <div
      className={`mx-4 md:mx-0 md:absolute md:top-[195px] md:right-[100px] rounded-xl bg-default-white w-full md:w-[350px] mq850:w-[90%] h-auto md:h-[231px] overflow-hidden text-left text-xs text-black font-poppins ${className}`}
    >
      <div className="flex flex-col md:block p-4 md:p-0">
        <div className="flex justify-between items-center md:absolute md:top-[20px] md:left-[13px]">
          <span className="text-base font-medium">Group Board</span>
          <div className="flex items-center md:absolute md:top-[-6px] md:left-[203px]">
            <img
              className="w-[32px] h-[32px] overflow-hidden mr-2"
              alt=""
              src="/group/icon7.svg"
            />
            <div className="flex flex-col items-center">
              <b className="text-base leading-[150%] font-[Rubik]">{totalPoints}</b>
              <div className="tracking-[0.04em] leading-[150%] uppercase font-medium opacity-[0.5] text-[10px]">
                POINTS
              </div>
            </div>
          </div>
        </div>

        <img
          className="hidden md:block absolute top-[53px] left-[calc(50%_-_142px)] max-h-full w-[289px]"
          alt=""
          src="/group/vector-33.svg"
        />

        <div className="flex flex-wrap justify-around mt-4 md:mt-0 md:absolute md:top-[67px] md:left-[calc(50%_-_129.5px)] md:flex-row md:items-start md:justify-start md:py-[4px] md:px-[6px] md:gap-[44px] text-[#8a8a8a]">
          <div className="w-[101px] relative rounded-[12px] bg-[#f9f9f9] h-[43px] overflow-hidden shrink-0 mb-4 md:mb-0">
            <div className="absolute top-[calc(50%_-_12.5px)] left-[calc(50%_-_24.5px)]">
              General
            </div>
            <div className="absolute top-[calc(50%_+_4.5px)] left-[calc(50%_-_15.5px)] text-sm font-medium text-[#1d1b1b]">
              {generalCount}/3
            </div>
            <img
              className="absolute top-[9px] left-[5px] w-[24px] h-[24px] overflow-hidden"
              alt=""
              src="/group/icon4.svg"
            />
          </div>
          <div className="w-[101px] relative rounded-[12px] bg-[#f9f9f9] h-[43px] overflow-hidden shrink-0 mb-4 md:mb-0">
            <div className="absolute top-[calc(50%_-_12.5px)] left-[calc(50%_-_22.5px)]">
              Session
            </div>
            <div className="absolute top-[calc(50%_+_4.5px)] left-[calc(50%_-_7.5px)] text-sm font-medium text-[#1d1b1b]">
              0h-0m
            </div>
            <img
              className="absolute top-[12px] left-[3px] w-[24px] h-[24px] overflow-hidden"
              alt=""
              src="/group/icon5.svg"
            />
          </div>
          <div className="w-[101px] relative rounded-[12px] bg-[#f9f9f9] h-[43px] overflow-hidden shrink-0 mb-4 md:mb-0 md:absolute md:top-[81px] md:left-[calc(50%_-_50px)]">
            <div className="absolute top-[calc(50%_-_12.5px)] left-[calc(50%_-_24.5px)]">
              Specific
            </div>
            <div className="absolute top-[calc(50%_+_4.5px)] left-[calc(50%_-_10.5px)] text-sm font-medium text-[#1d1b1b]">
              {subjectCount}/2
            </div>
            <img
              className="absolute top-[12px] left-[5px] w-[24px] h-[24px] overflow-hidden"
              alt=""
              src="/group/icon6.svg"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

GroupBoard.propTypes = {
  className: PropTypes.string,
  generalCount: PropTypes.number.isRequired,
  subjectCount: PropTypes.number.isRequired,
  totalGroups: PropTypes.number.isRequired,
  totalPoints: PropTypes.number.isRequired,
};

export default GroupBoard;