// Button.js
import React from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

const Button = ({
  className = "",
  button,
  showIcon,
  buttonPosition,
  buttonBackgroundColor,
  buttonTop,
  buttonLeft,
  buttonWidth,
  buttonHeight,
  buttonColor,
  link,
  zIndex,
}) => {
  const navigate = useNavigate();

  const buttonStyle = {
    position: buttonPosition || 'relative',
    backgroundColor: buttonBackgroundColor,
    top: buttonTop,
    left: buttonLeft,
    width: buttonWidth || 'auto',
    height: buttonHeight,
    color: buttonColor,
    zIndex: zIndex || 1,
    transform: buttonLeft?.includes('calc') ? 'translateX(-50%)' : 'none',
    cursor: 'pointer',
    whiteSpace: 'nowrap',
    padding: '0 20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '14px',
    fontWeight: '500',
    textDecoration: 'none',
    border: 'none',
    outline: 'none',
  };

  const handleClick = (e) => {
    e.stopPropagation();
    if (link) {
      navigate(link);
    }
  };

  return (
    <button
      className={`rounded-xl relative ${className}`}
      style={buttonStyle}
      onClick={handleClick}
    >
      {showIcon && (
        <img
          className="w-[20px] h-[20px] overflow-hidden shrink-0 mr-2"
          alt=""
          src="/icon3.svg"
        />
      )}
      <span>{button}</span>
    </button>
  );
};

Button.propTypes = {
  className: PropTypes.string,
  button: PropTypes.string.isRequired,
  showIcon: PropTypes.bool,
  buttonPosition: PropTypes.string,
  buttonBackgroundColor: PropTypes.string,
  buttonTop: PropTypes.string,
  buttonLeft: PropTypes.string,
  buttonWidth: PropTypes.string,
  buttonHeight: PropTypes.string,
  buttonColor: PropTypes.string,
  link: PropTypes.string,
  zIndex: PropTypes.number,
};

export default Button;
