import { useMemo } from "react";
import PropTypes from "prop-types";

const Accordion2 = ({
  className = "",
  title,
  propTop,
  propWidth,
  propRight,
  propRight1,
}) => {
  const Accordion2Style = useMemo(() => {
    return {
      top: propTop,
    };
  }, [propTop]);

  const iconStyle = useMemo(() => {
    return {
      width: propWidth,
      right: propRight,
    };
  }, [propWidth, propRight]);

  const chevronTopIconStyle = useMemo(() => {
    return {
      right: propRight1,
    };
  }, [propRight1]);

  return (
    <div
      className={`absolute top-[4.5rem] left-[0rem] w-[38.75rem] h-[4.5rem] text-left text-[1rem] text-gray-500 font-captions-captions-bold ${className}`}
      style={Accordion2Style}
    >
      <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%]">
        <img
          className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] max-w-full overflow-hidden max-h-full"
          alt=""
          src="/learning/container1.svg"
        />
        <div className="absolute h-[1.39%] w-[93.65%] top-[99.31%] right-[3.18%] bottom-[-0.69%] left-[3.18%] bg-light-separators-colors-primary border-light-separators-colors-primary border-t-[1px] border-solid box-border" />
        <div
          className="absolute h-[33.33%] w-[3.87%] top-[33.33%] right-[2.65%] bottom-[33.33%] left-[93.48%]"
          style={iconStyle}
        >
          <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded opacity-[0]" />
          <img
            className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] max-w-full overflow-hidden max-h-full"
            alt=""
            src="/learning/16--chevrontop.svg"
            style={chevronTopIconStyle}
          />
        </div>
        <b className="absolute top-[33.33%] left-[3.26%] tracking-[0.01em] leading-[120%]">
          {title}
        </b>
      </div>
    </div>
  );
};

Accordion2.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,

  /** Style props */
  propTop: PropTypes.any,
  propWidth: PropTypes.any,
  propRight: PropTypes.any,
  propRight1: PropTypes.any,
};

export default Accordion2;
