import axios from 'axios';

const fetchGroupMembers = async (groupId) => {
  try {
    const accessToken = localStorage.getItem("accessToken");
    if (!accessToken) {
      throw new Error('Access token is missing');
    }

    const response = await axios.get('https://api.staging.sensebod.com/api/v1/groups/membership/', {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      params: {
        group: groupId
      }
    });

    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
  } catch (error) {
    console.error('Error fetching group members:', error);
    throw error;
  }
};

export default fetchGroupMembers;