import { useMemo } from "react";
import PropTypes from "prop-types";

const Toggle = ({
  className = "",
  toggleWidth,
  togglePosition,
  toggleHeight,
  toggleTop,
  toggleRight,
  toggleBottom,
  toggleLeft,
  rectangleDivBackgroundColor,
}) => {
  const toggleStyle = useMemo(() => {
    return {
      width: toggleWidth,
      position: togglePosition,
      height: toggleHeight,
      top: toggleTop,
      right: toggleRight,
      bottom: toggleBottom,
      left: toggleLeft,
    };
  }, [
    toggleWidth,
    togglePosition,
    toggleHeight,
    toggleTop,
    toggleRight,
    toggleBottom,
    toggleLeft,
  ]);

  const rectangleDiv2Style = useMemo(() => {
    return {
      backgroundColor: rectangleDivBackgroundColor,
    };
  }, [rectangleDivBackgroundColor]);

  return (
    <div
      className={`w-[44px] relative h-[24px] ${className}`}
      style={toggleStyle}
    >
      <div
        className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded-[24px] bg-[#6a5ae0]"
        style={rectangleDiv2Style}
      />
      <div className="absolute h-[83.33%] w-[45.45%] top-[8.33%] right-[4.55%] bottom-[8.33%] left-[50%] rounded-[50%] bg-default-white" />
    </div>
  );
};

Toggle.propTypes = {
  className: PropTypes.string,

  /** Style props */
  toggleWidth: PropTypes.any,
  togglePosition: PropTypes.any,
  toggleHeight: PropTypes.any,
  toggleTop: PropTypes.any,
  toggleRight: PropTypes.any,
  toggleBottom: PropTypes.any,
  toggleLeft: PropTypes.any,
  rectangleDivBackgroundColor: PropTypes.any,
};

export default Toggle;
