import { useState, useEffect } from 'react';

const useQuizData = (token) => {
  const [quizCount, setQuizCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchQuizData = async () => {
      if (!token) {
        setError('No access token available');
        setLoading(false);
        return;
      }

      try {
        const response = await fetch('https://api.staging.sensebod.com/api/v1/courses/quiz_attempted/', {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        });

        console.log(response.data);

        if (!response.ok) {
          throw new Error(`Failed to fetch quiz data: ${response.status}`);
        }

        const data = await response.json();
        setQuizCount(data.length);
        setError(null);
      } catch (err) {
        console.error('Error fetching quiz data:', err);
        setError(err.message);
        setQuizCount(0);
      } finally {
        setLoading(false);
      }
    };

    fetchQuizData();
  }, [token]);

  return { quizCount, loading, error };
};

export default useQuizData;