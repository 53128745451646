import Accordion2 from "./Accordion2";
import PropTypes from "prop-types";

const ContentCourse = ({ className = "" }) => {
  return (
    <div
      className={`absolute top-[0rem] left-[0rem] w-[38.75rem] h-[54.938rem] text-left text-[1rem] text-gray-500 font-captions-captions-bold ${className}`}
    >
      <div className="absolute top-[0rem] left-[0rem] rounded-lg bg-default-white w-[38.75rem] h-[54.938rem]" />
      <div className="absolute top-[4.938rem] left-[0rem] w-[38.75rem] h-[50rem]">
        <div className="absolute top-[0rem] left-[0rem] w-[38.75rem] h-[4.5rem]">
          <img
            className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] max-w-full overflow-hidden max-h-full"
            alt=""
            src="/learning/container.svg"
          />
          <div className="absolute h-[33.33%] w-[3.26%] top-[33.33%] right-[3.26%] bottom-[33.33%] left-[93.48%]">
            <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded opacity-[0]" />
            <img
              className="absolute h-full w-[118.81%] top-[0%] right-[-18.81%] bottom-[0%] left-[0%] max-w-full overflow-hidden max-h-full"
              alt=""
              src="/learning/ui--chevron-down.svg"
            />
          </div>
          <div className="absolute h-[1.39%] w-[93.65%] top-[99.31%] right-[3.18%] bottom-[-0.69%] left-[3.18%] bg-light-separators-colors-primary border-light-separators-colors-primary border-t-[1px] border-solid box-border" />
          <b className="absolute top-[33.33%] left-[3.26%] tracking-[0.01em] leading-[120%]">
            Introduction
          </b>
        </div>
        <Accordion2 title="Lorem Ipsum Theory" />
        <Accordion2
          title="Lorem Ipsum for Newbiew"
          propTop="23rem"
          propWidth="3.87%"
          propRight="2.65%"
          propRight1="0%"
        />
        <Accordion2
          title="Lorem Ipsum Getting Started"
          propTop="27.5rem"
          propWidth="3.26%"
          propRight="3.26%"
          propRight1="-18.81%"
        />
        <Accordion2
          title="Lorem Ipsum and others "
          propTop="32rem"
          propWidth="3.26%"
          propRight="3.26%"
          propRight1="-18.81%"
        />
        <Accordion2
          title="Placeholders for History Geeks"
          propTop="36.5rem"
          propWidth="3.26%"
          propRight="3.26%"
          propRight1="-18.81%"
        />
        <Accordion2
          title="Getting Started with Using Lorem Ipsum "
          propTop="41rem"
          propWidth="3.87%"
          propRight="2.65%"
          propRight1="0%"
        />
        <div className="absolute top-[45.5rem] left-[0rem] w-[38.75rem] h-[4.5rem]">
          <img
            className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] max-w-full overflow-hidden max-h-full"
            alt=""
            src="/learning/container2.svg"
          />
          <div className="absolute h-[33.33%] w-[3.26%] top-[33.33%] right-[3.26%] bottom-[33.33%] left-[93.48%]">
            <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded opacity-[0]" />
            <img
              className="absolute h-full w-[118.81%] top-[0%] right-[-18.81%] bottom-[0%] left-[0%] max-w-full overflow-hidden max-h-full"
              alt=""
              src="/learning/ui--chevron-down.svg"
            />
          </div>
          <b className="absolute top-[33.33%] left-[3.26%] tracking-[0.01em] leading-[120%]">
            Resources
          </b>
        </div>
        <div className="absolute top-[10rem] left-[1.369rem] w-[36.438rem] h-[12rem] text-[0.875rem] text-light-text-color-body-2 font-poppins">
          <div className="absolute top-[0rem] left-[0rem] w-[36.438rem] h-[2rem]">
            <img
              className="absolute top-[0rem] left-[0rem] w-[2rem] h-[2rem] object-cover"
              alt=""
              src="/learning/icon-search1@2x.png"
            />
            <div className="absolute top-[0.438rem] left-[2.106rem] tracking-[0.01em] leading-[130%]">{`Lorem Ipsum comes from sections 1.10.32 `}</div>
            <div className="absolute top-[0.438rem] left-[34.063rem] tracking-[0.01em] leading-[130%]">
              06:02
            </div>
            <div className="absolute top-[0.438rem] left-[27.95rem] tracking-[0.01em] leading-[130%]">
              Preview
            </div>
          </div>
          <div className="absolute top-[2.5rem] left-[0rem] w-[36.438rem] h-[2rem]">
            <img
              className="absolute top-[0rem] left-[0rem] w-[2rem] h-[2rem] object-cover"
              alt=""
              src="/learning/icon-search1@2x.png"
            />
            <div className="absolute top-[0.438rem] left-[2.106rem] tracking-[0.01em] leading-[130%]">
              and 1.10.33 of "de Finibus Bonorum et Malorum"
            </div>
            <div className="absolute top-[0.438rem] left-[34.063rem] tracking-[0.01em] leading-[130%]">
              04:22
            </div>
          </div>
          <div className="absolute top-[5rem] left-[0rem] w-[36.438rem] h-[2rem]">
            <img
              className="absolute top-[0rem] left-[0rem] w-[2rem] h-[2rem] object-cover"
              alt=""
              src="/learning/icon-search1@2x.png"
            />
            <div className="absolute top-[0.438rem] left-[2.106rem] tracking-[0.01em] leading-[130%]">
              and 1.10.33 of "de Finibus Bonorum et Malorum"
            </div>
            <div className="absolute top-[0.438rem] left-[34.063rem] tracking-[0.01em] leading-[130%]">
              06:02
            </div>
            <div className="absolute top-[0.438rem] left-[27.95rem] tracking-[0.01em] leading-[130%]">
              Preview
            </div>
          </div>
          <div className="absolute top-[7.5rem] left-[0rem] w-[36.438rem] h-[2rem]">
            <img
              className="absolute top-[0rem] left-[0rem] w-[2rem] h-[2rem] object-cover"
              alt=""
              src="/learning/icon-search1@2x.png"
            />
            <div className="absolute top-[0.438rem] left-[2.106rem] tracking-[0.01em] leading-[130%]">
              and 1.10.33 of "de Finibus Bonorum et Malorum"
            </div>
            <div className="absolute top-[0.438rem] left-[34.063rem] tracking-[0.01em] leading-[130%]">
              06:02
            </div>
            <div className="absolute top-[0.438rem] left-[27.95rem] tracking-[0.01em] leading-[130%]">
              Preview
            </div>
          </div>
          <div className="absolute top-[10rem] left-[0rem] w-[36.438rem] h-[2rem]">
            <img
              className="absolute top-[0rem] left-[0rem] w-[2rem] h-[2rem] object-cover"
              alt=""
              src="/learning/icon-search1@2x.png"
            />
            <div className="absolute top-[0.438rem] left-[2.106rem] tracking-[0.01em] leading-[130%]">
              and 1.10.33 of "de Finibus Bonorum et Malorum"
            </div>
            <div className="absolute top-[0.438rem] left-[34.063rem] tracking-[0.01em] leading-[130%]">
              06:02
            </div>
            <div className="absolute top-[0.438rem] left-[27.95rem] tracking-[0.01em] leading-[130%]">
              Preview
            </div>
          </div>
        </div>
      </div>
      <b className="absolute top-[1.625rem] left-[0.95rem] text-[1.938rem] tracking-[0.01em] leading-[120%] font-poppins">
        Lessons
      </b>
    </div>
  );
};

ContentCourse.propTypes = {
  className: PropTypes.string,
};

export default ContentCourse;
