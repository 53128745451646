import { useMemo } from "react";
import PropTypes from "prop-types";

const Comments = ({
  className = "",
  vidalLisk,
  contraryToPopularBeliefLorem,
  propTop,
}) => {
  const commentsStyle = useMemo(() => {
    return {
      top: propTop,
    };
  }, [propTop]);

  return (
    <div
      className={`absolute top-[16.438rem] left-[0rem] w-[36.656rem] h-[7.375rem] text-left text-[1rem] text-gray-500 font-poppins ${className}`}
      style={commentsStyle}
    >
      <div className="absolute top-[0rem] left-[0rem] w-[12.525rem] h-[3.5rem]">
        <img
          className="absolute top-[0rem] left-[0rem] w-[3.5rem] h-[3.5rem] object-cover"
          alt=""
          src="/learning/avatar1@2x.png"
        />
        <div className="absolute top-[0.375rem] left-[3.838rem] tracking-[0.01em] leading-[120%] font-semibold">
          {vidalLisk}
        </div>
        <img
          className="absolute top-[2.063rem] left-[3.838rem] w-[4.263rem] h-[1rem]"
          alt=""
          src="/learning/rate.svg"
        />
        <div className="absolute top-[2.063rem] left-[8.525rem] text-[0.75rem] tracking-[0.01em] leading-[120%] font-captions-captions-bold text-light-text-color-body-2">
          4 hour ago.
        </div>
      </div>
      <div className="absolute h-[38.98%] w-full top-[61.02%] left-[0%] text-[0.875rem] tracking-[0.01em] leading-[130%] inline-block">
        {contraryToPopularBeliefLorem}
      </div>
    </div>
  );
};

Comments.propTypes = {
  className: PropTypes.string,
  vidalLisk: PropTypes.string,
  contraryToPopularBeliefLorem: PropTypes.string,

  /** Style props */
  propTop: PropTypes.any,
};

export default Comments;
