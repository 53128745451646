import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

const Loader = ({ duration = 4000, text="Loading..." }) => {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(false);
    }, duration);

    return () => clearTimeout(timer);
  }, [duration]);

  if (!isVisible) return null;

  return (
    <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-[#1A3651] bg-opacity-80 z-50 font-poppins">
      <div className="text-center">
        <svg className="w-20 h-20 mx-auto mb-4" viewBox="0 0 100 100">
          <circle
            cx="50"
            cy="50"
            r="45"
            fill="none"
            stroke="#F09A19"
            strokeWidth="8"
            strokeLinecap="round"
          >
            <animateTransform
              attributeName="transform"
              type="rotate"
              dur="2s"
              repeatCount="indefinite"
              from="0 50 50"
              to="360 50 50"
            />
            <animate
              attributeName="stroke-dasharray"
              dur="1.5s"
              repeatCount="indefinite"
              values="0 280;230 50;0 280"
            />
            <animate
              attributeName="stroke-dashoffset"
              dur="1.5s"
              repeatCount="indefinite"
              values="0;-140;-280"
            />
          </circle>
        </svg>
        <p className="text-white text-xl font-semibold">{text}</p>
      </div>
    </div>
  );
};

Loader.propTypes = {
  duration: PropTypes.number,
  text: PropTypes.string
};

export default Loader;