import { useMemo } from "react";
import PropTypes from "prop-types";

const Button = ({
  className = "",
  onButtonContainerClick,
  buttonWidth,
  buttonPosition,
  buttonBackgroundColor,
  buttonTextColor = "white", // Default to white if no color is provided
  buttonPadding,
  buttonBottom,
  buttonLeft,
  buttonTop,
  button,
  buttonFontSize,
  buttonFontWeight,
  buttonFontFamily,
  type = "button",  // Add a type prop with default value "button"
}) => {
  const buttonStyle = useMemo(() => {
    return {
      width: buttonWidth,
      position: buttonPosition,
      backgroundColor: buttonBackgroundColor,
      padding: buttonPadding,
      bottom: buttonBottom,
      left: buttonLeft,
      top: buttonTop,
    };
  }, [
    buttonWidth,
    buttonPosition,
    buttonBackgroundColor,
    buttonPadding,
    buttonBottom,
    buttonLeft,
    buttonTop,
  ]);

  const button1Style = useMemo(() => {
    return {
      fontSize: buttonFontSize,
      fontWeight: buttonFontWeight,
      fontFamily: buttonFontFamily,
      color: buttonTextColor, // Set the text color using the prop
    };
  }, [buttonFontSize, buttonFontWeight, buttonFontFamily, buttonTextColor]);

  return (
    <button
      type={type}  // Use the type prop to define button behavior
      className={`relative rounded-xl flex flex-row items-center justify-center box-border text-center cursor-pointer ${className}`} // Removed fixed color class
      onClick={onButtonContainerClick}
      style={buttonStyle}
    >
      <div className="relative leading-[150%] font-medium" style={button1Style}>
        {button}
      </div>
    </button>
  );
};

Button.propTypes = {
  className: PropTypes.string,
  button: PropTypes.string,

  /** Style props */
  buttonWidth: PropTypes.any,
  buttonPosition: PropTypes.any,
  buttonBackgroundColor: PropTypes.any,
  buttonTextColor: PropTypes.string, // New prop for text color
  buttonPadding: PropTypes.any,
  buttonBottom: PropTypes.any,
  buttonLeft: PropTypes.any,
  buttonTop: PropTypes.any,
  buttonFontSize: PropTypes.any,
  buttonFontWeight: PropTypes.any,
  buttonFontFamily: PropTypes.any,

  /** Action props */
  onButtonContainerClick: PropTypes.func,

  /** Button type */
  type: PropTypes.oneOf(["button", "submit", "reset"]), // Define the type prop
};

export default Button;