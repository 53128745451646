import React, { useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../components/Button";
import Progress7 from "../../components/Progress7";

const PreferencesScreen1 = () => {
  const navigate = useNavigate();
  const [selectedMentor, setSelectedMentor] = useState(null);

  const handleMentorSelect = (mentor) => {
    setSelectedMentor(mentor);
  };

  const onButtonContainerClick = useCallback(() => {
    // Retrieve the existing preferencesData from localStorage
    const existingPreferences = JSON.parse(localStorage.getItem("preferencesData")) || {};

    // Add the selected mentor type to the preferencesData
    const updatedPreferences = {
      ...existingPreferences,
      mentor_type: selectedMentor,
    };

    // Store the updated preferencesData back in localStorage
    localStorage.setItem("preferencesData", JSON.stringify(updatedPreferences));

    // Navigate to the next screen
    navigate("/12-preferencesscreen");
  }, [navigate, selectedMentor]);

  const handleGoBack = () => {
    navigate(-1); // This will navigate back to the previous screen
  };

  // Skip
  const handleSkip = () => {
    navigate("/12-preferencesscreen");
  };

  return (
    <div className="min-h-screen w-full relative bg-default-white overflow-x-hidden text-left text-sm text-black font-poppins">
      {/* Background images with responsive positioning */}
      <div className="fixed inset-0 overflow-hidden pointer-events-none">
        <img
          className="absolute top-0 left-1/2 -translate-x-1/2 w-full max-w-[1933px] h-auto object-cover opacity-50"
          alt="Background pattern"
          src="/preference.svg"
        />
        <img
          className="absolute -bottom-16 -left-16 w-[300px] h-[300px] mq750:w-[400px] mq750:h-[400px] mq1280:w-[526px] mq1280:h-[526px] object-contain opacity-30"
          alt="Decorative system"
          src="/easystem-4-1@2x.png"
        />
        <img
          className="absolute -top-16 -right-16 w-[300px] h-[300px] mq750:w-[400px] mq750:h-[400px] mq1280:w-[562px] mq1280:h-[562px] object-contain opacity-30"
          alt="Decorative system"
          src="/easystem-4-22@2x.png"
        />
      </div>

      {/* Skip button - positioned at top right */}
      <div className="absolute top-6 right-6 z-20">
        <div 
          className="text-[#c3beb5] font-medium cursor-pointer hover:text-gray-600"
          onClick={handleSkip}
        >
          Skip
        </div>
      </div>

      {/* Main content container */}
      <div className="relative z-10 max-w-[1200px] mx-auto px-4 py-8 mq750:py-12">
        {/* Progress Bar and Back Button */}
        <div className="w-full max-w-[921px] mx-auto mb-8 flex items-center justify-between">
          <img
            onClick={handleGoBack}
            className="w-8 h-8 cursor-pointer"
            alt="Go Back"
            src="/icon.svg"
          />
          <div className="flex-1 mx-4">
            <Progress7
              progressWidth="100%"
              progressPosition="relative"
              progressHeight="21px"
              progressTop="0"
              progressLeft="0"
              rectangleDivBackgroundColor="rgba(5, 93, 178, 0.1)"
              rectangleDivWidth="70%"
              rectangleDivRight="30%"
              rectangleDivBackgroundColor1="#f09a19"
            />
          </div>
          <div className="text-buttonselect leading-[150%]">7 of 10</div>
        </div>

        {/* Logo */}
        <div className="flex justify-center mb-8">
          <img
            className="w-24 h-24 mq750:w-32 mq750:h-32 object-contain"
            alt="Logo"
            src="/download-1-1@2x.png"
          />
        </div>

        {/* Info Box */}
        <div className="relative mx-auto max-w-[350px] mq750:max-w-[450px] mb-12">
          <img
            className="w-full h-auto"
            alt="Info background"
            src="/vector-1.svg"
          />
          <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-sm text-black font-medium p-4 w-[80%] text-center">
            Would you prefer guidance from an AI mentor or a human mentor?
          </div>
        </div>

        {/* Mentor Selection Options */}
        <div className="max-w-[450px] mx-auto space-y-4">
          {/* Human Mentor Option */}
          <div
            onClick={() => handleMentorSelect("Human Mentor")}
            className={`
              cursor-pointer p-4 rounded-xl border-[2px] border-solid border-[#efeefc]
              transition-all duration-200 hover:shadow-md
              ${selectedMentor === "Human Mentor" ? "bg-blue-50 border-blue-200" : "bg-white"}
            `}
          >
            <div className="flex items-start gap-4">
              <img
                className="w-16 h-16 object-cover"
                alt="Human mentor"
                src="/avatar@2x.png"
              />
              <div>
                <h3 className="text-base font-semibold text-[#0c092a] mb-2">Human Mentor</h3>
                <p className="text-xs leading-[140%] text-[#858494]">
                  Connect with an experienced human mentor for personalised guidance and support.
                </p>
              </div>
            </div>
          </div>

          {/* AI Mentor Option */}
          <div
            onClick={() => handleMentorSelect("AI Mentor")}
            className={`
              cursor-pointer p-4 rounded-xl border-[2px] border-solid border-[#efeefc] 
              transition-all duration-200 hover:shadow-md
              ${selectedMentor === "AI Mentor" ? "bg-blue-50 border-blue-200" : "bg-white"}
            `}
          >
            <div className="flex items-start gap-4">
              <img
                className="w-16 h-16"
                alt="AI mentor"
                src="/avatar1.svg"
              />
              <div>
                <h3 className="text-base font-semibold text-[#0c092a] mb-2">AI Mentor</h3>
                <p className="text-xs leading-[140%] text-[#858494]">
                  Get instant, 24/7 assistance from your AI mentor for all your learning needs.
                </p>
              </div>
            </div>
          </div>

          {/* Both Mentors Option */}
          <div
            onClick={() => handleMentorSelect("Both Mentor")}
            className={`
              cursor-pointer p-4 rounded-xl border-[2px] border-solid border-[#efeefc]
              transition-all duration-200 hover:shadow-md
              ${selectedMentor === "Both Mentor" ? "bg-blue-50 border-blue-200" : "bg-white"}
            `}
          >
            <div className="flex items-start gap-4">
              <div className="flex -space-x-3">
                <img
                  className="w-16 h-16"
                  alt="AI mentor"
                  src="/avatar1.svg"
                />
                <img
                  className="w-16 h-16 object-cover"
                  alt="Human mentor"
                  src="/avatar@2x.png"
                />
              </div>
              <div>
                <h3 className="text-base font-semibold text-[#0c092a] mb-2">Both Mentors</h3>
                <p className="text-xs leading-[140%] text-[#858494]">
                  Get the best of both worlds with AI and human mentorship combined.
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* Next Button */}
        <div className="sticky bottom-4 mt-8 max-w-[370px] mx-auto">
          <Button
            buttonWidth="100%"
            buttonPosition="relative"
            buttonBackgroundColor="#055db2"
            buttonPadding="16px"
            button="Next"
            buttonFontSize="16px"
            buttonFontWeight="bold"
            buttonFontFamily="Poppins"
            onButtonContainerClick={onButtonContainerClick}
          />
        </div>
      </div>
    </div>
  );
};

export default PreferencesScreen1;
