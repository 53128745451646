import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

const JoinGroup = () => {
  const { groupId } = useParams(); // We don't need userId from params anymore
  const [group, setGroup] = useState(null);
  const [message, setMessage] = useState('');
  const [isJoined, setIsJoined] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  const fetchGroupDetails = async () => {
    try {
      const response = await fetch(`https://api.staging.sensebod.com/api/v1/groups/group/${groupId}/`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      });

      if (!response.ok) {
        throw new Error(`Failed to fetch group details: ${response.statusText}`);
      }

      const data = await response.json();
      console.log("Group", data);
      setGroup(data);
      setIsLoading(false);

      // Check if the user is already a member of the group
      const userId = localStorage.getItem("userId");
      const isMember = data.members.some(member => member.member.user_id === userId);
      if (isMember) {
        setIsJoined(true);
        setMessage("You are already a member of this group.");
      }
    } catch (error) {
      setMessage(`Error fetching group details: ${error.message}`);
      console.error("Error fetching group details:", error);
      setIsLoading(false);
    }
  };

  const joinGroup = async () => {
    try {
      const userId = localStorage.getItem("userId");
      if (!userId) {
        throw new Error("User ID not found. Please log in again.");
      }

      const response = await fetch("https://api.staging.sensebod.com/api/v1/groups/membership_request/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
        body: JSON.stringify({
          user: userId,
          group: groupId,
        }),
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.detail || `Failed to join group: ${response.statusText}`);
      }

      setMessage('Group join request successful!');
      setIsJoined(true);
      console.log("Group join request successful:", data);
    } catch (error) {
      setMessage(`Error joining group: ${error.message}`);
      console.error("Error joining group:", error);
    }
  };

  useEffect(() => {
    if (groupId) {
      fetchGroupDetails();
    }
  }, [groupId]);

  return (
    <div className="w-full min-h-screen flex items-center justify-center bg-gray-100">
      <div className="bg-white p-6 rounded-lg shadow-md max-w-md">
        <h1 className="text-2xl font-bold mb-4">Join Group Request</h1>

        {isLoading ? (
          <p>Loading group details...</p>
        ) : group ? (
          <>
            <h2 className="text-xl font-semibold mb-2">{group.name}</h2>
            <p className="text-gray-700 mb-4"><b>Group Description: </b>{group.description}</p>
            <p className="text-gray-500 mb-4"><b>Created by </b>{group.created_by.username}</p>

            {isJoined ? (
              <>
                <p className="text-green-600 mb-4">{message}</p>
                <button
                  className="bg-blue-500 text-white px-4 py-2 rounded-md cursor-pointer"
                  onClick={() => navigate("/")}
                >
                  Go to Home
                </button>
              </>
            ) : (
              <>
                <p className="text-gray-700 mb-4">Do you want to join this group?</p>
                <div className="flex space-x-4">
                  <button
                    className="bg-green-500 text-white px-4 py-2 rounded-md cursor-pointer"
                    onClick={joinGroup}
                  >
                    Yes, Join
                  </button>
                  <button
                    className="bg-gray-500 text-white px-4 py-2 rounded-md cursor-pointer"
                    onClick={() => navigate("/")}
                  >
                    No, Go Back
                  </button>
                </div>
                {message && <p className="text-red-600 mt-4">{message}</p>}
              </>
            )}
          </>
        ) : (
          <p>Failed to load group details. {message}</p>
        )}
      </div>
    </div>
  );
};

export default JoinGroup;