import React, { useState, useEffect, useCallback, useRef } from 'react';

const AIChatComponent = () => {
  const [ws, setWs] = useState(null);
  const [connected, setConnected] = useState(false);
  const [messages, setMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState('');
  const messagesEndRef = useRef(null);
  const lastUserMessageRef = useRef('');

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(scrollToBottom, [messages]);

  const connectWebSocket = useCallback(() => {
    const socket = new WebSocket('wss://api.staging.sensebod.com/ws/ai-chat/1/');

    socket.onopen = () => {
      console.log('WebSocket connected');
      setConnected(true);
    };

    socket.onmessage = (event) => {
      try {
        const data = JSON.parse(event.data);
        console.log('Received message:', data);
        if (data.message !== lastUserMessageRef.current) {
          setMessages(prevMessages => [...prevMessages, { type: 'ai', content: data.message }]);
        }
      } catch (error) {
        console.error('Error parsing received data:', error);
      }
    };

    socket.onclose = () => {
      console.log('WebSocket disconnected');
      setConnected(false);
    };

    socket.onerror = (error) => {
      console.error('WebSocket error:', error);
    };

    setWs(socket);

    return () => {
      socket.close();
    };
  }, []);

  useEffect(() => {
    const cleanup = connectWebSocket();
    return cleanup;
  }, [connectWebSocket]);

  const sendMessage = () => {
    if (ws && ws.readyState === WebSocket.OPEN && inputMessage.trim()) {
      const messageObj = { message: inputMessage.trim() };
      ws.send(JSON.stringify(messageObj));
      setMessages(prevMessages => [...prevMessages, { type: 'user', content: inputMessage.trim() }]);
      lastUserMessageRef.current = inputMessage.trim();
      setInputMessage('');
    }
  };

  return (
    <div className="flex flex-col h-screen max-w-2xl mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">AI Chat</h1>
      <div className="flex-grow overflow-auto mb-4 border rounded p-2">
        {messages.map((msg, index) => (
          <div key={index} className={`mb-2 ${msg.type === 'user' ? 'text-right' : 'text-left'}`}>
            <span className={`inline-block p-2 rounded ${msg.type === 'user' ? 'bg-blue-200' : 'bg-blue-500 text-white'}`}>
              {msg.content}
            </span>
          </div>
        ))}
        <div ref={messagesEndRef} />
      </div>
      <div className="flex">
        <input
          type="text"
          value={inputMessage}
          onChange={(e) => setInputMessage(e.target.value)}
          onKeyPress={(e) => e.key === 'Enter' && sendMessage()}
          className="flex-grow border rounded-l p-2"
          placeholder="Type your message..."
        />
        <button
          onClick={sendMessage}
          disabled={!connected}
          className="bg-blue-500 text-white p-2 rounded-r disabled:bg-gray-300"
        >
          Send
        </button>
      </div>
      <p className="mt-2">{connected ? 'Connected' : 'Disconnected'}</p>
    </div>
  );
};

export default AIChatComponent;