import { useNavigate } from "react-router-dom";

const StartQuiz = () => {
  const navigate = useNavigate();
  const username = localStorage.getItem("username");
  return (
    <div className="w-full relative bg-[#fbf1f1] h-[100vh] overflow-hidden text-left text-base text-default-white font-poppins">
      <img
        className="absolute top-[-1089.1px] left-[-1174.5px] w-[2827.8px] h-[2818.5px] object-contain hidden"
        alt=""
        src="/learning/o4@2x.png"
      />
      <img
        className="absolute top-[245px] left-[calc(50%_-_985px)] w-[556.7px] h-[556.7px] object-contain hidden"
        alt=""
        src="/learning/easystem-4-1@2x.png"
      />
      <img
        className="absolute top-[-78.5px] left-[-470.7px] w-[2863.3px] h-[1567.3px] object-contain hidden"
        alt=""
        src="/learning/o5@2x.png"
      />
      <img
        className="absolute top-[-120px] left-[calc(50%_+_222px)] w-[562px] h-[562px] object-contain hidden"
        alt=""
        src="/learning/easystem-4-2@2x.png"
      />
      <div className="absolute top-[calc(50%_-_316px)] left-[calc(50%_-_416px)] rounded-xl bg-background-main border-[#efe7e7] border-[1px] border-solid box-border w-[833px] h-[583px]" />
      <b className="absolute top-[220px] left-[calc(50%_-_264px)] text-[40px] text-[#c3cece]">
        Quiz: Are You Ready Now?
      </b>
      <b className="absolute top-[280px] left-[calc(50%_-_105px)]">
        Quiz 1 | 3 Questions | 5 min
      </b>
      <img
        className="absolute top-[calc(50%_-_142px)] left-[calc(50%_-_246px)] w-[492px] h-[347px] object-cover"
        alt=""
        src="/learning/brazuca-planning@2x.png"
      />
      <div className="absolute bottom-[94px] left-[calc(50%_-_274px)] rounded-xl bg-buttonselect w-[548px] flex flex-row items-center justify-center py-[16px] px-[133px] box-border text-center"
      onClick={() => navigate("/quiz-questions")}
      >
        <b className="relative leading-[150%] cursor-pointer">Start Quiz</b>
      </div>
      <div className="absolute w-[calc(100%_-_2px)] top-[0px] right-[2px] left-[0px] bg-default-white border-[#efefef] border-b-[1px] border-solid box-border flex flex-row items-center justify-start py-[20px] pl-[41px] pr-[40px] gap-[856px] text-background-main">
        <div className="flex flex-row items-center justify-start gap-[10px]">
        <img
            className="w-[40px] rounded-11xl h-[40px] overflow-hidden shrink-0 object-contain cursor-pointer"
            alt=""
            src="/learning/iconamoonarrowup2@2x.png"
            onClick={() => navigate("/quiz")}
          />
          <div className="flex flex-row items-start justify-start p-[20px]">
            <div className="relative leading-[100%]">Quiz</div>
          </div>
        </div>
        <div className="flex flex-row items-center justify-start gap-[60px] text-primary-gold">
          <div className="hidden flex-row items-start justify-start gap-[20px]">
            <div className="rounded-[9px] bg-background-main flex flex-row items-start justify-start">
              <div className="flex flex-row items-start justify-start p-[20px]">
                <div className="relative leading-[100%]">Prev</div>
              </div>
            </div>
            <div className="rounded-[9px] bg-background-main flex flex-row items-start justify-start">
              <div className="flex flex-row items-start justify-start p-[20px]">
                <div className="relative leading-[100%]">Next</div>
              </div>
            </div>
          </div>
          <div className="rounded-[9px] bg-[rgba(26,54,81,0.2)] hidden flex-row items-start justify-start">
            <div className="flex flex-row items-start justify-start p-[20px]">
              <div className="relative leading-[100%]">Mark as Complete</div>
            </div>
          </div>
          <div className="border-[#efefef] border-l-[1px] border-solid flex flex-row items-center justify-start py-[0px] px-[40px] text-[#3d3d3d]">
            <div className="w-[176px] relative h-[56px]">
              <img
                className="absolute h-full w-[31.82%] top-[0%] right-[68.18%] bottom-[0%] left-[0%] max-w-full overflow-hidden max-h-full object-cover"
                alt=""
                src="/learning/avatar1@2x.png"
              />
              <div className="absolute top-[20px] left-[64px] leading-[100%]">
              {username}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StartQuiz;
