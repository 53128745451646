import React from 'react';
import Slider from "react-slick";
import { useNavigate } from "react-router-dom";

const ImprovedSlider = ({ items, settings, baseImageURL, type, onItemClick }) => {
  const navigate = useNavigate();
  const mobileSettings = {
    ...settings,
    dots: true,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: '30px', 
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerPadding: '30px', 
        }
      }
    ],
    className: "center",
    appendDots: dots => (
      <div style={{ bottom: "-30px" }}>
        <ul style={{ margin: "0px", padding: "0px" }}> {dots} </ul>
      </div>
    ),
    customPaging: i => (
      <div className="custom-dot"></div>
    ),
    swipe: true,
    draggable: false,
  };

  const renderGroupContent = (item) => (
    <div className="relative rounded-[30px] overflow-hidden h-[198px] mx-4 transition-transform duration-300 ease-in-out hover:scale-105 select-none" onClick={() =>  navigate(`/groups`)}>
      <img
        src={item.banner ? `${baseImageURL}${item.banner}` : "/group/Group-02.png"}
        alt={item.name}
        className="w-full h-full object-cover pointer-events-none"
      />
      <div className="absolute bottom-0 left-0 right-0 bg-[#1A3651] text-white p-3 cursor-pointer h-[85px]">
        <p className="text-[16px] font-medium text-white">{item.name}</p>
        <div className="flex justify-between items-center mt-2 px-[40px]">
          <div className="flex items-center">
            <img src="/home/Icon.svg" alt="Members" className="w-[20px] h-[20px] mr-1" />
            <span className="text-[11px]">{item.member_count}</span>
          </div>
          {/* <div className="flex items-center">
            <img src="/home/Icon (1).svg" alt="Rating" className="w-[20px] h-[20px] mr-1" />
            <span className="text-[11px]">{item.group_points}</span>
          </div> */}
          <div className="flex items-center">
            <img src="/home/Icon (2).svg" alt="Privacy" className="w-[20px] h-[20px] mr-1" />
            <span className="text-[11px]">{item.join_privacy_settings}</span>
          </div>
        </div>
      </div>
    </div>
  );

  const renderCourseContent = (item) => (
    <div 
      className="bg-white rounded-xl shadow-md overflow-hidden mx-4"
      onClick={() => onItemClick && onItemClick(item)}
    >
      <img
        src={item.banner ? `${baseImageURL}${item.banner}` : "/learning/courses/courses.jpg"}
        alt={item.name}
        className="w-full h-40 object-cover"
      />
      <div className="p-4">
        <h3 className="text-base font-semibold mb-2 text-gray-800 truncate">{item.name}</h3>
        <p className="text-sm text-gray-600 line-clamp-2">{item.description || item.type}</p>
      </div>
    </div>
  );

  return (
    <div className="relative pb-10 top-[20px]">
      <Slider {...mobileSettings}>
        {items.map((item, index) => (
          <div key={index} className="px-2">
            {type === 'groups' ? renderGroupContent(item) : renderCourseContent(item)}
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default ImprovedSlider;