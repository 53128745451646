import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import MainNavigation from "../../components/MainNavigation";
import NavBar from "../../components/NavBar";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Loader from "../../components/Loader";

const MembershipRequests = () => {
  const { groupId } = useParams(); // Get groupId from URL
  const [group, setGroup] = useState(null);
  const [membershipRequests, setMembershipRequests] = useState([]);
  const [loading, setLoading] = useState(true);

  // Fetch Group Details
  const fetchGroupDetails = async () => {
    try {
      const response = await fetch(
        `https://api.staging.sensebod.com/api/v1/groups/group/${groupId}/`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      );
      if (response.ok) {
        const data = await response.json();
        setGroup(data);
      } else {
        console.error("Failed to fetch group details:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching group details:", error);
    }
  };

  // Fetch Membership Requests for the specific group
  const fetchMembershipRequests = async () => {
    try {
      const response = await fetch(
        "https://api.staging.sensebod.com/api/v1/groups/membership_request/",
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      );
      if (response.ok) {
        const data = await response.json();
        console.log("All requests:", data);

        // Filter requests to only include those for the specific group
        const filteredRequests = data.filter(request => request.group.uuid === groupId);
        console.log("Filtered requests:", filteredRequests);

        setMembershipRequests(filteredRequests);
        setLoading(false);
      } else {
        console.error("Failed to fetch membership requests:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching membership requests:", error);
    }
  };

  // Fetch group details and membership requests on component mount
  useEffect(() => {
    fetchGroupDetails();
    fetchMembershipRequests();
  }, [groupId]);

  // Approve Request
  const approveRequestStatus = async (requestId) => {
    try {
      const response = await fetch(
        `https://api.staging.sensebod.com/api/v1/groups/membership_request/${requestId}/approved/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      );
      if (response.ok) {
        const updatedRequest = await response.json();
        updateRequestInState(updatedRequest);
      } else {
        console.error("Failed to approve request:", response.statusText);
      }
    } catch (error) {
      console.error("Error approving request:", error);
    }
  };

  // Reject Request
  const rejectRequestStatus = async (requestId) => {
    try {
      const response = await fetch(
        `https://api.staging.sensebod.com/api/v1/groups/membership_request/${requestId}/rejected/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      );
      if (response.ok) {
        const updatedRequest = await response.json();
        updateRequestInState(updatedRequest);
      } else {
        console.error("Failed to reject request:", response.statusText);
      }
    } catch (error) {
      console.error("Error rejecting request:", error);
    }
  };

  // Update request in state
  const updateRequestInState = (updatedRequest) => {
    setMembershipRequests((prevRequests) =>
      prevRequests.map((request) =>
        request.uuid === updatedRequest.uuid ? updatedRequest : request
      )
    );
  };

  if (loading) {
    return <div><Loader /></div>;
  }

  return (
    <div className="w-full relative bg-[#fbf1f1] min-h-screen overflow-auto text-left text-xl text-default-white font-poppins pb-[200px] ">
      <a href="/aichat" className="fixed bottom-6 left-4 md:left-[285px] z-[10000]">
        <img
          className="w-[75px] h-[75px] object-contain cursor-pointer"
          alt="AI Chat"
          src="/home/AIChart.svg"
        />
      </a>
      <MainNavigation
        homeClass=""
        learningClass=""
        mentorClass=""
        groupsClass="left-[-29px] rounded-tl-[0px] rounded-tr-11xl rounded-br-11xl rounded-bl-[0px] bg-buttonselect border-buttonselect border-[2px] border-solid box-border text-default-white"
        profileClass="custom-profile-class"
      />
      <NavBar />

      <div className="mq850:top-[65px] relative">

        <div className="px-4 md:px-0 md:absolute  md:top-[116px] w-full md:w-[500px] md:left-[calc(50%_-_59px)] text-[24px] md:text-[32px] leading-[150%] font-semibold text-[#0d0c0c] text-center md:text-left mt-4 md:mt-0">
          Requests for {group?.name}
        </div>

        <div className="p-4 md:p-8 md:absolute md:top-[136px] md:left-[300px] w-[92%] md:w-auto">
          {membershipRequests.length > 0 ? (
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
              {membershipRequests.map((request) => (
                <div key={request.uuid} className="bg-white rounded-lg shadow-md p-6">
                  <p className="text-sm text-black mb-2">
                    <strong>Requested By:</strong> {request.user.username}
                  </p>
                  <p className="text-sm text-gray-700 mb-2">
                    <strong>Status:</strong> {request.status}
                  </p>
                  <p className="text-sm text-gray-700 mb-2">
                    <strong>Request Date:</strong> {new Date(request.request_date).toLocaleString()}
                  </p>
                  <div className="flex flex-wrap gap-2 mt-4">
                    {request.status === "pending" && (
                      <>
                        <button
                          className="bg-green-500 text-white px-3 py-2 rounded-md cursor-pointer text-sm"
                          onClick={() => approveRequestStatus(request.uuid)}
                        >
                          Approve
                        </button>
                        <button
                          className="bg-red-500 text-white px-3 py-2 rounded-md text-sm"
                          onClick={() => rejectRequestStatus(request.uuid)}
                        >
                          Reject
                        </button>
                      </>
                    )}
                    {request.status !== "pending" && (
                      <span className={`px-3 py-2 rounded-md text-sm ${request.status === "approved" ? "bg-green-100 text-green-700" : "bg-red-100 text-red-700"}`}>
                        {request.status}
                      </span>
                    )}
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="text-background-main text-center md:text-left">No membership requests available for this group.</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MembershipRequests;