import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import MainNavigation from "../../components/MainNavigation";
import NavBar from "../../components/NavBar";
import { Dialog } from '@headlessui/react';

const MyGroups = () => {
    const [groups, setGroups] = useState([]);
    const [shareLink, setShareLink] = useState("");
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [copied, setCopied] = useState(false); // To handle copy status
  

  // Fetch groups created by user
  const fetchGroupsCreatedByUser = async () => {
    try {
      const response = await fetch(
        "https://api.staging.sensebod.com/api/v1/groups/group/",
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      );
      if (response.ok) {
        const data = await response.json();
        const userId = localStorage.getItem("userId");

        // Filter groups that are created by the current user
        const userGroups = data.filter((group) => group.created_by === userId);
        setGroups(userGroups);
      } else {
        console.error("Failed to fetch groups:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching groups:", error);
    }
  };

  useEffect(() => {
    // Fetch groups on page load
    fetchGroupsCreatedByUser();
  }, []);

  // Generate shareable link for the group
  const generateShareLink = (groupId) => {
    const link = `http://localhost:1300/groups/join/${groupId}/${localStorage.getItem("userId")}`;
    setShareLink(link);
    setCopied(false); // Reset copied status
    openModal();
  };

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  // Copy link to clipboard
  const copyToClipboard = () => {
    navigator.clipboard.writeText(shareLink);
    setCopied(true);
  };

  

  return (
    <div className="w-full relative bg-[#fbf1f1] min-h-screen overflow-auto text-left text-xl text-default-white font-poppins pb-[200px]">
      <a href="/aichat" className="fixed bottom-6 left-[285px] z-[10000]">
        <img
          className="w-[75px] h-[75px] object-contain cursor-pointer"
          alt="AI Chat"
          src="/home/AIChart.svg"
        />
      </a>
      <NavBar />
      

      <div className="absolute top-[116px] left-[calc(50%_-_59px)] text-[32px] leading-[150%] font-semibold text-[#0d0c0c]">
        My Groups
      </div>

      <div className="p-8 absolute top-[136px] left-[300px]">
        {groups.length > 0 ? (
          <div className="grid grid-cols-3 mq750:grid-cols-1 lg:grid-cols-3 gap-6">
            {groups.map((group) => (
              <div key={group.uuid} className="bg-white rounded-lg shadow-md p-6">
                <h3 className="text-lg font-semibold text-gray-700 mb-2">{group.name}</h3>
                <p className="text-sm text-gray-700 mb-2">
                  <strong>Group Type:</strong> {group.group_type}
                </p>
                <p className="text-sm text-gray-700 mb-2">
                  <strong>Max Members:</strong> {group.max_members}
                </p>
                <button
                  className="bg-blue-500 text-white px-3 py-2 rounded-md mt-4 cursor-pointer"
                  onClick={() => generateShareLink(group.uuid)}
                >
                  Generate Share Link
                </button>
              </div>
            ))}
          </div>
        ) : (
          <div>No groups available.</div>
        )}
      </div>

      {/* Modal to show the shareable link */}
      <Dialog open={isModalOpen} onClose={closeModal} className="relative z-50">
        <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
        <div className="fixed inset-0 flex items-center justify-center p-4">
          <Dialog.Panel className="mx-auto bg-white rounded-lg shadow-lg p-6 w-[350px]">
            <Dialog.Title className="text-lg font-bold">Share Group Link</Dialog.Title>
            <Dialog.Description className="mt-2 text-sm text-gray-600">
              Share this link with others to invite them to join your group.
            </Dialog.Description>
            <div className="mt-4">
              <input
                type="text"
                value={shareLink}
                readOnly
                className="w-[300px] px-3 py-2 border border-gray-300 rounded-md"
              />
            </div>
            <div className="mt-4 flex justify-end space-x-3">
              <button
                className="bg-blue-500 text-white px-4 py-2 rounded-md cursor-pointer"
                onClick={copyToClipboard}
              >
                {copied ? "Copied!" : "Copy Link"}
              </button>
              <button
                className="bg-gray-300 text-gray-800 px-4 py-2 rounded-md"
                onClick={closeModal}
              >
                Close
              </button>
            </div>
          </Dialog.Panel>
        </div>
      </Dialog>

      <MainNavigation
        homeClass=""
        learningClass=""
        mentorClass=""
        groupsClass="left-[-29px] rounded-tl-[0px] rounded-tr-11xl rounded-br-11xl rounded-bl-[0px] bg-buttonselect border-buttonselect border-[2px] border-solid box-border text-default-white"
        profileClass="custom-profile-class"
      />
    </div>
  );
};

export default MyGroups;
