import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import DescriptionSection from './DescriptionSection';
import fetchAllGroups from './fetchAllGroups';

const GroupSetting = ({ className = "", selectedGroup }) => {
  const [expandedIndex, setExpandedIndex] = useState(0);
  const [groupMembers, setGroupMembers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [showAllMembers, setShowAllMembers] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isJoinLinkDialogOpen, setIsJoinLinkDialogOpen] = useState(false);
  const [joinLink, setJoinLink] = useState('');
  const toggleShowAllMembers = () => {
    setShowAllMembers(!showAllMembers);
  };

  const openDialog = () => setIsDialogOpen(true);
  const closeDialog = () => setIsDialogOpen(false);

  useEffect(() => {
    const fetchMembers = async () => {
      if (selectedGroup?.uuid) {
        try {
          setLoading(true);
          const allGroups = await fetchAllGroups();
          const group = Object.values(allGroups).find(g => g.groupId === selectedGroup.uuid);
          if (group && group.members) {
            setGroupMembers(group.members);
          } else {
            setError('Group not found or has no members');
          }
        } catch (err) {
          setError('Failed to fetch group members');
        } finally {
          setLoading(false);
        }
      }
    };
    fetchMembers();
  }, [selectedGroup]);

  const sections = [
    {
      title: 'Description',
      description: selectedGroup?.description || 'No description available for this group.',
      expandedIcon: '/group/16--chevrontop.svg',
      collapsedIcon: '/group/16--chevrontop1.svg',
    },
    {
      title: 'Files and Links',
      description: 'No files or links available for this group.',
      expandedIcon: '/group/16--chevrontop.svg',
      collapsedIcon: '/group/16--chevrontop1.svg',
    },
    {
      title: 'Polls',
      description: 'No polls available for this group.',
      expandedIcon: '/group/16--chevrontop.svg',
      collapsedIcon: '/group/16--chevrontop1.svg',
    },
    {
      title: 'Task Board',
      description: 'No tasks available for this group.',
      expandedIcon: '/group/16--chevrontop.svg',
      collapsedIcon: '/group/16--chevrontop1.svg',
    },
    {
      title: 'Members',
      description: (
        <div>
          <ul className="list-disc pl-5 mt-2">
            {(showAllMembers ? groupMembers : groupMembers.slice(0, 5)).map((member) => (
              <li key={member.uuid} className="mb-1 text-2xs">
                <span className="font-medium">{member.memberUsername}</span>
                {member.role === "admin" && (
                  <span
                    onClick={openDialog} // Open dialog on click
                    className="ml-2 px-2 py-1 bg-red-500 text-white rounded-md cursor-pointer"
                  >
                    Admin
                  </span>
                )}
              </li>
            ))}
          </ul>
          {groupMembers.length > 5 && (
            <button
              onClick={toggleShowAllMembers}
              className="text-blue-500 text-2xs mt-2 cursor-pointer"
            >
              {showAllMembers ? "Show Less" : "View All"}
            </button>
          )}
        </div>
      ),
    },
    {
      title: 'Events',
      description: 'No events available for this group.',
      expandedIcon: '/group/16--chevrontop.svg',
      collapsedIcon: '/group/16--chevrontop1.svg',
    },
    {
      title: 'Forums',
      description: 'No forums available for this group.',
      expandedIcon: '/group/16--chevrontop.svg',
      collapsedIcon: '/group/16--chevrontop1.svg',
    },
    {
      title: 'Privacy and Support',
      description: 'No privacy or support information available for this group.',
      expandedIcon: '/group/16--chevrontop.svg',
      collapsedIcon: '/group/16--chevrontop1.svg',
    },
  ];



  const handleToggle = (index) => {
    setExpandedIndex((prevIndex) => (prevIndex === index ? -1 : index));
  };

  const generateJoinLink = () => {
    const baseUrl = window.location.origin; // Gets the base URL of your application
    const link = `${baseUrl}/groups/join/${selectedGroup.uuid}`;
    setJoinLink(link);
    setIsJoinLinkDialogOpen(true);
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(joinLink).then(() => {
      alert('Link copied to clipboard!');
    }, (err) => {
      console.error('Could not copy text: ', err);
    });
  };

  return (
    <div className={`absolute top-[193px] left-[962px] rounded-[10px] bg-background-main overflow-hidden flex flex-col items-center justify-start pt-[33px] pb-[20px] pl-[15px] pr-[20px] gap-[7px] text-left text-xs text-[#d7cbcb] font-poppins ${className}`}>
      <div className="flex flex-col items-center justify-start gap-[16px] text-base text-default-white">
        <img
          className="w-[110px] relative rounded-[50%] h-[110px] object-cover"
          alt=""
          src={selectedGroup?.avatar || "/group/Group-02.png"}
        />
        <div className="flex flex-col items-center justify-start gap-[10px]">
          <div className="flex flex-col items-center justify-start">
            <div className="relative font-semibold">{selectedGroup?.name || 'Group Name'}</div>
            <div className="relative text-xs text-[#989ba1]">
              {selectedGroup?.creator || 'Group Creator'} - {groupMembers.length || 0} members
            </div>
          </div>
          <div className="flex flex-col items-start justify-start text-3xs text-[#e4e6e7]">
            <div className="w-[67.1px] relative h-[15px]">
              <img
                className="absolute top-[1.8px] left-[0px] w-[10.7px] h-[10.7px]"
                alt=""
                src="/group/star-1.svg"
              />
              <div className="absolute top-[0px] left-[16.1px]">0 (Rating)</div>
            </div>
          </div>
          {/* New Generate Join Link button */}
          <button
            onClick={generateJoinLink}
            className="mt-2 px-4 py-2 bg-blue-500 text-white rounded-md text-xs"
          >
            Generate Join Link
          </button>
        </div>
      </div>


      {sections.map((section, index) => (
        <DescriptionSection
          key={index}
          title={section.title}
          description={section.description}
          expandedIcon={section.expandedIcon}
          collapsedIcon={section.collapsedIcon}
          isExpanded={expandedIndex === index}
          toggleExpand={() => handleToggle(index)}
          content={section.content}
        />
      ))}

      {/* Dialog component for full member list */}
      {isDialogOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-5 rounded-md shadow-lg max-w-md w-full">
            <h2 className="text-xl font-semibold mb-4">Full Members List</h2>
            <ul className="list-disc pl-5">
              {groupMembers.map((member) => (
                <li key={member.uuid} className="mb-1">
                  <span className="font-medium">{member.memberUsername}</span>
                  {member.role === "admin" && (
                    <span className="ml-2 text-red-500">(Admin)</span>
                  )}
                </li>
              ))}
            </ul>
            <button
              onClick={closeDialog}
              className="mt-4 px-4 py-2 bg-blue-500 text-white rounded-md"
            >
              Close
            </button>
          </div>
        </div>
      )}

      {isJoinLinkDialogOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-5 rounded-md shadow-lg max-w-md w-full">
            <h2 className="text-xl font-semibold mb-4">Group Join Link</h2>
            <p className="mb-4 break-all">{joinLink}</p>
            <div className="flex justify-between">
              <button
                onClick={copyToClipboard}
                className="px-4 py-2 bg-green-500 text-white rounded-md"
              >
                Copy to Clipboard
              </button>
              <button
                onClick={() => setIsJoinLinkDialogOpen(false)}
                className="px-4 py-2 bg-blue-500 text-white rounded-md"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

GroupSetting.propTypes = {
  className: PropTypes.string,
  selectedGroup: PropTypes.shape({
    uuid: PropTypes.string,
    name: PropTypes.string,
    creator: PropTypes.string,
    description: PropTypes.string,
    avatar: PropTypes.string,
  }),
};

export default GroupSetting;
