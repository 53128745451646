import axios from 'axios';

const fetchAllGroups = async () => {
  try {
    const accessToken = localStorage.getItem("accessToken");
    if (!accessToken) {
      throw new Error('Access token is missing');
    }

    const response = await axios.get('https://api.staging.sensebod.com/api/v1/groups/membership/', {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      }
    });

    if (response.status === 200) {
      console.log("Fetched data", response.data);
      
      // Group the data by group
      const groupedData = response.data.reduce((acc, item) => {
        const groupId = item.group.uuid;
        if (!acc[groupId]) {
          acc[groupId] = {
            groupId: groupId,
            groupName: item.group.name,
            groupType: item.group.group_type,
            banner: item.group.banner,
            members: []
          };
        }
        acc[groupId].members.push({
          uuid: item.uuid,
          userId: item.member.user_id,
          memberUsername: item.member.username,
          picture: item.member.picture,
          role: item.role,
          dateJoined: item.date_joined,
          membershipType: item.membership_type
        });
        return acc;
      }, {});
      
      return Object.values(groupedData); // Convert the object to an array of groups
    } else {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
  } catch (error) {
    console.error('Error fetching groups:', error);
    throw error;
  }
};

export default fetchAllGroups;