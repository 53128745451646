import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../components/Button";
import Progress7 from "../../components/Progress7";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import axios from "axios";

const PreferencesScreen3 = () => {
  const navigate = useNavigate();
  const [selectedDate, setSelectedDate] = useState(null);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    const userId = localStorage.getItem("userId");
    const token = localStorage.getItem("accessToken");

    if (!userId || !token) {
      console.error("User ID or token not found in localStorage.");
      return;
    }

    try {
      const response = await axios.patch(
        `https://api.staging.sensebod.com/api/v1/users/${userId}/`,
        {
          first_name: firstName,
          last_name: lastName,
          email: email,
          date_of_birth: selectedDate ? dayjs(selectedDate).format('YYYY-MM-DD') : null,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        console.log("User data updated successfully:", response.data);
        navigate("/6-preferencesscreen");
      } else {
        console.error("Failed to update user data:", response.data);
      }
    } catch (error) {
      console.error("An error occurred while updating user data:", error);
    }
  };

  const handleGoBack = () => {
    navigate(-1);
  };

  const handleSkip = () => {
    navigate("/6-preferencesscreen");
  };

  return (
    <div className="min-h-screen w-full relative bg-default-white overflow-x-hidden text-left text-sm text-black font-poppins">
      {/* Background images with responsive positioning */}
      <div className="fixed inset-0 overflow-hidden pointer-events-none">
        <img
          className="absolute top-0 left-1/2 -translate-x-1/2 w-full max-w-[1933px] h-auto object-cover opacity-50"
          alt="Background pattern"
          src="/preference.svg"
        />
        <img
          className="absolute -bottom-16 -left-16 w-[300px] h-[300px] mq750:w-[400px] mq750:h-[400px] mq1280:w-[526px] mq1280:h-[526px] object-contain opacity-30"
          alt="Decorative system"
          src="/easystem-4-1@2x.png"
        />
        <img
          className="absolute -top-16 -right-16 w-[300px] h-[300px] mq750:w-[400px] mq750:h-[400px] mq1280:w-[562px] mq1280:h-[562px] object-contain opacity-30"
          alt="Decorative system"
          src="/easystem-4-22@2x.png"
        />
      </div>

      {/* Skip button - positioned at top right */}
      <div className="absolute top-6 right-6 z-20">
        <div 
          className="text-[#c3beb5] font-medium cursor-pointer hover:text-gray-600"
          onClick={handleSkip}
        >
          Skip
        </div>
      </div>

      {/* Main content container */}
      <div className="relative z-10 max-w-[1200px] mx-auto px-4 py-8 mq750:py-12">
        {/* Progress Bar and Back Button */}
        <div className="w-full max-w-[921px] mx-auto mb-8 flex items-center justify-between">
          <img
            onClick={handleGoBack}
            className="w-8 h-8 cursor-pointer"
            alt="Go Back"
            src="/icon.svg"
          />
          <div className="flex-1 mx-4">
            <Progress7
              progressWidth="100%"
              progressPosition="relative"
              progressHeight="21px"
              progressTop="0"
              progressLeft="0"
              rectangleDivBackgroundColor="rgba(5, 93, 178, 0.1)"
              rectangleDivWidth="10%"
              rectangleDivRight="90%"
              rectangleDivBackgroundColor1="#f09a19"
            />
          </div>
          <div className="text-buttonselect leading-[150%]">1 of 10</div>
        </div>

        {/* Logo */}
        <div className="flex justify-center mb-8">
          <img
            className="w-24 h-24 mq750:w-32 mq750:h-32 object-contain"
            alt="Logo"
            src="/download-1-1@2x.png"
          />
        </div>

        {/* Info Box */}
        <div className="relative mx-auto max-w-[350px] mq750:max-w-[450px] mb-8">
          <img
            className="w-full h-auto"
            alt="Info background"
            src="/vector-1.svg"
          />
          <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-sm text-black font-medium p-4 w-[80%] text-center">
            Please provide some basic details to help me personalize your learning
            experience.
          </div>
        </div>

        {/* Form */}
        <form onSubmit={handleSubmit} className="max-w-[370px] mx-auto space-y-6">
          {/* First Name Field */}
          <div className="w-full">
            <label className="block text-xs tracking-[-0.01em] leading-[20px] mb-1">
              First Name
            </label>
            <div className="relative">
              <input
                type="text"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                className="w-full h-12 pl-12 pr-4 rounded-xl border border-[#efeefc] text-black leading-tight focus:outline-none focus:border-buttonselect font-poppins text-[14px] bg-white"
              />
              <img
                className="absolute left-4 top-1/2 -translate-y-1/2 w-5 h-5"
                alt=""
                src="/my-profile.svg"
              />
            </div>
          </div>

          {/* Last Name Field */}
          <div className="w-full">
            <label className="block text-xs tracking-[-0.01em] leading-[20px] mb-1">
              Last Name
            </label>
            <div className="relative">
              <input
                type="text"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                className="w-full h-12 pl-12 pr-4 rounded-xl border border-[#efeefc] text-black leading-tight focus:outline-none focus:border-buttonselect font-poppins text-[14px] bg-white"
              />
              <img
                className="absolute left-4 top-1/2 -translate-y-1/2 w-5 h-5"
                alt=""
                src="/my-profile.svg"
              />
            </div>
          </div>

          {/* Email Field */}
          <div className="w-full">
            <label className="block text-xs tracking-[-0.01em] leading-[20px] mb-1">
              Email
            </label>
            <div className="relative">
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="w-full h-12 pl-12 pr-4 rounded-xl border border-[#efeefc] text-black leading-tight focus:outline-none focus:border-buttonselect font-poppins text-[14px] bg-white"
              />
              <img
                className="absolute left-4 top-1/2 -translate-y-1/2 w-5 h-5"
                alt=""
                src="/icon2.svg"
              />
            </div>
          </div>

          {/* Date Picker Field */}
          <div className="w-full">
            <label className="block text-xs tracking-[-0.01em] leading-[20px] mb-1">
              Date of Birth (optional)
            </label>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                value={selectedDate}
                onChange={(newValue) => setSelectedDate(newValue)}
                slotProps={{
                  textField: {
                    placeholder: "MM/DD/YYYY",
                    sx: {
                      width: '100%',
                      '& .MuiOutlinedInput-root': {
                        height: '48px',
                        borderRadius: '12px',
                        backgroundColor: 'white',
                        '& fieldset': {
                          borderColor: '#efeefc',
                          borderWidth: '1px',
                        },
                        '&:hover fieldset': {
                          borderColor: '#efeefc',
                          borderWidth: '1px',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: '#055db2',
                          borderWidth: '1px',
                        },
                      },
                      '& .MuiOutlinedInput-input': {
                        padding: '12px 14px',
                        fontFamily: 'Poppins',
                        fontSize: '14px',
                      },
                    },
                  },
                }}
              />
            </LocalizationProvider>
          </div>

          {/* Submit Button */}
          <div className="pt-4">
            <Button
              buttonWidth="100%"
              buttonPosition="relative"
              buttonBackgroundColor="#055db2"
              buttonPadding="16px"
              button="Next"
              buttonFontSize="16px"
              buttonFontWeight="bold"
              buttonFontFamily="Poppins"
              type="submit"
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default PreferencesScreen3;