import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import FlashCard from './FlashCard';

const demoFlashcards = [
  { front: "What is the capital of France?", back: "Paris" },
  { front: "What is the largest planet in our solar system?", back: "Jupiter" },
  { front: "Who wrote 'Romeo and Juliet'?", back: "William Shakespeare" },
  { front: "What is the chemical symbol for gold?", back: "Au" },
  { front: "In which year did World War II end?", back: "1945" }
];

const FlashCardsDisplay = () => {
  const navigate = useNavigate();
  const [flashcards, setFlashcards] = useState([]);
  const [subjectArea, setSubjectArea] = useState('');
  const [currentCardIndex, setCurrentCardIndex] = useState(0);
  const [isFlipped, setIsFlipped] = useState(false);
  const [showBackContent, setShowBackContent] = useState(false);
  const [isDemo, setIsDemo] = useState(false);
  const [timer, setTimer] = useState(30); // 30 seconds timer
  const [canFlip, setCanFlip] = useState(false);
  const [timerRunning, setTimerRunning] = useState(true);

  useEffect(() => {
    const storedData = localStorage.getItem('currentFlashcardsData');
    if (storedData) {
      const { flashcards, subjectArea } = JSON.parse(storedData);
      setFlashcards(flashcards);
      setSubjectArea(subjectArea);
      setIsDemo(false);
    } else {
      setFlashcards(demoFlashcards);
      setSubjectArea('Demo');
      setIsDemo(true);
    }
  }, []);

  // Timer effect
  useEffect(() => {
    let interval;
    if (timerRunning && timer > 0) {
      interval = setInterval(() => {
        setTimer((prev) => prev - 1);
      }, 1000);
    } else if (timer === 0) {
      setCanFlip(true);
      setTimerRunning(false);
    }
    return () => clearInterval(interval);
  }, [timer, timerRunning]);

  const handleNextCard = () => {
    setIsFlipped(false);
    setShowBackContent(false);
    setTimer(30); // Reset timer
    setCanFlip(false);
    setTimerRunning(true);
    setTimeout(() => {
      setCurrentCardIndex((prevIndex) => 
        prevIndex < flashcards.length - 1 ? prevIndex + 1 : prevIndex
      );
    }, 0);
  };

  const handlePrevCard = () => {
    setIsFlipped(false);
    setShowBackContent(false);
    setTimer(30); // Reset timer
    setCanFlip(false);
    setTimerRunning(true);
    setTimeout(() => {
      setCurrentCardIndex((prevIndex) => 
        prevIndex > 0 ? prevIndex - 1 : prevIndex
      );
    }, 0);
  };

  const handleFlip = () => {
    if (!canFlip) return; // Prevent flipping if timer hasn't elapsed
    if (!isFlipped) {
      setShowBackContent(true);
    }
    setIsFlipped(!isFlipped);
  };

  const handleFinish = () => {
    if (!isDemo) {
      localStorage.removeItem('currentFlashcardsData');
    }
    navigate('/course/ai-flashcards');
  };

  const handleCancel = () => {
    if (window.confirm('Are you sure you want to exit? Your progress will not be saved.')) {
      if (!isDemo) {
        localStorage.removeItem('currentFlashcardsData');
      }
      navigate('/course/ai-flashcards');
    }
  };

  if (flashcards.length === 0) {
    return <div>Loading...</div>;
  }

  const isLastCard = currentCardIndex === flashcards.length - 1;

  return (
    <div className="min-h-screen bg-background-main py-6 sm:py-12 px-4 sm:px-6 lg:px-8 font-poppins">
      <div className="max-w-3xl mx-auto">
        <div className="flex justify-between items-center mb-4">
          <button
            onClick={handleCancel}
            className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
          >
            Back
          </button>
          <h1 className="text-2xl sm:text-3xl font-bold text-center text-primary-gold">Study Flash Cards</h1>
          <div className="w-20"></div>
        </div>
        <h2 className="text-xl sm:text-2xl font-semibold text-center text-white mb-4 sm:mb-8">
          on {subjectArea}
        </h2>

        {/* Timer Display */}
        <div className="text-center mb-4">
          <div className={`text-xl font-bold ${timer > 0 ? 'text-yellow-400' : 'text-green-400'}`}>
            {timer > 0 ? (
              `Wait ${timer} seconds before flipping`
            ) : (
              'You can now flip the card!'
            )}
          </div>
        </div>
        
        <FlashCard 
          front={flashcards[currentCardIndex].front}
          back={flashcards[currentCardIndex].back}
          isFlipped={isFlipped}
          onFlip={handleFlip}
          showBackContent={showBackContent}
        />

        <div className="mt-4 sm:mt-8 flex flex-col sm:flex-row justify-between items-center relative mq850:top-[70px] top-[50px]">
          <button
            onClick={handlePrevCard}
            disabled={currentCardIndex === 0}
            className="w-full sm:w-auto mb-2 sm:mb-0 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded disabled:opacity-50"
          >
            Previous
          </button>
          <span className="text-lg font-semibold my-2 sm:my-0 text-white">
            {currentCardIndex + 1} / {flashcards.length}
          </span>
          {!isLastCard ? (
            <button
              onClick={handleNextCard}
              className="w-full sm:w-auto mt-2 sm:mt-0 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            >
              Next
            </button>
          ) : (
            <button
              onClick={handleFinish}
              className="w-full sm:w-auto mt-2 sm:mt-0 bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
            >
              Finish
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default FlashCardsDisplay;