import React, { useState, useEffect } from 'react';
import fetchGroupMembers from './fetchGroupMembers'; // Assuming this is the file containing your existing function

const GroupMembersComponent = ({ groupId }) => {
  const [members, setMembers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const loadMembers = async () => {
      try {
        setLoading(true);
        const data = await fetchGroupMembers(groupId);
        setMembers(data);
        setLoading(false);
      } catch (err) {
        setError('Failed to load group members');
        setLoading(false);
      }
    };

    loadMembers();
  }, [groupId]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div className="border rounded-lg p-4">
      <h2 className="text-xl font-bold mb-4">Group Members</h2>
      <div>
        {members.length === 0 ? (
          <p>No members found in this group.</p>
        ) : (
          <ul className="list-disc pl-5">
            {members.map((member) => (
              <li key={member.uuid} className="mb-2">
                <span className="font-semibold">{member.member_username}</span>
                <span className="ml-2 text-sm text-gray-500">
                  (Joined: {new Date(member.date_joined).toLocaleDateString()})
                </span>
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default GroupMembersComponent;