import FrameComponent from "../../components/profile/FrameComponent";
import FrameComponent1 from "../../components/profile/FrameComponent1";
import PropTypes from "prop-types";
import MainNavigation from "../../components/MainNavigation";
import NavBar from "../../components/NavBar";
import Rightside from "../../components/profile/Rightside";

const Achievements = ({ className = "" }) => {
    return (
        <div className="w-full relative bg-[#fbf1f1] min-h-screen overflow-auto text-left text-xl text-default-white font-poppins">
        <a href="/aichat" className="fixed bottom-6 left-[285px] z-[10000]">
        <img
          className="w-[75px] h-[75px] object-contain cursor-pointer"
          alt="AI Chat"
          src="/home/AIChart.svg"
        />
      </a>
      <NavBar />
        <div
            className={`w-[1268px] bg-[#fbf1f1] max-w-full overflow-hidden flex flex-col items-start justify-start pt-[100px] pb-[0px] pl-[284px] pr-[38px] box-border gap-[45px] leading-[normal] tracking-[normal] text-left text-[32px] text-[#0d0c0c] font-poppins mq450:pl-[20px] mq450:box-border mq700:gap-[22px] mq700:pl-[142px] mq700:box-border`}
        >
            <div className="w-[700px] flex flex-row items-start justify-center max-w-full">
                <h1 className="m-[0px] relative text-inherit leading-[150%] font-semibold font-[inherit] inline-block min-w-[104px] mq450:text-lgi mq450:leading-[29px] mq950:text-[26px] mq950:leading-[38px]">
                    Profile
                </h1>
            </div>
            <section className="self-stretch h-[640px] flex flex-row items-start justify-start pt-[0px] px-[0px] pb-[412.1px] box-border gap-[93px] max-w-full text-left text-5xl text-default-white font-poppins mq450:pb-[174px] mq450:box-border mq700:h-auto mq700:gap-[23px] mq950:gap-[46px] mq950:pb-[268px] mq950:box-border">
                <div className="flex-1 rounded-xl bg-background-main border-[#efe7e7] border-[1px] border-solid box-border flex flex-col items-end justify-start pt-[28px] px-[25px] pb-[29px] gap-[26px] max-w-[calc(100%_-_333px)] shrink-0 mq700:pt-[20px] mq700:pb-[20px] mq700:box-border">
                    <div className="w-[617px] h-[660px] relative rounded-xl bg-background-main border-[#efe7e7] border-[1px] border-solid box-border hidden max-w-full" />
                    <div className="w-[371px] flex flex-row items-start justify-between max-w-full gap-[20px]">
                        <div className="flex flex-col items-start justify-start pt-[1px] px-[0px] pb-[0px]">
                            <h1 className="m-[0px] relative text-inherit leading-[29px] font-bold font-[inherit] z-[1] mq450:text-lgi mq450:leading-[23px]">
                                Achievements
                            </h1>
                        </div>
                        <img
                            className="h-[24px] w-[24px] relative overflow-hidden shrink-0 z-[1]"
                            loading="lazy"
                            alt=""
                            src="/profile/info.svg"
                        />
                    </div>
                    <div className="self-stretch flex flex-row items-start justify-end py-[0px] pl-[25px] pr-[23px] box-border max-w-full text-center text-xs text-[rgba(255,194,38,0.5)]">
                        <div className="flex-1 flex flex-col items-start justify-start gap-[16px] max-w-full">
                            <div className="self-stretch flex flex-col items-start justify-start gap-[10px] max-w-full">
                                <div className="self-stretch flex flex-row items-end justify-start flex-wrap content-end gap-[21.5px] max-w-full">
                                    <div className="flex-1 flex flex-row items-end justify-start min-w-[269px] max-w-full [row-gap:20px] mq700:flex-wrap">
                                        <div className="flex-1 flex flex-row items-end justify-between pt-[27.4px] pb-[22.8px] pl-[31px] pr-[27px] box-border relative min-w-[268px] shrink-0 max-w-full gap-[20px] mq700:flex-wrap">
                                            <div className="h-[107.3px] flex flex-col items-end justify-start py-[0px] pl-[0px] pr-[3px] box-border gap-[3.8px]">
                                                <div className="w-[80.8px] h-[80.8px] relative z-[2] flex items-center justify-center">
                                                    <img
                                                        className="w-full h-full z-[2] object-contain absolute left-[0px] top-[8px] [transform:scale(1.248)]"
                                                        loading="lazy"
                                                        alt=""
                                                        src="/profile/badge.svg"
                                                    />
                                                </div>
                                                <div className="w-[79.6px] h-[22.7px] relative tracking-[0.04em] leading-[150%] uppercase font-medium flex items-center justify-center shrink-0 z-[2]">
                                                    New User
                                                </div>
                                            </div>
                                            <div className="h-[122.8px] flex flex-col items-start justify-end pt-[0px] px-[0px] pb-[4.6px] box-border">
                                                <div className="w-[1.3px] flex-1 relative [background:linear-gradient(180deg,_rgba(255,_255,_255,_0.1),_rgba(255,_255,_255,_0.5)_46.63%,_rgba(255,_255,_255,_0.1))] z-[2]" />
                                            </div>
                                            <div className="h-full w-full absolute !m-[0] top-[0px] right-[0px] bottom-[0px] left-[0px]">

                                                <img
                                                    className="absolute top-[42.9px] left-[170.5px] w-[65.7px] h-[65.7px] z-[2]"
                                                    loading="lazy"
                                                    alt=""
                                                    src="/profile/badge2.svg"
                                                />
                                            </div>
                                            <div className="w-[92.1px] flex flex-col items-start justify-start py-[0px] pl-[0px] pr-[8px] box-border">
                                                <div className="self-stretch h-[22.7px] relative tracking-[0.04em] leading-[150%] uppercase font-medium flex items-center justify-center shrink-0 z-[2]">
                                                    First Quiz
                                                </div>
                                            </div>
                                            <div className="h-[122.8px] w-[10.1px] flex flex-col items-start justify-end pt-[0px] pb-[4.6px] pl-[0px] pr-[8px] box-border">
                                                <div className="w-[1.3px] flex-1 relative [background:linear-gradient(180deg,_rgba(255,_255,_255,_0.1),_rgba(255,_255,_255,_0.5)_46.63%,_rgba(255,_255,_255,_0.1))] z-[2]" />
                                            </div>
                                            <FrameComponent
                                                badge="/profile/badge3.svg"
                                                iconLock="/profile/iconlock.svg"
                                            />
                                        </div>
                                        <div className="h-[145.2px] flex flex-col items-start justify-end pt-[0px] px-[0px] pb-[27px] box-border">
                                            <div className="w-[1.3px] flex-1 relative [background:linear-gradient(180deg,_rgba(255,_255,_255,_0.1),_rgba(255,_255,_255,_0.5)_46.63%,_rgba(255,_255,_255,_0.1))] shrink-0 z-[2]" />
                                        </div>
                                    </div>
                                    <FrameComponent1
                                        badge="/profile/badge3.svg"
                                        iconLock="/profile/iconlock.svg"
                                    />
                                </div>
                                <div className="self-stretch flex flex-row items-end justify-start flex-wrap content-end gap-[21.5px] max-w-full">
                                    <div className="flex-1 flex flex-row items-end justify-start min-w-[269px] max-w-full [row-gap:20px] mq700:flex-wrap">
                                        <div className="flex-1 flex flex-row items-end justify-between pt-[27.4px] pb-[22.8px] pl-[31px] pr-[27px] box-border relative min-w-[268px] shrink-0 max-w-full gap-[20px] mq700:flex-wrap">

                                            <FrameComponent
                                                badge="/profile/badge3.svg"
                                                iconLock="/profile/iconlock.svg"
                                                propWidth="84.3px"
                                                propPadding="0px 3px 0px 0px"
                                                propAlignSelf="stretch"
                                            />
                                            <div className="h-[122.8px] flex flex-col items-start justify-end pt-[0px] px-[0px] pb-[4.6px] box-border">
                                                <div className="w-[1.3px] flex-1 relative [background:linear-gradient(180deg,_rgba(255,_255,_255,_0.1),_rgba(255,_255,_255,_0.5)_46.63%,_rgba(255,_255,_255,_0.1))] z-[2]" />
                                            </div>
                                            <FrameComponent
                                                badge="/profile/badge3.svg"
                                                iconLock="/profile/iconlock.svg"
                                                propWidth="91.7px"
                                                propPadding="0px 10px 0px 0px"
                                                propAlignSelf="stretch"
                                            />
                                            <div className="h-[122.8px] w-[9.9px] flex flex-col items-start justify-end pt-[0px] pb-[4.6px] pl-[0px] pr-[8px] box-border">
                                                <div className="w-[1.3px] flex-1 relative [background:linear-gradient(180deg,_rgba(255,_255,_255,_0.1),_rgba(255,_255,_255,_0.5)_46.63%,_rgba(255,_255,_255,_0.1))] z-[2]" />
                                            </div>
                                            <FrameComponent
                                                badge="/profile/badge3.svg"
                                                iconLock="/profile/iconlock.svg"
                                                propWidth="80.8px"
                                                propPadding="unset"
                                                propAlignSelf="unset"
                                            />
                                        </div>
                                        <div className="h-[145.2px] flex flex-col items-start justify-end pt-[0px] px-[0px] pb-[27px] box-border">
                                            <div className="w-[1.3px] flex-1 relative [background:linear-gradient(180deg,_rgba(255,_255,_255,_0.1),_rgba(255,_255,_255,_0.5)_46.63%,_rgba(255,_255,_255,_0.1))] shrink-0 z-[2]" />
                                        </div>
                                    </div>
                                    <FrameComponent1
                                        badge="/profile/badge3.svg"
                                        iconLock="/profile/iconlock.svg"
                                    />
                                </div>
                            </div>
                            <div className="self-stretch flex flex-row items-end justify-start flex-wrap content-end gap-[21.5px] max-w-full">
                                <div className="flex-1 flex flex-row items-end justify-start min-w-[269px] max-w-full [row-gap:20px] mq700:flex-wrap">
                                    <div className="flex-1 flex flex-row items-end justify-between pt-[27.4px] pb-[22.8px] pl-[31px] pr-[27px] box-border relative min-w-[268px] shrink-0 max-w-full gap-[20px] mq700:flex-wrap">

                                        <FrameComponent
                                            badge="/profile/badge3.svg"
                                            iconLock="/profile/iconlock.svg"
                                            propWidth="84.3px"
                                            propPadding="0px 3px 0px 0px"
                                            propAlignSelf="stretch"
                                        />
                                        <div className="h-[122.8px] flex flex-col items-start justify-end pt-[0px] px-[0px] pb-[4.6px] box-border">
                                            <div className="w-[1.3px] flex-1 relative [background:linear-gradient(180deg,_rgba(255,_255,_255,_0.1),_rgba(255,_255,_255,_0.5)_46.63%,_rgba(255,_255,_255,_0.1))] z-[2]" />
                                        </div>
                                        <FrameComponent
                                            badge="/profile/badge3.svg"
                                            iconLock="/profile/iconlock.svg"
                                            propWidth="91.7px"
                                            propPadding="0px 10px 0px 0px"
                                            propAlignSelf="stretch"
                                        />
                                        <div className="h-[122.8px] w-[9.9px] flex flex-col items-start justify-end pt-[0px] pb-[4.6px] pl-[0px] pr-[8px] box-border">
                                            <div className="w-[1.3px] flex-1 relative [background:linear-gradient(180deg,_rgba(255,_255,_255,_0.1),_rgba(255,_255,_255,_0.5)_46.63%,_rgba(255,_255,_255,_0.1))] z-[2]" />
                                        </div>
                                        <FrameComponent
                                            badge="/profile/badge3.svg"
                                            iconLock="/profile/iconlock.svg"
                                            propWidth="80.8px"
                                            propPadding="unset"
                                            propAlignSelf="unset"
                                        />
                                    </div>
                                    <div className="h-[145.2px] flex flex-col items-start justify-end pt-[0px] px-[0px] pb-[27px] box-border">
                                        <div className="w-[1.3px] flex-1 relative [background:linear-gradient(180deg,_rgba(255,_255,_255,_0.1),_rgba(255,_255,_255,_0.5)_46.63%,_rgba(255,_255,_255,_0.1))] shrink-0 z-[2]" />
                                    </div>
                                </div>
                                <FrameComponent1
                                    badge="/profile/badge3.svg"
                                    iconLock="/profile/iconlock.svg"
                                />
                            </div>
                        </div>
                    </div>
                </div>

            </section>
            <Rightside />


            
        </div>
        
            <MainNavigation
                homeClass=""
                learningClass=""
                mentorClass=""
                groupsClass=""
                profileClass="left-[-29px] rounded-tl-[0px] rounded-tr-11xl rounded-br-11xl rounded-bl-[0px] bg-buttonselect border-buttonselect border-[2px] border-solid box-border text-default-white"
            />
        </div>
    );
};

Achievements.propTypes = {
    className: PropTypes.string,
};

export default Achievements;