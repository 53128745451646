import { useCallback, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../components/Button";
import Progress7 from "../../components/Progress7";
import axios from "axios";

const PreferencesScreen5 = () => {
  const navigate = useNavigate();
  const [schoolInput, setSchoolInput] = useState("");
  const [schools, setSchools] = useState([]);
  const [filteredSchools, setFilteredSchools] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);

  const onButtonContainerClick = useCallback(() => {
    navigate("/8-preferencesscreen");
  }, [navigate]);

  const handleGoBack = () => {
    navigate(-1); // Navigate back to the previous screen
  };
  const handleSkip = () => {
    navigate("/8-preferencesscreen");
  }

  // Fetch schools when the component mounts
  useEffect(() => {
    const fetchSchools = async () => {
      const districtName = localStorage.getItem("district_name");
      const regionId = localStorage.getItem("region_id");

      if (!districtName || !regionId) return; // Do not fetch if district or region data is missing

      try {
        const response = await axios.get(`https://api.staging.sensebod.com/api/v1/schools/?district_name=${districtName}&region_id=${regionId}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        });
        console.log("School", response.data);
        setSchools(response.data); // Set the fetched schools data to state
      } catch (error) {
        console.error("Error fetching schools:", error);
      }
    };

    fetchSchools();
  }, []);

  const handleSchoolInputChange = (e) => {
    const input = e.target.value;
    setSchoolInput(input);

    if (input) {
      // Filter the schools based on the input
      const filtered = schools.filter((school) =>
        school.toLowerCase().includes(input.toLowerCase())
      );
      setFilteredSchools(filtered);
      setShowDropdown(true);
    } else {
      setShowDropdown(false);
    }
  };

  const storeSelectedSchool = (selectedSchool) => {
    const country = {
      country_short_code: localStorage.getItem("country_short_code"),
      country: localStorage.getItem("country"),
    };
    const region = {
      region_name: localStorage.getItem("region_name"),
    };
    const district = {
      district_name: localStorage.getItem("district_name"),
    };

    const schoolData = {
      school: {
        school_name: selectedSchool,
        country,
        region,
        district,
      },
    };

    // Store the school data in localStorage
    localStorage.setItem("selectedSchoolData", JSON.stringify(schoolData));
    console.log("Stored school data:", schoolData);
  };

  const handleSchoolSelect = (school) => {
    setSchoolInput(school);
    setShowDropdown(false);
    storeSelectedSchool(school); // Store the selected school data in localStorage
  };

  return (
    <div className="min-h-screen w-full relative bg-default-white overflow-x-hidden text-left text-sm text-[#686b6d] font-poppins">
      {/* Background images with responsive positioning */}
      <div className="fixed inset-0 overflow-hidden pointer-events-none">
        <img
          className="absolute top-0 left-1/2 -translate-x-1/2 w-full max-w-[1933px] h-auto object-cover opacity-50"
          alt="Background pattern"
          src="/preference.svg"
        />
        <img
          className="absolute -bottom-16 -left-16 w-[300px] h-[300px] mq750:w-[400px] mq750:h-[400px] mq1280:w-[526px] mq1280:h-[526px] object-contain opacity-30"
          alt="Decorative system"
          src="/easystem-4-1@2x.png"
        />
        <img
          className="absolute -top-16 -right-16 w-[300px] h-[300px] mq750:w-[400px] mq750:h-[400px] mq1280:w-[562px] mq1280:h-[562px] object-contain opacity-30"
          alt="Decorative system"
          src="/easystem-4-22@2x.png"
        />
      </div>

      {/* Skip button - positioned at top right */}
      <div className="absolute top-6 right-6 z-20">
        <div 
          className="text-[#c3beb5] font-medium cursor-pointer hover:text-gray-600"
          onClick={() => navigate("/8-preferencesscreen")}
        >
          Skip
        </div>
      </div>

      {/* Main content container */}
      <div className="relative z-10 max-w-[1200px] mx-auto px-4 py-8 mq750:py-12">
        {/* Progress Bar and Back Button */}
        <div className="w-full max-w-[921px] mx-auto mb-8 flex items-center justify-between">
          <img
            onClick={() => navigate(-1)}
            className="w-8 h-8 cursor-pointer"
            alt="Go Back"
            src="/icon.svg"
          />
          <div className="flex-1 mx-4">
            <Progress7
              progressWidth="100%"
              progressPosition="relative"
              progressHeight="21px"
              progressTop="0"
              progressLeft="0"
              rectangleDivBackgroundColor="rgba(5, 93, 178, 0.1)"
              rectangleDivWidth="30%"
              rectangleDivRight="70%"
              rectangleDivBackgroundColor1="#f09a19"
            />
          </div>
          <div className="text-buttonselect leading-[150%]">3 of 10</div>
        </div>

        {/* Logo */}
        <div className="flex justify-center mb-8">
          <img
            className="w-24 h-24 mq750:w-32 mq750:h-32 object-contain"
            alt="Logo"
            src="/download-1-1@2x.png"
          />
        </div>

        {/* Info Box */}
        <div className="relative mx-auto max-w-[350px] mq750:max-w-[450px] mb-12">
          <img
            className="w-full h-auto"
            alt="Info background"
            src="/vector-1.svg"
          />
          <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-sm text-black font-medium p-4 w-[80%] text-center">
            Tell me where your school is so I can tailor content specifically for you.
          </div>
        </div>

        {/* School Search Form */}
        <div className="max-w-[370px] mx-auto">
          <div className="relative w-full">
            {/* Search Label */}
            <label className="block text-xs tracking-[-0.01em] leading-[20px] mb-1">
              School
            </label>

            {/* Search Input */}
            <div className="relative">
              <input
                type="text"
                value={schoolInput}
                onChange={handleSchoolInputChange}
                placeholder="Start typing to search..."
                className="w-[90%] h-12 pl-4 pr-10 rounded-xl border border-[#efeefc] text-black leading-tight focus:outline-none focus:border-buttonselect font-poppins text-[14px] bg-white"
              />
              <div className="absolute inset-y-0 right-4 flex items-center">
                <svg className="w-5 h-5 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                </svg>
              </div>
            </div>

            {/* Dropdown Results */}
            {showDropdown && filteredSchools.length > 0 && (
              <div className="absolute z-10 w-full mt-1 max-h-60 overflow-auto rounded-xl border border-[#efeefc] bg-white shadow-lg">
                {filteredSchools.map((school, index) => (
                  <div
                    key={index}
                    className="px-4 py-3 hover:bg-gray-50 cursor-pointer text-black transition-colors duration-150"
                    onClick={() => handleSchoolSelect(school)}
                  >
                    {school}
                  </div>
                ))}
              </div>
            )}
          </div>

          {/* Next Button */}
          <div className="mt-8">
            <Button
              buttonWidth="100%"
              buttonPosition="relative"
              buttonBackgroundColor="#055db2"
              buttonPadding="16px"
              button="Next"
              buttonFontSize="16px"
              buttonFontWeight="bold"
              buttonFontFamily="Poppins"
              onButtonContainerClick={() => navigate("/8-preferencesscreen")}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PreferencesScreen5;
