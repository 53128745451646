// NavigationBar.js
import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

const NavigationBar = ({
  currentTitle,
  username,
  onNavigateToCourse,
  onPrevious,
  onNext,
  isPrevDisabled,
  isNextDisabled,
}) => {
  return (
    <div className="absolute w-[calc(100%_-_2px)] top-[0px] right-[2px] bg-default-white border-[#efefef] border-b-[1px] border-solid box-border flex flex-row items-center justify-start py-[20px] pl-[41px] pr-[40px] gap-[217px] text-background-main">
      <div className="flex flex-row items-center justify-start gap-[10px]">
        <img
          className="w-[40px] rounded-11xl h-[40px] overflow-hidden shrink-0 object-contain cursor-pointer"
          alt=""
          src="/learning/iconamoonarrowup2@2x.png"
          onClick={onNavigateToCourse}
        />
        <div className="flex flex-row items-start justify-start p-[20px] w-[200px]">
          <div className="relative leading-[100%]">{currentTitle}</div>
        </div>
      </div>

      <div className="w-[823px] flex flex-row items-center justify-start gap-[109px] text-primary-gold">
        <div className="flex flex-row items-start justify-start gap-[20px]">
          <div
            className={`rounded-[9px] flex flex-row items-start justify-start p-[20px] ${
              isPrevDisabled
                ? 'bg-gray-600 cursor-not-allowed text-gray-500'
                : 'bg-background-main cursor-pointer'
            }`}
            onClick={onPrevious}
            style={{ pointerEvents: isPrevDisabled ? 'none' : 'auto' }}
          >
            <div className="relative leading-[100%]">Prev</div>
          </div>

          <div
            className={`rounded-[9px] flex flex-row items-start justify-start p-[20px] ${
              isNextDisabled
                ? 'bg-gray-600 cursor-not-allowed text-gray-500'
                : 'bg-background-main cursor-pointer'
            }`}
            onClick={onNext}
            style={{ pointerEvents: isNextDisabled ? 'none' : 'auto' }}
          >
            <div className="relative leading-[100%]">Next</div>
          </div>
        </div>
        <div className="rounded-[9px] bg-[rgba(26,54,81,0.2)] flex flex-row items-start justify-start">
          <div className="flex flex-row items-start justify-start p-[20px]">
            <div className="relative leading-[100%]">Mark as Complete</div>
          </div>
        </div>
        <div className="border-[#efefef] border-l-[1px] border-solid left-[10px] flex flex-row items-center justify-start py-[0px] px-[40px] text-[#3d3d3d]">
          <div className="w-[176px] relative h-[56px]">
            <img
              className="absolute h-full w-[31.82%] top-[0%] right-[68.18%] bottom-[0%] left-[0%] max-w-full overflow-hidden max-h-full object-cover"
              alt=""
              src="/learning/avatar1@2x.png"
            />
            <div className="absolute top-[20px] left-[64px] leading-[100%]">
              {username}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

NavigationBar.propTypes = {
  currentTitle: PropTypes.string.isRequired,
  username: PropTypes.string,
  onNavigateToCourse: PropTypes.func.isRequired,
  onPrevious: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
  isPrevDisabled: PropTypes.bool,
  isNextDisabled: PropTypes.bool,
};

NavigationBar.defaultProps = {
  username: 'User',
  isPrevDisabled: false,
  isNextDisabled: false,
};

export default NavigationBar;
