import React, { useState, useEffect, useCallback, useRef } from "react";
import { useNavigate } from "react-router-dom";
import MainNavigation from "../../components/MainNavigation";
import NavBar from "../../components/NavBar";
import Loader from "../../components/Loader";

const AIChat = ({ className = "" }) => {
  const navigate = useNavigate();
  const [ws, setWs] = useState(null);
  const [connected, setConnected] = useState(false);
  const [messages, setMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState('');
  const messagesEndRef = useRef(null);
  const lastUserMessageRef = useRef('');
  const [isLoading, setIsLoading] = useState(true);
  const [isTyping, setIsTyping] = useState(false);

  // Add useEffect to set viewport meta tag
  useEffect(() => {
    let viewportMeta = document.querySelector('meta[name="viewport"]');
    if (!viewportMeta) {
      viewportMeta = document.createElement('meta');
      viewportMeta.name = 'viewport';
      document.head.appendChild(viewportMeta);
    }

    const viewportContent = 'width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no';
    viewportMeta.setAttribute('content', viewportContent);

    return () => {
      viewportMeta.setAttribute('content', 'width=device-width, initial-scale=1');
    };
  }, []);

  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");
    if (!accessToken) {
      navigate("/login");
    }
  }, [navigate]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(scrollToBottom, [messages, isTyping]);

  const connectWebSocket = useCallback(() => {
    const accessToken = localStorage.getItem("accessToken");
    const userId = localStorage.getItem("userId");
    console.log(userId);
    const numbersOnly = userId.replace(/\D/g, '');

    const wsUrl = accessToken
      ? `wss://api.staging.sensebod.com/ws/ai-chat/${numbersOnly}/?token=${accessToken}`
      : 'wss://api.staging.sensebod.com/ws/ai-chat/1/';

    const socket = new WebSocket(wsUrl);

    socket.onopen = () => {
      console.log('WebSocket connected');
      setConnected(true);
      setIsLoading(false);
    };

    socket.onmessage = (event) => {
      try {
        const data = JSON.parse(event.data);
        
        console.log('Received message:', data);
        if (data.message !== lastUserMessageRef.current) {
          setIsTyping(false);
          setMessages(prevMessages => [...prevMessages, {
            uuid: Date.now(),
            sender: "AI",
            text: data.message,
            timestamp: new Date().toISOString()
          }]);
        }
      } catch (error) {
        console.error('Error parsing received data:', error);
      }
    };

    socket.onclose = () => {
      console.log('WebSocket disconnected');
      setConnected(false);
      setIsLoading(true);
    };

    socket.onerror = (error) => {
      console.error('WebSocket error:', error);
      setIsLoading(false);
    };

    setWs(socket);

    return () => {
      socket.close();
    };
  }, []);

  useEffect(() => {
    const cleanup = connectWebSocket();
    return cleanup;
  }, [connectWebSocket]);

  const sendMessage = () => {
    if (ws && ws.readyState === WebSocket.OPEN && inputMessage.trim()) {
      const messageObj = { message: inputMessage.trim() };
      ws.send(JSON.stringify(messageObj));
      setMessages(prevMessages => [...prevMessages, {
        uuid: Date.now(),
        sender: "You",
        text: inputMessage.trim(),
        timestamp: new Date().toISOString()
      }]);
      lastUserMessageRef.current = inputMessage.trim();
      setInputMessage('');
      setIsTyping(true);
    }
  };

  const parseMessageContent = (content) => {
    if (typeof content !== 'string') {
      console.warn('parseMessageContent received non-string content:', content);
      return content;
    }
  
    // Split the content by new lines first
    const lines = content.split('\n');
    
    return lines.map((line, lineIndex) => {
      // Process each line for bold text
      const parts = line.split(/(\*\*.*?\*\*)/g);
      const processedLine = parts.map((part, partIndex) => {
        if (part.startsWith('**') && part.endsWith('**')) {
          return <strong key={`${lineIndex}-${partIndex}`}>{part.slice(2, -2)}</strong>;
        }
        return part;
      });

      // Return each line with a line break, except for the last line
      return (
        <React.Fragment key={lineIndex}>
          {processedLine}
          {lineIndex < lines.length - 1 && <br />}
        </React.Fragment>
      );
    });
  };

  if (isLoading) {
    return <Loader duration={10000}  />;
  }

  return (
    <div className="w-full relative bg-[#fbf1f1] min-h-screen overflow-hidden text-center text-xs md:text-[6px] text-default-white font-poppins">
      <MainNavigation
        homeClass=""
        learningClass=""
        mentorClass="left-[-29px] rounded-tl-[0px] rounded-tr-11xl rounded-br-11xl rounded-bl-[0px] bg-buttonselect border-buttonselect border-[2px] border-solid box-border text-default-white"
        groupsClass=""
        profileClass=""
      />
      
      <NavBar />

      <p className="hidden md:block absolute text-center text-[#D8D1D1] text-[36px] font-[400] font-[Poppins] line-height-[48px] top-[385px] left-[826px]">No Mentor Here</p>

      <div className="flex flex-col md:flex-row px-4 md:px-0 mt-20 md:mt-0">
        {/* Sidebar */}
        <div className="hidden md:block md:w-[300px]">
          <div className="flex justify-between items-center mb-4 md:mb-0 md:block">
            <div className="flex space-x-4 md:space-x-0 md:block">
              <div className="flex items-center md:absolute md:top-[135px] md:left-[290px]">
                <img className="w-4 h-4 md:w-[16px] md:h-[16px]" alt="" src="/mentor/Icon (3).svg" />
                <p className="ml-2 text-[#92929D] text-sm font-semibold md:absolute md:top-[-14px] md:left-[20px]">Mentors</p>
              </div>
              <div className="flex items-center md:absolute md:top-[135px] md:left-[370px]">
                <img className="w-4 h-4 md:w-[16px] md:h-[16px]" alt="" src="/mentor/Icon (3).svg" />
                <p className="ml-2 text-[#92929D] text-sm font-semibold md:absolute md:top-[-14px] md:left-[25px]">AI</p>
              </div>
              <div className="flex items-center md:absolute md:top-[135px] md:left-[420px]">
                <img className="w-4 h-4 md:w-[16px] md:h-[16px]" alt="" src="/mentor/Icon (3).svg" />
                <p className="ml-2 text-[#92929D] text-sm font-semibold md:absolute md:top-[-14px] md:left-[30px]">Messages</p>
              </div>
            </div>
          </div>

          <div className="flex justify-between items-center mb-4 md:block">
            <p className="text-[#555758] text-sm font-medium md:absolute md:top-[185px] md:left-[295px]">AI</p>
            <div className="flex items-center">
              <p className="text-[#F09A19] text-xs font-normal bg-[#1A3651] px-2 py-1 rounded-full md:absolute md:top-[194px] md:left-[360px]">0 chats</p>
              <a href="/Mentor3" className="ml-4 md:ml-0">
                <img className="w-6 h-6 bg-[#1A3651] rounded-full p-1 md:absolute md:top-[194px] md:left-[550px]" alt="" src="/mentor/fe_plus.svg" />
              </a>
            </div>
          </div>

          <div className="relative mb-4 md:mb-0 md:absolute md:top-[242px] md:left-[280px] mq750:hidden">
            <img className="absolute top-3 left-3 w-5 h-5 md:w-[17.2px] md:h-[17.2px]" alt="" src="/mentor/search.svg" />
            <input type="text" name="search" placeholder="Search... " className="w-full md:w-[260px] h-10 md:h-[41px] pl-10 border-none text-sm text-[#989BA1] rounded-full bg-white" />
             </div>

             <textarea className="w-full mq750:hidden md:w-[290px] h-40 md:h-[350px] p-2 md:p-0 md:pl-[10px] md:pt-[10px] rounded-lg md:rounded-[10px] bg-white text-sm text-[#707070] resize-none outline-none md:absolute md:top-[320px] md:left-[285px]"></textarea>
         
        </div>

        {/* Chat Area */}
        <div className={`flex-grow flex flex-col md:w-[654px] md:h-[610px] md:absolute md:top-[180px] md:left-[650px] text-left text-base md:text-[15px] text-[#0c092a] font-poppins ${className}`}>
          <div className="flex-grow flex flex-col bg-white rounded-lg md:rounded-sm border border-[#b9b9b9] h-[calc(100vh-80px)] md:h-full mq850:mb-[25px]">
            {/* AI Assistant header - fixed at top */}
            <div className="sticky top-0 z-10 bg-white p-4 md:p-0 md:absolute md:top-[18px] md:left-[27px] flex items-center">
              <img
                className="w-8 h-8 md:w-[32px] md:h-[32px] rounded-full mr-3"
                alt=""
                src="/mentor/FloatChat.svg"
              />
              <div>
                <div className="font-medium">Study Buddy</div>
                <div className="text-xs text-[#858494]">
                  AI Assistant
                </div>
              </div>
            </div>

            {/* Messages display area */}
            <div className="flex-grow overflow-y-auto p-4 md:absolute md:top-[80px] md:left-[20px] md:right-[20px] md:bottom-[120px]">
              {messages.map((msg) => (
                <div key={msg.uuid} className={`mb-4 flex ${msg.sender === "You" ? "justify-end" : "justify-start"}`}>
                  <div className={`p-3 rounded-lg max-w-[70%] ${msg.sender === "You" ? "bg-[#1e3a5f] text-white" : "bg-[#f0f0f0] text-[#0c092a]"}`}>
                    <p className="mb-2">{parseMessageContent(msg.text)}</p>
                    <div className={`text-xs text-right ${msg.sender === "You" ? "text-[#ffa500]" : "text-[#858494]"}`}>
                      {new Date(msg.timestamp).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                    </div>
                  </div>
                </div>
              ))}
              {isTyping && (
                <div className="mb-4 flex justify-start">
                  <div className="p-3 rounded-lg bg-[#f0f0f0] text-[#0c092a]">
                    <p className="mb-2">Study Buddy is typing...</p>
                  </div>
                </div>
              )}
              <div ref={messagesEndRef} />
            </div>

            {/* Input area for typing messages - sticky to bottom */}
            <div className="sticky bottom-0 bg-white p-4 md:p-0 md:absolute md:w-full md:bottom-[0] md:h-[64px]">
              <div className="flex items-center bg-white border-t border-[#b9b9b9] md:border-none">
                <input
                  type="text"
                  className="flex-grow p-2 bg-transparent outline-none text-[18px]"
                  placeholder="Type a message"
                  value={inputMessage}
                  onChange={(e) => setInputMessage(e.target.value)}
                  onKeyPress={(e) => e.key === 'Enter' && sendMessage()}
                />
                <div className="w-10 h-10 rounded-full bg-buttonselect flex items-center justify-center cursor-pointer" onClick={sendMessage}>
                  <img
                    className="w-5 h-5"
                    alt="Send"
                    src="/group/send.svg"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AIChat;