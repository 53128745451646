import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';

const StudentList = ({ students, currentUsername }) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  // Handle window resize
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Common styles for current user highlight
  const currentUserBgClass = "bg-primary-gold";
  const defaultBgClass = isMobile ? "" : "bg-[#252728]";

  if (isMobile) {
    return (
      <div className="px-4">
        {students.map((student) => {
          const isCurrentUser = student.name === currentUsername;
          
          return (
            <div 
              key={student.position}
              className={`flex items-center justify-between py-3 border-b border-gray-700 transition-colors
                ${isCurrentUser ? currentUserBgClass : defaultBgClass}`}
            >
              <div className="flex items-center gap-3">
                <span className="w-6 text-gray-400 text-sm">
                  {student.position}
                </span>
                <img 
                  src={student.avatar} 
                  alt={student.name}
                  className="w-10 h-10 rounded-full object-cover"
                />
                <div className="text-left">
                  <p className="text-white text-sm font-medium">
                    {student.name}
                    {isCurrentUser && <span className="text-primary-gold ml-2">(you)</span>}
                  </p>
                  <p className="text-primary-gold text-xs">{student.school}</p>
                </div>
              </div>
              <div className="text-right text-sm text-gray-300">
                {student.points} pts
              </div>
            </div>
          );
        })}
      </div>
    );
  }

  // Desktop Layout
  return (
    <div className="absolute top-[401px] left-[7px] w-[596px] h-[512px] text-left text-xs font-poppins">
      {students.map((student, index) => {
        const isCurrentUser = student.name === currentUsername;
        
        return (
          <div
            key={student.position}
            className="absolute w-[596px] h-[48px]"
            style={{ top: `${index * 58}px` }}
          >
            <div className={`absolute top-0 left-0 rounded-[12px] w-full h-full transition-colors
              ${isCurrentUser ? currentUserBgClass : defaultBgClass}`}
            />
            <b className="absolute top-[13px] left-[27.8px] text-[14.4px] leading-[150%] inline-block text-center w-[17.4px]">
              {student.position}
            </b>
            <div className="absolute top-[9px] left-[66px] w-[217.2px] flex flex-row items-center justify-start gap-[12px]">
              <img
                className="w-[32px] relative h-[32px] object-cover rounded-full"
                alt={`${student.name}'s Avatar`}
                src={student.avatar}
              />
              <b className="relative leading-[150%]">
                {student.name}
                {isCurrentUser && <span className="text-primary-gold ml-2">(you)</span>}
              </b>
            </div>
            <div className="absolute top-[16px] left-[524px] leading-[150%] text-right">
              {student.points} pts
            </div>
            <div className={`absolute top-[16px] left-[358px] text-[13px] leading-[150%] font-medium  
              ${isCurrentUser ? "text-white" : "text-primary-gold"}
              `}>
              {student.school}
            </div>
          </div>
        );
      })}
    </div>
  );
};

StudentList.propTypes = {
  students: PropTypes.arrayOf(
    PropTypes.shape({
      position: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      points: PropTypes.number.isRequired,
      avatar: PropTypes.string.isRequired,
      school: PropTypes.string.isRequired,
    })
  ).isRequired,
  currentUsername: PropTypes.string.isRequired,
};

export default StudentList;