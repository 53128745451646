import React, { useState, useEffect } from "react";
import Rightside from "../../components/profile/Rightside";
import PropTypes from "prop-types";
import MainNavigation from "../../components/MainNavigation";
import NavBar from "../../components/NavBar";
import { useNavigate } from 'react-router-dom';
import Loader from "../../components/Loader";
import { Menu, X } from "lucide-react";

const SchoolsLeaderboard = ({ className = "" }) => {
    const navigate = useNavigate();
    const [schools, setSchools] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [isRightsideOpen, setIsRightsideOpen] = useState(false);
    

    useEffect(() => {
        const fetchSchools = async () => {
            setLoading(true);
            try {
                const accessToken = localStorage.getItem("accessToken");
                if (!accessToken) {
                    throw new Error("No access token found");
                }

                const response = await fetch('https://api.staging.sensebod.com/api/v1/leaderboards/top_school/', {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`,
                    }
                });

                if (!response.ok) {
                    throw new Error('Failed to fetch school data');
                }

                const data = await response.json();
                const sortedSchools = data.sort((a, b) =>
                    parseFloat(b.total_points) - parseFloat(a.total_points)
                );
                setSchools(sortedSchools);
            } catch (err) {
                console.error('Error fetching schools:', err);
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchSchools();
    }, []);

    if (loading) {
        return <Loader duration={50000} />;
    }

    return (
        <div className={`w-full relative bg-[#fbf1f1] min-h-screen overflow-x-hidden text-left text-xl font-poppins ${className}`}>
            <NavBar />
            
            {/* Desktop Navigation */}
            <div className="hidden lg:block">
                <MainNavigation
                    homeClass=""
                    learningClass=""
                    mentorClass=""
                    groupsClass=""
                    profileClass="left-[-29px] rounded-tl-[0px] rounded-tr-11xl rounded-br-11xl rounded-bl-[0px] bg-buttonselect border-buttonselect border-[2px] border-solid box-border text-default-white"
                />
            </div>

            {/* Mobile Navigation */}
            <div className="lg:hidden px-4">
                <MainNavigation
                    homeClass=""
                    learningClass=""
                    mentorClass=""
                    groupsClass=""
                    profileClass="rounded-tl-[0px] rounded-tr-11xl rounded-br-11xl rounded-bl-[0px] bg-buttonselect border-buttonselect border-[2px] border-solid box-border text-default-white"
                />
            </div>

            {/* Menu Button for Mobile */}
            <button 
                onClick={() => setIsRightsideOpen(!isRightsideOpen)}
                className="fixed right-4 top-20 z-[10001] lg:hidden bg-buttonselect p-2 rounded-full"
            >
                {isRightsideOpen ? (
                    <X className="w-6 h-6 text-white" />
                ) : (
                    <Menu className="w-6 h-6 text-white" />
                )}
            </button>

            {/* Profile Title */}
            <div className="lg:absolute lg:top-[116px] lg:left-[calc(50%_-_59px)] text-[32px] leading-[150%] font-semibold text-[#0d0c0c] text-center lg:text-left mt-14 lg:mt-0">
                Profile
            </div>

            {/* Mobile Layout */}
            <div className="lg:hidden px-4 mt-6">
                {/* Navigation Tabs */}
                <div className="w-full mb-6">
                    <div className="rounded-[12px] bg-[#1e2237] p-3 flex justify-around">
                        <div onClick={() => navigate('/national-leaderboard')} className="text-default-white font-medium cursor-pointer">
                            National
                        </div>
                        <div onClick={() => navigate('/school-student-leaderboard')} className="text-default-white font-medium cursor-pointer">
                            School
                        </div>
                        <div onClick={() => navigate('/school-leaderboard')} className="text-default-white font-medium cursor-pointer relative">
                            Top Schools
                            <div className="absolute -bottom-3 left-0 right-0 h-[3px] bg-primary-gold rounded-[10px]" />
                        </div>
                    </div>
                </div>

                {/* Schools List for Mobile */}
                <div className="bg-default-white rounded-xl border border-[#efe7e7] p-4">
                    {/* Header */}
                    <div className="grid grid-cols-12 gap-2 p-3 bg-default-white border border-[#d5d5d5] rounded-lg mb-2">
                        <div className="col-span-2"><b>Rank</b></div>
                        <div className="col-span-5"><b>Schools</b></div>
                        <div className="col-span-3"><b>Points</b></div>
                        <div className="col-span-2"><b>Badge</b></div>
                    </div>

                    {/* Schools List */}
                    <div className="space-y-4">
                        {schools.map((school, index) => (
                            <div key={index} className="grid grid-cols-12 gap-2 p-3 border-b border-[#d5d5d5]">
                                <div className="col-span-2 font-semibold">{index + 1}</div>
                                <div className="col-span-5 font-semibold truncate">{school.school}</div>
                                <div className="col-span-3 font-semibold">{parseFloat(school.total_points).toLocaleString()}</div>
                                <div className="col-span-2">
                                    <img
                                        className="w-8 h-8 object-cover"
                                        alt=""
                                        src={school.school_badge ? `${school.school_badge}` : `/profile/school-${index + 1}.svg`}
                                    />
                                </div>
                            </div>
                        ))}
                    </div>
                </div>

                {/* Mobile Rightside Panel */}
                <div className={`fixed right-0 top-0 h-full w-3/4 bg-[#fbf1f1] transform transition-transform duration-300 ease-in-out z-[10000] ${
                    isRightsideOpen ? 'translate-x-0' : 'translate-x-full'
                } pt-24`}>
                    <Rightside isMobile={true} />
                </div>
            </div>

            {/* Desktop Layout */}
            <div className="hidden lg:block">
                <div className="absolute top-[193px] left-[284px] w-[615px] h-[990px] pb-[100px]">
                    <div className="absolute top-[0px] left-[0px] w-[615px] h-[990px]">
                        <div className="absolute top-[-1px] left-[-1px] rounded-xl bg-background-main border-[#efe7e7] border-[1px] border-solid box-border w-[617px] h-[926px]" />
                        
                        {/* Navigation Tabs */}
                        <div className="absolute top-[35px] left-[65px] w-[485px] h-[50px] text-xl text-default-white">
                            <div className="absolute top-[0px] left-[0px] rounded-[12px] bg-[#1e2237] w-[485px] h-[50px]" />
                            <div onClick={() => navigate('/national-leaderboard')} className="absolute top-[12px] left-[34px] font-medium cursor-pointer z-10">
                                National
                            </div>
                            <div onClick={() => navigate('/school-student-leaderboard')} className="absolute top-[12px] left-[195px] font-medium cursor-pointer z-10">
                                School
                            </div>
                            <div onClick={() => navigate('/school-leaderboard')} className="absolute top-[12px] left-[338px] font-medium cursor-pointer z-10">
                                Top Schools
                            </div>
                            <div className="absolute top-[47px] left-[337px] rounded-[10px] bg-primary-gold w-[122px] h-[3px]" />
                        </div>

                        {/* Header */}
                        <div className="absolute h-[4.85%] w-[91.38%] top-[11.72%] right-[4.55%] bottom-[83.43%] left-[4.07%]">
                            <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded-[8px] bg-default-white border-[#d5d5d5] border-[0.6px] border-solid box-border" />
                            <b className="absolute w-[7.85%] top-[calc(50%_-_8px)] left-[6.41%] inline-block">Rank</b>
                            <b className="absolute w-[11.17%] top-[calc(50%_-_8px)] left-[22.6%] inline-block">Schools</b>
                            <b className="absolute w-[9.22%] top-[calc(50%_-_8px)] left-[84.43%] inline-block">Badge</b>
                            <b className="absolute top-[calc(50%_-_8px)] left-[60.5%]">Points</b>
                        </div>

                        {/* Schools List */}
                        <div className="absolute h-[74.65%] w-[91.38%] top-[17.07%] right-[4.55%] bottom-[8.28%] left-[4.07%]">
                            <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] bg-default-white border-[#d5d5d5] border-[0.6px] border-solid box-border" />
                            <div className="absolute w-full top-[calc(50%_-_359.5px)] right-[0%] left-[0%] h-[728px]">
                                {schools.map((school, index) => (
                                    <div key={index} className="absolute w-full h-[57px]" style={{ top: `calc(50% - ${364 - (index * 61)}px)` }}>
                                        <div className="absolute w-[1.07%] top-[calc(50%_-_6.5px)] left-[8.72%] font-semibold inline-block h-[22.3px] opacity-[0.9] mix-blend-normal">
                                            {index + 1}
                                        </div>
                                        <img className="absolute h-[1.93%] w-full top-[98.07%] right-[0%] bottom-[0%] left-[0%] max-w-full overflow-hidden max-h-full mix-blend-normal" alt="" src="/profile/line-2.svg" />
                                        <div className="absolute w-[32.03%] top-[calc(50%_-_7.5px)] left-[22.6%] font-semibold whitespace-pre-wrap inline-block h-[22.3px] opacity-[0.9] mix-blend-normal">
                                            {school.school}
                                        </div>
                                        <div className="absolute w-[6.94%] top-[calc(50%_-_6.5px)] left-[60.5%] font-semibold inline-block h-[22.3px] opacity-[0.9] mix-blend-normal">
                                            {parseFloat(school.total_points).toLocaleString()}
                                        </div>
                                        <img
                                            className="absolute h-[87.72%] w-[8.9%] top-[0%] right-[7.83%] bottom-[12.28%] left-[83.27%] max-w-full overflow-hidden max-h-full object-cover"
                                            alt=""
                                            src={school.school_badge ? `${school.school_badge}` : `/profile/school-${index + 1}.svg`}
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
                <Rightside isMobile={false} />
            </div>

            {/* AI Chat Button */}
            <a href="/aichat" className="fixed bottom-6 right-6 lg:right-auto lg:bottom-6 lg:left-[285px] z-[10000]">
                <img
                    className="w-[50px] h-[50px] lg:w-[75px] lg:h-[75px] object-contain cursor-pointer"
                    alt="AI Chat"
                    src="/home/AIChart.svg"
                />
            </a>
        </div>
    );
};

SchoolsLeaderboard.propTypes = {
    className: PropTypes.string,
};

export default SchoolsLeaderboard;