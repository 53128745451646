import { useCallback, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../components/Button";
import Progress7 from "../../components/Progress7";
import axios from "axios";

const PreferencesScreen4 = () => {
  const navigate = useNavigate();
  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedCountryCode, setSelectedCountryCode] = useState("");
  const [region, setRegion] = useState("");
  const [district, setDistrict] = useState("");
  const [regions, setRegions] = useState([]);
  const [districts, setDistricts] = useState([]);

  const onButtonContainerClick = useCallback(() => {
    navigate("/7-preferencesscreen");
  }, [navigate]);

  const handleGoBack = () => {
    navigate(-1); // This will navigate back to the previous screen
  };

  // Skip
  const handleSkip = () => {
    navigate("/7-preferencesscreen");
  };


  // Fetch countries on component mount
  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await axios.get("https://api.staging.sensebod.com/api/v1/countries/", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        });
        setCountries(response.data);
      } catch (error) {
        console.error("Error fetching countries:", error);
      }
    };

    fetchCountries();
  }, []);

  // Fetch regions based on selected country's short code
  useEffect(() => {
    const fetchRegions = async () => {
      if (!selectedCountryCode) return; // Do not fetch if no country code is selected

      try {
        const response = await axios.get(
          `https://api.staging.sensebod.com/api/v1/regions/?country_short_code=${selectedCountryCode}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            },
          }
        );
        setRegions(response.data);
      } catch (error) {
        console.error("Error fetching regions:", error);
      }
    };

    fetchRegions();
  }, [selectedCountryCode]);

  // Fetch districts based on selected region id
  useEffect(() => {
    const fetchDistricts = async () => {
      if (!region) return; // Do not fetch if no region is selected
      try {
        const response = await axios.get(
          `https://api.staging.sensebod.com/api/v1/districts/?region_id=${region}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            },
          }
        );
        setDistricts(response.data);
      } catch (error) {
        console.error("Error fetching districts:", error);
      }
    };

    fetchDistricts();
  }, [region]);

  const handleCountryChange = (e) => {
    const selectedCountry = countries.find((country) => country.country === e.target.value);
    if (selectedCountry) {
      setSelectedCountry(selectedCountry.country);
      setSelectedCountryCode(selectedCountry.country_short_code);

      // Store country details in localStorage
      localStorage.setItem("country", selectedCountry.country);
      localStorage.setItem("country_short_code", selectedCountry.country_short_code);

      // Reset regions and districts when country changes
      setRegions([]);
      setDistricts([]);
      setRegion("");
      setDistrict("");
      localStorage.removeItem("region_name");
      localStorage.removeItem("region_id");
      localStorage.removeItem("district_name");
    }
  };

  const handleRegionChange = (e) => {
    setRegion(e.target.value);
    const selectedRegion = regions.find(region => region.region_id === Number(e.target.value));
    if (selectedRegion) {
      // Store region details in localStorage
      localStorage.setItem("region_name", selectedRegion.region_name);
      localStorage.setItem("region_id", selectedRegion.region_id);
    }
    setDistrict(""); // Reset selected district
    localStorage.removeItem("district_name"); // Reset district name in localStorage
  };

  const handleDistrictChange = (e) => {
    const selectedDistrictName = e.target.value;
    setDistrict(selectedDistrictName);

    // Store district name in localStorage
    localStorage.setItem("district_name", selectedDistrictName);
  };

  return (
    <div className="min-h-screen w-full relative bg-default-white overflow-x-hidden text-left text-sm text-[#686b6d] font-poppins">
      {/* Background images with responsive positioning */}
      <div className="fixed inset-0 overflow-hidden pointer-events-none">
        <img
          className="absolute top-0 left-1/2 -translate-x-1/2 w-full max-w-[1933px] h-auto object-cover opacity-50"
          alt="Background pattern"
          src="/preference.svg"
        />
        <img
          className="absolute -bottom-16 -left-16 w-[300px] h-[300px] mq750:w-[400px] mq750:h-[400px] mq1280:w-[526px] mq1280:h-[526px] object-contain opacity-30"
          alt="Decorative system"
          src="/easystem-4-1@2x.png"
        />
        <img
          className="absolute -top-16 -right-16 w-[300px] h-[300px] mq750:w-[400px] mq750:h-[400px] mq1280:w-[562px] mq1280:h-[562px] object-contain opacity-30"
          alt="Decorative system"
          src="/easystem-4-22@2x.png"
        />
      </div>

      {/* Skip button - positioned at top right */}
      <div className="absolute top-6 right-6 z-20">
        <div 
          className="text-[#c3beb5] font-medium cursor-pointer hover:text-gray-600"
          onClick={() => navigate("/7-preferencesscreen")}
        >
          Skip
        </div>
      </div>

      {/* Main content container */}
      <div className="relative z-10 max-w-[1200px] mx-auto px-4 py-8 mq750:py-12">
        {/* Progress Bar and Back Button */}
        <div className="w-full max-w-[921px] mx-auto mb-8 flex items-center justify-between">
          <img
            onClick={() => navigate(-1)}
            className="w-8 h-8 cursor-pointer"
            alt="Go Back"
            src="/icon.svg"
          />
          <div className="flex-1 mx-4">
            <Progress7
              progressWidth="100%"
              progressPosition="relative"
              progressHeight="21px"
              progressTop="0"
              progressLeft="0"
              rectangleDivBackgroundColor="rgba(5, 93, 178, 0.1)"
              rectangleDivWidth="20%"
              rectangleDivRight="90%"
              rectangleDivBackgroundColor1="#f09a19"
            />
          </div>
          <div className="text-buttonselect leading-[150%]">2 of 10</div>
        </div>

        {/* Logo */}
        <div className="flex justify-center mb-8">
          <img
            className="w-24 h-24 mq750:w-32 mq750:h-32 object-contain"
            alt="Logo"
            src="/download-1-1@2x.png"
          />
        </div>

        {/* Info Box */}
        <div className="relative mx-auto max-w-[350px] mq750:max-w-[450px] mb-8">
          <img
            className="w-full h-auto"
            alt="Info background"
            src="/vector-1.svg"
          />
          <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-sm text-black font-medium p-4 w-[80%] text-center">
            I need your contact information to keep you updated and connected.
          </div>
        </div>

        {/* Form */}
        <form className="max-w-[370px] mx-auto space-y-6">
          {/* Country Select */}
          <div className="w-full">
            <label className="block text-xs tracking-[-0.01em] leading-[20px] mb-1">
              Country
            </label>
            <div className="relative">
              <select
                value={selectedCountry}
                onChange={handleCountryChange}
                className="w-full h-12 px-4 rounded-xl border border-[#efeefc] text-black leading-tight focus:outline-none focus:border-buttonselect font-poppins text-[14px] bg-white appearance-none"
              >
                <option value="">Select country</option>
                {countries.map((country, index) => (
                  <option key={index} value={country.country}>
                    {country.country}
                  </option>
                ))}
              </select>
              <div className="absolute inset-y-0 right-4 flex items-center pointer-events-none">
                <svg className="w-4 h-4 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
                </svg>
              </div>
            </div>
          </div>

          {/* Region Select */}
          <div className="w-full">
            <label className="block text-xs tracking-[-0.01em] leading-[20px] mb-1">
              Region
            </label>
            <div className="relative">
              <select
                value={region}
                onChange={handleRegionChange}
                className="w-full h-12 px-4 rounded-xl border border-[#efeefc] text-black leading-tight focus:outline-none focus:border-buttonselect font-poppins text-[14px] bg-white appearance-none"
              >
                <option value="">Select region</option>
                {regions.map((regionItem, index) => (
                  <option key={index} value={regionItem.region_id}>
                    {regionItem.region_name}
                  </option>
                ))}
              </select>
              <div className="absolute inset-y-0 right-4 flex items-center pointer-events-none">
                <svg className="w-4 h-4 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
                </svg>
              </div>
            </div>
          </div>

          {/* District Select */}
          <div className="w-full">
            <label className="block text-xs tracking-[-0.01em] leading-[20px] mb-1">
              District
            </label>
            <div className="relative">
              <select
                value={district}
                onChange={handleDistrictChange}
                className="w-full h-12 px-4 rounded-xl border border-[#efeefc] text-black leading-tight focus:outline-none focus:border-buttonselect font-poppins text-[14px] bg-white appearance-none"
              >
                <option value="">Select district</option>
                {districts.map((districtItem, index) => (
                  <option key={index} value={districtItem.district_name}>
                    {districtItem.district_name}
                  </option>
                ))}
              </select>
              <div className="absolute inset-y-0 right-4 flex items-center pointer-events-none">
                <svg className="w-4 h-4 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
                </svg>
              </div>
            </div>
          </div>

          {/* Submit Button */}
          <div className="pt-4">
            <Button
              buttonWidth="100%"
              buttonPosition="relative"
              buttonBackgroundColor="#055db2"
              buttonPadding="16px"
              button="Next"
              buttonFontSize="16px"
              buttonFontWeight="bold"
              buttonFontFamily="Poppins"
              type="submit"
              onButtonContainerClick={() => navigate("/7-preferencesscreen")}
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default PreferencesScreen4;
