import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import LeaderboardPagination from "./LeaderboardPagination";
import TopStudent from "./TopStudent";
import StudentList from "./StudentList";
import { useNavigate } from 'react-router-dom';
import Loader from "../Loader";

const TOP_THREE_RECTANGLES = {
  1: '/profile/rectangle-942.svg',
  2: '/profile/rectangle-941.svg',
  3: '/profile/rectangle-94.svg'
};

const ITEMS_PER_PAGE = 15;
const MOBILE_ITEMS_PER_PAGE = 10;

const currentUsername = localStorage.getItem("username");

const getSchoolAcronym = (schoolName) => {
  if (!schoolName) return 'N/A';
  return schoolName
    .split(' ')
    .map(word => word.charAt(0).toUpperCase())
    .join('');
};

const Leaderboard = ({ className = "" }) => {
  const navigate = useNavigate();
  const [students, setStudents] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [isMobile, setIsMobile] = useState(false);
  const currentUsername = localStorage.getItem("username");

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    
    checkMobile();
    window.addEventListener('resize', checkMobile);
    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  useEffect(() => {
    const fetchStudents = async () => {
      setIsLoading(true);
      const accessToken = localStorage.getItem('accessToken');

      if (!accessToken) {
        setError('Authorization token not found. Please log in again.');
        setIsLoading(false);
        return;
      }

      try {
        const response = await axios.get('https://api.staging.sensebod.com/api/v1/leaderboards/national/', {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        const sortedData = response.data.sort((a, b) => {
          const pointsA = parseFloat(a.total_points);
          const pointsB = parseFloat(b.total_points);
          return pointsB - pointsA;
        });

        const formattedStudents = sortedData.map((student, index) => ({
          position: index + 1,
          name: student.username,
          points: parseFloat(student.total_points),
          avatar: student.picture || '/profile/avatar2@2x.png',
          school: getSchoolAcronym(student.school_name),
          fullSchoolName: student.school_name || 'None',
          schoolBadge: student.school_badge || null,
          ...(index < 3 && { rectangleSrc: TOP_THREE_RECTANGLES[index + 1] })
        }));
        
        setStudents(formattedStudents);
      } catch (error) {
        console.error('Error fetching students:', error);
        if (error.response?.status === 401) {
          setError('Your session has expired. Please log in again.');
        } else {
          setError('Failed to load leaderboard data. Please try again later.');
        }
      } finally {
        setIsLoading(false);
      }
    };

    fetchStudents();
  }, [navigate]);

  const MobileView = () => {
    const userRank = students.findIndex(s => s.name === currentUsername) + 1;
    const totalStudents = students.length;
    const percentage = Math.round((totalStudents - userRank) / totalStudents * 100);
    const topThree = students.slice(0, 3);
  
    return (
      <div className="min-h-screen bg-background-main pb-20">
        {/* Navigation Tabs */}
        <div className="px-4 pt-4">
          <div className="relative">
            <div className="rounded-[12px] bg-[#1e2237] w-[80%] h-[50px] text-[16px] flex items-center justify-between px-8">
              <div
                onClick={() => navigate('/national-leaderboard')}
                className=" font-medium cursor-pointer z-10 text-white"
              >
                National
              </div>
              <div
                onClick={() => navigate('/school-student-leaderboard')}
                className=" font-medium cursor-pointer z-10 text-white/60"
              >
                School
              </div>
              <div
                onClick={() => navigate('/school-leaderboard')}
                className="font-medium cursor-pointer z-10 text-white/60"
              >
                Top Schools
              </div>
            </div>
            <div className="absolute bottom-0 left-7 rounded-[10px] bg-primary-gold w-[85.1px] h-[3px]" />
          </div>
        </div>
  
        {/* Rank Card */}
        <div className="mx-4 mt-6">
          <div className="bg-teal-600/20 rounded-2xl p-4">
            <div className="flex items-center text-[12px] gap-4">
              <div className="bg-teal-500 rounded-2xl w-16 h-16 flex items-center justify-center font-bold text-white">
                #{userRank}
              </div>
              <div className="flex-1">
                <h2 className="text-white font-medium">You are doing better than</h2>
                <p className="text-white font-bold">{percentage}% of other learners!</p>
              </div>
            </div>
          </div>
        </div>
  
        {/* Top 3 Students */}
        <div className="relative h-[350px] mx-4 mt-6">
          {topThree.map((student) => (
            <TopStudent
              key={student.position}
              student={student}
              currentUsername={currentUsername}
              className="mobile"
            />
          ))}
        </div>
  
        {/* Remaining Students List */}
        <div className="px-4 space-y-2 mt-6">
          {students.slice(3).map((student) => (
            <div 
              key={student.position}
              className={`flex items-center justify-between py-3 px-4 rounded-xl 
                ${student.name === currentUsername 
                  ? 'bg-primary-gold opacity-90' 
                  : 'bg-[#1A2237]'
                } transition-colors`}
            >
              <div className="flex items-center gap-3">
                <span className="w-6 text-white font-medium">
                  {student.position}
                </span>
                <img 
                  src={student.avatar} 
                  alt={student.name}
                  className="w-10 h-10 rounded-full"
                />
                <div>
                  <p className="text-white font-medium">
                    {student.name}
                  </p>
                </div>
              </div>
              <div className="flex items-center gap-8">
                <span className={`
                ${student.name === currentUsername 
                  ? 'text-white' 
                  : 'text-primary-gold'
                }
                
                font-medium`}>{student.school}</span>
                <span className="text-gray-300">{student.points.toLocaleString()} pts</span>
              </div>
            </div>
          ))}
        </div>
  
        {/* Mobile Pagination */}
        {students.length > MOBILE_ITEMS_PER_PAGE && (
          <div className="px-4 py-6">
            <LeaderboardPagination
              currentPage={currentPage}
              totalPages={Math.ceil(students.length / MOBILE_ITEMS_PER_PAGE)}
              onPageChange={setCurrentPage}
            />
          </div>
        )}
      </div>
    );
  };

  if (isLoading) return <Loader duration={50000} />;

  if (error) {
    return (
      <div className="min-h-[400px] flex items-center justify-center">
        <div className="text-red-500 text-center p-4 bg-red-100 rounded-lg">
          {error}
        </div>
      </div>
    );
  }

  if (isMobile) {
    return <MobileView />;
  }

  // Desktop View
  const topThreeStudents = students.slice(0, 3).map(student => ({
    ...student,
    rectangleSrc: TOP_THREE_RECTANGLES[student.position]
  }));

  const startIndex = 3;
  const endIndex = Math.min(startIndex + ITEMS_PER_PAGE - 3, students.length);
  const remainingStudents = students.slice(startIndex, endIndex);
  const totalPages = Math.ceil((students.length - 3) / (ITEMS_PER_PAGE - 3));

  return (
    <div
      className={`absolute top-[0px] left-[0px] w-[615px] h-[990px] text-center text-[40px] text-default-white font-poppins ${className}`}
    >
      <div className="absolute top-[-1px] left-[-1px] rounded-xl bg-background-main border-[#efe7e7] border-[1px] border-solid box-border w-[617px] h-[926px]" />
      <div className="absolute top-[35px] left-[65px] w-[485px] h-[50px] text-left text-xl">
        <div className="absolute top-[0px] left-[0px] rounded-[12px] bg-[#1e2237] w-[485px] h-[50px]" />
        <div
          onClick={() => navigate('/national-leaderboard')}
          className="absolute top-[12px] left-[34px] font-medium cursor-pointer z-10"
        >
          National
        </div>
        <div
          onClick={() => navigate('/school-student-leaderboard')}
          className="absolute top-[12px] left-[195px] font-medium cursor-pointer z-10"
        >
          School
        </div>
        <div
          onClick={() => navigate('/school-leaderboard')}
          className="absolute top-[12px] left-[338px] font-medium cursor-pointer z-10"
        >
          Top Schools
        </div>
        <div className="absolute top-[47px] left-[34px] rounded-[10px] bg-primary-gold w-[85.1px] h-[3px]" />
      </div>

      {topThreeStudents.map((student) => (
        <TopStudent
          key={student.position}
          student={student}
          currentUsername={currentUsername}
        />
      ))}

      <StudentList
        students={remainingStudents}
        currentUsername={currentUsername}
      />

      {students.length > ITEMS_PER_PAGE && (
        <LeaderboardPagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={setCurrentPage}
        />
      )}
    </div>
  );
};

Leaderboard.propTypes = {
  className: PropTypes.string,
};

export default Leaderboard;