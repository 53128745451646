import React from 'react';

const FlashCard = ({ front, back, isFlipped, onFlip, showBackContent }) => {
  return (
    <div 
      className="w-full max-w-sm sm:max-w-md h-48 sm:h-64 perspective-1000 cursor-pointer mx-auto font-poppins"
      onClick={onFlip}
    >
      <div 
        className={`relative w-full h-full transition-transform duration-500 transform-style-preserve-3d ${
          isFlipped ? 'rotate-y-180' : ''
        }`}
      >
        {/* Front of the card */}
        <div className="absolute w-full h-full bg-white border border-blue-gray-200 rounded-xl shadow-lg p-4 sm:p-6 backface-hidden mq850:w-[92%]">
          <div className="flex items-center justify-center h-full">
            <p className="text-base sm:text-xl font-semibold text-blue-gray-900 text-center">{front}</p>
          </div>
        </div>

        {/* Back of the card */}
        <div className="absolute w-full h-full bg-yellow-100 border border-yellow-200 rounded-xl shadow-lg p-4 sm:p-6 backface-hidden rotate-y-180 mq850:w-[92%]">
          <div className="flex items-center justify-center h-full">
            <p className="text-sm sm:text-lg text-blue-gray-900 text-center">
              {showBackContent ? back : "Flip to see the answer"}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FlashCard;