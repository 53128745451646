import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import MainNavigation from "../../components/MainNavigation";
import NavBar from "../../components/NavBar";
import Rightside from "../../components/profile/Rightside";
import SettingHeader from "../../components/profile/SettingHeader";
import Loader from "../../components/Loader";

const SettingPreference = () => {
    const navigate = useNavigate();
    const [userData, setUserData] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const [editedPreferences, setEditedPreferences] = useState({});
    const userId = localStorage.getItem("userId");

    useEffect(() => {
        const accessToken = localStorage.getItem("accessToken");
        if (!accessToken) {
            navigate("/login");
        } else {
            fetchUserData(accessToken);
        }
    }, [navigate]);

    const fetchUserData = async (token) => {
        try {
            const response = await axios.get(`https://api.staging.sensebod.com/api/v1/users/${userId}/`, {
                headers: { Authorization: `Bearer ${token}` }
            });
            setUserData(response.data);
            setEditedPreferences(response.data.preferences);
        } catch (error) {
            console.error("Error fetching user data:", error);
        }
    };

    const handleEdit = () => {
        setIsEditing(true);
    };

    const handleCancel = () => {
        setIsEditing(false);
        setEditedPreferences(userData.preferences);
    };

    const handleSave = async () => {
        try {
            const token = localStorage.getItem("accessToken");
            const updatedData = {
                preferences: editedPreferences
            };

            const response = await axios.patch(`https://api.staging.sensebod.com/api/v1/users/${userId}/`, updatedData, {
                headers: { Authorization: `Bearer ${token}` }
            });

            setUserData(response.data);
            setEditedPreferences(response.data.preferences);
            setIsEditing(false);
        } catch (error) {
            console.error("Error updating user preferences:", error);
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setEditedPreferences(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleArrayInputChange = (e, index, field) => {
        const { value } = e.target;
        setEditedPreferences(prevState => ({
            ...prevState,
            [field]: [
                ...prevState[field].slice(0, index),
                value,
                ...prevState[field].slice(index + 1)
            ]
        }));
    };

    if (!userData) return <Loader duration={10000} />;

    return (
        <div className="w-full relative bg-[#fbf1f1] min-h-screen overflow-auto text-left text-xl text-default-white font-poppins">
            
            <NavBar />
            <MainNavigation
                profileClass="left-[-29px] rounded-tl-[0px] rounded-tr-11xl rounded-br-11xl rounded-bl-[0px] bg-buttonselect border-buttonselect border-[2px] border-solid box-border text-default-white"
            />
            <div className="absolute top-[116px] left-[calc(50%_-_59px)] text-[32px] leading-[150%] font-semibold text-[#0d0c0c]">
                Profile
            </div>
            <div className="w-full absolute h-[973px] overflow-hidden text-left text-sm text-default-white font-poppins">
            <div className="absolute top-[122px] left-[285px] flex flex-row items-start justify-start gap-[9px] text-[#92929d] font-menu-menu-1-bold">
                    <div className="flex flex-row items-center justify-start gap-[8px]">
                        <div className="w-[16px] relative h-[16px]">
                            <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded-[2px] opacity-[0]" />
                            <img
                                className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] max-w-full overflow-hidden max-h-full"
                                alt=""
                                src="/profile/16--chevronright.svg"
                            />
                        </div>
                        <div className="flex flex-row items-center justify-center">
                            <div className="relative tracking-[0.01em] leading-[130%] font-semibold">
                                Profiles
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-row items-center justify-start gap-[8px]">
                        <div className="w-[16px] relative h-[16px]">
                            <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded-[2px] opacity-[0]" />
                            <img
                                className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] max-w-full overflow-hidden max-h-full"
                                alt=""
                                src="/profile/16--chevronright.svg"
                            />
                        </div>
                        <div className="flex flex-row items-center justify-center">
                            <div className="relative tracking-[0.01em] leading-[130%] font-semibold">
                                Settings
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-row items-center justify-start gap-[8px]">
                        <div className="w-[16px] relative h-[16px]">
                            <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded-[2px] opacity-[0]" />
                            <img
                                className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] max-w-full overflow-hidden max-h-full"
                                alt=""
                                src="/profile/16--chevronright.svg"
                            />
                        </div>
                        <div className="flex flex-row items-center justify-center">
                            <div className="relative tracking-[0.01em] leading-[130%] font-semibold">
                                Account Details
                            </div>
                        </div>
                    </div>
                </div>
            <SettingHeader preference="text-[#232360]" />
            
            <div className="absolute top-[281px] left-[304px] w-[589px] h-[603px] text-xs text-[#939699]">
                
                <div className="absolute top-[0px] left-[0px] w-[589px] h-[603px]">
                    {/* Learning Goals */}
                    <div className="absolute top-[0px] left-[0px] w-[274px] flex flex-col items-start justify-start gap-[17px]">
                        <div className="self-stretch flex flex-col items-start justify-start">
                            <div className="self-stretch flex flex-col items-start justify-start gap-[4px]">
                                <div className="self-stretch flex flex-row items-center justify-start">
                                    <div className="relative tracking-[-0.01em] leading-[20px]">
                                        Learning Goals
                                    </div>
                                </div>
                                <div className="self-stretch rounded-[12px] bg-[#f5f5f5] border-[#efeefc] border-[1px] border-solid box-border h-[148px] overflow-hidden shrink-0 flex flex-col items-start justify-start py-[12px] px-[16px] text-sm">
                                    {isEditing ? (
                                        editedPreferences.learning_goals.map((goal, index) => (
                                            <input
                                                key={index}
                                                type="text"
                                                value={goal}
                                                onChange={(e) => handleArrayInputChange(e, index, 'learning_goals')}
                                                className="w-full bg-transparent mb-2"
                                            />
                                        ))
                                    ) : (
                                        <div className="relative tracking-[-0.03em] leading-[20px]">
                                            {userData.preferences.learning_goals.join(', ')}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    {/* Focus Subject Areas */}
                    <div className="absolute top-[200px] left-[0px] w-[274px] flex flex-col items-start justify-start gap-[17px]">
                        <div className="self-stretch flex flex-col items-start justify-start">
                            <div className="self-stretch flex flex-col items-start justify-start gap-[4px]">
                                <div className="self-stretch flex flex-row items-center justify-start">
                                    <div className="relative tracking-[-0.01em] leading-[20px]">
                                        Focus Subject Areas
                                    </div>
                                </div>
                                <div className="self-stretch rounded-[12px] bg-[#f5f5f5] border-[#efeefc] border-[1px] border-solid box-border h-[148px] overflow-hidden shrink-0 flex flex-col items-start justify-start py-[12px] px-[16px] text-sm">
                                    {isEditing ? (
                                        editedPreferences.focus_subject_areas.map((subject, index) => (
                                            <input
                                                key={index}
                                                type="text"
                                                value={subject}
                                                onChange={(e) => handleArrayInputChange(e, index, 'focus_subject_areas')}
                                                className="w-full bg-transparent mb-2"
                                            />
                                        ))
                                    ) : (
                                        <div className="relative tracking-[-0.03em] leading-[20px]">
                                            {userData.preferences.focus_subject_areas.join(', ')}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    {/* Daily Target */}
                    <div className="absolute top-[0px] left-[315px] w-[274px] flex flex-col items-start justify-start gap-[17px]">
                        <div className="self-stretch flex flex-col items-start justify-start">
                            <div className="self-stretch flex flex-col items-start justify-start gap-[4px]">
                                <div className="self-stretch flex flex-row items-center justify-start">
                                    <div className="relative tracking-[-0.01em] leading-[20px]">
                                        Daily Target
                                    </div>
                                </div>
                                <div className="self-stretch rounded-[12px] bg-[#f5f5f5] border-[#efeefc] border-[1px] border-solid box-border h-[48px] overflow-hidden shrink-0 flex flex-row items-center justify-between py-[12px] px-[16px] text-sm">
                                    {isEditing ? (
                                        <input
                                            type="text"
                                            name="daily_target"
                                            value={editedPreferences.daily_target || ''}
                                            onChange={handleInputChange}
                                            className="w-full bg-transparent"
                                        />
                                    ) : (
                                        <div className="relative tracking-[-0.03em] leading-[20px]">
                                            {userData.preferences.daily_target}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    {/* Preferred Mentor Guidance */}
                    <div className="absolute top-[100px] left-[315px] w-[274px] flex flex-col items-start justify-start gap-[17px]">
                        <div className="self-stretch flex flex-col items-start justify-start">
                            <div className="self-stretch flex flex-col items-start justify-start gap-[4px]">
                                <div className="self-stretch flex flex-row items-center justify-start">
                                    <div className="relative tracking-[-0.01em] leading-[20px]">
                                        Preferred Mentor Guidance
                                    </div>
                                </div>
                                <div className="self-stretch rounded-[12px] bg-[#f5f5f5] border-[#efeefc] border-[1px] border-solid box-border h-[48px] overflow-hidden shrink-0 flex flex-row items-center justify-between py-[12px] px-[16px] text-sm">
                                    {isEditing ? (
                                        <select
                                            name="preferred_mentor_guidance"
                                            value={editedPreferences.preferred_mentor_guidance || ''}
                                            onChange={handleInputChange}
                                            className="w-full bg-transparent"
                                        >
                                            <option value="BOTH">Both</option>
                                            <option value="AI">AI</option>
                                            <option value="HUMAN">Human</option>
                                        </select>
                                    ) : (
                                        <div className="relative tracking-[-0.03em] leading-[20px]">
                                            {userData.preferences.preferred_mentor_guidance}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    {/* Current Grade */}
                    <div className="absolute top-[200px] left-[315px] w-[274px] flex flex-col items-start justify-start gap-[17px]">
                        <div className="self-stretch flex flex-col items-start justify-start">
                            <div className="self-stretch flex flex-col items-start justify-start gap-[4px]">
                                <div className="self-stretch flex flex-row items-center justify-start">
                                    <div className="relative tracking-[-0.01em] leading-[20px]">
                                        Current Grade
                                    </div>
                                </div>
                                <div className="self-stretch rounded-[12px] bg-[#f5f5f5] border-[#efeefc] border-[1px] border-solid box-border h-[48px] overflow-hidden shrink-0 flex flex-row items-center justify-between py-[12px] px-[16px] text-sm">
                                    {isEditing ? (
                                        <input
                                            type="text"
                                            name="current_grade"
                                            value={editedPreferences.current_grade || ''}
                                            onChange={handleInputChange}
                                            className="w-full bg-transparent"
                                        />
                                    ) : (
                                        <div className="relative tracking-[-0.03em] leading-[20px]">
                                            {userData.preferences.current_grade}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    {/* Notification Preferences */}
                    <div className="absolute top-[300px] left-[315px] w-[274px] flex flex-col items-start justify-start gap-[17px]">
                        <div className="self-stretch flex flex-col items-start justify-start">
                            <div className="self-stretch flex flex-col items-start justify-start gap-[4px]">
                                <div className="self-stretch flex flex-row items-center justify-start">
                                    <div className="relative tracking-[-0.01em] leading-[20px]">
                                        Notification Preferences
                                    </div>
                                </div>
                                <div className="self-stretch rounded-[12px] bg-[#f5f5f5] border-[#efeefc] border-[1px] border-solid box-border h-[48px] overflow-hidden shrink-0 flex flex-row items-center justify-between py-[12px] px-[16px] text-sm">
                                    {isEditing ? (
                                        <select
                                            name="notification_preferences"
                                            value={editedPreferences.notification_preferences || ''}
                                            onChange={handleInputChange}
                                            className="w-full bg-transparent"
                                        >
                                            <option value="ALL">All</option>
                                            <option value="NONE">None</option>
                                            <option value="IMPORTANT">Important Only</option>
                                        </select>
                                    ) : (
                                        <div className="relative tracking-[-0.03em] leading-[20px]">
                                            {userData.preferences.notification_preferences}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                {isEditing ? (
                    <div className="absolute top-[-33px] left-[400px] flex flex-row gap-[17px]">
                        <div className="rounded-xl bg-default-white w-[88px] flex flex-row items-center justify-center py-[12px] px-[0px] box-border cursor-pointer" onClick={handleCancel}>
                            <div className="relative tracking-[-0.01em] leading-[20px] font-semibold">
                                Cancel
                            </div>
                        </div>
                        <div className="rounded-xl bg-buttonselect w-[88px] flex flex-row items-center justify-center py-[12px] px-[0px] box-border text-default-white cursor-pointer" onClick={handleSave}>
                            <div className="relative tracking-[-0.01em] leading-[20px] font-semibold">
                                Save
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="absolute top-[-33px] left-[529px] rounded-[5px] bg-background-main w-[60px] h-[25px] text-[11px] text-default-white cursor-pointer" onClick={handleEdit}>
                        <div className="absolute top-[3px] left-[11px] w-[38px] h-[20px]">
                            <img
                                className="absolute top-[3px] left-[0px] w-[14px] h-[14px] overflow-hidden"
                                alt=""
                                src="/profile/edit.svg"
                            />
                            <div className="absolute top-[0px] left-[18px] tracking-[-0.01em] leading-[20px] font-medium">
                                Edit
                            </div>
                        </div>
                    </div>
                )}

                
            </div>

            <Rightside />

            <a href="/aichat" className="fixed bottom-6 left-[285px] z-[10000]">
                <img
                    className="w-[75px] h-[75px] object-contain cursor-pointer"
                    alt="AI Chat"
                    src="/home/AIChart.svg"
                />
            </a>
        </div>
        </div>
    );
};

export default SettingPreference;