import React, { useState, useEffect } from 'react';
import fetchUserGroups from "../../components/groups/fetchUserGroups";

const SingleGroup = () => {
  const [userGroups, setUserGroups] = useState({});

  useEffect(() => {
    const fetchGroups = async () => {
      try {
        const groups = await fetchUserGroups();
        console.log(groups);
        setUserGroups(groups);
      } catch (error) {
        console.error('Error fetching user groups:', error);
      }
    };
    fetchGroups();
  }, []);

  return (
    <div>
      <h1>Your Groups</h1>
      {Object.values(userGroups).map(group => (
        <div key={group.groupId}>
          <h2>{group.groupName}</h2>
          <h3>Members:</h3>
          <ul>
            {group.members.map(member => (
              <li key={member.uuid}>
                {member.memberUsername} - {member.role} ({member.membershipType})
              </li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  );
};

export default SingleGroup;

