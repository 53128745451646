import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import MainNavigation from "../../components/MainNavigation";
import NavBar from "../../components/NavBar";
import { Input } from "postcss";

const HumanMentor = () => {
  const navigate = useNavigate();



  useEffect(() => {
    // Check if the user is logged in by checking for the accessToken in localStorage
    const accessToken = localStorage.getItem("accessToken");

    // If no accessToken is found, redirect to the login page
    if (!accessToken) {
      navigate("/login"); // Replace with the actual path to your login page
    }
  }, [navigate]);

  return (
    <div className="w-full relative bg-[#fbf1f1] h-[1170px] overflow-hidden text-center text-[6px] text-default-white font-poppins">
      <img
        className="absolute top-[-317px] left-[-399px] w-[1276.7px] h-[1305.2px] object-contain hidden"
        alt=""
        src="/o@2x.png"
      />
      <img
        className="absolute top-[604px] left-[calc(50%_-_984px)] w-[556.7px] h-[556.7px] object-contain hidden"
        alt=""
        src="/easystem-4-1@2x.png"
      />
      <img
        className="absolute top-[-185px] left-[-68px] w-[1933.8px] h-[1738.8px] object-contain hidden"
        alt=""
        src="/o1@2x.png"
      />
      <img
        className="absolute top-[-120px] left-[calc(50%_+_223px)] w-[562px] h-[562px] object-contain hidden"
        alt=""
        src="/easystem-4-2@2x.png"
      />
      <a href="/aichat" className="fixed bottom-6 left-[285px] z-[10000]">
        <img
          className="w-[75px] h-[75px] object-contain cursor-pointer"
          alt="AI Chat"
          src="/home/AIChart.svg"
        />
      </a>
      <NavBar />
      <p className=" absolute text-center text-[#D8D1D1] text-[36px] font-[400] font-[Poppins]
                line-height-[48px] top-[385px] left-[826px]">No Mentor Here</p>

      <div className="flex-col overflow-hidden border-solid h-[200px] cursor-[pointer] w-[300px] "> 

<img
   className=" absolute top-[135px] left-[290px] w-[16px] h-[16px] "
   alt=""
   src="/mentor/Icon (3).svg" 
   />

<p className=" absolute text-center text-[#92929D] text-[14px] font-[600] font-[Mulish]
                line-height-[48px] top-[121px] left-[310px]">Mentors</p>

<img
   className=" absolute top-[135px] left-[370px] w-[16px] h-[16px] "
   alt=""
   src="/mentor/Icon (3).svg" 
   />

<p className=" absolute text-center text-[#92929D] text-[14px] font-[600] font-[Mulish]
                line-height-[48px] top-[121px] left-[395px]">Humans</p>
  
  <img
   className=" absolute top-[135px] left-[455px] w-[16px] h-[16px] "
   alt=""
   src="/mentor/Icon (3).svg" 
   />

<p className=" absolute text-center text-[#92929D] text-[14px] font-[600] font-[Mulish]
                line-height-[48px] top-[121px] left-[480px]">Messages</p>

<p className=" absolute text-center text-[#92929D] text-[14px] font-[400] font-[Poppins]
                line-height-[48px] top-[185px] left-[295px]">Mentors</p>

<p className=" absolute text-center text-[#F09A19] text-[8px] font-[400] font-[Poppins] bg-[#1A3651] w-[45px] h-[15px] rounded-[100px] pt-[3px]
                pr-[3px] line-height-[48px] top-[194px] left-[360px]">0 chats</p>
                
<a href="/Mentor3">
<img
   className=" absolute top-[194px] left-[550px] w-[16px] h-[16px]  bg-[#1A3651] w-[13.5px] h-[13.5px] rounded-[12px] pt-[8px] pl-[8px]
                pr-[8px] pb-[8px]"
   alt=""
   src="/mentor/fe_plus.svg" 
   />      
   </a>
       </div>

<div className="absolute text-gray-400  top-[242px] left-[280px] ">
  <img
     className=" absolute top-[15px] left-[20px] w-[17.2px] h-[17.2px] cursor-[pointer]"
     alt=""
     src="/mentor/search.svg" 
   />   
      <form action="">

      <input
        type="text"
        name="search"
        placeholder="Search... "
        className=" top-[242px] left-[335px]  font-[Poppins] font-[400] bg-[#fffff] w-[260px] h-[41px] pl-[50px]
                 border-none text-[14px] text-[#989BA1] rounded-[100px] gap-[10px]"
        
      />

</form>
   
</div>
<textarea className="absolute rounded-[10px] gap-[1px]
left-[285px] font-[Poppins] text-[15px] pl-[10px] pt-[10px]
bg-[#FFFFFF] h-[350px] w-[290px] border-[none] resize-none outline-none
text-start text-[#707070] top-[320px] "></textarea>





      <MainNavigation 
      homeClass=""
      learningClass=""
      mentorClass="left-[-29px] rounded-tl-[0px] rounded-tr-11xl rounded-br-11xl rounded-bl-[0px] bg-buttonselect border-buttonselect border-[2px] border-solid box-border text-default-white"
      groupsClass="another-custom-class"
      profileClass="custom-profile-class"
    />
    </div>
  );
};

export default HumanMentor;
