import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Dialog } from '@headlessui/react';
import Input from "../components/Input";
import Button from "../components/Button";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faLock, faPhone, faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

const SignupPage = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState("");
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [accessCode, setAccessCode] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [dialogError, setDialogError] = useState("");
  const navigate = useNavigate();

  const handleUsernameChange = (e) => setUsername(e.target.value);
  const handlePasswordChange = (e) => setPassword(e.target.value);
  const handleConfirmPasswordChange = (e) => setConfirmPassword(e.target.value);
  const handlePhoneNumberChange = (e) => setPhoneNumber(e.target.value);
  const handleAccessCodeChange = (e) => setAccessCode(e.target.value);
  const toggleShowPassword = () => setShowPassword(!showPassword);

  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");
    if (accessToken) {
      navigate("/home");
    }
  }, [navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");

    if (!username || !password || !confirmPassword || !phoneNumber) {
      setError("All fields are required.");
      return;
    }

    if (password !== confirmPassword) {
      setError("Passwords do not match.");
      return;
    }

    // Open the access code dialog
    setIsDialogOpen(true);
    setDialogError(""); // Clear any previous dialog errors
  };

  const handleAccessCodeSubmit = async () => {
    setIsLoading(true);
    setDialogError("");
    setError(""); // Clear any previous form errors

    try {
      // Proceed with signup
      const signupResponse = await axios.post("https://api.staging.sensebod.com/api/v1/signup/", {
        username,
        password,
        phone_number: phoneNumber,
        access_code: accessCode,
      });

      if (signupResponse.status === 201) {
        const { access, refresh, user_id } = signupResponse.data;
        localStorage.setItem("accessToken", access);
        localStorage.setItem("refreshToken", refresh);
        localStorage.setItem("userId", user_id);
        localStorage.setItem("username", username);
        navigate("/preference-1");
      } else {
        setDialogError("Signup failed. Please try again.");
      }
    } catch (error) {
      console.error("Error during signup:", error);
      const response = error.response;

      if (response && response.data) {
        if (response.data.access_code) {
          // Specific error for access code
          setDialogError("Invalid or expired access code. Please try again.");
        } else {
          let errorMessage = "";
          if (response.data.username && response.data.username.length > 0) {
            errorMessage += response.data.username[0] + " ";
          }
          if (response.data.phone_number && response.data.phone_number.length > 0) {
            errorMessage += response.data.phone_number[0];
          }
          setError(errorMessage.trim() || "An error occurred. Please try again.");
          setIsDialogOpen(false); // Close the dialog for non-access code errors
        }
      } else if (error.request) {
        setError("No response from the server. Please try again.");
        setIsDialogOpen(false);
      } else {
        setError("An unexpected error occurred. Please try again.");
        setIsDialogOpen(false);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleLoginClick = () => navigate("/");

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
    setDialogError("");
    setAccessCode("");
  };

  return (
    <div className="flex flex-col md:flex-none items-center justify-center w-full min-h-screen md:h-screen bg-default-white text-base text-buttonselect font-poppins md:relative">
      {/* Mobile view */}
      <div className="w-full px-4 py-8 flex flex-col items-center md:hidden">
      {/* <img
          className="absolute top-[16px] w-[300px] h-[90.7px] overflow-hidden"
          alt=""
          src="/sensebody-secondary-logo--1-1.svg"
        /> */}
        <h2 className="text-3xl font-bold mb-8">Create an account</h2>
        <form onSubmit={handleSubmit} className="w-full max-w-[370px]">
          <div className="mb-4">
            <Input
              type="text"
              value={username}
              onChange={handleUsernameChange}
              placeholder="Your username"
              inputWidth="95%"
              icon={faUser}
              iconWidth="24px"
              iconHeight="24px"
            />
          </div>
          <div className="mb-4">
            <Input
              type={showPassword ? "text" : "password"}
              value={password}
              onChange={handlePasswordChange}
              placeholder="Your password"
              inputWidth="95%"
              icon={faLock}
              iconWidth="24px"
              iconHeight="24px"
              toggleIcon={showPassword ? faEyeSlash : faEye}
              onToggleIconClick={toggleShowPassword}
            />
          </div>
          <div className="mb-4">
            <Input
              type={showPassword ? "text" : "password"}
              value={confirmPassword}
              onChange={handleConfirmPasswordChange}
              placeholder="Confirm your password"
              inputWidth="95%"
              icon={faLock}
              iconWidth="24px"
              iconHeight="24px"
              toggleIcon={showPassword ? faEyeSlash : faEye}
              onToggleIconClick={toggleShowPassword}
            />
          </div>
          <div className="mb-4">
            <Input
              type="text"
              value={phoneNumber}
              onChange={handlePhoneNumberChange}
              placeholder="Your phone number"
              inputWidth="95%"
              icon={faPhone}
              iconWidth="24px"
              iconHeight="24px"
            />
          </div>
          {error && <div className="text-red-600 text-center mb-4">{error}</div>}
          <Button
            buttonWidth="100%"
            buttonBackgroundColor="#055db2"
            buttonPadding="16px"
            button="Sign up"
            buttonFontSize="16px"
            buttonFontWeight="bold"
            buttonFontFamily="Poppins"
            type="submit"
          />
        </form>
        <div className="mt-8 w-full max-w-[370px]">
          <div className="flex items-center justify-center mb-4">
            <div className="border-t border-[#e6e6e6] flex-grow"></div>
            <div className="mx-4 text-[#858494]">OR</div>
            <div className="border-t border-[#e6e6e6] flex-grow"></div>
          </div>
          <div className="flex justify-center space-x-4 mb-8">
            <button className="w-[54px] h-[54px] rounded-full bg-default-white shadow-[0px_4px_4px_rgba(0,_0,_0,_0.25)] flex items-center justify-center">
              <img className="w-[40.5px] h-[40.5px] object-cover" alt="" src="/image-1@2x.png" />
            </button>
            <img className="w-[53px] h-[54px]" alt="" src="/group-91.svg" />
          </div>
          <p className="text-center text-xs text-[#9e9c9a]">
            By continuing, you agree to the{' '}
            <span className="font-medium text-primary-gold">Terms of Services</span>
            {' & '}
            <span className="font-medium text-primary-gold">Privacy Policy</span>.
          </p>
        </div>
        <div className="mt-8">
          <Button
            button="Login"
            buttonWidth="200px"
            buttonBackgroundColor="#d9d9d9"
            buttonTextColor="#055DB2"
            buttonPadding="12px"
            buttonFontSize="16px"
            buttonFontWeight="bold"
            buttonFontFamily="Poppins"
            onButtonContainerClick={handleLoginClick}
          />
        </div>
      </div>

      {/* Desktop view (unchanged) */}
      <div className="hidden md:block absolute top-[-5px] w-full h-[947px]">
        <img
          className="absolute top-[-100px] right-[10px] w-[821.9px] h-[821.9px] object-contain"
          alt=""
          src="/easystem-4-11@2x.png"
        />
        <img
          className="absolute top-[208px] right-[calc(50%_-_651px)] w-[801px] h-[739px] object-contain"
          alt=""
          src="/bg-signup.png"
        />
        <div className="absolute top-[36px] opacity-[0.5] right-[20px]">
          <Button
            button="Login"
            buttonWidth="100px"
            buttonBackgroundColor="#d9d9d9"
            buttonTextColor="#055DB2"
            buttonPadding="12px"
            buttonFontSize="16px"
            buttonFontWeight="bold"
            buttonFontFamily="Poppins"
            onButtonContainerClick={handleLoginClick}
          />
        </div>
        <img
          className="absolute top-[160px] right-[420px] w-[300px] h-[90.7px] overflow-hidden"
          alt=""
          src="/sensebody-secondary-logo--1-1.svg"
        />
      </div>
      <div className="hidden md:block absolute max-w-md p-8 top-[-317px] left-[-399px] w-[1276.7px] h-[1305.2px] text-left text-sm text-default-white">
        <div className="absolute top-[0px] left-[0px] w-[1276.7px] h-[1305.2px]">
          <div className="absolute top-[317px] left-[399px] bg-background-main w-[581px] h-[100vh]" />
          <img
            className="absolute top-[0px] left-[0px] w-[1276.7px] h-[1305.2px] object-contain"
            alt=""
            src="/o14@2x.png"
          />
          <b className="absolute top-[397px] left-[calc(50%_-_130px)] text-[40px]">
            Create an account
          </b>

          <form onSubmit={handleSubmit}>
          <Input
            type="text"
            value={username}
            onChange={handleUsernameChange}
            placeholder="Your username"
            inputPosition="absolute"
            inputPadding="16px"
            inputGap="16px"
            inputBottom="740px"
            inputLeft="calc(50% - 139.35px)"
            inputWidth="370px"
            icon={faUser}
            iconWidth="24px"
            iconHeight="24px"
            iconColor="#055db2"
          />
          <Input
            type={showPassword ? "text" : "password"}
            value={password}
            onChange={handlePasswordChange}
            placeholder="Your password"
            icon={faLock}
            inputPosition="absolute"
            inputPadding="16px"
            inputGap="16px"
            inputBottom="640px"
            inputLeft="calc(50% - 139.35px)"
            inputWidth="370px"
            iconWidth="24px"
            iconHeight="24px"
            toggleIcon={showPassword ? faEyeSlash : faEye}
            onToggleIconClick={toggleShowPassword}
            iconColor="#055db2"
          />
          <Input
            type={showPassword ? "text" : "password"}
            value={confirmPassword}
            onChange={handleConfirmPasswordChange}
            placeholder="Confirm your password"
            icon={faLock}
            inputPosition="absolute"
            inputPadding="16px"
            inputGap="16px"
            inputBottom="540px"
            inputLeft="calc(50% - 139.35px)"
            inputWidth="370px"
            iconWidth="24px"
            iconHeight="24px"
            toggleIcon={showPassword ? faEyeSlash : faEye}
            onToggleIconClick={toggleShowPassword}
            iconColor="#055db2"
          />
          <Input
            type="text"
            value={phoneNumber}
            onChange={handlePhoneNumberChange}
            placeholder="Your phone number"
            inputPosition="absolute"
            inputPadding="16px"
            inputGap="16px"
            inputBottom="440px"
            inputLeft="calc(50% - 139.35px)"
            inputWidth="370px"
            icon={faPhone}
            iconWidth="24px"
            iconHeight="24px"
            iconColor="#055db2"
          />

            {error && (
              <div className="text-red-600 absolute top-[890px] w-[300px] left-[550px] text-center mb-4">
                {error}
              </div>
            )}

            <Button
              buttonWidth="370px"
              buttonBackgroundColor="#055db2"
              buttonPadding="16px 133px"
              buttonBottom="unset"
              buttonLeft="calc(50% - 137.35px)"
              buttonTop="966px"
              button="Sign up"
              buttonFontSize="16px"
              buttonFontWeight="bold"
              buttonFontFamily="Poppins"
              type="submit"
            />
          </form>

          <div className="absolute top-[1050px] left-[501px] w-[377px] h-[24px] text-center text-base text-[#858494]">
            <div className="absolute top-[0px] left-[175.2px] leading-[150%] flex items-center justify-center w-[26.5px]">
              OR
            </div>
            <div className="absolute top-[12px] left-[220.2px] border-[#e6e6e6] border-t-[1px] border-solid box-border w-[157.8px] h-[1px]" />
            <div className="absolute top-[12px] left-[0px] border-[#e6e6e6] border-t-[1px] border-solid box-border w-[156.6px] h-[1px]" />
          </div>
          <div className="absolute top-[1100px] left-[614px] w-[152px] h-[55px]">
            <div className="absolute top-[0px] left-[0px] w-[152px] h-[55px]">
              <div className="absolute top-[1px] left-[0px] w-[54px] h-[54px]">
                <div className="absolute top-[0px] left-[0px] shadow-[0px_4px_4px_rgba(0,_0,_0,_0.25)] rounded-[50%] bg-default-white w-[54px] h-[54px]" />
                <img
                  className="absolute top-[6.8px] left-[6.8px] w-[40.5px] h-[40.5px] object-cover"
                  alt=""
                  src="/image-1@2x.png"
                />
              </div>
              <img
                className="absolute top-[0px] left-[99px] w-[53px] h-[54px]"
                alt=""
                src="/group-91.svg"
              />
            </div>
          </div>
          <div className="absolute top-[1207px] left-[526px] leading-[140%] text-center flex items-center w-[327px] font-poppins">
            <span className="w-full">
              <span>
                <span>By continuing, you agree to the</span>
                <span className="font-poppins">{` `}</span>
              </span>
              <span className="font-medium text-primary-gold">
                Terms of Services
              </span>
              <span className="font-poppins">
                <span className="font-medium">{` `}</span>
                <span className="font-poppins">{`&`}</span>
                <span className="font-medium font-poppins">{` `}</span>
              </span>
              <span className="font-medium text-primary-gold">
                Privacy Policy
              </span>
              <span className="font-poppins">.</span>
            </span>
          </div>
        </div>
      </div>

      {/* Access Code Dialog */}
      <Dialog open={isDialogOpen} onClose={handleCloseDialog} className="relative z-50 font-poppins">
        <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
        <div className="fixed inset-0 flex items-center justify-center p-4">
          <Dialog.Panel className="w-full max-w-sm rounded bg-white p-6 relative">
            <button
              onClick={handleCloseDialog}
              className="absolute top-2 right-2 text-red hover:text-gray-600 cursor-pointer"
            >
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="red">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
            <Dialog.Title className="text-lg font-medium leading-6 text-gray-900 mb-2">Enter Access Code</Dialog.Title>
            <Dialog.Description className="text-sm text-gray-500 mb-4">
              Please enter your access code to complete the signup process.
            </Dialog.Description>
            <div className="mt-2">
              <Input
                type="text"
                value={accessCode}
                onChange={handleAccessCodeChange}
                placeholder="Access Code"
                inputWidth="90%"
              />
            </div>
            {dialogError && <div className="text-red-600 text-center mt-2 mb-2">{dialogError}</div>}
            <div className="mt-4">
              <Button
                button={isLoading ? "Loading..." : "Submit"}
                buttonWidth="100%"
                buttonBackgroundColor="#055db2"
                buttonPadding="12px"
                buttonFontSize="16px"
                buttonFontWeight="bold"
                buttonFontFamily="Poppins"
                onButtonContainerClick={handleAccessCodeSubmit}
                disabled={isLoading}
              />
            </div>
          </Dialog.Panel>
        </div>
      </Dialog>
    </div>
  );
};

export default SignupPage;