import { useMemo } from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Input = ({
  className = "",
  inputClassName = "",
  inputPosition,
  inputPadding,
  inputGap,
  inputBottom,
  inputLeft,
  inputWidth,
  inputTop,
  icon,
  iconWidth,
  iconHeight,
  iconColor,
  toggleIconColor,
  placeholder = "Enter text",
  value,
  onChange,
  type = "text",
  toggleIcon,
  onToggleIconClick,
}) => {
  const inputStyle = useMemo(() => {
    return {
      position: inputPosition,
      padding: inputPadding,
      gap: inputGap,
      bottom: inputBottom,
      left: inputLeft,
      top: inputTop,
      width: inputWidth,
    };
  }, [
    inputPosition,
    inputPadding,
    inputGap,
    inputBottom,
    inputLeft,
    inputTop,
    inputWidth,
  ]);

  const iconStyle = useMemo(() => {
    return {
      width: iconWidth,
      height: iconHeight,
      color: iconColor,
    };
  }, [iconWidth, iconHeight, iconColor]);

  const toggleIconStyle = useMemo(() => {
    return {
      width: iconWidth,
      height: iconHeight,
      color: toggleIconColor || iconColor,
    };
  }, [iconWidth, iconHeight, toggleIconColor, iconColor]);

  return (
    <div
      className={`relative rounded-xl bg-default-white border-[#efeefc] border-[2px] border-solid flex flex-row items-center justify-start p-[16px] gap-[16px] ${className}`}
      style={inputStyle}
    >
      {icon && (
        <FontAwesomeIcon
          icon={icon}
          className="w-[24px] h-[24px] overflow-hidden shrink-0"
          style={iconStyle}
        />
      )}
      <input
        type={type}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        className={`flex-1 outline-none border-none bg-transparent text-[#999] text-base font-poppins ${inputClassName}`}
      />
      {toggleIcon && (
        <FontAwesomeIcon
          icon={toggleIcon}
          className="w-[24px] h-[24px] cursor-pointer ml-2"
          style={toggleIconStyle}
          onClick={onToggleIconClick}
        />
      )}
    </div>
  );
};

Input.propTypes = {
  className: PropTypes.string,
  inputClassName: PropTypes.string,
  icon: PropTypes.object,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  type: PropTypes.oneOf(["text", "number", "date", "email", "password"]),

  /** Style props */
  inputPosition: PropTypes.string,
  inputPadding: PropTypes.string,
  inputGap: PropTypes.string,
  inputBottom: PropTypes.string,
  inputLeft: PropTypes.string,
  inputWidth: PropTypes.string,
  inputTop: PropTypes.string,
  iconWidth: PropTypes.string,
  iconHeight: PropTypes.string,
  iconColor: PropTypes.string,
  toggleIconColor: PropTypes.string,

  /** New props for password visibility toggle */
  toggleIcon: PropTypes.object,
  onToggleIconClick: PropTypes.func,
};

export default Input;