import React from 'react';

const StudySessionCard = ({ lastSessionTime = "0hr / 0m" }) => {
  return (
    <div className="
      absolute top-[495px] right-[100px] w-[350px] h-48 
      mq1024:static mq1024:mx-auto mq1024:mt-8
      mq750:w-[90%] mq750:h-auto
      rounded-lg overflow-hidden flex shadow-lg font-[Poppins]
    ">
      {/* Left side with illustration */}
      <div className="w-1/2 bg-[#F09A19] p-4 relative">
        <img 
          src="/group/the-little-things-working@2x.png" 
          alt="Student studying" 
          className="absolute bottom-0 left-0 w-full h-auto"
        />
      </div>
      
      {/* Right side with text */}
      <div className="w-1/2 bg-background-main p-6 flex flex-col justify-center">
        <h2 className="text-white text-[18px] font-bold mb-4">Study Session</h2>
        <div>
          <p className="text-white text-[16px]">Last Session</p>
          <p className="text-white text-[16px] font-semibold">{lastSessionTime}</p>
        </div>
      </div>
    </div>
  );
};

export default StudySessionCard;
