import PropTypes from 'prop-types';
import { useNavigate, useLocation } from 'react-router-dom';

const Rightside = ({ className = '', isMobile = false }) => {
  const navigate = useNavigate();
  const location = useLocation();

  // Function to check if a route is the current path
  const isActive = (path) => location.pathname === path;

  // Handlers for navigation
  const handleNavigate = (path) => () => {
    navigate(path);
  };

  // Mobile styles
  const mobileClasses = {
    container: "w-full h-auto px-6 py-8",
    innerBox: "w-full bg-default-white rounded-xl border-[#efe7e7] border p-6",
    menuContainer: "flex flex-col space-y-8",
    menuItem: "font-semibold text-[15px]",
  };

  // Desktop styles (original)
  const desktopClasses = {
    container: "absolute top-[193px] left-[992px] w-[238px] h-[227.9px] text-left text-[15px] text-[#88888c] font-poppins",
    innerBox: "absolute top-[-1px] left-[-1px] rounded-xl bg-default-white border-[#efe7e7] border-[1px] border-solid box-border w-[240px] h-[229.9px]",
    menuContainer: "absolute top-[41.3px] left-[30px] w-[111px] h-[157.9px]",
  };

  // Common active styles
  const getActiveClass = (path) => {
    return isActive(path) ? 'text-[#272835] font-bold' : '';
  };

  if (isMobile) {
    return (
      <div className={`${mobileClasses.container} ${className}`}>
        <div className={mobileClasses.innerBox}>
          <div className={mobileClasses.menuContainer}>
            <div
              onClick={handleNavigate('/national-leaderboard')}
              className={`${mobileClasses.menuItem} ${
                isActive("/national-leaderboard") ||
                isActive("/school-student-leaderboard") ||
                isActive("/school-leaderboard") 
                  ? 'text-[#272835] font-bold' 
                  : 'text-[#88888c]'
              }`}
            >
              Leaderboard
            </div>
            {/* <div
              onClick={handleNavigate('/achievements')}
              className={`${mobileClasses.menuItem} ${
                isActive('/achievements') 
                  ? 'text-[#272835] font-bold' 
                  : 'text-[#88888c]'
              }`}
            >
              Achievements
            </div> */}
            <div
              onClick={handleNavigate('/statistics')}
              className={`${mobileClasses.menuItem} ${
                isActive('/statistics') 
                  ? 'text-[#272835] font-bold' 
                  : 'text-[#88888c]'
              }`}
            >
              Statistics
            </div>
            <div
              onClick={handleNavigate('/settings')}
              className={`${mobileClasses.menuItem} ${
                isActive('/settings') ||
                isActive('/password') ||
                isActive('/setting-preference') ||
                isActive('/user-preference')
                  ? 'text-[#272835] font-bold' 
                  : 'text-[#88888c]'
              }`}
            >
              Settings
            </div>
          </div>
        </div>
      </div>
    );
  }

  // Desktop view (original layout)
  return (
    <div className={`${desktopClasses.container} ${className}`}>
      <div className={desktopClasses.innerBox} />
      <div className={desktopClasses.menuContainer}>
        <div className="absolute top-[0px] left-[0px] w-[111px] h-[157.9px]">
          <div className="absolute top-[0px] left-[0px] w-[111px] h-[157.9px]">
            <div className="absolute top-[0px] left-[0px] w-[111px] h-[157.9px]">
              <div
                onClick={handleNavigate('/national-leaderboard')}
                className={`absolute top-[0px] left-[0px] font-semibold inline-block w-[98px] h-[24.4px] cursor-pointer ${
                  isActive("/national-leaderboard") ||
                  isActive("/school-student-leaderboard") ||
                  isActive("/school-leaderboard") ? 'text-[#272835] font-bold' : ''
                }`}
              >
                Leaderboard
              </div>
              <div
                // onClick={handleNavigate('/achievements')}
                className={`absolute top-[44.5px] left-[0px] font-semibold inline-block w-[111px] h-[24.4px] cursor-pointer ${
                  isActive('/achievements') ? 'text-[#272835] font-bold' : ''
                }`}
              >
                Achievements
              </div>
              <div
                onClick={handleNavigate('/statistics')}
                className={`absolute top-[89.1px] left-[0px] font-semibold inline-block w-[71px] h-[24.4px] cursor-pointer ${
                  isActive('/statistics') ? 'text-[#272835] font-bold' : ''
                }`}
              >
                Statistics
              </div>
              <div
                onClick={handleNavigate('/settings')}
                className={`absolute top-[133.6px] left-[0px] font-semibold inline-block w-[63px] h-[24.4px] cursor-pointer ${
                  isActive('/settings') ||
                  isActive('/password') ||
                  isActive('/setting-preference') ||
                  isActive('/user-preference')
                  ? 'text-[#272835] font-bold' : ''
                }`}
              >
                Settings
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Rightside.propTypes = {
  className: PropTypes.string,
  isMobile: PropTypes.bool,
};

export default Rightside;