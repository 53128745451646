import React, { useEffect, useState } from "react";

const QuizComplete = () => {
  const [quizResults, setQuizResults] = useState({
    totalPoints: 0,
    correctAnswers: 0,
    incorrectAnswers: 0,
    skippedQuestions: 0,
    userAnswers: [],
  });

  useEffect(() => {
    const storedResults = localStorage.getItem("quizResults");
    if (storedResults) {
      setQuizResults(JSON.parse(storedResults));
    }
  }, []);

  return (
    <div className="w-full min-h-screen bg-background-main text-left text-xs text-default-white font-poppins font-body-xs flex flex-col items-center py-10">
      {/* Top Images */}
      <div className="relative w-full flex justify-center items-center flex-wrap gap-4 mb-10">
        
        <img
          className="w-[640px] h-[645px] object-cover"
          alt=""
          src="/learning/allura-celebrations@2x.png"
        />
      </div>

      {/* Points and Summary */}
      <div className="rounded-xl bg-gradient-to-b from-[#055db2] to-[#14b8b9] w-[503px] h-[183px] flex flex-row items-center justify-center py-8 px-10 box-border gap-8 text-sm text-[#a7e0c4] mb-10">
        <div className="w-[70px] relative h-[70px] overflow-hidden shrink-0">
          <div className="absolute top-[28px] left-[30px] tracking-[-0.4px]">
            /{quizResults.userAnswers.length}
          </div>
          <div className="absolute top-[26px] left-[18px] text-xl leading-[16px] font-medium text-default-white">
            {quizResults.correctAnswers}
          </div>
          <div className="absolute top-[0px] left-[0px] rounded-[50%] border-[#5bc691] border-[6px] border-solid box-border w-[70px] h-[70px]" />
        </div>
        <div className="flex-1 flex flex-col items-center justify-center gap-4 text-[64px]">
          <div className="self-stretch relative leading-[24px] font-semibold">
            <span>+{quizResults.totalPoints}</span>
            <span className="text-base text-default-white"> Points</span>
          </div>
          <div className="self-stretch relative text-base leading-[16px] font-medium font-poppins text-default-white">
            You answered {quizResults.correctAnswers} out of {quizResults.userAnswers.length} questions correctly
          </div>
        </div>
      </div>

      {/* Quiz Summary */}
      <div className="w-[444px] text-[15px] text-[#858494] font-poppins mb-10">
        <div className="grid grid-cols-2 gap-8 text-center">
          <div className="flex flex-col items-center">
            <div className="tracking-[0.04em] leading-[150%] uppercase font-medium">
              CORRECT ANSWER
            </div>
            <div className="text-[32px] leading-[140%] font-medium text-default-white">
              {quizResults.correctAnswers} questions
            </div>
          </div>
          <div className="flex flex-col items-center">
            <div className="tracking-[0.04em] leading-[150%] uppercase font-medium">
              COMPLETION
            </div>
            <div className="text-[32px] leading-[140%] font-medium text-default-white">
              {Math.round((quizResults.correctAnswers / quizResults.userAnswers.length) * 100)}%
            </div>
          </div>
          <div className="flex flex-col items-center">
            <div className="tracking-[0.04em] leading-[150%] uppercase font-medium">
              Skipped
            </div>
            <div className="text-xl leading-[140%] font-medium font-poppins text-default-white">
              {quizResults.skippedQuestions}
            </div>
          </div>
          <div className="flex flex-col items-center">
            <div className="tracking-[0.04em] leading-[150%] uppercase font-medium">
              INCORRECT ANSWER
            </div>
            <div className="text-xl leading-[140%] font-medium font-poppins text-default-white">
              {quizResults.incorrectAnswers}
            </div>
          </div>
        </div>
      </div>

      {/* User's Answers */}
      <div className="w-full md:w-[696px] flex flex-col items-center text-center mb-10">
        <b className="text-[32px] text-primary-gold">Your answers</b>
        <div className="text-xl text-default-white">
          Congratulations! You have completed this Quiz!
        </div>
      </div>

      {/* Display each question and user's answer */}
      {quizResults.userAnswers.map((answer, index) => (
        <div
          key={index}
          className="w-[35%] md:w-[679px] bg-[#0e1d2c] rounded-[12px] p-4 mb-4 shadow-md"
        >
          <div className="flex items-center justify-between mb-2">
            <div className="font-semibold">{`Question ${index + 1}`}</div>
            <img
              className="w-[14px] h-[14px] hidden"
              alt=""
              src="/learning/report.svg"
            />
          </div>
          <div className="border-t border-[#4f257b] my-2" />
          <div className="flex flex-col gap-2 text-[rgba(255,255,255,0.8)]">
            {answer.options.map((option, idx) => (
              <div
                key={idx}
                className={`px-4 py-2 rounded-[10px] ${
                  option === answer.selectedOption
                    ? option === answer.correctAnswer
                      ? "bg-[#14b8b9]"
                      : "bg-[#df4559]"
                    : "bg-[rgba(20,184,185,0.2)]"
                }`}
              >
                <div className="text-sm text-default-white">{option}</div>
              </div>
            ))}
          </div>
        </div>
      ))}

      {/* Action Buttons */}
      <div className="flex flex-row items-center justify-center gap-4 mt-10">
        <button className="bg-primary-gold text-[#361757] py-2 px-4 rounded-[10px] font-semibold shadow-sm">
          Retry
        </button>
        <button className="bg-buttonselect text-default-white py-2 px-4 rounded-[10px] font-semibold shadow-sm">
          Continue Lesson
        </button>
      </div>
    </div>
  );
};

export default QuizComplete;
