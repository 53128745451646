import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import MainNavigation from "../../components/MainNavigation";
import NavBar from "../../components/NavBar";

const HumanMentors = ({ className = "" }) => {
  const navigate = useNavigate();
  const [messages, setMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState('');
  const [isTyping, setIsTyping] = useState(false);
  const [showChatList, setShowChatList] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedChat, setSelectedChat] = useState(null);
  const messagesEndRef = useRef(null);

  // Mock data for chat list
  const [chatList, setChatList] = useState([
    {
      id: 1,
      name: "Daphne Cole",
      lastMessage: "History, Government, Literature ...",
      avatar: "/mentor/Avatar.svg",
      lastMessageTime: "1m"
    },
    {
      id: 2,
      name: "John Doe",
      lastMessage: "Mathematics, Physics ...",
      avatar: "/mentor/Avatar.svg",
      lastMessageTime: "5m"
    },
    {
      id: 3,
      name: "Jane Smith",
      lastMessage: "Biology, Chemistry ...",
      avatar: "/mentor/Avatar.svg",
      lastMessageTime: "10m"
    }
  ]);

  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");
    if (!accessToken) {
      navigate("/login");
    }
    // Set the first chat as selected by default
    if (chatList.length > 0 && !selectedChat) {
      setSelectedChat(chatList[0]);
    }
  }, [navigate, chatList, selectedChat]);

  const sendMessage = () => {
    if (inputMessage.trim()) {
      setMessages(prevMessages => [...prevMessages, {
        uuid: Date.now(),
        sender: "You",
        text: inputMessage.trim(),
        timestamp: new Date().toISOString()
      }]);
      setInputMessage('');
      setIsTyping(true);
      // Simulate response after 2 seconds
      setTimeout(() => {
        setIsTyping(false);
        setMessages(prevMessages => [...prevMessages, {
          uuid: Date.now(),
          sender: selectedChat ? selectedChat.name : "Mentor",
          text: "This is a simulated response.",
          timestamp: new Date().toISOString()
        }]);
      }, 2000);
    }
  };

  const parseMessageContent = (content) => {
    // Implement parsing logic if needed
    return content;
  };

  const toggleChatList = () => {
    setShowChatList(!showChatList);
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const filteredChatList = chatList.filter(chat =>
    chat.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
    chat.lastMessage.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleChatSelect = (chat) => {
    setSelectedChat(chat);
    setMessages([]); // Clear messages when switching chats
  };

  return (
    <div className="w-full relative bg-[#fbf1f1] min-h-screen overflow-hidden text-center text-xs md:text-[6px] text-default-white font-poppins">
      <NavBar />
      
      <p className="hidden md:block absolute text-center text-[#D8D1D1] text-[36px] font-[400] font-[Poppins] line-height-[48px] top-[385px] left-[826px]">No Mentor Here</p>

      {/* <a href="/aichat" className="fixed bottom-6 left-4 md:left-[285px] z-[10000]">
        <img
          className="w-[50px] h-[50px] md:w-[75px] md:h-[75px] object-contain cursor-pointer"
          alt="AI Chat"
          src="/home/AIChart.svg"
        />
      </a> */}

      <div className="flex flex-col md:flex-row px-4 md:px-0 mt-20 md:mt-0">
        {/* Sidebar */}
        <div className="w-full md:w-[300px] mb-6 md:mb-0">
          <div className="flex justify-between items-center mb-4 md:mb-0 md:block">
            <div className="flex space-x-4 md:space-x-0 md:block">
              <div className="flex items-center md:absolute md:top-[135px] md:left-[290px]">
                <img className="w-4 h-4 md:w-[16px] md:h-[16px]" alt="" src="/mentor/Icon (3).svg" />
                <p className="ml-2 text-[#92929D] text-sm font-semibold md:absolute md:top-[-14px] md:left-[20px]">Mentors</p>
              </div>
              <div className="flex items-center md:absolute md:top-[135px] md:left-[370px]">
                <img className="w-4 h-4 md:w-[16px] md:h-[16px]" alt="" src="/mentor/Icon (3).svg" />
                <p className="ml-2 text-[#92929D] text-sm font-semibold md:absolute md:top-[-14px] md:left-[25px]">Humans</p>
              </div>
              <div className="flex items-center md:absolute md:top-[135px] md:left-[455px]">
                <img className="w-4 h-4 md:w-[16px] md:h-[16px]" alt="" src="/mentor/Icon (3).svg" />
                <p className="ml-2 text-[#92929D] text-sm font-semibold md:absolute md:top-[-14px] md:left-[25px]">Messages</p>
              </div>
            </div>
          </div>

          <div className="flex justify-between items-center mb-4 md:block">
            <p className="text-[#92929D] text-sm font-medium md:absolute md:top-[185px] md:left-[295px]">Mentors</p>
            <div className="flex items-center">
              <button 
                onClick={toggleChatList}
                className="text-[#F09A19] text-xs font-normal bg-[#1A3651] px-2 py-1 rounded-full md:absolute md:top-[194px] md:left-[360px] cursor-pointer"
              >
                {chatList.length} chats
              </button>
              <a href="/Chat" className="ml-4 md:ml-0">
                <img className="w-6 h-6 bg-[#1A3651] rounded-full p-1 md:absolute md:top-[194px] md:left-[550px]" alt="" src="/mentor/fe_plus.svg" />
              </a>
            </div>
          </div>

          <div className="relative mb-4 md:mb-0 md:absolute md:top-[242px] md:left-[280px]">
            <img className="absolute top-3 left-3 w-5 h-5 md:w-[17.2px] md:h-[17.2px]" alt="" src="/mentor/search.svg" />
            <input 
              type="text" 
              name="search" 
              placeholder="Search... " 
              className="w-full mq850:w-[90%] md:w-[260px] h-10 md:h-[41px] pl-10 border-none text-sm text-[#989BA1] rounded-full bg-white"
              value={searchQuery}
              onChange={handleSearchChange}
            />
          </div>

          {showChatList && (
            <div className="w-full md:w-[310px] rounded-[10px] bg-default-white h-[400px] overflow-y-auto shrink-0 flex flex-col items-start justify-start md:absolute md:top-[310px] md:left-[285px]">
              {filteredChatList.map((chat) => (
                <div 
                  key={chat.id} 
                  className={`w-full md:w-[290px] ${selectedChat && selectedChat.id === chat.id ? 'bg-[#2B4E72]' : 'bg-[#1A3651]'} mb-[20px] overflow-hidden flex flex-col items-start justify-start py-[15px] cursor-pointer px-[20px]`}
                  onClick={() => handleChatSelect(chat)}
                >
                  <div className="self-stretch flex flex-col items-start justify-start gap-[16px]">
                    <div className="self-stretch flex flex-row items-start justify-between">
                      <div className="flex flex-row items-start justify-start gap-[8px]">
                        <img className="w-[32px] relative rounded-[50%] h-[32px] object-cover" alt="Group Avatar" src={chat.avatar}/>
                        <div className="flex flex-col items-start justify-start">
                          <div className="relative font-[Poppins] text-[14px] text-white">{chat.name}</div>
                          <div className="relative text-[8px] font-[400px] font-[Poppins] text-white">{chat.lastMessage}</div>
                        </div>
                      </div>
                      <div className="relative text-[8px] font-[400px] font-[Poppins] text-white">{chat.lastMessageTime}</div>
                    </div>
                    <div className="relative text-[10px] font-[400px] font-[Poppins] text-white cursor-pointer">....</div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>

        {/* Chat Area */}
        <div className={`w-full mq750:mb-[20px] md:w-[654px] md:h-[610px] md:absolute md:top-[180px] md:left-[650px] text-left text-base md:text-[15px] text-[#0c092a] font-poppins ${className}`}>
          <div className="bg-white rounded-lg md:rounded-sm border border-[#b9b9b9] h-[calc(100vh-200px)] md:h-full flex flex-col">
           
            <div className="p-4 md:p-0 md:absolute md:top-[18px] md:left-[27px] flex items-center">
              <img
                className="w-8 h-8 md:w-[32px] md:h-[32px] rounded-full mr-3"
                alt=""
                src={selectedChat ? selectedChat.avatar : "/mentor/Avatar.svg"}
              />
              <div>
                <div className="font-medium">{selectedChat ? selectedChat.name : "Select a chat"}</div>
                <div className="text-xs text-[#858494]">
                  {selectedChat ? "Online" : ""}
                </div>
              </div>
            </div>

            {/* Messages display area */}
            <div className="flex-grow overflow-y-auto p-4 md:absolute md:top-[80px] md:left-[20px] md:right-[20px] md:bottom-[120px]">
              {messages.map((msg) => (
                <div key={msg.uuid} className={`mb-4 flex ${msg.sender === "You" ? "justify-end" : "justify-start"}`}>
                  <div className={`p-3 rounded-lg max-w-[70%] ${msg.sender === "You" ? "bg-[#1e3a5f] text-white" : "bg-[#f0f0f0] text-[#0c092a]"}`}>
                    <p className="mb-2">{parseMessageContent(msg.text)}</p>
                    <div className={`text-xs text-right ${msg.sender === "You" ? "text-[#ffa500]" : "text-[#858494]"}`}>
                      {new Date(msg.timestamp).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                    </div>
                  </div>
                </div>
              ))}
              {isTyping && (
                <div className="mb-4 flex justify-start">
                  <div className="p-3 rounded-lg bg-[#f0f0f0] text-[#0c092a]">
                    <p className="mb-2">{selectedChat ? `${selectedChat.name} is typing...` : "Mentor is typing..."}</p>
                  </div>
                </div>
              )}
              <div ref={messagesEndRef} />
            </div>

            {/* Input area for typing messages */}
            <div className="p-4 md:p-0 md:absolute md:w-full md:bottom-[0] md:h-[64px]">
              <div className="flex items-center bg-white border-t border-[#b9b9b9] md:border-none">
                <input
                  type="text"
                  className="flex-grow p-2 bg-transparent outline-none"
                  placeholder="Type a message"
                  value={inputMessage}
                  onChange={(e) => setInputMessage(e.target.value)}
                  onKeyPress={(e) => e.key === 'Enter' && sendMessage()}
                />
                <div className="w-10 h-10 rounded-full bg-buttonselect flex items-center justify-center cursor-pointer" onClick={sendMessage}>
                  <img
                    className="w-5 h-5"
                    alt="Send"
                    src="/group/send.svg"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <MainNavigation 
        homeClass=""
        learningClass=""
        mentorClass="left-[-29px] rounded-tl-[0px] rounded-tr-11xl rounded-br-11xl rounded-bl-[0px] bg-buttonselect border-buttonselect border-[2px] border-solid box-border text-default-white"
        groupsClass="another-custom-class"
        profileClass="custom-profile-class"
      />
    </div>
  );
};

export default HumanMentors;