import React, { useState, useCallback, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Button from "../../components/Button";
import Progress7 from "../../components/Progress7";
import Loader from "../../components/Loader";

const PreferencesScreen = () => {
  const navigate = useNavigate();

  const [notificationOptions, setNotificationOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  // Function to fetch notification preferences from the API
  const fetchNotificationPreferences = async () => {
    try {
      setIsLoading(true);
      const token = localStorage.getItem("accessToken"); // Retrieve the token from localStorage

      const response = await axios.get(
        "https://api.staging.sensebod.com/api/v1/preference/notification-preference/",
        {
          headers: {
            Authorization: `Bearer ${token}`, // Include the token in the request headers
          },
        }
      );
      setNotificationOptions(response.data); // Assuming the API returns an array of notification preferences
      console.log("Notification Preferences", response.data);
    } catch (error) {
      console.error("Error fetching notification preferences:", error);
    }
    finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchNotificationPreferences();
  }, []); // Fetch notification preferences when the component mounts

  const onButtonContainerClick = useCallback(() => {
    // Retrieve the existing preferencesData from localStorage
    const existingPreferences = JSON.parse(localStorage.getItem("preferencesData")) || {};

    // Add the selected notification option to the preferencesData
    const updatedPreferences = {
      ...existingPreferences,
      notification_preference: selectedOption,
    };

    // Store the updated preferencesData back in localStorage
    localStorage.setItem("preferencesData", JSON.stringify(updatedPreferences));

    // Navigate to the next screen
    navigate("/14-preferencesscreen");
  }, [selectedOption, navigate]);

  const handleGoBack = () => {
    navigate(-1); // This will navigate back to the previous screen
  };

  // Skip
  const handleSkip = () => {
    navigate("/6-preferencesscreen");
  };

  const handleOptionSelect = (option) => {
    setSelectedOption(option.value); // Use the value as the selected option
  };

  if (isLoading) {
    return <Loader duration={50000} />;
  }

  return (
    <div className="min-h-screen w-full relative bg-default-white overflow-x-hidden text-left text-sm text-black font-poppins">
      {/* Background images with responsive positioning */}
      <div className="fixed inset-0 overflow-hidden pointer-events-none">
        <img
          className="absolute top-0 left-1/2 -translate-x-1/2 w-full max-w-[1933px] h-auto object-cover opacity-50"
          alt="Background pattern"
          src="/preference.svg"
        />
        <img
          className="absolute -bottom-16 -left-16 w-[300px] h-[300px] mq750:w-[400px] mq750:h-[400px] mq1280:w-[526px] mq1280:h-[526px] object-contain opacity-30"
          alt="Decorative system"
          src="/easystem-4-1@2x.png"
        />
        <img
          className="absolute -top-16 -right-16 w-[300px] h-[300px] mq750:w-[400px] mq750:h-[400px] mq1280:w-[562px] mq1280:h-[562px] object-contain opacity-30"
          alt="Decorative system"
          src="/easystem-4-22@2x.png"
        />
      </div>

      {/* Skip button - positioned at top right */}
      <div className="absolute top-6 right-6 z-20">
        <div 
          className="text-[#c3beb5] font-medium cursor-pointer hover:text-gray-600"
          onClick={() => navigate("/home")}
        >
          Skip
        </div>
      </div>

      {/* Main content container */}
      <div className="relative z-10 max-w-[1200px] mx-auto px-4 py-8 mq750:py-12">
        {/* Progress Bar and Back Button */}
        <div className="w-full max-w-[921px] mx-auto mb-8 flex items-center justify-between">
          <img
            onClick={() => navigate(-1)}
            className="w-8 h-8 cursor-pointer"
            alt="Go Back"
            src="/icon.svg"
          />
          <div className="flex-1 mx-4">
            <Progress7
              progressWidth="100%"
              progressPosition="relative"
              progressHeight="21px"
              progressTop="0"
              progressLeft="0"
              rectangleDivBackgroundColor="rgba(5, 93, 178, 0.1)"
              rectangleDivWidth="90%"
              rectangleDivRight="10%"
              rectangleDivBackgroundColor1="#f09a19"
            />
          </div>
          <div className="text-buttonselect leading-[150%]">9 of 10</div>
        </div>

        {/* Logo */}
        <div className="flex justify-center mb-8">
          <img
            className="w-24 h-24 mq750:w-32 mq750:h-32 object-contain"
            alt="Logo"
            src="/download-1-1@2x.png"
          />
        </div>

        {/* Info Box */}
        <div className="relative mx-auto max-w-[350px] mq750:max-w-[450px] mb-12">
          <img
            className="w-full h-auto"
            alt="Info background"
            src="/vector-1.svg"
          />
          <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-sm text-black font-medium p-4 w-[80%] text-center">
            Choose your notification preferences and stay motivated.
          </div>
        </div>

        {/* Notification Options Grid */}
        <div className="max-w-[770px] mx-auto">
          <div className="grid grid-cols-2 mq750:grid-cols-2 gap-4">
            {notificationOptions.map((option, index) => (
              <div
                key={index}
                onClick={() => handleOptionSelect(option)}
                className={`
                  cursor-pointer p-5 rounded-xl border-2 border-solid transition-all duration-200
                  hover:shadow-md relative
                  ${selectedOption === option.value 
                    ? 'bg-green-50 border-green-200' 
                    : 'bg-white border-[#EFEEFC] hover:border-buttonselect'
                  }
                `}
              >
                <div className="flex items-center justify-between gap-3">
                  <div>
                    <div className="font-semibold tracking-[-0.03em] leading-[20px] text-[#0c092a]">
                      {option.display_name}
                    </div>
                    {/* You can add a description here if the API provides one */}
                  </div>
                  {selectedOption === option.value && (
                    <svg 
                      className="w-5 h-5 text-green-600 flex-shrink-0" 
                      fill="none" 
                      stroke="currentColor" 
                      viewBox="0 0 24 24"
                    >
                      <path 
                        strokeLinecap="round" 
                        strokeLinejoin="round" 
                        strokeWidth="2" 
                        d="M5 13l4 4L19 7"
                      />
                    </svg>
                  )}
                </div>
              </div>
            ))}
          </div>

          {/* Next Button */}
          <div className="sticky bottom-4 mt-8 max-w-[370px] mx-auto">
            <Button
              buttonWidth="100%"
              buttonPosition="relative"
              buttonBackgroundColor="#055db2"
              buttonPadding="16px"
              button="Next"
              buttonFontSize="16px"
              buttonFontWeight="bold"
              buttonFontFamily="Poppins"
              onButtonContainerClick={onButtonContainerClick}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PreferencesScreen;
