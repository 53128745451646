import { useMemo } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

const Button = ({
  
  className = "",
  link,
  clickHere,
  buttonPosition,
  buttonBorderRadius,
  buttonBackgroundColor,
  buttonHeight,
  buttonPadding,
  buttonTop,
  buttonLeft,
  buttonWidth,
  clickHereFontSize,
  clickHereFontFamily,
}) => {
  const navigate = useNavigate();
  const buttonStyle = useMemo(() => {
    return {
      position: buttonPosition,
      borderRadius: buttonBorderRadius,
      backgroundColor: buttonBackgroundColor,
      height: buttonHeight,
      padding: buttonPadding,
      top: buttonTop,
      left: buttonLeft,
      width: buttonWidth,
    };
  }, [
    buttonPosition,
    buttonBorderRadius,
    buttonBackgroundColor,
    buttonHeight,
    buttonPadding,
    buttonTop,
    buttonLeft,
    buttonWidth,
  ]);

  const clickHereStyle = useMemo(() => {
    return {
      fontSize: clickHereFontSize,
      fontFamily: clickHereFontFamily,
    };
  }, [clickHereFontSize, clickHereFontFamily]);

  return (
    <div
      className={`relative rounded-lg bg-light-primary-color-50 h-12 flex flex-row items-center justify-center py-3.5 px-6 box-border text-center text-base text-default-white font-captions-captions-bold ${className}`}
      style={buttonStyle}
    >
      <b
        className="relative tracking-[0.01em] leading-[120%] cursor-pointer"
        style={clickHereStyle}
        onClick={() => navigate(link)}
      >
        {clickHere}
      </b>
    </div>
  );
};

Button.propTypes = {
  className: PropTypes.string,
  clickHere: PropTypes.string,
  link: PropTypes.string,

  /** Style props */
  buttonPosition: PropTypes.any,
  buttonBorderRadius: PropTypes.any,
  buttonBackgroundColor: PropTypes.any,
  buttonHeight: PropTypes.any,
  buttonPadding: PropTypes.any,
  buttonTop: PropTypes.any,
  buttonLeft: PropTypes.any,
  buttonWidth: PropTypes.any,
  clickHereFontSize: PropTypes.any,
  clickHereFontFamily: PropTypes.any,
};

export default Button;
