import axios from 'axios';

const fetchUserGroups = async () => {

  const userId = localStorage.getItem('userId'); 



  try {
    const accessToken = localStorage.getItem("accessToken");
    if (!accessToken) {
      throw new Error('Access token is missing');
    }

    const response = await axios.get('https://api.staging.sensebod.com/api/v1/groups/membership/', {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      }
    });

    if (response.status === 200) {
      console.log("Data",response.data);
      // Filter the data to only include groups where the user is a member
      const userGroups = response.data.filter(item => item.member === userId);

      // Group the data by group
      const groupedData = userGroups.reduce((acc, item) => {
        if (!acc[item.group]) {
          acc[item.group] = {
            groupId: item.group,
            groupName: item.group_name,
            members: []
          };
        }
        acc[item.group].members.push({
          uuid: item.uuid,
          memberUsername: item.member_username,
          role: item.role,
          dateJoined: item.date_joined,
          membershipType: item.membership_type
        });
        return acc;
      }, {});

      return groupedData;
    } else {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
  } catch (error) {
    console.error('Error fetching groups:', error);
    throw error;
  }
};

export default fetchUserGroups;