import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

const QuizQuestions = () => {
  const questions = [
    {
      question: "Lorem Ipsum Dolar Sit",
      options: ["Lorem", "Ipsum", "Dolar", "Sitar"],
      correctAnswer: "Dolar",
      points: 10,
    },
    {
      question: "Another Question?",
      options: ["Option 1", "Option 2", "Option 3", "Option 4"],
      correctAnswer: "Option 1",
      points: 10,
    },
    {
      question: "Yet Another Question?",
      options: ["Option A", "Option B", "Option C", "Option D"],
      correctAnswer: "Option C",
      points: 10,
    },
    {
      question: "Lorem Ipsum Dolar Sit",
      options: ["Lorem", "Ipsum", "Dolar", "Sitar"],
      correctAnswer: "Dolar",
      points: 10,
    },
    {
      question: "Another Question?",
      options: ["Option 1", "Option 2", "Option 3", "Option 4"],
      correctAnswer: "Option 2",
      points: 10,
    },
    {
      question: "Yet Another Question?",
      options: ["Option A", "Option B", "Option C", "Option D"],
      correctAnswer: "Option A",
      points: 10,
    },
    // Add more questions as needed
    {
      question: "Final Question?",
      options: ["Answer A", "Answer B", "Answer C", "Answer D"],
      correctAnswer: "Answer D",
      points: 10,
    }
  ];

  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [selectedOption, setSelectedOption] = useState(null);
  const [totalUserPoints, setTotalUserPoints] = useState(0);
  const [timeLeft, setTimeLeft] = useState(300); // 5 minutes in seconds
  const [userAnswers, setUserAnswers] = useState([]);
  const navigate = useNavigate();

  const currentQuestion = questions[currentQuestionIndex];

  useEffect(() => {
    // Countdown timer effect
    const timer = setInterval(() => {
      setTimeLeft((prevTime) => {
        if (prevTime <= 1) {
          clearInterval(timer);
          handleQuizComplete();
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000);

    return () => clearInterval(timer); // Cleanup on component unmount
  }, [timeLeft]);

  const handleOptionClick = (option) => {
    setSelectedOption(option);
  };

  const handleConfirmClick = () => {
    const isCorrect = selectedOption === currentQuestion.correctAnswer;
    if (isCorrect) {
      setTotalUserPoints((prevPoints) => prevPoints + currentQuestion.points);
    }

    // Store user's answer
    setUserAnswers((prevAnswers) => [
      ...prevAnswers,
      {
        question: currentQuestion.question,
        options: currentQuestion.options,
        selectedOption,
        correctAnswer: currentQuestion.correctAnswer,
      },
    ]);

    // Reset selection and navigate to the next question
    setSelectedOption(null);
    setTimeLeft(300); // Reset timer for the next question

    if (currentQuestionIndex < questions.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    } else {
      handleQuizComplete();
    }
  };

  const handleQuizComplete = () => {
    // Save quiz results to localStorage
    const quizResults = {
      totalPoints: totalUserPoints + (selectedOption === currentQuestion.correctAnswer ? currentQuestion.points : 0),
      correctAnswers: userAnswers.filter(answer => answer.selectedOption === answer.correctAnswer).length + (selectedOption === currentQuestion.correctAnswer ? 1 : 0),
      incorrectAnswers: userAnswers.filter(answer => answer.selectedOption !== answer.correctAnswer).length + (selectedOption !== currentQuestion.correctAnswer ? 1 : 0),
      skippedQuestions: questions.length - userAnswers.length - 1, // Consider current question not skipped
      userAnswers: [
        ...userAnswers,
        {
          question: currentQuestion.question,
          options: currentQuestion.options,
          selectedOption,
          correctAnswer: currentQuestion.correctAnswer,
        },
      ],
    };
    localStorage.setItem("quizResults", JSON.stringify(quizResults));
    navigate("/quiz-complete");
  };

  // Format timeLeft in MM:SS format
  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes.toString().padStart(2, "0")}:${remainingSeconds.toString().padStart(2, "0")}`;
  };

  return (
    <div className="w-full relative bg-[#fbf1f1] min-h-screen overflow-hidden text-left text-xs text-default-white font-poppins font-body-xs">
      {/* Background Images */}
      <img
        className="absolute hidden lg:block top-[-1089.1px] left-[-1174.5px] w-[2827.8px] h-[2818.5px] object-contain"
        alt=""
        src="/learning/o2@2x.png"
      />
      <img
        className="absolute hidden lg:block top-[245px] left-[calc(50%_-_985px)] w-[556.7px] h-[556.7px] object-contain"
        alt=""
        src="/learning/easystem-4-1@2x.png"
      />
      <img
        className="absolute hidden lg:block top-[-78.5px] left-[-470.7px] w-[2863.3px] h-[1567.3px] object-contain"
        alt=""
        src="/learning/o3@2x.png"
      />
      <img
        className="absolute hidden lg:block top-[-120px] left-[calc(50%_+_222px)] w-[562px] h-[562px] object-contain"
        alt=""
        src="/learning/easystem-4-2@2x.png"
      />

      {/* Overlay */}
      <div className="absolute inset-0 bg-background-main" />

      {/* Navigation Icons */}
      <img
        className="absolute top-[26px] right-[10px] lg:right-[50px] w-[30px] h-[30px]"
        alt=""
        src="/learning/group-14.svg"
      />

      {/* Header Section */}
      <div className="absolute top-[64px] left-[10px] mq1300:left-[20px] mq1650:left-[50px] mq1750:left-[75px] lg:left-[98px] w-[calc(100%-20px)] mq1650:w-[95%] lg:w-[1087px] h-[62px] text-3xs">
        <div className="flex flex-wrap items-center justify-start py-[16px] px-[14px] gap-2 mq850:gap-[15px] mq1024:gap-[30px] mq1280:gap-[40px] lg:gap-[68px]">
          <div className="rounded-[8px] bg-[#14b8b9] flex flex-row items-center justify-center py-[8px] px-[10px] gap-[4px]">
            <img className="w-[14px] h-[14px]" alt="User Icon" src="/learning/user.svg" />
            <div className="font-semibold">{currentQuestionIndex + 1}</div>
          </div>

          {/* Timer Box */}
          <div className="shadow-md rounded-[6px] bg-default-white flex flex-row items-center justify-center py-[8px] px-[10px] gap-[2px] text-center text-xs text-[#4f257b]">
            <img className="w-[14px] h-[14px]" alt="Clock Icon" src="/learning/clock.svg" />
            <div className="tracking-[0.04px] leading-[12px] uppercase font-medium">{formatTime(timeLeft)}</div>
          </div>

          {/* Progress Bar */}
          <div className="mq850:flex w-[80%] max-w-full mq1300:max-w-[70%] mq1500:max-w-[70%] mq1650:max-w-[70%] h-[15px] text-right text-base text-[#6a5ae0] font-poppins relative">
            <div className="relative w-full bg-primary-gold bg-opacity-10 rounded-[8px] h-[15px]">
              <div className="absolute top-0 left-0 h-full bg-primary-gold rounded-[8px]" style={{ width: `${((currentQuestionIndex + 1) / questions.length) * 100}%` }} />
              <div className="absolute top-0 right-0 h-full flex items-center justify-center text-xs lg:text-sm text-gray-700 font-medium">
                {currentQuestionIndex + 1} of {questions.length}
              </div>
            </div>
          </div>

          {/* Total Points */}
          <div className="rounded-[8px] bg-primary-gold flex flex-row items-center justify-center py-[8px] px-[10px] gap-[4px] text-[#361757]">
            <img className="w-[14px] h-[14px]" alt="Ticket Icon" src="/learning/ticket.svg" />
            <div className="font-semibold">{totalUserPoints}</div>
          </div>
        </div>
      </div>

      {/* Question and Instructions */}
      <div className="absolute top-[138px] left-[5%] lg:left-[calc(50%_-_351px)] w-[90%] lg:w-[703px] flex flex-col items-start justify-center pt-[0px] px-[0px] pb-[10px] gap-[2px] text-center text-sm text-primary-gold">
        <b className="self-stretch leading-[26px]">{currentQuestion.question}</b>
        <div className="self-stretch text-xs leading-[16px] text-default-white">
          Please choose one of the options below and confirm your answer by clicking the "Confirm" button
        </div>
      </div>

      {/* Options */}
      <div className="absolute top-[50%] left-[5%] lg:left-[calc(50%_-_331px)] w-[90%] lg:w-[661px] flex flex-col items-center justify-start gap-[18px]">
        {currentQuestion.options.map((option, index) => (
          <div
            key={index}
            className={`self-stretch rounded-[10px] ${selectedOption === option ? 'bg-primary-gold' : 'bg-[rgba(20,184,185,0.3)]'} h-[44px] flex flex-row items-center justify-between py-[0px] pl-[20px] pr-[8px] box-border cursor-pointer`}
            onClick={() => handleOptionClick(option)}
          >
            <div className="leading-[16px]">{option}</div>
            {selectedOption === option && (
              <img className="w-[24px] h-[24px]" alt="Checked Icon" src="/learning/checked.svg" />
            )}
          </div>
        ))}
      </div>

      {/* Confirm Button */}
      <div className="absolute bottom-[10%] left-[5%] lg:left-[calc(50%_-_293px)] w-[90%] lg:w-[586px] flex flex-row items-center justify-center py-[0px] pl-[30px] pr-[20px] gap-[29px] text-[#fdc872]">
        <div className="rounded-[10px] flex flex-row items-center justify-center py-[12px] px-[0px] gap-[1px] cursor-pointer">
          <span className="font-semibold">Skip</span>
          <span className="font-black"> (-1</span>
          <img className="w-[12px] h-[12px]" alt="Ticket Icon" src="/learning/ticket1.svg" />
          <span className="font-extrabold">)</span>
        </div>
        <div className="relative text-xl font-medium text-[rgba(255,255,255,0.5)]">
          /
        </div>
        <div className="w-[271px] flex flex-col items-center justify-start text-sm text-default-white font-poppins cursor-pointer" onClick={handleConfirmClick}>
          <div className="self-stretch rounded-[10px] bg-[rgba(20,184,185,0.2)] h-[44px] flex flex-col items-center justify-center">
            <div className="leading-[16px] font-medium">Confirm</div>
          </div>
        </div>
      </div>

      {/* Decorative Image */}
      <div className="absolute top-[224px] left-[calc(50%_-_115px)] rounded-[24px] bg-[#14b8b9] w-[231px] h-[202px]">
        <img className="absolute top-[17px] left-[6px] w-[220px] h-[167px] object-cover" alt="" src="/learning/model@2x.png" />
      </div>
    </div>
  );
};

export default QuizQuestions;
