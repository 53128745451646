import React from 'react';
import PropTypes from 'prop-types';
import Tag from './Tag';

const TopStudent = ({ className = '', student, currentUsername }) => {
  const isCurrentUser = student.name === currentUsername;
  const isMobile = window.innerWidth <= 768;

  const YouIndicator = () => (
    <div className="absolute top-[-20px] left-1/2 transform -translate-x-1/2 text-primary-gold text-sm font-medium">
      (you)
    </div>
  );

  const MobileLayout = () => {
    if (student.position > 3) return null;

    const getPodiumStyles = () => {
      const styles = {
        1: {
          avatar: "w-14 h-14 border-yellow-400",
          container: "absolute left-1/2 top-[-16px] -translate-x-1/2 text-center z-20",
          podium: "mt-4 w-32 h-48 bg-gradient-to-b from-blue-400 to-blue-600",
          points: "text-base",
          name: "text-lg",
          number: "text-5xl",
        },
        2: {
          avatar: "w-12 h-12 border-gray-300",
          container: "absolute left-4 top-[38px] text-center z-10",
          podium: "mt-4 w-28 h-40 bg-gradient-to-b from-blue-500 to-blue-700",
          points: "text-sm",
          name: "text-base",
          number: "text-4xl",
        },
        3: {
          avatar: "w-12 h-12 border-[#CD7F32]",
          container: "absolute right-4 top-[74px] text-center z-10",
          podium: "mt-4 w-28 h-32 bg-gradient-to-b from-blue-600 to-blue-800",
          points: "text-sm",
          name: "text-base",
          number: "text-4xl",
        },
      };
      return styles[student.position];
    };

    const {
      avatar,
      container,
      podium,
      points,
      name,
      number
    } = getPodiumStyles();

    return (
      <div className={container}>
        {/* Avatar Section */}
        <div className="relative inline-block">
          <img
            src={student.avatar}
            alt={student.name}
            className={`rounded-full border-4 ${avatar} mx-auto`}
          />
          {student.position === 1 && (
            <div className="absolute -top-2 -right-2">
              <div className="bg-yellow-400 rounded-lg">
                <svg 
                  xmlns="http://www.w3.org/2000/svg" 
                  viewBox="0 0 24 24" 
                  fill="currentColor" 
                  className="w-4 h-4 text-white"
                >
                  <path d="M12 1l3.22 6.966 7.78.533-5.88 5.137L18.65 22 12 18.1 5.35 22l1.53-8.364L1 8.499l7.78-.533L12 1z"/>
                </svg>
              </div>
            </div>
          )}
          
        </div>

        {/* Name and Points */}
        <h3 className={`font-semibold mt-2 text-white ${name}`}>{student.name}</h3>
        <p className={`text-gray-300 ${points}`}>
          {student.points.toLocaleString()} Points
        </p>

        {/* Podium */}
        <div className={`${podium} rounded-t-lg mx-auto flex flex-col items-center justify-end pb-4`}>
          <span className={`${number} font-bold text-white`}>{student.position}</span>
          <span className="mt-2 px-3 py-1 bg-gray-700/50 rounded text-white text-sm">
            {student.school}
          </span>
        </div>
      </div>
    );
  };

  const DesktopLayout = () => {
      switch (student.position) {
        case 1:
          return (
            <div className="absolute top-[110.2px] left-[calc(50%_-_210.3px)] w-[425px] h-[303.9px]">
              <div className="absolute top-[136.6px] left-[calc(50%_-_44.6px)] w-[85.8px] h-[167.3px]">
                <div className="absolute top-[0px] left-[calc(50%_-_42.9px)] w-[85.8px] h-[167.3px]">
                  <div className="absolute top-[10.2px] left-[calc(50%_-_42.9px)] [background:linear-gradient(180deg,_#055db2,_#14b8b9)] w-[85.8px] h-[157.1px]" />
                  <img
                    className="absolute top-[0px] left-[calc(50%_-_42.9px)] w-[85.8px] h-[10.2px]"
                    alt=""
                    src={student.rectangleSrc}
                  />
                </div>
                <div className="absolute top-[23.5px] left-[calc(50%_-_13.8px)] leading-[140%] font-semibold flex items-center justify-center w-[28.9px] h-[61.5px]">
                  {student.position}
                </div>
              </div>
              <div className="absolute top-[0px] left-[calc(50%_-_212.5px)] w-[425px] h-[288.4px] text-base font-[Rubik]">
                <div className="absolute top-[80.4px] left-[calc(50%_-_212.5px)] w-[425px] h-[208.1px]">
                  <div className="absolute top-[0px] left-[calc(50%_-_51.7px)] leading-[150%] font-medium inline-block w-[101px] h-[24.7px]">
                    {student.name}
                  </div>
                  <Tag
                    text={`${student.points} Points`}
                    tagPosition="absolute"
                    tagBackgroundColor="rgba(20, 184, 185, 0.2)"
                    tagTop="22.4px"
                    tagLeft="calc(50% - 46.3px)"
                    textTextAlign="center"
                  />
                  <Tag
                    text={student.school}
                    tagPosition="absolute"
                    tagBackgroundColor="rgba(240, 154, 25, 0.5)"
                    tagTop="143.1px"
                    tagLeft="calc(50% - 27.5px)"
                    textTextAlign="center"
                  />
                </div>
                <div className="relative">
                  {isCurrentUser && <YouIndicator />}
                  <img
                    className="absolute top-[19.3px] left-[calc(50%_-_25.1px)] w-[43.7px] h-[45px] object-cover"
                    alt={`${student.name}'s Avatar`}
                    src={student.avatar}
                  />
                </div>
                <img
                  className="absolute top-[0px] left-[calc(50%_-_17.3px)] w-[31.2px] h-[32.1px]"
                  alt="Medal Icon"
                  src="/profile/medal.svg"
                />
              </div>
            </div>
          );
        case 2:
          return (
            <div className="absolute top-[150px] left-[calc(50%_-_232.3px)] w-[94px] h-[258.9px]">
              <div className="absolute top-[117.3px] left-[calc(50%_-_40.6px)] w-[81.1px] h-[141.6px]">
                <div className="absolute top-[0px] left-[calc(50%_-_40.55px)] w-[81.1px] h-[141.6px]">
                  <div className="absolute top-[12.6px] left-[calc(50%_-_40.55px)] bg-buttonselect w-[81.1px] h-[129px]" />
                  <img
                    className="absolute top-[0px] left-[calc(50%_-_40.55px)] w-[81.1px] h-[12.6px]"
                    alt=""
                    src={student.rectangleSrc}
                  />
                </div>
                <div className="absolute top-[23.9px] left-[calc(50%_-_11.85px)] leading-[140%] font-semibold flex items-center justify-center w-[23px] h-[65.3px]">
                  {student.position}
                </div>
                <Tag
                  text={student.school}
                  tagPosition="absolute"
                  tagBackgroundColor="rgba(240, 154, 25, 0.5)"
                  tagTop="85px"
                  tagLeft="calc(50% - 24px)"
                  textTextAlign="center"
                />
              </div>
              <div className="absolute top-[0px] left-[calc(50%_-_47px)] w-[94px] h-[117.5px] text-base font-[Rubik]">
                <div className="absolute top-[61.1px] left-[calc(50%_-_47px)] w-[94px] h-[56.5px]">
                  <div className="absolute top-[0px] left-[calc(50%_-_51.7px)] leading-[150%] font-medium inline-block w-[101px] h-[24.7px]">
                    {student.name}
                  </div>
                  <Tag
                    text={`${student.points} Points`}
                    tagPosition="absolute"
                    tagBackgroundColor="rgba(20, 184, 185, 0.2)"
                    tagTop="22.5px"
                    tagLeft="calc(50% - 47px)"
                    textTextAlign="center"
                  />
                </div>
                <div className="relative">
                  {isCurrentUser && <YouIndicator />}
                  <img
                    className="absolute top-[0px] left-[calc(50%_-_23.4px)] w-[43.7px] h-[45px] object-cover"
                    alt={`${student.name}'s Avatar`}
                    src={student.avatar}
                  />
                </div>
              </div>
            </div>
          );
        case 3:
          return (
            <div className="absolute top-[170px] left-[calc(50%_+_138.3px)] w-[94px] h-[233.2px]">
              <div className="absolute top-[117.3px] left-[calc(50%_-_39.2px)] w-[81.1px] h-[115.9px]">
                <div className="absolute top-[0px] left-[calc(50%_-_40.55px)] w-[81.1px] h-[115.9px]">
                  <div className="absolute top-[10.3px] left-[calc(50%_-_40.55px)] bg-buttonselect w-[81.1px] h-[105.6px]" />
                  <img
                    className="absolute top-[0px] left-[calc(50%_-_40.55px)] w-[81.1px] h-[10.3px]"
                    alt=""
                    src={student.rectangleSrc}
                  />
                </div>
                <div className="absolute top-[9.8px] left-[calc(50%_-_12.75px)] leading-[140%] font-semibold flex items-center justify-center w-[24px] h-[53px]">
                  {student.position}
                </div>
                <Tag
                  text={student.school}
                  tagPosition="absolute"
                  tagBackgroundColor="rgba(240, 154, 25, 0.5)"
                  tagTop="60px"
                  tagLeft="calc(50% + -30px)"
                  textTextAlign="center"
                />
              </div>
              <div className="absolute top-[0px] left-[calc(50%_-_47px)] w-[94px] h-[117.5px] text-base font-[Rubik]">
                <div className="relative">
                  {isCurrentUser && <YouIndicator />}
                  <img
                    className="absolute top-[0px] left-[calc(50%_-_22.8px)] w-[43.7px] h-[45px] object-cover"
                    alt={`${student.name}'s Avatar`}
                    src={student.avatar}
                  />
                </div>
                <div className="absolute top-[61.1px] left-[calc(50%_-_47px)] w-[94px] h-[56.5px]">
                  <Tag
                    text={`${student.points} Points`}
                    tagPosition="absolute"
                    tagBackgroundColor="rgba(20, 184, 185, 0.2)"
                    tagTop="22.5px"
                    tagLeft="calc(50% - 41.7px)"
                    textTextAlign="left"
                  />
                  <div className="absolute top-[0px] left-[calc(50%_-_51.7px)] leading-[150%] font-medium inline-block w-[101px] h-[24.7px]">
                    {student.name}
                  </div>
                </div>
              </div>
            </div>
          );
        default:
          return null;
      }
    };

  return (
    <div className={`relative ${className}`}>
      {isMobile ? <MobileLayout /> : <DesktopLayout />}
    </div>
  );
};

TopStudent.propTypes = {
  className: PropTypes.string,
  student: PropTypes.shape({
    position: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    points: PropTypes.number.isRequired,
    avatar: PropTypes.string.isRequired,
    rectangleSrc: PropTypes.string,
    school: PropTypes.string.isRequired,
  }).isRequired,
  currentUsername: PropTypes.string.isRequired,
};

export default TopStudent;